@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&amp;display=swap);

#area-chart,
#bar-chart,
#line-chart,
#pie-chart,
#stacked {
  min-height: 250px;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

.nav-sidebar li ul {
  background: #eee;
}

.nav-sidebar li ul li:last-child {
  border-bottom: 0;
}

body {
  background: #f8f8fc;
  color: #495046;
  font-family: Nunito Sans, sans-serif;
  font-size: 0.81rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
  overflow-x: hidden;
  text-align: left;
}

input[editext="input"] {
  max-width: 500px;
}

.horizontalMenucontainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-left;
  min-height: 100vh;
  position: relative;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.66em;
  margin-top: 0;
}

.overflow-auto {
  overflow: auto;
}

p {
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: 0;
}

abbr[data-original-title],
abbr[title] {
  border-bottom: 0;
  cursor: help;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* address,
dl,
ol,
ul {
  margin-bottom: 1rem;
}

dl,
ol,
ul {
  margin-top: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
} */

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: transparent;
  color: #6c756e;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  color: inherit;
  outline: 0;
  text-decoration: none;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  color: #282d3c;
  display: block;
  margin-bottom: 1rem;
  margin-top: 0;
}

figure {
  margin: 0 0 1rem;
}

img {
  border-style: none;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  caption-side: bottom;
  color: #505662;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  text-align: left;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  /* margin-bottom: 0.5rem; */
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:focus,
textarea:focus {
  outline: 0;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legright {
  color: inherit;
  display: block;
  font-size: 1.5rem;
  line-height: inherit;
  margin-bottom: 0.5rem;
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%;
}

.bcolor {
  background-color: #cf4c3a !important;
}

.bcolor1 {
  background-color: #d6124a !important;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: none;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-family: Nunito Sans, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 0.66em;
}

.h1,
h1 {
  font-size: 2rem;
}

.h2,
h2 {
  font-size: 1.75rem;
}

.h3,
h3 {
  font-size: 1.5rem;
}

.h4,
h4 {
  font-size: 1.25rem;
}

.h5,
h5 {
  font-size: 1rem;
}

.h6,
h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.171875rem;
  font-weight: 300;
}

.display-1 {
  font-size: 9.5rem;
  font-weight: 500;
  line-height: 1.1;
}

.display-2 {
  font-size: 4rem;
}

.display-2,
.display-3 {
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 3.5rem;
}

.display-4 {
  font-size: 3rem;
}

.display-4,
.display-5 {
  font-weight: 300;
  line-height: 1.1;
}

.display-5 {
  font-size: 2.5rem;
}

.display-6 {
  font-size: 2.3rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  border: 0;
  border-top: 1px solid #ebecf1;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.small,
small {
  font-size: 0.75rem;
  font-weight: 400;
}

.mark,
mark {
  background-color: #fcf8e3;
  padding: 0.2em;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.list-unstyled li {
  border-bottom: 1px solid #ebecf1;
}

#lightgallery.list-unstyled li {
  border: 0;
  margin: 10px 0;
}

#sidebar .list-unstyled li {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.03);
}

.nav-sidebar .list-unstyled li:last-child {
  border-bottom: 0;
}

.list-inline {
  list-style: none;
  padding-left: 0;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  font-size: 1.171875rem;
  margin-bottom: 1rem;
}

.blockquote-footer {
  color: #868e96;
  display: block;
  font-size: 80%;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid,
.img-thumbnail {
  height: auto;
  max-width: 100%;
}

.img-thumbnail {
  background-color: #fff;
  border: 1px solid #ebecf1;
  border-radius: 3px;
  padding: 0.25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  line-height: 1;
  margin-bottom: 0.5rem;
}

.figure-caption {
  color: #868e96;
  font-size: 90%;
}

.fs-5 {
  font-size: 5px;
}

.fs-6 {
  font-size: 6px;
}

.fs-7 {
  font-size: 7px;
}

.fs-8 {
  font-size: 8px;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-35 {
  font-size: 35px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-45 {
  font-size: 45px !important;
}

.fs-50 {
  font-size: 50px !important;
}

.fs-55 {
  font-size: 55px !important;
}

.fs-60 {
  font-size: 60px !important;
}

.fs-65 {
  font-size: 65px !important;
}

.fs-70 {
  font-size: 70px;
}

.fs-75 {
  font-size: 75px;
}

.fs-80 {
  font-size: 80px;
}

.fs-85 {
  font-size: 85px;
}

.fs-90 {
  font-size: 90px;
}

.fs-95 {
  font-size: 95px;
}

.fs-100 {
  font-size: 100px;
}

code,
kbd,
pre,
samp {
  font-family: Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

code {
  font-size: 85%;
  word-break: break-word;
}

a > code,
code {
  color: inherit;
}

kbd {
  background-color: #343a40;
  border-radius: 3px;
  color: #fff;
  font-size: 85%;
  padding: 0.2rem 0.4rem;
}

kbd kbd {
  font-size: 100%;
  font-weight: 700;
  padding: 0;
}

pre code {
  color: inherit;
  font-size: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.historyColorUser {
  color: #3e80eb !important;
}

.historyColorHost {
  color: 3e80eb;
}

.container,
.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  #sticky {
    color: #fff;
    font-size: 1.75em;
    padding: 1px;
    text-align: center;
    z-index: 0;
  }

  #sticky,
  #sticky.stick {
    height: 130px !important;
  }

  #sticky.stick {
    z-index: 1;
  }

  .header.top-header {
    z-index: 999 !important;
  }

  .app-content.page-body {
    margin-top: 9.5rem;
  }

  .comb-page .app-content.page-body {
    margin-top: 0;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }

  .hor-content .container,
  .hor-header .container,
  .horizontal-main .container {
    max-width: 85%;
  }
}

.number-font,
.number-font1 {
  font-family: Nunito Sans, sans-serif;
}

#global-loader {
  background: #fff;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999999;
}

#global-loader,
#global-loader img {
  bottom: 0;
  left: 0;
  margin: 0 auto;
  right: 0;
  text-align: center;
}

#global-loader img {
  position: absolute;
  top: 43%;
}

.loader {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  border: 2px solid #fff;
  border-radius: 50%;
  border-top-color: #000;
  display: inline-block;
  height: 30px;
  margin: 1rem 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  width: 30px;
}

.loader.animate {
  opacity: 1;
}

h2.animate {
  opacity: 1;
  transform: translateY(0);
}

.custom-card.blue {
  background: #ebf5fd;
  border: 1px solid #5dacf2;
  border-radius: 8px;
  color: #1687ed;
}

.custom-card.blue .loader {
  border-color: #1687ed #99d0ff #99d0ff;
}

.custom-card.red {
  background: #ffe6e6;
  border: 1px solid #ff8080;
  border-radius: 8px;
  color: red;
}

.custom-card.red .loader {
  border-color: red #ff9696 #ff9696;
}

.custom-card.green {
  background: #cbffce;
  border: 1px solid #00cb0d;
  border-radius: 8px;
  color: #007e08;
}

.custom-card.green .loader {
  border-color: #007e08 #75cc7d #75cc7d;
}

.default-card.default .loader {
  border-color: #e0e3f5;
}

.parent {
  -webkit-animation: spin 200s infinite;
  animation: spin 200s infinite;
  transform-origin: 66px 56px;
}

.child1 {
  -webkit-animation: child1 10s 2s infinite alternate;
  animation: child1 10s 2s infinite alternate;
}

.child2 {
  -webkit-animation: child2 5s infinite alternate;
  animation: child2 5s infinite alternate;
}

.child3 {
  -webkit-animation: child3 10s infinite alternate;
  animation: child3 10s infinite alternate;
  transform-origin: center center;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@-webkit-keyframes child1 {
  0% {
    transform: translate(0);
  }

  to {
    transform: translate(35px, -20px);
  }
}

@keyframes child1 {
  0% {
    transform: translate(0);
  }

  to {
    transform: translate(35px, -20px);
  }
}

@-webkit-keyframes child2 {
  0% {
    transform: translateY(0);
  }

  to {
    transform: translateY(-5px);
  }
}

@keyframes child2 {
  0% {
    transform: translateY(0);
  }

  to {
    transform: translateY(-5px);
  }
}

@-webkit-keyframes child3 {
  0% {
    transform: translateY(-10px) rotate(50deg);
  }

  to {
    transform: translateY(20px) rotate(0deg);
  }
}

@keyframes child3 {
  0% {
    transform: translateY(-10px) rotate(50deg);
  }

  to {
    transform: translateY(20px) rotate(0deg);
  }
}

.index1 {
  height: 270px;
}

.topsales {
  height: 330px;
}

.salesscroll {
  height: 280px;
}

.countryscroll {
  height: 350px;
}

.countrytable td {
  padding-bottom: 10px;
  padding-top: 10px;
}

.countrytable tr {
  border-bottom: 1px solid #ebecf1;
}

.countrytable tr:last-child {
  border-bottom: 0;
}

* html .fileinput-button {
  line-height: 24px;
  margin: 1px -3px 0 0;
}

* + html .fileinput-button {
  margin: 1px 0 0;
  padding: 2px 15px;
}

@media (max-width: 767px) {
  .files .btn span {
    display: none;
  }

  .files .preview * {
    width: 40px;
  }

  .files .name * {
    word-wrap: break-word;
    display: inline-block;
    width: 80px;
  }

  .files .progress {
    width: 20px;
  }

  .files .delete {
    width: 60px;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.list-icons .svg-icon,
.list-icons .svg-icon2 {
  height: 60px;
  width: 60px;
}

.file-manger.list-group-transparent .list-group-item {
  border: 0 !important;
  padding: 0.5rem;
}

.file-manger.list-group-transparent .list-group-item.active {
  background: transparent;
}

.file-manger.list-group-transparent a i {
  color: #5c5c5c;
  display: block;
  font-size: 20px !important;
  height: 25px;
  line-height: 25px;
  text-align: center;
  vertical-align: middle;
  width: 40px;
}

.file-manger.list-group-transparent .list-group-item .svg-icon {
  height: 24px;
  width: 24px;
}

.file-manger-icon {
  position: relative;
}

.file-manger-absolute-icon {
  color: #fff;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 30px;
}

.file-manger-icon i {
  font-size: 70px;
  margin: 0 auto;
  text-align: center;
}

.file-manger-icon img {
  height: 70px;
  width: 70px;
}

.profile-footer {
  background: #f0f0f2;
  margin: 25px -25px -25px;
  padding: 15px;
}

.w-200 {
  width: 200px;
}

.option-dots {
  border-radius: 100%;
  color: #5c678f;
  font-size: 18px;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 10px 12px;
  text-align: center;
  width: 2.5rem;
}

.new.option-dots {
  line-height: 55px;
}

.new-list.option-dots {
  line-height: 55px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.new-list2.option-dots {
  background: 0 0;
  border: 1px solid #ebecf1;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  color: #5c678f;
  font-size: 18px;
  height: 40px;
  line-height: normal;
  margin-right: 8px;
  width: 40px;
}

.new-list2.option-dots:hover {
  background: #f0f0f2;
}

.ms-body .action-header {
  background: #fff;
}

hr.divider:after {
  background: hsla(240, 7%, 95%, 0.1);
  border: 1px solid hsla(270, 7%, 94%, 0.1);
  border-radius: 50%;
  color: #f0f0f2;
  content: "OR";
  display: block;
  font-size: 11px;
  font-weight: 500;
  height: 30px;
  left: 0;
  line-height: 30px;
  margin: 0 auto;
  padding: 0 0.25em;
  position: relative;
  right: 0;
  text-align: center;
  top: -1.5em;
  width: 30px;
  z-index: 9;
}

.error-page {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  height: 100vh;
  position: relative;
}

.error-page:before {
  background: linear-gradient(
    270deg,
    rgba(254, 96, 96, 0.7) 0,
    rgba(227, 9, 113, 0.7)
  );
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
}

.bg-style {
  position: relative;
}

.bg-style:before {
  background: linear-gradient(rgba(68, 84, 195, 0.9), rgba(68, 84, 195, 0.8));
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 100%;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}

.bg-style .svg-icon {
  fill: #fff;
}

.bg-style .header-brand-img {
  left: 55px;
  position: absolute;
  top: 30px;
}

.custom-progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #e8ebf2;
  border: none;
  border-radius: 0;
  height: 10px;
  width: 80%;
}

.custom-progress::-webkit-progress-bar {
  background-color: #e8ebf2;
  border-radius: 0;
}

.custom-progress::-webkit-progress-value {
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
  border-radius: 0;
}

.custom-progress::-moz-progress-bar {
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
}

.custom-progress::-ms-fill {
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
  border-radius: 0;
}

.map svg {
  height: 400px;
}

.coming-footer {
  left: 0;
  margin: 10px auto 0;
  padding-top: 30px;
  right: 0;
  width: 400px;
}

.card-pay .tabs-menu li a {
  background: #f0f0f2;
  border-right: 1px solid #ebecf1;
  padding: 0.5rem 1rem;
}

.card-pay .tabs-menu li a.active {
  border-radius: 2px;
  text-align: center;
}

.card-pay .tabs-menu li:last-child a {
  border-right: 0;
}

.morris-donut-wrapper-demo {
  height: 270px;
}

.shop-title {
  font-size: 15px;
}

.apexcharts-toolbar {
  display: none !important;
}

#SvgjsText1920 {
  font-size: 20px;
  font-weight: 500;
}

.html-code {
  background: #e3e2e5;
  border: 1px solid #ebecf1;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  color: #282d3c;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: -1px;
  margin-left: 20px;
  margin-top: 15px;
  padding: 9px 20px;
  position: relative;
  text-transform: uppercase;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1;
}

.html-code .svg-icon {
  left: 14px;
  position: absolute;
}

.list-style {
  list-style: inherit;
  padding-left: inherit;
}

.list-style ul {
  list-style: inherit;
  padding-left: 30px;
}

.list-style li {
  line-height: 30px;
}

.list-style2 {
  list-style: decimal;
  padding-left: inherit;
}

.list-style2 ul {
  list-style: decimal;
  padding-left: 30px;
}

.list-style2 li {
  line-height: 30px;
}

.list-style3 {
  list-style: circle;
  padding-left: inherit;
}

.list-style3 ul {
  list-style: decimal;
  padding-left: 30px;
}

.list-style3 li {
  line-height: 30px;
}

.list-style4 {
  list-style: lower-alpha;
  padding-left: inherit;
}

.list-style4 ul {
  list-style: decimal;
  padding-left: 30px;
}

.list-style4 li,
.order-list li {
  line-height: 30px;
}

.list-style5 {
  list-style: square;
  padding-left: inherit;
}

.list-style5 ul {
  list-style: decimal;
  padding-left: 30px;
}

.list-style5 li {
  line-height: 30px;
}

.list-style6 {
  list-style: lower-roman;
  padding-left: inherit;
}

.list-style6 ul {
  list-style: decimal;
  padding-left: 30px;
}

.list-style6 li {
  line-height: 30px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .expenses-card .fs-50 {
    font-size: 25px !important;
  }

  .expenses-card .fs-18 {
    font-size: 14px !important;
  }
}

@media (max-width: 567px) {
  .card-pay .tabs-menu li {
    width: 100%;
  }

  .card-pay .tabs-menu li a {
    margin-bottom: 15px;
  }
}

.browse-file.form-control {
  background-color: transparent;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  height: 41px;
}

.input-group-btn .btn-primary {
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-radius: 0;
  padding: 0.52rem 15px;
}

.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value,
.apexcharts-datalabels,
.apexcharts-pie-label {
  cursor: default;
  filter: none;
  font-weight: 500;
  pointer-events: none;
  text-shadow: none;
}

.select2,
.select2:focus .select2 > *,
.select2 > :focus {
  outline-width: 0 !important;
}

@media (min-width: 1440px) {
  .crypto-header-section h3 {
    font-size: 32px;
  }

  .crypto-header-section .w-5 {
    width: 2rem !important;
  }

  .crypto-header-section .h-5 {
    height: 2rem !important;
  }
}

@media (min-width: 1600px) {
  .crypto-header-section h3 {
    font-size: 35px;
  }

  .crypto-header-section .w-5 {
    width: 2.5rem !important;
  }

  .crypto-header-section .h-5 {
    height: 2.5rem !important;
  }
}

@media (max-width: 768px) {
  .page-single .card-group {
    display: block;
  }

  .d-md-down-none {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .item-card .btn-svgs {
    width: 100%;
  }
}

.header.bg-primary
  .form-control.header-search.bg-transparent.border-transparent {
  color: #fff;
}

@media (max-width: 1200px) {
  .sales-img {
    display: block;
    margin: 0 auto;
    text-align: center !important;
    width: 50% !important;
  }
}

.error-text {
  text-shadow: 7px 3px 3px rgba(0, 0, 0, 0.3);
}

.errorbgcolor {
  background-color: #9a8adb;
  /* background-image: url(../images/png/bubbl.png); */
}

.error-text-color {
  color: #9a8adb;
}

.box div {
  /* background-image: url(../images/png/bubble.png); */
  background-position: 50%;
  background-size: cover;
  height: 100px;
  position: absolute;
  width: 100px;
}

.box div:first-child {
  -webkit-animation: animate 10s linear infinite;
  animation: animate 10s linear infinite;
  left: 42%;
  top: 12%;
}

.box div:nth-child(2) {
  -webkit-animation: animate 7s linear infinite;
  animation: animate 7s linear infinite;
  left: 50%;
  top: 70%;
}

.box div:nth-child(3) {
  -webkit-animation: animate 9s linear infinite;
  animation: animate 9s linear infinite;
  left: 6%;
  top: 17%;
}

.box div:nth-child(4) {
  -webkit-animation: animate 5s linear infinite;
  animation: animate 5s linear infinite;
  left: 60%;
  top: 20%;
}

.box div:nth-child(5) {
  -webkit-animation: animate 6s linear infinite;
  animation: animate 6s linear infinite;
  left: 10%;
  top: 67%;
}

.box div:nth-child(6) {
  -webkit-animation: animate 12s linear infinite;
  animation: animate 12s linear infinite;
  left: 70%;
  top: 80%;
}

.box div:nth-child(7) {
  -webkit-animation: animate 5s linear infinite;
  animation: animate 5s linear infinite;
  left: 80%;
  top: 60%;
}

.box div:nth-child(8) {
  -webkit-animation: animate 6s linear infinite;
  animation: animate 6s linear infinite;
  left: 25%;
  top: 32%;
}

.box div:nth-child(9) {
  -webkit-animation: animate 9s linear infinite;
  animation: animate 9s linear infinite;
  left: 25%;
  top: 90%;
}

.box div:nth-child(10) {
  left: 80%;
  top: 20%;
}

.box div:nth-child(10),
.box div:nth-child(11) {
  -webkit-animation: animate 5s linear infinite;
  animation: animate 5s linear infinite;
}

.box div:nth-child(11) {
  left: 50%;
  top: 40%;
}

.box div:nth-child(12) {
  -webkit-animation: animate 6s linear infinite;
  animation: animate 6s linear infinite;
  left: 40%;
  top: 37%;
}

.box div:nth-child(13) {
  -webkit-animation: animate 12s linear infinite;
  animation: animate 12s linear infinite;
  left: 90%;
  top: 80%;
}

.box div:nth-child(14) {
  -webkit-animation: animate 5s linear infinite;
  animation: animate 5s linear infinite;
  left: 750%;
  top: 68%;
}

.box div:nth-child(15) {
  -webkit-animation: animate 6s linear infinite;
  animation: animate 6s linear infinite;
  left: 68%;
  top: 56%;
}

.box div:nth-child(16) {
  -webkit-animation: animate 9s linear infinite;
  animation: animate 9s linear infinite;
  left: 79%;
  top: 90%;
}

.box div:nth-child(17) {
  -webkit-animation: animate 5s linear infinite;
  animation: animate 5s linear infinite;
  left: 80%;
  top: 40%;
}

@-webkit-keyframes animate {
  0% {
    opacity: 1;
    -webkit-transform: scale(0) translateY(0) rotate(0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(1.3) translateY(-90px) rotate(0deg);
  }
}

.lh-1 {
  line-height: 1 !important;
}

.lh-2 {
  line-height: 2 !important;
}

.bootstrap-tagsinput {
  border-radius: 4px;
  color: #555;
  cursor: text;
  display: inline-block;
  line-height: 22px;
  max-width: 100%;
  padding: 4px 6px;
  vertical-align: middle;
}

.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  opacity: 1;
}

.bootstrap-tagsinput.form-control input::placeholder {
  color: #fff;
  opacity: 1;
}

.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #fff;
}

.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #fff;
}

.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}

.bootstrap-tagsinput .tag {
  color: #fff;
  margin-right: 2px;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  cursor: pointer;
  margin-left: 8px;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "\ea00";
  font-family: feather !important;
  padding: 0 2px;
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.bootstrap-tagsinput .tag {
  margin-right: 10px;
}

.bootstrap-tagsinput input {
  background-color: transparent;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  color: #343a40;
  font-family: initial;
  font-size: medium;
  line-height: normal;
  margin: 0;
  outline: none;
  padding: 0 6px;
}

@media (max-width: 767px) {
  .app-header .responsive-navbar.navbar .navbar-collapse {
    background: #fff;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #f0f0ff;
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    margin-top: 0;
    position: fixed;
    right: 0;
    top: 73px;
    width: 100%;
    z-index: 999;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

ul li ul:hover {
  display: block;
  opacity: 1;
  visibility: visible;
}

.example .pagination {
  margin-bottom: 0;
}

.pagination {
  border-radius: 3px;
  display: flex;
  list-style: none;
  padding-left: 0;
}

.page-link {
  background-color: #fff;
  border: 0 !important;
  color: #6c756e;
  display: block;
  font-size: 14px;
  line-height: 1.25;
  margin-left: -1px;
  padding: 0.5rem 0.75rem;
  position: relative;
}

.page-link:hover {
  text-decoration: none;
}

.page-link:focus,
.page-link:hover {
  background-color: transparent;
  box-shadow: none;
  z-index: 2;
}

.page-link:focus {
  color: #664dc9;
  outline: 0;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
}

.page-item.active .page-link {
  border-radius: 4px;
}

.page-item:first-child .page-link {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.page-item.active .page-link {
  color: #fff !important;
  z-index: 1;
}

.page-item.active .page-link:hover {
  color: #fff !important;
}

.page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6;
  color: #ced4da;
  cursor: auto;
  pointer-events: none;
}

.pagination-lg .page-link {
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0.75rem 1.5rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.pagination-lg .page-item:last-child .page-link {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.pagination-sm .page-link {
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.pagination-sm .page-item:last-child .page-link {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-group .panel {
  border: 1px solid #e6ebf1;
  border-radius: 4px;
  box-shadow: none;
}

.panel-default > .panel-heading {
  background-color: #f0f0f2;
  border-radius: 0;
  padding: 0;
}

.panel-title {
  font-size: 14px;
  margin-bottom: 0;
}

.panel-title > a {
  display: block;
  padding: 15px;
  text-decoration: none;
}

.more-less {
  color: #212121;
  float: right;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: 0 solid #e6ebf1;
  font-size: 14px;
  line-height: 25px;
}

.panel > .list-group {
  margin-bottom: 0;
}

.panel > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}

.panel-default .list-group-item.active {
  background-color: #f0f0f2;
  border-color: #f0f0f2;
  color: #000;
}

.panel-primary .list-group-item.active:hover {
  background-color: #664dc9;
  border-color: #664dc9;
  color: #fff !important;
}

.panel-success .list-group-item.active {
  background-color: #38cb89;
  border-color: #38cb89;
  color: #fff;
}

.panel-success .list-group-item.active:hover {
  background-color: #38cb89;
  border-color: #38cb89;
  color: #fff !important;
}

.panel-info .list-group-item.active {
  background-color: #3e80eb;
  border-color: #3e80eb;
  color: #fff;
}

.panel-info .list-group-item.active:hover {
  background-color: #3e80eb;
  border-color: #3e80eb;
  color: #fff !important;
}

.panel-warning .list-group-item.active {
  background-color: #ffab00;
  border-color: #ffab00;
  color: #fff;
}

.panel-warning .list-group-item.active:hover {
  background-color: #ffab00;
  border-color: #ffab00;
  color: #fff !important;
}

.panel-danger .list-group-item.active {
  background-color: #ef4b4b;
  border-color: #ef4b4b;
  color: #fff;
}

.panel-danger .list-group-item.active:hover {
  background-color: #ef4b4b;
  border-color: #ef4b4b;
  color: #fff !important;
}

.panel-default .list-group-item.active:focus,
.panel-default .list-group-item.active:hover {
  background-color: #f0f0f2;
  border-color: #f0f0f2;
  color: #000;
}

.wrap {
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

a:active,
a:focus,
a:hover {
  outline: 0;
  text-decoration: none;
}

.panel1 {
  background: none;
  border: solid #fff;
  border-width: 0 0 1px;
  box-shadow: none;
}

.panel1:last-child {
  border-bottom: none;
}

.panel-group1 > .panel1:first-child .panel-heading1 {
  border-radius: 4px 4px 0 0;
}

.panel-group1 .panel1 {
  border-radius: 0;
}

.panel-title1 {
  font-size: 14px;
  margin-bottom: 0;
}

.panel-group1 .panel1 + .panel1 {
  margin-top: 0;
}

.panel-heading1 {
  color: #fff;
  padding: 0;
}

.panel-group1 .panel-body {
  border: 1px solid #e6ebf1;
}

.panel-title1 a {
  border-radius: 4px;
  color: #fff;
  display: block;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 20px;
  position: relative;
}

.panel-title1 [aria-expanded="true"] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#accordion1 .panel-title1 a:hover {
  color: #fff !important;
}

.panel-body1 {
  background: #fff;
  padding: 10px;
}

.panel1:last-child .panel-body1 {
  border-radius: 0 0 4px 4px;
}

.panel1:last-child .panel-heading1 {
  border-radius: 0 0 4px 4px;
  transition: border-radius 0.3s linear 0.2s;
}

.panel1:last-child .panel-heading1.active {
  border-radius: 0;
  transition: border-radius 0s linear;
}

.panel-title a.accordion-toggle:before {
  color: #fff;
  content: "\f068";
  float: right;
  font-family: FontAwesome;
  padding: 0 10px 0 0;
}

.panel-title a.accordion-toggle.collapsed:before {
  color: #fff;
  content: "\f067";
  float: right;
  font-family: FontAwesome;
  padding: 0 10px 0 0;
}

.panel-heading1 a.collapsed:before {
  content: "\e9af";
}

.panel-heading1 a.collapsed:before,
.panel-heading1 a:before {
  font-family: feather !important;
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 8px;
  transform: scale(1);
  transition: all 0.5s;
}

.panel-heading1 a:before {
  content: "\e994";
}

.panel-heading1.active a:before {
  content: " ";
  transform: scale(0);
  transition: all 0.5s;
}

.demo-accordion p:last-child,
.panel-group1 p:last-child {
  margin-bottom: 0;
}

#bs-collapse .panel-heading1 a:after {
  content: "\f01a";
  font-family: FontAwesome;
  font-size: 24px;
  position: absolute;
  right: 5px;
  top: 10px;
  transform: scale(0);
  transition: all 0.5s;
}

#bs-collapse .panel-heading1.active a:after {
  content: "\f01a";
  transform: scale(1);
  transition: all 0.5s;
}

#accordion .panel-heading1 a:before {
  content: "\f01a";
  font-family: FontAwesome;
  font-size: 24px;
  position: absolute;
  right: 5px;
  top: 10px;
  transform: rotate(180deg);
  transition: all 0.5s;
}

#accordion .panel-heading1.active a:before {
  transform: rotate(0deg);
  transition: all 0.5s;
}

.acc-header a {
  background-color: #f0f0f2;
  border: 1px solid #e6ebf1;
  border-radius: 4px;
  display: block;
  padding: 15px;
}

.acc-header [aria-expanded="true"] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.acc-body {
  border: 1px solid #e6ebf1;
  padding: 15px;
}

.alert {
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 0.9375rem;
  margin-bottom: 1rem;
  padding: 0.75rem 1.25rem;
  position: relative;
}

.alert:first-child {
  margin-bottom: 1rem !important;
}

.alert:last-child {
  margin-bottom: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
}

.alert-link:hover,
.alert-link a:hover {
  text-decoration: underline;
}

.alert-dismissible {
  padding-right: 3.90625rem;
}

hr.message-inner-separator {
  background-image: -o-linear-gradient(
    left,
    transparent,
    rgba(0, 0, 0, 0.15),
    transparent
  );
  border: 0;
  clear: both;
  height: 1px;
  margin-bottom: 13px;
  margin-top: 10px;
  width: 100%;
}

.alert-dismissible .btn-close {
  color: inherit;
  padding: 0.75rem 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
}

.alert-success {
  background-color: #38cb89;
  border-color: #38cb89;
  color: #fff;
}

.alert-success hr {
  border-top-color: #c5e7a4;
}

.alert-success .alert-link {
  color: #172e00;
}

.alert-info {
  background-color: #3e80eb;
  border-color: #3e80eb;
  color: #fff;
}

.alert-info hr {
  border-top-color: #b3dcf9;
}

.alert-info .alert-link {
  color: #193c56;
}

.alert-warning {
  background-color: #f3a403;
  border-color: #f3a403;
  color: #fff;
}

.alert-warning hr {
  border-top-color: #fae8a4;
}

.alert-warning .alert-link {
  color: #4d3f05;
}

.alert-danger {
  background-color: #ef4b4b;
  border-color: #ef4b4b;
  color: #fff;
}

.alert-danger hr {
  border-top-color: #ecacab;
}

.alert-danger .alert-link {
  color: #3f0a09;
}

.alert-light {
  background-color: #f2f2f3;
  border-color: #f2f2f3;
  color: #818182;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  background-color: #d6d8d9;
  border-color: #c6c8ca;
  color: #1b1e21;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.alert-icon {
  padding-left: 3rem;
}

.alert-icon > i {
  color: inherit !important;
  font-size: 1rem;
  left: 1rem;
  position: absolute;
  top: 1rem;
}

.alert-avatar {
  padding-left: 3.75rem;
}

.alert-avatar .avatar {
  left: 0.75rem;
  position: absolute;
  top: 0.5rem;
}

.btn-close {
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.3s;
}

.alert-light-success {
  background-color: #cbf6e2;
  border-color: #cbf6e2;
  color: #38cb89;
}

.alert-light-danger {
  background-color: #fac8c8;
  border-color: #fac8c8;
  color: #ef4b4b;
}

.alert-light-warning {
  background-color: #fee8bb;
  border-color: #fee8bb;
  color: #ffab00;
}

.alert-light-info {
  background-color: #ccd6f8;
  border-color: #ccd6f8;
  color: #3e80eb;
}

.country-selector .avatar {
  height: 1.2rem;
  width: 1.4rem;
}

.avatar {
  color: #fff;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 600;
  height: 2rem;
  line-height: 2rem;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: bottom;
  width: 2rem;
}

.noborder {
  border-radius: 0;
}

.brround {
  border-radius: 50%;
}

.bradius {
  border-radius: 25%;
}

.avatar i {
  font-size: 125%;
  vertical-align: middle;
}

.avatar-status {
  background: #868e96;
  border: 2px solid #fff;
  border-radius: 50%;
  bottom: -2px;
  height: 0.75rem;
  position: absolute;
  right: -2px;
  width: 0.75rem;
}

.avatar-sm {
  font-size: 0.75rem;
  height: 1.5rem;
  line-height: 1.5rem;
  width: 1.5rem;
}

.avatar-md {
  font-size: 1rem;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 2.5rem;
}

.avatar-lg {
  font-size: 1.25rem;
  height: 3rem;
  line-height: 3rem;
  width: 3rem;
}

.avatar-xl {
  font-size: 1.75rem;
  height: 4rem !important;
  line-height: 3.8rem;
  width: 4rem !important;
}

.avatar-xxl {
  font-size: 2rem;
  height: 5rem;
  line-height: 5rem;
  width: 5rem;
}

.avatar-placeholder {
  background: #ced4da
    url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="134" height="134" viewBox="0 0 134 134"><path fill="$gray" d="M65.92 66.34h2.16c14.802.42 30.928 6.062 29.283 20.35l-1.618 13.32c-.844 6.815-5.208 7.828-13.972 7.866H52.23c-8.764-.038-13.13-1.05-13.973-7.865l-1.62-13.32C34.994 72.4 51.12 66.76 65.92 66.34zM49.432 43.934c0-9.82 7.99-17.81 17.807-17.81 9.82 0 17.81 7.99 17.81 17.81 0 9.82-7.99 17.807-17.81 17.807-9.82 0-17.808-7.987-17.808-17.806z"/></svg>')
    no-repeat 50%/80%;
}

.avatar-list {
  font-size: 0;
  margin: 0 0 -0.5rem;
  padding: 0;
}

.avatar-list .avatar {
  margin-bottom: 0.5rem;
}

.avatar-list .avatar:not(:last-child) {
  margin-right: 0.5rem;
}

.avatar-list-stacked .avatar {
  box-shadow: 0 0 0 2px #fff;
  margin-right: -0.8em !important;
}

.avatar-blue {
  background-color: #083dc1;
  color: #467fcf;
}

.avatar-indigo {
  background-color: #d1d5f0;
  color: #6574cd;
}

.avatar-purple {
  background-color: #e4cff9;
  color: #aa4cf2;
}

.avatar-pink {
  background-color: #fcd3e1;
  color: #ec82ef;
}

.avatar-red {
  background-color: #f0bcbc;
  color: #dc0441;
}

.avatar-orange {
  background-color: #fee0c7;
  color: #fc7303;
}

.avatar-yellow {
  background-color: #fbedb7;
  color: #ecb403;
}

.avatar-green {
  background-color: #cfeab3;
  color: #2dce89;
}

.avatar-teal {
  background-color: #bfefea;
  color: #2bcbba;
}

.avatar-cyan {
  background-color: #b9e3ea;
  color: #116e7c;
}

.avatar-white {
  background-color: #fff;
  color: #fff;
}

.avatar-gray {
  background-color: #dbdde0;
  color: #868e96;
}

.avatar-gray-dark {
  background-color: #c2c4c6;
  color: #343a40;
}

.avatar-azure {
  background-color: #c7e6fb;
  color: #45aaf2;
}

.avatar-lime {
  background-color: #d7f2c2;
  color: #7bd235;
}

.product-price {
  font-size: 1rem;
}

.product-price strong {
  font-size: 1.5rem;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge {
  border-radius: 4px;
  display: inline-block;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 1.4;
  padding: 2px 6px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.rounded-pill {
  border-radius: 10rem;
  padding-left: 1.2em;
  padding-right: 1.2em;
}

.rounded-nill {
  border-radius: 10rem;
  padding-left: 1.2em;
}

.badgetext {
  float: right;
}

.badge-default {
  background: #54575f;
  color: #fff;
}

.shape {
  border-color: hsla(0, 0%, 100%, 0) #d9534f hsla(0, 0%, 100%, 0)
    hsla(0, 0%, 100%, 0);
  border-style: solid;
  border-width: 0 70px 40px 0;
  float: right;
  height: 0;
  transform: rotate(1turn);
  width: 0;
}

.offer {
  background: #fff;
  border: 1px solid #ddd;
  margin: 15px 0;
  overflow: hidden;
}

.offer-danger {
  border-color: #dc0441;
}

.offer-danger .shape {
  border-color: transparent #dc0441 transparent transparent;
}

.offer-success {
  border-color: #2dce89;
}

.bg-success-light {
  background-color: rgba(29, 171, 45, 0.15) !important;
  color: #36b37e;
}

.bg-warning-light {
  background-color: rgba(255, 171, 0, 0.15) !important;
  color: #ffab00;
}

.bg-danger-light {
  background-color: rgba(250, 92, 124, 0.15);
  color: #f94859;
}

.bg-info-light {
  background-color: rgba(3, 85, 208, 0.15);
  color: #0a7ffb;
}

.bg-pink-light {
  background-color: rgba(236, 130, 239, 0.15);
  color: #ec82ef;
}

.bg-cyan-light {
  background-color: rgba(17, 110, 124, 0.15);
  color: #116e7c;
}

.bg-purple-light {
  background-color: rgba(170, 76, 242, 0.15);
  color: #aa4cf2;
}

.offer-success .shape {
  border-color: transparent #2dce89 transparent transparent;
}

.offer-default {
  border-color: #6b6f80;
}

.offer-default .shape {
  border-color: transparent #6b6f80 transparent transparent;
}

.offer-info {
  border-color: #45aaf2;
}

.offer-info .shape {
  border-color: transparent #45aaf2 transparent transparent;
}

.offer-warning {
  border-color: #ecb403;
}

.offer-warning .shape {
  border-color: transparent #ecb403 transparent transparent;
}

.shape-text {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  position: relative;
  right: -47px;
  top: 0;
  transform: rotate(30deg);
  white-space: nowrap;
}

.offer-content {
  padding: 20px;
}

.bg-gradient-danger,
.bg-gradient-success,
.bg-gradient-warning {
  color: #fff;
}

.bg-gradient-info {
  background-image: linear-gradient(270deg, #58ebbb 0, #0fd796);
  color: #fff;
}

.breadcrumb {
  background: #f0f0f2;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  list-style: none;
  margin-bottom: 0;
}

.breadcrumb-item + .breadcrumb-item:before {
  color: #cfd1e1;
  content: "/";
  display: inline-block;
  opacity: 0.5;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: underline;
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c756e;
}

.breadcrumb1 {
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
}

.breadcrumb-item1 a:hover {
  color: #123787;
  text-decoration: underline;
}

.breadcrumb-item1 + .breadcrumb-item1:before {
  content: "/";
  display: inline-block;
  opacity: 0.5;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.breadcrumb-item1 + .breadcrumb-item1:hover:before {
  text-decoration: underline;
  text-decoration: none;
}

.breadcrumb-item1.active {
  color: #6c756e;
}

.breadcrumb-arrow {
  background-color: #f0f0f2;
  height: 36px;
  line-height: 36px;
  list-style: none;
  padding: 0;
}

.breadcrumb-arrow li {
  display: inline-block;
  vertical-align: top;
}

.breadcrumb-arrow li:first-child a {
  border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  padding: 0 10px;
}

.breadcrumb-arrow li a,
.breadcrumb-arrow li span {
  display: inline-block;
  vertical-align: top;
}

.breadcrumb-arrow li:not(:first-child) {
  margin-left: -5px;
}

.breadcrumb-arrow li + li:before {
  content: "";
  padding: 0;
}

.breadcrumb-arrow li span {
  color: #6c756e;
  padding: 0 10px;
}

.breadcrumb-arrow li:not(:first-child) span,
.breadcrumb-arrow li a {
  height: 36px;
  line-height: 36px;
  padding: 0 10px 0 25px;
}

.breadcrumb-arrow li a {
  color: #fff;
  position: relative;
  text-decoration: none;
}

.breadcrumb-arrow li a:after,
.breadcrumb-arrow li a:before {
  border-bottom: 18px solid transparent;
  border-top: 18px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  top: -1px;
  width: 0;
}

.breadcrumb-arrow li a:before {
  border-start-style: solid;
  border-start-width: 11px;
  right: -11px;
  z-index: 3;
}

.breadcrumb-arrow li a:after {
  border-left: 11px solid #6e79ca;
  right: -12px;
  z-index: 2;
}

@media (max-width: 375px) {
  .breadcrumb {
    font-size: 12px;
  }

  .page-title {
    font-size: 20px !important;
  }
}

.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item:before,
.breadcrumb-style3 .breadcrumb-item + .breadcrumb-item:before {
  font-family: feather;
  line-height: 20px;
  text-align: center;
  width: 25px;
}

.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item:before {
  content: "\e92f";
}

.breadcrumb-style3 .breadcrumb-item + .breadcrumb-item:before {
  content: "\e9d3";
}

.breadcrumb-style4 .breadcrumb-icon {
  margin-left: 4px;
  margin-top: 10px;
}

.breadcrumb-style4 .breadcrumb-item {
  margin-top: 3px;
}

.breadcrumb-style4 .breadcrumb-item .svg-icon {
  width: 20px;
}

.breadcrumb-style4 .breadcrumb-item:first-child {
  margin-top: 0;
}

.svg-breadcrumb.breadcrumb-style4 .breadcrumb-item + .breadcrumb-item:before {
  margin-top: 8px;
  padding: 0 12px;
}

.btn {
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-block;
  font-size: 0.81rem;
  font-weight: 400;
  line-height: 1.4;
  padding: 0.375rem 1em;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.btn:hover {
  text-decoration: none;
}

.btn:focus {
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  box-shadow: 0 0 0 2px hsla(204, 4%, 77%, 0.25);
  outline: 0;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  background-image: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn,
  .collapsing,
  .fade {
    transition: none;
  }
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-success {
  background-color: #38cb89;
  border-color: #38cb89;
  box-shadow: 0 0 10px -5px rgba(56, 203, 137, 0.5);
  color: #fff !important;
}

.btn-success:hover {
  color: #fff !important;
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
  background-color: #42c48a !important;
  border-color: #42c48a !important;
}

.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 2px rgba(56, 203, 137, 0.1);
}

.btn-success.disabled,
.btn-success:disabled,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background-color: #42c48a;
  border-color: #42c48a;
  color: #fff;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(56, 203, 137, 0.1);
}

.btn-info {
  box-shadow: 0 0 10px -5px rgba(91, 127, 255, 0.1);
}

.btn-info,
.btn-info:hover {
  background-color: #3e80eb;
  border-color: #3e80eb;
  color: #fff !important;
}

.btn-info.focus,
.btn-info:focus {
  background-color: #3e80eb;
  border-color: #3e80eb;
  box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
}

.btn-info.disabled,
.btn-info:disabled,
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  background-color: #3e80eb;
  border-color: #3e80eb;
  color: #fff;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
}

.btn-warning {
  background-color: #ffab00;
  border-color: #ffab00;
  box-shadow: 0 0 10px -5px rgba(255, 171, 0, 0.5);
  color: #fff !important;
}

.btn-warning:hover {
  background-color: #feb521;
  border-color: #feb521;
  color: #fff !important;
}

.btn-warning.focus,
.btn-warning:focus {
  background-color: #feb521;
  border-color: #feb521;
  box-shadow: 0 0 0 2px rgba(255, 171, 0, 0.1);
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #feb521;
  border-color: #feb521;
  color: #fff;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  background-color: #f3a403;
  border-color: #feb521;
  color: #fff;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(255, 171, 0, 0.1);
}

.btn-danger {
  box-shadow: 0 0 10px -5px rgba(239, 75, 75, 0.44);
}

.btn-danger,
.btn-danger:hover {
  background-color: #ef4b4b;
  border-color: #ef4b4b;
  color: #fff !important;
}

.btn-danger.focus,
.btn-danger:focus {
  background-color: #ef4b4b;
  border-color: #ef4b4b;
  box-shadow: 0 0 0 2px rgba(239, 75, 75, 0.1);
}

.btn-danger.disabled,
.btn-danger:disabled,
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  background-color: #ef4b4b;
  border-color: #ef4b4b;
  color: #fff;
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(239, 75, 75, 0.1);
}

.btn-light {
  background-color: #f0f0f2;
  border-color: #f0f0f2;
  box-shadow: 0 0 10px -5px rgba(244, 242, 246, 0.2);
  color: #495057;
}

.btn-light:hover {
  background-color: #efedf1;
  border-color: #efedf1;
  color: #495057;
}

.btn-light.focus,
.btn-light:focus {
  background-color: #efedf1;
  border-color: #efedf1;
  box-shadow: 0 0 0 2px rgba(244, 242, 246, 0.1);
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: #f0f0f2;
  border-color: #f0f0f2;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  background-color: #f0f0f2;
  border-color: #f0f0f2;
  color: #495057;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(244, 242, 246, 0.1);
}

.btn-dark {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}

.btn-dark:hover {
  background-color: #23272b;
  border-color: #1d2124;
  color: #fff;
}

.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  background-color: #1d2124;
  border-color: #171a1d;
  color: #fff;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(217, 221, 231, 0.1);
}

.btn-outline-default {
  background-color: transparent;
  background-image: none;
  border: 1px solid #f0f0f2;
  color: #6c756e;
}

.btn-outline-default:hover {
  background: rgba(67, 87, 133, 0.2);
  border: 1px solid #f0f0f2;
  color: #6c756e !important;
}

.btn-outline-default.focus,
.btn-outline-default:focus {
  box-shadow: 0 0 0 2px rgba(217, 221, 231, 0.1);
}

.btn-outline-default.disabled,
.btn-outline-default:disabled {
  background-color: transparent;
  color: #435785;
}

.btn-outline-default:not(:disabled):not(.disabled).active,
.btn-outline-default:not(:disabled):not(.disabled):active,
.show > .btn-outline-default.dropdown-toggle {
  background: rgba(67, 87, 133, 0.2);
  border: 1px solid #f0f0f2;
  color: #6c756e !important;
}

.btn-outline-default:not(:disabled):not(.disabled).active:focus,
.btn-outline-default:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(247, 45, 102, 0.1);
}

.btn-outline-success {
  background-color: transparent;
  background-image: none;
  border-color: #38cb89;
  box-shadow: 0 0 10px -5px rgba(56, 203, 137, 0.5);
  color: #38cb89;
}

.btn-outline-success:hover {
  background-color: #38cb89;
  border-color: #38cb89;
  color: #fff !important;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  background-color: transparent;
  color: #38cb89;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  background-color: #38cb89;
  border-color: #38cb89;
  color: #fff !important;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
}

.btn-outline-info {
  background-color: transparent;
  background-image: none;
  border-color: #3e80eb;
  box-shadow: 0 0 10px -5px rgba(91, 127, 255, 0.5);
  color: #3e80eb;
}

.btn-outline-info:hover {
  background-color: #3e80eb;
  border-color: #3e80eb;
  color: #fff !important;
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  color: #3e80eb;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  background-color: #3e80eb;
  border-color: #3e80eb;
  color: #fff;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
}

.btn-outline-warning {
  background-color: transparent;
  background-image: none;
  border-color: #ffab00;
  box-shadow: 0 0 10px -5px rgba(255, 171, 0, 0.5);
  color: #ffab00;
}

.btn-outline-warning:hover {
  background-color: #ffab00;
  border-color: #ffab00;
  color: #fff !important;
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  background-color: transparent;
  color: #ffab00;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  background-color: #ffab00;
  border-color: #ffab00;
  color: #fff;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
}

.btn-outline-danger {
  background-color: transparent;
  background-image: none;
  border-color: #dc0441;
  box-shadow: 0 0 10px -5px rgba(220, 4, 65, 0.5);
  color: #dc0441;
}

.btn-outline-danger:hover {
  background-color: #dc0441;
  border-color: #dc0441;
  color: #fff !important;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  background-color: transparent;
  color: #dc0441;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  background-color: #dc0441;
  border-color: #dc0441;
  color: #fff;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
}

.btn-outline-light {
  background-color: #fff;
  background-image: none;
  border-color: #747b83;
  box-shadow: 0 0 10px -5px #e8ebf2;
  color: #495057;
}

.btn-outline-light:hover {
  background-color: #f0f0f2;
  border-color: #f0f0f2;
  color: #495057;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  background-color: transparent;
  color: #495057;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  background-color: #f0f0f2;
  border-color: #f0f0f2;
  color: #495057;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}

.btn-outline-dark {
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
  box-shadow: 0 0 10px -5px rgba(244, 242, 246, 0.5);
  color: #343a40;
}

.btn-outline-dark:hover {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  background-color: transparent;
  color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}

.btn-link {
  background-color: transparent;
  font-weight: 400;
}

.btn-link:hover {
  background-color: transparent;
}

.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
  border-color: transparent;
  text-decoration: underline;
}

.btn-link.focus,
.btn-link:focus {
  box-shadow: none;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #868e96;
  pointer-events: none;
}

.btn-group-lg > .btn,
.btn-lg {
  border-radius: 3px;
  font-size: 1.125rem;
  font-size: 16px;
  line-height: 1.625;
  min-width: 2.75rem;
  padding: 0.7rem 2rem;
}

.btn-group-sm > .btn,
.btn-sm {
  border-radius: 0.21rem;
  font-size: 0.711rem;
  min-width: 1.625rem;
  padding: 0.25rem 0.5rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  background: #fff;
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height 0.35s ease;
}

.btn-icon {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
}

.btn-icon2 {
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  width: 40px;
}

.btn-pill {
  border-radius: 10rem;
}

.btn-square {
  border-radius: 2px;
}

.btn-facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-facebook:hover {
  background-color: #30497c;
  border-color: #2d4373;
  color: #fff;
}

.btn-facebook.focus,
.btn-facebook:focus {
  box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
}

.btn-facebook.disabled,
.btn-facebook:disabled {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-facebook:not(:disabled):not(.disabled).active,
.btn-facebook:not(:disabled):not(.disabled):active,
.show > .btn-facebook.dropdown-toggle {
  background-color: #2d4373;
  border-color: #293e6a;
  color: #fff;
}

.btn-facebook:not(:disabled):not(.disabled).active:focus,
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
}

.btn-twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-twitter:hover {
  background-color: #0d8ddc;
  border-color: #0c85d0;
  color: #fff;
}

.btn-twitter.focus,
.btn-twitter:focus {
  box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
}

.btn-twitter.disabled,
.btn-twitter:disabled {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-twitter:not(:disabled):not(.disabled).active,
.btn-twitter:not(:disabled):not(.disabled):active,
.show > .btn-twitter.dropdown-toggle {
  background-color: #0c85d0;
  border-color: #0b7ec4;
  color: #fff;
}

.btn-twitter:not(:disabled):not(.disabled).active:focus,
.btn-twitter:not(:disabled):not(.disabled):active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
}

.btn-google {
  background-color: #dc4e41;
  border-color: #dc4e41;
  color: #fff;
}

.btn-google:hover {
  background-color: #d03526;
  border-color: #c63224;
  color: #fff;
}

.btn-google.focus,
.btn-google:focus {
  box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
}

.btn-google.disabled,
.btn-google:disabled {
  background-color: #dc4e41;
  border-color: #dc4e41;
  color: #fff;
}

.btn-google:not(:disabled):not(.disabled).active,
.btn-google:not(:disabled):not(.disabled):active,
.show > .btn-google.dropdown-toggle {
  background-color: #c63224;
  border-color: #bb2f22;
  color: #fff;
}

.btn-google:not(:disabled):not(.disabled).active:focus,
.btn-google:not(:disabled):not(.disabled):active:focus,
.show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
}

.btn-youtube {
  background-color: red;
  border-color: red;
  color: #fff;
}

.btn-youtube:hover {
  background-color: #d90000;
  border-color: #c00;
  color: #fff;
}

.btn-youtube.focus,
.btn-youtube:focus {
  box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
}

.btn-youtube.disabled,
.btn-youtube:disabled {
  background-color: red;
  border-color: red;
  color: #fff;
}

.btn-youtube:not(:disabled):not(.disabled).active,
.btn-youtube:not(:disabled):not(.disabled):active,
.show > .btn-youtube.dropdown-toggle {
  background-color: #c00;
  border-color: #bf0000;
  color: #fff;
}

.btn-youtube:not(:disabled):not(.disabled).active:focus,
.btn-youtube:not(:disabled):not(.disabled):active:focus,
.show > .btn-youtube.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
}

.btn-vimeo {
  background-color: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff;
}

.btn-vimeo:hover {
  background-color: #139ecb;
  border-color: #1295bf;
  color: #fff;
}

.btn-vimeo.focus,
.btn-vimeo:focus {
  box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
}

.btn-vimeo.disabled,
.btn-vimeo:disabled {
  background-color: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff;
}

.btn-vimeo:not(:disabled):not(.disabled).active,
.btn-vimeo:not(:disabled):not(.disabled):active,
.show > .btn-vimeo.dropdown-toggle {
  background-color: #1295bf;
  border-color: #108cb4;
  color: #fff;
}

.btn-vimeo:not(:disabled):not(.disabled).active:focus,
.btn-vimeo:not(:disabled):not(.disabled):active:focus,
.show > .btn-vimeo.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
}

.btn-dribbble {
  background-color: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}

.btn-dribbble:hover {
  background-color: #e62a72;
  border-color: #e51e6b;
  color: #fff;
}

.btn-dribbble.focus,
.btn-dribbble:focus {
  box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
}

.btn-dribbble.disabled,
.btn-dribbble:disabled {
  background-color: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}

.btn-dribbble:not(:disabled):not(.disabled).active,
.btn-dribbble:not(:disabled):not(.disabled):active,
.show > .btn-dribbble.dropdown-toggle {
  background-color: #e51e6b;
  border-color: #dc1a65;
  color: #fff;
}

.btn-dribbble:not(:disabled):not(.disabled).active:focus,
.btn-dribbble:not(:disabled):not(.disabled):active:focus,
.show > .btn-dribbble.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
}

.btn-github {
  background-color: #181717;
  border-color: #181717;
  color: #fff;
}

.btn-github:hover {
  background-color: #040404;
  border-color: #000;
  color: #fff;
}

.btn-github.focus,
.btn-github:focus {
  box-shadow: 0 0 0 2px hsla(0, 2%, 9%, 0.5);
}

.btn-github.disabled,
.btn-github:disabled {
  background-color: #181717;
  border-color: #181717;
  color: #fff;
}

.btn-github:not(:disabled):not(.disabled).active,
.btn-github:not(:disabled):not(.disabled):active,
.show > .btn-github.dropdown-toggle {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.btn-github:not(:disabled):not(.disabled).active:focus,
.btn-github:not(:disabled):not(.disabled):active:focus,
.show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px hsla(0, 2%, 9%, 0.5);
}

.btn-instagram {
  background-color: #e4405f;
  border-color: #e4405f;
  color: #fff;
}

.btn-instagram:hover {
  background-color: #de1f44;
  border-color: #d31e40;
  color: #fff;
}

.btn-instagram.focus,
.btn-instagram:focus {
  box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
}

.btn-instagram.disabled,
.btn-instagram:disabled {
  background-color: #e4405f;
  border-color: #e4405f;
  color: #fff;
}

.btn-instagram:not(:disabled):not(.disabled).active,
.btn-instagram:not(:disabled):not(.disabled):active,
.show > .btn-instagram.dropdown-toggle {
  background-color: #d31e40;
  border-color: #c81c3d;
  color: #fff;
}

.btn-instagram:not(:disabled):not(.disabled).active:focus,
.btn-instagram:not(:disabled):not(.disabled):active:focus,
.show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
}

.btn-pinterest {
  background-color: #bd081c;
  border-color: #bd081c;
  color: #fff;
}

.btn-pinterest:hover {
  background-color: #980617;
  border-color: #8c0615;
  color: #fff;
}

.btn-pinterest.focus,
.btn-pinterest:focus {
  box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
}

.btn-pinterest.disabled,
.btn-pinterest:disabled {
  background-color: #bd081c;
  border-color: #bd081c;
  color: #fff;
}

.btn-pinterest:not(:disabled):not(.disabled).active,
.btn-pinterest:not(:disabled):not(.disabled):active,
.show > .btn-pinterest.dropdown-toggle {
  background-color: #8c0615;
  border-color: #800513;
  color: #fff;
}

.btn-pinterest:not(:disabled):not(.disabled).active:focus,
.btn-pinterest:not(:disabled):not(.disabled):active:focus,
.show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
}

.btn-vk {
  background-color: #6383a8;
  border-color: #6383a8;
  color: #fff;
}

.btn-vk:hover {
  background-color: #527093;
  border-color: #4d6a8b;
  color: #fff;
}

.btn-vk.focus,
.btn-vk:focus {
  box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
}

.btn-vk.disabled,
.btn-vk:disabled {
  background-color: #6383a8;
  border-color: #6383a8;
  color: #fff;
}

.btn-vk:not(:disabled):not(.disabled).active,
.btn-vk:not(:disabled):not(.disabled):active,
.show > .btn-vk.dropdown-toggle {
  background-color: #4d6a8b;
  border-color: #496482;
  color: #fff;
}

.btn-vk:not(:disabled):not(.disabled).active:focus,
.btn-vk:not(:disabled):not(.disabled):active:focus,
.show > .btn-vk.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
}

.btn-rss {
  background-color: orange;
  border-color: orange;
  color: #fff;
}

.btn-rss:hover {
  background-color: #d98c00;
  border-color: #cc8400;
  color: #fff;
}

.btn-rss.focus,
.btn-rss:focus {
  box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
}

.btn-rss.disabled,
.btn-rss:disabled {
  background-color: orange;
  border-color: orange;
  color: #fff;
}

.btn-rss:not(:disabled):not(.disabled).active,
.btn-rss:not(:disabled):not(.disabled):active,
.show > .btn-rss.dropdown-toggle {
  background-color: #cc8400;
  border-color: #bf7c00;
  color: #fff;
}

.btn-rss:not(:disabled):not(.disabled).active:focus,
.btn-rss:not(:disabled):not(.disabled):active:focus,
.show > .btn-rss.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
}

.btn-flickr {
  background-color: #0063dc;
  border-color: #0063dc;
  color: #fff;
}

.btn-flickr:hover {
  background-color: #0052b6;
  border-color: #004ca9;
  color: #fff;
}

.btn-flickr.focus,
.btn-flickr:focus {
  box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
}

.btn-flickr.disabled,
.btn-flickr:disabled {
  background-color: #0063dc;
  border-color: #0063dc;
  color: #fff;
}

.btn-flickr:not(:disabled):not(.disabled).active,
.btn-flickr:not(:disabled):not(.disabled):active,
.show > .btn-flickr.dropdown-toggle {
  background-color: #004ca9;
  border-color: #00469c;
  color: #fff;
}

.btn-flickr:not(:disabled):not(.disabled).active:focus,
.btn-flickr:not(:disabled):not(.disabled):active:focus,
.show > .btn-flickr.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
}

.btn-bitbucket {
  background-color: #0052cc;
  border-color: #0052cc;
  color: #fff;
}

.btn-bitbucket:hover {
  background-color: #0043a6;
  border-color: #003e99;
  color: #fff;
}

.btn-bitbucket.focus,
.btn-bitbucket:focus {
  box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
}

.btn-bitbucket.disabled,
.btn-bitbucket:disabled {
  background-color: #0052cc;
  border-color: #0052cc;
  color: #fff;
}

.btn-bitbucket:not(:disabled):not(.disabled).active,
.btn-bitbucket:not(:disabled):not(.disabled):active,
.show > .btn-bitbucket.dropdown-toggle {
  background-color: #003e99;
  border-color: #00388c;
  color: #fff;
}

.btn-bitbucket:not(:disabled):not(.disabled).active:focus,
.btn-bitbucket:not(:disabled):not(.disabled):active:focus,
.show > .btn-bitbucket.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
}

.btn-blue {
  background-color: #083dc1;
  border-color: #083dc1;
  color: #fff;
}

.btn-blue:hover {
  background-color: #1747bd;
  border-color: #1747bd;
  color: #fff !important;
}

.btn-blue.focus,
.btn-blue:focus {
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
}

.btn-blue.disabled,
.btn-blue:disabled,
.btn-blue:not(:disabled):not(.disabled).active,
.btn-blue:not(:disabled):not(.disabled):active,
.show > .btn-blue.dropdown-toggle {
  background-color: #1747bd;
  border-color: #1747bd;
  color: #fff;
}

.btn-blue:not(:disabled):not(.disabled).active:focus,
.btn-blue:not(:disabled):not(.disabled):active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
}

.btn-indigo {
  background-color: #6574cd;
  border-color: #6574cd;
  color: #fff;
}

.btn-indigo:hover {
  background-color: #485ac4;
  border-color: #3f51c1;
  color: #fff !important;
}

.btn-indigo.focus,
.btn-indigo:focus {
  box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
}

.btn-indigo.disabled,
.btn-indigo:disabled {
  background-color: #6574cd;
  border-color: #6574cd;
  color: #fff;
}

.btn-indigo:not(:disabled):not(.disabled).active,
.btn-indigo:not(:disabled):not(.disabled):active,
.show > .btn-indigo.dropdown-toggle {
  background-color: #3f51c1;
  border-color: #3b4db7;
  color: #fff;
}

.btn-indigo:not(:disabled):not(.disabled).active:focus,
.btn-indigo:not(:disabled):not(.disabled):active:focus,
.show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
}

.btn-purple {
  background-color: #aa4cf2;
  border-color: #aa4cf2;
  color: #fff;
}

.btn-purple:hover {
  background-color: #923ce6;
  border-color: #8c31e4;
  color: #fff !important;
}

.btn-purple.focus,
.btn-purple:focus {
  box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
}

.btn-purple.disabled,
.btn-purple:disabled {
  background-color: #aa4cf2;
  border-color: #aa4cf2;
  color: #fff;
}

.btn-purple:not(:disabled):not(.disabled).active,
.btn-purple:not(:disabled):not(.disabled):active,
.show > .btn-purple.dropdown-toggle {
  background-color: #8c31e4;
  border-color: #8526e3;
  color: #fff;
}

.btn-purple:not(:disabled):not(.disabled).active:focus,
.btn-purple:not(:disabled):not(.disabled):active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
}

.btn-pink {
  background-color: #ec82ef;
  border-color: #ec82ef;
  color: #fff;
}

.btn-pink:hover {
  background-color: #f44982;
  border-color: #f33d7a;
  color: #fff !important;
}

.btn-pink.focus,
.btn-pink:focus {
  box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
}

.btn-pink.disabled,
.btn-pink:disabled {
  background-color: #ec82ef;
  border-color: #ec82ef;
  color: #fff;
}

.btn-pink:not(:disabled):not(.disabled).active,
.btn-pink:not(:disabled):not(.disabled):active,
.show > .btn-pink.dropdown-toggle {
  background-color: #f33d7a;
  border-color: #f23172;
  color: #fff;
}

.btn-pink:not(:disabled):not(.disabled).active:focus,
.btn-pink:not(:disabled):not(.disabled):active:focus,
.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
}

.btn-red {
  background-color: #dc0441;
  border-color: #dc0441;
  color: #fff;
}

.btn-red:hover {
  background-color: #ac1b1a;
  border-color: #a11918;
  color: #fff;
}

.btn-red.focus,
.btn-red:focus {
  box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
}

.btn-red.disabled,
.btn-red:disabled {
  background-color: #dc0441;
  border-color: #dc0441;
  color: #fff;
}

.btn-red:not(:disabled):not(.disabled).active,
.btn-red:not(:disabled):not(.disabled):active,
.show > .btn-red.dropdown-toggle {
  background-color: #a11918;
  border-color: #961717;
  color: #fff;
}

.btn-red:not(:disabled):not(.disabled).active:focus,
.btn-red:not(:disabled):not(.disabled):active:focus,
.show > .btn-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
}

.btn-orange {
  background-color: #fc7303;
  border-color: #fc7303;
  color: #fff;
}

.btn-orange:hover {
  background-color: #fd811e;
  border-color: #fc7a12;
  color: #fff;
}

.btn-orange.focus,
.btn-orange:focus {
  box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
}

.btn-orange.disabled,
.btn-orange:disabled {
  background-color: #fc7303;
  border-color: #fc7303;
  color: #fff;
}

.btn-orange:not(:disabled):not(.disabled).active,
.btn-orange:not(:disabled):not(.disabled):active,
.show > .btn-orange.dropdown-toggle {
  background-color: #fc7a12;
  border-color: #fc7305;
  color: #fff;
}

.btn-orange:not(:disabled):not(.disabled).active:focus,
.btn-orange:not(:disabled):not(.disabled):active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
}

.btn-yellow {
  background-color: #ffab00;
  border-color: #ffab00;
  color: #fff;
}

.btn-yellow:hover {
  background-color: #cea70c;
  border-color: #c29d0b;
  color: #fff;
}

.btn-yellow.focus,
.btn-yellow:focus {
  box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
}

.btn-yellow.disabled,
.btn-yellow:disabled {
  background-color: #ffab00;
  border-color: #ffab00;
  color: #fff;
}

.btn-yellow:not(:disabled):not(.disabled).active,
.btn-yellow:not(:disabled):not(.disabled):active,
.show > .btn-yellow.dropdown-toggle {
  background-color: #c29d0b;
  border-color: #b6940b;
  color: #fff;
}

.btn-yellow:not(:disabled):not(.disabled).active:focus,
.btn-yellow:not(:disabled):not(.disabled):active:focus,
.show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
}

.btn-green {
  background-color: #38cb89;
  border-color: #38cb89;
  color: #fff;
}

.btn-green:hover {
  background-color: #15ca7c;
  border-color: #15ca7c;
  color: #fff;
}

.btn-green.focus,
.btn-green:focus {
  box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
}

.btn-green.disabled,
.btn-green:disabled {
  background-color: #38cb89;
  border-color: #38cb89;
  color: #fff;
}

.btn-green:not(:disabled):not(.disabled).active,
.btn-green:not(:disabled):not(.disabled):active,
.show > .btn-green.dropdown-toggle {
  background-color: #15ca7c;
  border-color: #3e7a00;
  color: #fff;
}

.btn-green:not(:disabled):not(.disabled).active:focus,
.btn-green:not(:disabled):not(.disabled):active:focus,
.show > .btn-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
}

.btn-teal {
  background-color: #2bcbba;
  border-color: #2bcbba;
  color: #fff;
}

.btn-teal:hover {
  background-color: #24ab9d;
  border-color: #22a193;
  color: #fff;
}

.btn-teal.focus,
.btn-teal:focus {
  box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
}

.btn-teal.disabled,
.btn-teal:disabled {
  background-color: #2bcbba;
  border-color: #2bcbba;
  color: #fff;
}

.btn-teal:not(:disabled):not(.disabled).active,
.btn-teal:not(:disabled):not(.disabled):active,
.show > .btn-teal.dropdown-toggle {
  background-color: #22a193;
  border-color: #20968a;
  color: #fff;
}

.btn-teal:not(:disabled):not(.disabled).active:focus,
.btn-teal:not(:disabled):not(.disabled):active:focus,
.show > .btn-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
}

.btn-cyan {
  background-color: #116e7c;
  border-color: #116e7c;
  color: #fff;
}

.btn-cyan:hover {
  background-color: #138496;
  border-color: #117a8b;
  color: #fff;
}

.btn-cyan.focus,
.btn-cyan:focus {
  box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
}

.btn-cyan.disabled,
.btn-cyan:disabled {
  background-color: #116e7c;
  border-color: #116e7c;
  color: #fff;
}

.btn-cyan:not(:disabled):not(.disabled).active,
.btn-cyan:not(:disabled):not(.disabled):active,
.show > .btn-cyan.dropdown-toggle {
  background-color: #117a8b;
  border-color: #10707f;
  color: #fff;
}

.btn-cyan:not(:disabled):not(.disabled).active:focus,
.btn-cyan:not(:disabled):not(.disabled):active:focus,
.show > .btn-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
}

.btn-white {
  color: #6c756e;
}

.btn-white,
.btn-white:hover {
  background-color: #fff;
  background-image: none;
  border-color: #e7e9f1;
}

.btn-white.focus,
.btn-white:focus {
  box-shadow: 0 0 0 2px hsla(0, 0%, 100%, 0.5);
}

.btn-white.disabled,
.btn-white:disabled {
  background-color: #fff;
  border-color: #fff;
  color: #495057;
}

.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,
.show > .btn-white.dropdown-toggle {
  background-color: #f0f0f2;
  border-color: #e7e9f1;
  color: #495057;
}

.btn-white:not(:disabled):not(.disabled).active:focus,
.btn-white:not(:disabled):not(.disabled):active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px hsla(0, 0%, 100%, 0.5);
}

.btn-gray {
  background-color: #868e96;
  border-color: #868e96;
  color: #fff;
}

.btn-gray:hover {
  background-color: #727b84;
  border-color: #6c757d;
  color: #fff;
}

.btn-gray.focus,
.btn-gray:focus {
  box-shadow: 0 0 0 2px hsla(210, 7%, 56%, 0.5);
}

.btn-gray.disabled,
.btn-gray:disabled {
  background-color: #868e96;
  border-color: #868e96;
  color: #fff;
}

.btn-gray:not(:disabled):not(.disabled).active,
.btn-gray:not(:disabled):not(.disabled):active,
.show > .btn-gray.dropdown-toggle {
  background-color: #6c757d;
  border-color: #666e76;
  color: #fff;
}

.btn-gray:not(:disabled):not(.disabled).active:focus,
.btn-gray:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px hsla(210, 7%, 56%, 0.5);
}

.btn-gray-dark {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}

.btn-gray-dark:hover {
  background-color: #23272b;
  border-color: #1d2124;
  color: #fff;
}

.btn-gray-dark.focus,
.btn-gray-dark:focus {
  box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
}

.btn-gray-dark.disabled,
.btn-gray-dark:disabled {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}

.btn-gray-dark:not(:disabled):not(.disabled).active,
.btn-gray-dark:not(:disabled):not(.disabled):active,
.show > .btn-gray-dark.dropdown-toggle {
  background-color: #1d2124;
  border-color: #171a1d;
  color: #fff;
}

.btn-gray-dark:not(:disabled):not(.disabled).active:focus,
.btn-gray-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
}

.btn-azure {
  background-color: #3e80eb;
  border-color: #3e80eb;
  color: #fff;
}

.btn-azure:hover {
  background-color: #219af0;
  border-color: #1594ef;
  color: #fff !important;
}

.btn-azure.focus,
.btn-azure:focus {
  box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.5);
}

.btn-azure.disabled,
.btn-azure:disabled {
  background-color: #3e80eb;
  border-color: #3e80eb;
  color: #fff;
}

.btn-azure:not(:disabled):not(.disabled).active,
.btn-azure:not(:disabled):not(.disabled):active,
.show > .btn-azure.dropdown-toggle {
  background-color: #1594ef;
  border-color: #108ee7;
  color: #fff;
}

.btn-azure:not(:disabled):not(.disabled).active:focus,
.btn-azure:not(:disabled):not(.disabled):active:focus,
.show > .btn-azure.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.5);
}

.btn-lime {
  background-color: #7bd235;
  border-color: #7bd235;
  color: #fff;
}

.btn-lime:hover {
  background-color: #69b829;
  border-color: #63ad27;
  color: #fff;
}

.btn-lime.focus,
.btn-lime:focus {
  box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
}

.btn-lime.disabled,
.btn-lime:disabled {
  background-color: #7bd235;
  border-color: #7bd235;
  color: #fff;
}

.btn-lime:not(:disabled):not(.disabled).active,
.btn-lime:not(:disabled):not(.disabled):active,
.show > .btn-lime.dropdown-toggle {
  background-color: #63ad27;
  border-color: #5da324;
  color: #fff;
}

.btn-lime:not(:disabled):not(.disabled).active:focus,
.btn-lime:not(:disabled):not(.disabled):active:focus,
.show > .btn-lime.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
}

.btn-option {
  background: transparent;
  color: #9aa0ac;
}

.btn-option:hover {
  color: #6e7687;
}

.btn-option:focus {
  box-shadow: none;
  color: #6e7687;
}

.btn-list {
  font-size: 0;
  margin-bottom: -0.5rem;
}

.btn-list > .btn,
.btn-list > .dropdown {
  margin-bottom: 0.5rem;
}

.btn-list > .btn:not(:last-child),
.btn-list > .dropdown:not(:last-child) {
  margin-right: 0.5rem;
}

.btn-loading {
  color: transparent !important;
  pointer-events: none;
  position: relative;
}

.btn-loading:after {
  border-end-color: transparent !important;
  -webkit-animation: loader 0.5s linear infinite;
  animation: loader 0.5s linear infinite;
  border: 2px solid #fff;
  border-radius: 50%;
  border-top-color: transparent !important;
  content: "";
  display: block;
  height: 1.4em;
  left: calc(50% - 0.7em);
  position: absolute;
  position: absolute !important;
  top: calc(50% - 0.7em);
  transform-origin: center;
  width: 1.4em;
}

.btn-group-sm > .btn-loading.btn:after,
.btn-loading.btn-sm:after {
  height: 1em;
  left: calc(50% - 0.5em);
  top: calc(50% - 0.5em);
  width: 1em;
}

.btn-loading.btn-secondary:after {
  border-color: #495057;
}

.btn-icon.btn-svg {
  line-height: 15px;
}

.btn-icon.btn-svg .svg-icon {
  margin-top: 2px;
  width: 16px;
}

.btn-svgs {
  display: inline-flex;
}

.btn-svgs .svg-icon {
  margin-right: 6px;
}

.btn-svg-text {
  margin-top: 2px;
}

.btn-danger .svg-icon,
.btn-dark .svg-icon,
.btn-info .svg-icon,
.btn-primary .svg-icon,
.btn-secondary .svg-icon,
.btn-success .svg-icon,
.btn-warning .svg-icon {
  fill: hsla(0, 0%, 100%, 0.6);
}

.data-table-btn {
  font-size: 13px;
  left: 80px;
  padding: 5px 15px;
  position: absolute;
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  background-color: #664dc9;
  border-color: #664dc9;
  color: #fff;
}

.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: none !important;
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: none;
}

.btn-notify {
  background-color: #4e36c3;
  border-color: #4e36c3;
  color: #fff;
}

.btn-notify:hover,
.btn-notify:hover .input-group-btn {
  color: #fff !important;
}

.carousel {
  position: relative;
}

.carousel-inner {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel-item {
  align-items: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: pointer;
  display: none;
  perspective: 1000px;
  position: relative;
  transition: transform 0.6s ease;
  width: 100%;
}

.carousel-item.active {
  display: block;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  display: block;
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-start,
.carousel-item-prev.carousel-item-end {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-start,
  .carousel-item-prev.carousel-item-end {
    transform: translateZ(0);
  }

  .active.carousel-item-end,
  .carousel-item-next {
    transform: translate3d(100%, 0, 0);
  }

  .active.carousel-item-start,
  .carousel-item-prev {
    transform: translate3d(-100%, 0, 0);
  }

  .carousel-fade .active.carousel-item-prev,
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active {
    transform: translateZ(0);
  }
}

.active.carousel-item-end,
.carousel-item-next {
  transform: translateX(100%);
}

.active.carousel-item-start,
.carousel-item-prev {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 0.6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  opacity: 1;
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  opacity: 0;
}

.carousel-fade .active.carousel-item-prev,
.carousel-fade .active.carousel-item-start,
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active {
  transform: translateX(0);
}

.carousel-control-next,
.carousel-control-prev {
  align-items: center;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  text-align: center;
  top: 0;
  width: 15%;
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  opacity: 0.9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background: transparent no-repeat 50%;
  background-size: 100% 100%;
  display: inline-block;
  height: 20px;
  width: 20px;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  bottom: 10px;
  display: flex;
  justify-content: center;
  left: 0;
  list-style: none;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  position: absolute;
  right: 0;
  z-index: 15;
}

.carousel-indicators button {
  background-color: hsla(0, 0%, 100%, 0.5);
  flex: 0 1 auto;
  height: 7px;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
  text-indent: -999px;
  width: 7px;
}

.carousel-indicators button:before {
  top: -10px;
}

.carousel-indicators button:after,
.carousel-indicators button:before {
  content: "";
  display: inline-block;
  height: 10px;
  left: 0;
  position: absolute;
  width: 100%;
}

.carousel-indicators button:after {
  bottom: -10px;
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-indicators1 {
  display: flex;
  justify-content: center;
  left: 0;
  list-style: none;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 15;
}

.carousel-indicators1 button {
  background-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 50%;
  flex: 0 1 auto;
  height: 7px;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
  text-indent: -999px;
  width: 7px;
}

.carousel-indicators1 button:before {
  top: -10px;
}

.carousel-indicators1 button:after,
.carousel-indicators1 button:before {
  content: "";
  display: inline-block;
  height: 10px;
  left: 0;
  position: absolute;
  width: 100%;
}

.carousel-indicators1 button:after {
  bottom: -10px;
}

.carousel-indicators1 .active {
  background-color: #fff;
}

.carousel-indicators2 {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 10px;
  padding-left: 0;
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 15;
}

.carousel-indicators2 button {
  background-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 50%;
  flex: 0 1 auto;
  height: 7px;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
  text-indent: -999px;
  width: 7px;
}

.carousel-indicators2 button:before {
  top: -10px;
}

.carousel-indicators2 button:after,
.carousel-indicators2 button:before {
  content: "";
  display: inline-block;
  height: 10px;
  left: 0;
  position: absolute;
  width: 100%;
}

.carousel-indicators2 button:after {
  bottom: -10px;
}

.carousel-indicators2 .active {
  background-color: #fff;
}

.carousel-indicators3 {
  display: flex;
  justify-content: center;
  left: 10px;
  list-style: none;
  margin-top: 10px;
  padding-left: 0;
  position: absolute;
  z-index: 15;
}

.carousel-indicators3 button {
  background-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 50%;
  flex: 0 1 auto;
  height: 7px;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
  text-indent: -999px;
  width: 7px;
}

.carousel-indicators3 button:before {
  top: -10px;
}

.carousel-indicators3 button:after,
.carousel-indicators3 button:before {
  content: "";
  display: inline-block;
  height: 10px;
  left: 0;
  position: absolute;
  width: 100%;
}

.carousel-indicators3 button:after {
  bottom: -10px;
}

.carousel-indicators3 .active {
  background-color: #fff;
}

.carousel-indicators4 {
  bottom: 0;
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 10%;
  padding-left: 0;
  position: absolute;
  right: 10px;
  z-index: 15;
}

.carousel-indicators4 button {
  background-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 50%;
  flex: 0 1 auto;
  height: 7px;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
  text-indent: -999px;
  width: 7px;
}

.carousel-indicators4 button:before {
  top: -10px;
}

.carousel-indicators4 button:after,
.carousel-indicators4 button:before {
  content: "";
  display: inline-block;
  height: 10px;
  left: 0;
  position: absolute;
  width: 100%;
}

.carousel-indicators4 button:after {
  bottom: -10px;
}

.carousel-indicators4 .active {
  background-color: #fff;
}

.carousel-indicators5 {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 10px;
  list-style: none;
  padding-left: 0;
  position: absolute;
  z-index: 15;
}

.carousel-indicators5 button {
  background-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 50%;
  flex: 0 1 auto;
  height: 7px;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
  text-indent: -999px;
  width: 7px;
}

.carousel-indicators5 button:before {
  top: -10px;
}

.carousel-indicators5 button:after,
.carousel-indicators5 button:before {
  content: "";
  display: inline-block;
  height: 10px;
  left: 0;
  position: absolute;
  width: 100%;
}

.carousel-indicators5 button:after {
  bottom: -10px;
}

.carousel-indicators5 .active {
  background-color: #fff;
}

.carousel-caption {
  color: #fff;
  left: 15%;
  padding-bottom: 20px;
  padding-top: 20px;
  position: absolute;
  right: 15%;
  text-align: center;
  top: 30%;
  z-index: 10;
}

.carousel-item-background {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.carousel-top-indicators {
  top: 15px;
}

.carousel-top-right-indicators {
  left: auto;
  margin: 0 !important;
  right: 15px !important;
  top: 15px;
}

.carousel-top-left-indicators {
  left: 15px !important;
  margin: 0 !important;
  right: auto;
  top: 15px;
}

.carousel-bottom-right-indicators {
  bottom: 15px;
  left: auto;
  margin: 0 !important;
  right: 15px !important;
}

.carousel-bottom-left-indicators {
  bottom: 15px;
  left: 15px !important;
  margin: 0 !important;
  right: auto;
}

.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
}

.dropdown-toggle:after {
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid;
  color: #fff;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  width: 0;
}

.btn-light.dropdown-toggle:after,
.btn-outline-danger.dropdown-toggle:after,
.btn-outline-info.dropdown-toggle:after,
.btn-outline-primary.dropdown-toggle:after,
.btn-outline-secondary.dropdown-toggle:after,
.btn-outline-success.dropdown-toggle:after,
.btn-outline-warning.dropdown-toggle:after {
  color: #000 !important;
}

.fadein,
.header-search.show .dropdown-menu,
.main-header-message.show .dropdown-menu,
.main-header-notification.show .dropdown-menu,
.main-profile-menu.show .dropdown-menu {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadein;
  animation-name: fadein;
}

.dropdown-menu {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadein;
  animation-name: fadein;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 4px !important;
  box-shadow: none;
  box-shadow: 0 16px 18px rgba(4, 4, 7, 0.1);
  color: #495057;
  display: none;
  float: left;
  font-size: 0.8075rem;
  left: 0;
  list-style: none;
  margin: 0.125rem 0 0;
  min-width: 10rem;
  min-width: 12rem;
  padding: 0.5rem 0;
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: 1000;
}

.dropdown-menu-end {
  left: auto;
  right: 0;
}

.dropup .dropdown-menu {
  bottom: 100%;
  margin-bottom: 0.125rem;
  margin-top: 0;
  top: auto;
}

.dropup .dropdown-toggle:after {
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  width: 0;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: 0;
  right: auto;
  top: 0;
}

.dropend .dropdown-toggle:after {
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  border-right: 0;
  border-top: 0.3em solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  vertical-align: 0;
  width: 0;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-menu {
  left: auto;
  margin-right: 0.125rem;
  margin-top: 0;
  right: 100%;
  top: 0;
}

.dropstart .dropdown-toggle:after {
  content: "";
  display: inline-block;
  display: none;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  width: 0;
}

.dropstart .dropdown-toggle:before {
  border-bottom: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-top: 0.3em solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  vertical-align: 0;
  width: 0;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.header .dropdown-menu[data-popper-placement^="bottom"],
.header .dropdown-menu[data-popper-placement^="end"],
.header .dropdown-menu[data-popper-placement^="start"],
.header .dropdown-menu[data-popper-placement^="top"] {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  border: 1px solid #e6ebf1;
  border-radius: 4px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: -8px 12px 18px 0 rgba(15, 34, 58, 0.1);
  top: 76px !important;
}

.header-notify .show.dropdown-menu {
  left: -198px !important;
}

.header-message .show.dropdown-menu {
  left: -252px !important;
}

.profile-dropdown .show.dropdown-menu[data-popper-placement^="bottom"] {
  left: -136px !important;
}

.dropdown-menu[data-popper-placement^="bottom"],
.dropdown-menu[data-popper-placement^="end"],
.dropdown-menu[data-popper-placement^="start"],
.dropdown-menu[data-popper-placement^="top"] {
  bottom: auto;
  right: auto;
}

.dropdown-divider {
  border-top: 1px solid #e9ecef;
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
}

.notifyimg {
  border-radius: 50px;
  color: #fff;
  float: left;
  height: 40px;
  line-height: 37px;
  margin-right: 20px;
  text-align: center;
  vertical-align: middle;
  width: 40px;
}

.dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: #212529;
  display: block;
  font-weight: 400;
  padding: 0.5rem 1rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}

.drop-icon-wrap {
  text-align: center;
}

.drop-icon-wrap li {
  display: block;
}

.drop-icon-wrap .drop-icon-item {
  color: #878787;
  display: inline-block;
  float: left;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  text-transform: capitalize;
  width: 50%;
}

.block {
  display: block !important;
}

.drop-icon-item i {
  font-size: 20px;
  padding-bottom: 5px;
}

.drop-icon-wrap .drop-icon-item:focus,
.drop-icon-wrap .drop-icon-item:hover {
  background-color: #eef0ff;
  text-decoration: none;
}

.dropdown-item.user {
  font-size: 16px;
  padding: 5px;
}

.user-semi-title {
  font-size: 12px;
}

.floating {
  -webkit-animation: floating 3s ease infinite;
  animation: floating 3s ease infinite;
  will-change: transform;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #f3f6f8;
  text-decoration: none;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f3f6f8 !important;
  color: #3e4358 !important;
  text-decoration: none;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  background-color: transparent;
  color: #fff;
}

.dropdown-menu.show {
  display: block;
  max-height: 400px;
  overflow: auto;
  margin: 0;
}

.dropdown-menu-arrow.dropdown-menu-end:before {
  border-bottom: 10px solid #e6ebf1;
  left: 15px;
  top: -10px;
}

.dropdown-menu-arrow.dropdown-menu-end:after,
.dropdown-menu-arrow.dropdown-menu-end:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  position: absolute;
}

.dropdown-menu-arrow.dropdown-menu-end:after {
  border-bottom: 10px solid #fff;
  left: 16px;
  top: -8.5px;
}

.dropdown-menu-arrow.dropdown-menu-start:before {
  border-bottom: 10px solid #e6ebf1;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  left: 15px;
  position: absolute;
  top: -10px;
}

.dropdown-menu-arrow.dropdown-menu-start:after {
  border-bottom: 9px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  content: "";
  left: 16px;
  position: absolute;
  top: -8.5px;
}

.nav-item .show.dropdown-menu.dropdown-menu-arrow .show {
  top: -1px !important;
}

.dropdown-header {
  color: #868e96;
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0;
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
}

.dropdown-item-text {
  color: #212529;
  display: block;
  padding: 0.25rem 1.5rem;
}

.btn-group,
.btn-group-vertical {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  flex: 0 1 auto;
  position: relative;
}

.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}

.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group,
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-left;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.dropdown-toggle-split {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;
}

.dropdown-toggle-split:after,
.dropend .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.btn-group-vertical {
  align-items: flex-left;
  flex-direction: column;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-left: 0;
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

btn.dropdown-toggle ~ .dropdown-menu,
ul.dropdown-menu li.dropdown ul.dropdown-menu {
  background-color: #f4f4f4 !important;
  background-color: #fff !important;
  border: 0 solid #4285f4 !important;
  box-shadow: 0 0 3px rgba(25, 25, 25, 0.3) !important;
  margin: 0 !important;
  padding: 0 !important;
  top: 0 !important;
}

ul.dropdown-menu li.dropdown ul.dropdown-menu {
  position: absolute !important;
  width: 100% !important;
}

.dropdown-menu > li > a {
  clear: both;
  color: #333;
  display: block;
  font-weight: 400;
  line-height: 1.428571429;
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  background-color: #f0f0f2;
  color: #6c756e;
  text-decoration: none;
}

.dropdown-menu .divider {
  background: #e6ebf1;
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
}

.dropdown-menu .dropdown-plus-title {
  border: solid #e6ebf1 !important;
  border-width: 0 0 1px !important;
  color: #6b6f80 !important;
  cursor: pointer !important;
  font-weight: 500 !important;
  padding: 6px 12px !important;
  width: 100% !important;
}

ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
  line-height: 20px !important;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.btn.dropdown-toggle.btn-success ~ .dropdown-menu .dropdown-plus-title {
  border-color: #38cb89 !important;
}

.btn.dropdown-toggle.btn-info ~ .dropdown-menu .dropdown-plus-title {
  border-color: #3e80eb !important;
}

.btn.dropdown-toggle.btn-warning ~ .dropdown-menu .dropdown-plus-title {
  border-color: #ffab00 !important;
}

.btn.dropdown-toggle.btn-danger ~ .dropdown-menu .dropdown-plus-title {
  border-color: #ef4b4b !important;
}

.dropdown-menu-header {
  align-items: center;
  border-bottom: 1px solid #e6ebf1;
  display: flex;
  justify-content: space-between;
  padding: 12px 15px;
}

.dropdown-demo .dropdown-menu {
  float: none;
  position: static;
}

.dropdown-menu-header label {
  color: #6b6f80;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;
}

.dropdown-media-list .media {
  padding: 12px 15px;
}

.dropdown-media-list .media:focus,
.dropdown-media-list .media:hover {
  background-color: #f8f9fa;
}

.dropdown-media-list img {
  border-radius: 100%;
}

.dropdown-media-list .media-body {
  margin-left: 15px;
}

.dropdown-media-list .media-body > div {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.dropdown-media-list .media-body > div p {
  color: #6b6f80;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.dropdown-media-list .media-body > div span {
  font-size: 12px;
}

.dropdown-media-list .media-body > p {
  font-size: 14px;
  margin-bottom: 0;
}

.dropdown-list-footer {
  border-top: 1px solid #e6ebf1;
  font-size: 12px;
  padding: 10px 15px;
  text-align: center;
}

.label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.dropdown-media-list {
  padding: 0;
}

@media (min-width: 768px) {
  ul.dropdown-menu li.dropdown ul.dropdown-menu {
    width: auto !important;
  }

  ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
    padding-bottom: 15px !important;
    padding-top: 15px !important;
  }
}

.dropdown {
  display: block;
}

#sidebar .dropdown-menu {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  min-width: 12rem;
}

.dropdown-menu-arrow.dropdown-menu-end:after,
.dropdown-menu-arrow.dropdown-menu-end:before {
  left: auto;
  right: 22px;
}

.dropdown-toggle {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdown-toggle:after {
  vertical-align: 0.155em;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-icon {
  color: #3e4358;
  display: inline-block;
  margin-left: -0.5rem;
  margin-right: 0.5rem;
  text-align: center;
  vertical-align: -1px;
  width: 1em;
}

.header .profile-dropdown .dropdown-menu {
  top: 70px !important;
}

.header .header-dropdown-list {
  height: 300px;
}

.dropdown-header {
  border-bottom: 1px solid #e6ebf1;
  color: #6c756e;
  display: flex;
  padding: 15px;
}

.dropdown-toggle.split-dropdown {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .responsive-navbar .collapse.show .dropdown {
    position: inherit;
  }

  .responsive-navbar .collapse.show .dropdown .dropdown-menu.header-search {
    left: 0;
    right: 0;
    width: 100%;
  }

  .responsive-navbar
    .collapse.show
    .dropdown
    .dropdown-menu.header-search
    .form-control {
    border-radius: 5px;
  }

  .responsive-navbar .collapse.show .dropdown .dropdown-menu:after,
  .responsive-navbar .collapse.show .dropdown .dropdown-menu:before {
    display: none;
  }

  .responsive-navbar .collapse.show .dropdown .show.dropdown-menu {
    left: 0 !important;
    right: 0 !important;
    top: 59px !important;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}

.list-group-item-action {
  color: #6c756e;
  text-align: inherit;
  width: 100%;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  background-color: #f0f0f2;
  color: #6c756e;
  text-decoration: none;
  z-index: 0;
}

.list-group-item-action:active {
  background-color: #f0f0f2;
  color: #6c756e;
  z-index: 0;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #e6ebf1 !important;
  display: block;
  margin-bottom: -1px;
  padding: 0.75rem 1.25rem;
  position: relative;
}

.listorder {
  list-style-type: decimal;
}

.listorder,
.listorder1 {
  background-color: #fff;
  border: 1px solid #e6ebf1;
  list-style-position: inside;
  margin-bottom: -1px;
  padding: 0.75rem 1.25rem;
  position: relative;
}

.listorder1 {
  list-style-type: upper-alpha;
}

.listunorder {
  list-style-type: circle;
}

.listunorder,
.listunorder1 {
  background-color: #fff;
  border: 1px solid #e6ebf1;
  list-style-position: inside;
  margin-bottom: -1px;
  padding: 0.75rem 1.25rem;
  position: relative;
}

.listunorder1 {
  list-style-type: square;
}

.list-group-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.list-group-item:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-bottom: 0;
}

.list-group-item:focus,
.list-group-item:hover {
  text-decoration: none;
  z-index: 1;
}

.list-group-item.disabled,
.list-group-item:disabled {
  background-color: #fff;
  color: #7c90b1;
}

.list-group-item.active {
  background-color: #f0f0f2;
  border: 1px solid hsla(0, 0%, 93%, 0.5);
  color: #6c756e;
  z-index: 1;
}

.list-group-flush .list-group-item {
  border-bottom: 0 !important;
  border-left: 0;
  border-radius: 0;
  border-right: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-success {
  background-color: #d2ecb8;
  color: #316100;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  background-color: #c5e7a4;
  color: #316100;
}

.list-group-item-success.list-group-item-action.active {
  background-color: #316100;
  border-color: #316100;
  color: #fff;
}

.list-group-item-info {
  background-color: #cbe7fb;
  color: #24587e;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  background-color: #b3dcf9;
  color: #24587e;
}

.list-group-item-info.list-group-item-action.active {
  background-color: #24587e;
  border-color: #24587e;
  color: #fff;
}

.list-group-item-warning {
  background-color: #fbeebc;
  color: #7d6608;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  background-color: #fae8a4;
  color: #7d6608;
}

.list-group-item-warning.list-group-item-action.active {
  background-color: #7d6608;
  border-color: #7d6608;
  color: #fff;
}

.list-group-item-danger {
  background-color: #f1c1c0;
  color: #6b1110;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  background-color: #ecacab;
  color: #6b1110;
}

.list-group-item-danger.list-group-item-action.active {
  background-color: #6b1110;
  border-color: #6b1110;
  color: #fff;
}

.list-group-item-light {
  background-color: #fdfdfe;
  color: #818182;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  background-color: #ececf6;
  color: #818182;
}

.list-group-item-light.list-group-item-action.active {
  background-color: #818182;
  border-color: #818182;
  color: #fff;
}

.list-group-item-dark {
  background-color: #c6c8ca;
  color: #1b1e21;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  background-color: #b9bbbe;
  color: #1b1e21;
}

.list-group-item-dark.list-group-item-action.active {
  background-color: #1b1e21;
  border-color: #1b1e21;
  color: #fff;
}

.btn-close {
  color: #141517;
  float: right;
  font-size: 16px;
  font-size: 10px;
  line-height: 1;
  margin-top: 4px;
  opacity: 0.5;
}

.btn-close:focus,
.btn-close:hover {
  box-shadow: none;
  color: #000;
  opacity: 0.75;
  outline: 0;
  text-decoration: none;
}

.btn-close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  padding: 0;
}

.list-inline-dots .list-inline-item + .list-inline-item:before {
  content: "Â· ";
  margin-left: -2px;
  margin-right: 3px;
}

.list-separated-item {
  padding: 1rem 0;
}

.list-separated-item:first-child {
  padding-top: 0;
}

.list-group-item.active .icon {
  color: inherit !important;
}

.list-group-item .icon {
  color: #6b6f80 !important;
}

.list-group-transparent .list-group-item {
  background: none;
  border: 0;
  border-radius: 3px;
  padding: 0.5rem 1rem !important;
}

.list-group-transparent .list-group-item.active {
  background: #f0f0f2;
  font-weight: 600;
}

.visitor-list p {
  font-size: 12px;
}

.visitor-list i {
  font-size: 20px;
}

.sales-relative {
  position: relative;
}

.icon-absolute {
  border-radius: 50%;
  bottom: 10px;
  display: block;
  height: 70px;
  padding: 20px 0;
  position: absolute;
  right: 10px;
  text-align: center;
  width: 70px;
}

@media (min-width: 991px) and (max-width: 1024px) {
  .icon-absolute {
    display: none;
  }
}

.modal-open {
  display: block;
  overflow: hidden;
  overflow: inherit;
  overflow: auto !important;
  padding: 0 !important;
}

.modal,
.modal-open .modal {
  padding-right: 0 !important;
}

.modal {
  bottom: 0;
  display: none;
  left: 0;
  margin: 0 !important;
  outline: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
}

.modal-backdrop.fade.show,
.modal-open {
  padding-right: 0 !important;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  margin: 0.5rem;
  pointer-events: none;
  position: relative;
  width: auto;
}

.modal.fade .modal-dialog {
  transform: translateY(-25%);
  transition: transform 0.3s ease-out;
}

.modal.show .modal-dialog {
  transform: translate(0);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - 1rem);
}

.modal-content {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}

.modal-backdrop {
  background-color: #000;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999999;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal {
  z-index: 99999999;
}

.modal-header {
  align-items: flex-left;
  border-bottom: 1px solid #e6ebf1;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.modal-header .btn-close {
  background-image: none !important;
  font-size: 25px;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
}

.modal-body {
  flex: 1 1 auto;
  padding: 1rem;
  position: relative;
}

.modal-body p:last-child {
  margin-bottom: 0;
}

.modal-footer {
  align-items: center;
  border-top: 1px solid #e9ecef;
  display: flex;
  justify-content: flex-right;
  padding: 1rem;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  height: 50px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
  width: 50px;
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 1.75rem auto;
    max-width: 500px;
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.modal.animated .modal-dialog {
  transform: translate(0);
}

.modal.effect-scale .modal-dialog {
  opacity: 0;
  transform: scale(0.7);
  transition: all 0.3s;
}

.modal.effect-scale.show .modal-dialog {
  opacity: 1;
  transform: scale(1);
}

.modal.effect-slide-in-right .modal-dialog {
  opacity: 0;
  transform: translateX(20%);
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.modal.effect-slide-in-right.show .modal-dialog {
  opacity: 1;
  transform: translateX(0);
}

.modal.effect-slide-in-bottom .modal-dialog {
  opacity: 0;
  transform: translateY(20%);
  transition: all 0.3s;
}

.modal.effect-slide-in-bottom.show .modal-dialog {
  opacity: 1;
  transform: translateY(0);
}

.modal.effect-newspaper .modal-dialog {
  opacity: 0;
  transform: scale(0) rotate(2turn);
}

.modal.effect-newspaper .modal-dialog,
.modal.effect-newspaper.show ~ .modal-backdrop {
  transition: all 0.5s;
}

.modal.effect-newspaper.show .modal-dialog {
  opacity: 1;
  transform: scale(1) rotate(0deg);
}

.modal.effect-fall {
  perspective: 1300px;
}

.modal.effect-fall .modal-dialog {
  opacity: 0;
  transform: translateZ(600px) rotateX(20deg);
  transform-style: preserve-3d;
}

.modal.effect-fall.show .modal-dialog {
  opacity: 1;
  transform: translateZ(0) rotateX(0deg);
  transition: all 0.3s ease-in;
}

.modal.effect-flip-horizontal {
  perspective: 1300px;
}

.modal.effect-flip-horizontal .modal-dialog {
  opacity: 0;
  transform: rotateY(-70deg);
  transform-style: preserve-3d;
  transition: all 0.3s;
}

.modal.effect-flip-horizontal.show .modal-dialog {
  opacity: 1;
  transform: rotateY(0deg);
}

.modal.effect-flip-vertical {
  perspective: 1300px;
}

.modal.effect-flip-vertical .modal-dialog {
  opacity: 0;
  transform: rotateX(-70deg);
  transform-style: preserve-3d;
  transition: all 0.3s;
}

.modal.effect-flip-vertical.show .modal-dialog {
  opacity: 1;
  transform: rotateX(0deg);
}

.modal.effect-super-scaled .modal-dialog {
  opacity: 0;
  transform: scale(2);
  transition: all 0.3s;
}

.modal.effect-super-scaled.show .modal-dialog {
  opacity: 1;
  transform: scale(1);
}

.modal.effect-sign {
  perspective: 1300px;
}

.modal.effect-sign .modal-dialog {
  opacity: 0;
  transform: rotateX(-60deg);
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  transition: all 0.3s;
}

.modal.effect-sign.show .modal-dialog {
  opacity: 1;
  transform: rotateX(0deg);
}

.modal.effect-rotate-bottom {
  perspective: 1300px;
}

.modal.effect-rotate-bottom .modal-dialog {
  opacity: 0;
  transform: translateY(100%) rotateX(90deg);
  transform-origin: 0 100%;
  transform-style: preserve-3d;
  transition: all 0.3s ease-out;
}

.modal.effect-rotate-bottom.show .modal-dialog {
  opacity: 1;
  transform: translateY(0) rotateX(0deg);
}

.modal.effect-rotate-left {
  perspective: 1300px;
}

.modal.effect-rotate-left .modal-dialog {
  opacity: 0;
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform-origin: 0 100%;
  transform-style: preserve-3d;
  transition: all 0.3s;
}

.modal.effect-rotate-left.show .modal-dialog {
  opacity: 1;
  transform: translateZ(0) translateX(0) rotateY(0deg);
}

.modal.effect-just-me .modal-dialog {
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.3s;
}

.modal.effect-just-me .modal-content {
  background-color: transparent;
  border: 1px solid #4d4e50;
  padding: 0 9px;
}

.modal.effect-just-me .btn-close {
  color: #fff;
  text-shadow: none;
}

.modal.effect-just-me .modal-header {
  background-color: transparent;
  border-bottom-color: hsla(0, 0%, 100%, 0.1);
  padding-left: 0;
  padding-right: 0;
}

.modal.effect-just-me .modal-header h6 {
  color: #fff;
  font-weight: 500;
}

.modal.effect-just-me .modal-body {
  color: hsla(0, 0%, 100%, 0.8);
  padding-left: 0;
  padding-right: 0;
}

.modal.effect-just-me .modal-body h6 {
  color: #fff;
}

.modal.effect-just-me .modal-footer {
  background-color: transparent;
  border-top-color: hsla(0, 0%, 100%, 0.1);
  padding-left: 0;
  padding-right: 0;
}

.modal.effect-just-me.show ~ .modal-backdrop {
  opacity: 0.96;
}

.modal.effect-just-me.show .modal-dialog {
  opacity: 1;
  transform: scale(1);
}

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-link {
  display: block;
  padding: 0.2rem 0.9rem;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  opacity: 0.5;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: 0;
}

.nav-tabs .nav-link.disabled {
  background-color: transparent;
  border-color: transparent;
  color: #868e96;
  cursor: default;
  opacity: 0.4;
  pointer-events: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: #f0f0f2;
  border-radius: 4px;
}

.card-header-pills.nav-pills .nav-link {
  background: transparent;
  border-radius: 4px;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  padding: 2px 0.5rem;
  position: relative;
}

.navbar,
.navbar > .container,
.navbar > .container-fluid {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  font-size: 1.125rem;
  line-height: inherit;
  margin-right: 1rem;
  padding-bottom: 0.359375rem;
  padding-top: 0.359375rem;
  white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-text {
  display: inline-block;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}

.navbar-toggler {
  border-radius: 3px;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-left;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-left;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-left;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1279.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1280px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-left;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-left;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: hsla(0, 0%, 100%, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: hsla(0, 0%, 100%, 0.25);
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  border-color: hsla(0, 0%, 100%, 0.1);
  color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-text {
  color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.nav-item1 {
  align-items: center;
  cursor: pointer;
  display: flex;
  min-width: 2rem;
  padding: 10px;
  transition: color 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nav1 {
  background: #fff !important;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-item1.active,
.nav-item1:hover:not(.disabled) {
  color: #fff;
}

.nav-item1 .nav-link.disabled {
  color: #8e9cad;
  cursor: default;
  pointer-events: none;
}

.nav-tabs {
  border-bottom: 1px solid #e6ebf1;
  color: #000;
  margin: 0 -0.75rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.user-button {
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  border-radius: 3px;
  color: #fff !important;
  padding: 3px 10px;
}

.nav-tabs .nav-item1 {
  margin-bottom: -1px;
  position: relative;
}

.nav-tabs .nav-item1.nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.nav-tabs .nav-item1 .nav-link {
  border: 0;
  color: inherit;
  color: #6b6f80;
  font-weight: 400;
  margin-bottom: -1px;
  padding: 1rem 10px;
  transition: border-color 0.3s;
}

.nav-tabs .nav-item1 .nav-link.disabled {
  cursor: default;
  opacity: 0.6;
  pointer-events: none;
}

.nav-tabs .nav-item1 i {
  display: inline-block;
  font-size: 0 rem;
  line-height: 1;
  margin-right: 10px;
  vertical-align: baseline;
  width: 0.875rem;
}

.nav-tabs .nav-item1:hover .nav-submenu {
  display: block;
}

.nav-tabs .nav-submenu {
  background: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 0 0 3px 3px;
  border-top: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display: none;
  min-width: 10rem;
  position: absolute;
  z-index: 10;
}

.nav-tabs .nav-submenu .nav-item1 {
  color: #9aa0ac;
  cursor: pointer;
  display: block;
  margin: 0 !important;
  padding: 0.5rem 1rem;
  transition: background 0.3s;
}

.nav-tabs .nav-submenu .nav-item1.active {
  color: #467fcf;
}

.nav-tabs .nav-submenu .nav-item1:hover {
  background: rgba(0, 0, 0, 0.024);
  color: #6e7687;
  text-decoration: none;
}

.nav-item,
.nav-link {
  align-items: center;
  cursor: pointer;
  display: flex;
  min-width: 2rem;
  transition: color 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header .nav-item .badge,
.header .nav-link .badge {
  border-radius: 100%;
  display: block !important;
  font-size: 11px;
  height: 1rem;
  line-height: 1;
  padding: 3px 5px !important;
  position: absolute;
  right: 10px;
  top: 18px;
  width: 1rem;
}

#headerMenuCollapse .nav-item .badge,
#headerMenuCollapse .nav-link .badge {
  font-size: 10px;
  font-weight: 500;
  min-width: 1rem;
  position: relative;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  color: inherit;
  color: #6b6f80;
  font-size: 13px;
  font-weight: 500;
  padding: 1rem 1.6rem;
  text-align: center;
  transition: border-color 0.3s;
}

.nav-tabs .nav-link:hover:not(.disabled) {
  color: #fff;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
  position: relative;
}

.nav-item:hover .dropdown-menu,
.nav-link:hover .dropdown-menu,
.nav-link:hover .dropdown-menu.show,
.nav-tabs .nav-item:hover .nav-submenu {
  display: block;
}

@media (max-width: 1279px) and (min-width: 992px) {
  .nav-tabs .nav-link {
    font-size: 13px;
    padding: 1rem 0.95rem;
  }
}

@media (max-width: 480px) {
  .list-media .info {
    padding-bottom: 15px;
  }

  .list-media .info .text-right {
    display: none;
  }

  .header-brand-img {
    height: 40px;
    margin-top: 8px;
  }

  .m-portlet {
    display: none;
  }

  .searching.mt-3 {
    margin-top: 0.6rem !important;
  }

  .tabs-menu ul li {
    margin: 15px;
    width: 100%;
  }

  .tabs-menu ul li a {
    width: 100%;
  }

  .cal1 .clndr .clndr-table tr {
    height: 50px !important;
  }

  .tabs-menu1 ul li {
    margin: 15px;
    width: 100%;
  }

  .tabs-menu1 ul li a {
    width: 100%;
  }

  .wizard-card .nav-pills > li {
    padding: 9px !important;
    text-align: center;
  }

  .form-control.custom-select.w-auto {
    display: none;
  }

  .badge {
    margin: 10px 0;
  }

  .side-badge {
    margin: 0 !important;
  }

  .mail-inbox .badge {
    margin: 0;
  }

  .construction .display-5 {
    font-size: 1.5rem;
  }

  .header-brand {
    line-height: 2.5rem !important;
  }

  .jumbotron .display-3 {
    font-size: 2.5rem;
  }

  .mail-option .hidden-phone {
    display: none;
  }

  .app-header .header-brand {
    min-width: auto !important;
  }

  .dropdown.header-fullscreen {
    display: none;
  }

  .app-header1 .header-brand {
    min-width: auto;
  }

  .widget-text span {
    font-size: 10px;
  }

  .header-message {
    display: none;
  }

  .page-rightheader .btn.btn-list {
    display: contents;
  }
}

@media (max-width: 767px) {
  .card-tabs {
    display: block !important;
  }

  .header-brand {
    line-height: 2.7rem;
  }

  .header .input-icon.mt-2 {
    margin-top: 5px !important;
  }

  .footer .privacy {
    text-align: center !important;
  }

  .shop-dec .col-md-6.pr-0 {
    padding-right: 0.75rem !important;
  }

  .shop-dec .col-md-6.pl-0 {
    padding-left: 0.75rem !important;
  }
}

@media (max-width: 768px) and (min-width: 481px) {
  .header-brand {
    margin-left: 20px;
  }

  .searching i {
    margin-top: 5px !important;
  }

  .nav-link.leading-none span {
    padding: 0 !important;
  }
}

@media (max-width: 768px) {
  .nav-tabs .nav-link {
    width: 100%;
  }

  .page-subtitle {
    display: none;
  }

  .richText .richText-toolbar ul li a {
    border: 1px solid rgba(0, 40, 100, 0.12);
  }

  .ticket-card .col-md-1 {
    display: block;
    width: 100%;
  }

  .ticket-card img {
    display: block;
    margin: 0 auto;
    text-align: Center;
  }

  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 10px !important;
  }

  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center !important;
  }

  .page-title {
    font-size: 20px;
    line-height: 1.5rem;
  }

  .carousel-caption {
    display: none;
  }

  .demo-gallery > ul > li {
    width: 100% !important;
  }

  ul.inbox-pagination li span {
    display: none;
  }

  .dash1,
  .dash2 {
    margin-bottom: 1.2rem;
  }
}

@media (max-width: 990px) and (min-width: 767px) {
  .carousel-caption {
    top: 10%;
  }
}

@media (max-width: 568px) and (min-width: 480px) {
  .container-messages {
    position: absolute;
    top: 50px !important;
  }

  .messages-list {
    bottom: 73px !important;
    top: 24px !important;
  }

  .messages-right {
    bottom: 71px !important;
    top: 10px !important;
  }

  .messages-left-footer {
    bottom: 40px !important;
    display: none;
  }

  .messages-left .card-header {
    display: none;
  }

  .message-footer,
  .message-header {
    height: 50px !important;
  }

  .message-body {
    bottom: 50px !important;
    top: 50px !important;
  }

  .app-header .header-brand-img.mobile-logo {
    margin-top: 1px;
  }
}

@media (max-width: 990px) and (min-width: 569px) {
  .message-footer,
  .message-header {
    height: 50px !important;
  }

  .messages-right {
    bottom: 80px !important;
    top: 10px !important;
  }

  .message-body {
    bottom: 50px !important;
    top: 50px !important;
  }

  .messages-left .card-header {
    display: none;
  }

  .messages-list {
    top: 24px !important;
  }
}

@media (max-width: 990px) {
  .header-toggler {
    margin: 6px;
  }

  .nav-tabs .nav-link {
    width: 100%;
  }

  form.convFormDynamic button.submit {
    margin: 4px !important;
  }

  .messages-list .media {
    padding: 9px !important;
  }
}

.nav-tabs .nav-item i {
  display: inline-block;
  font-size: 0 rem;
  line-height: 1;
  margin-right: 10px;
  vertical-align: baseline;
  width: 0.875rem;
}

.nav-tabs .nav-submenu .nav-item {
  color: #9aa0ac;
  cursor: pointer;
  display: block;
  margin: 0 !important;
  padding: 0.5rem 1rem;
  transition: 0.3s;
}

.nav-tabs .nav-submenu .nav-item.active {
  color: #467fcf;
}

.nav-tabs .nav-submenu .nav-item:hover {
  background: rgba(0, 0, 0, 0.024);
  color: #6e7687;
  text-decoration: none;
}

.nav-link-icon {
  line-height: 0;
}

.expanel-default {
  border: #ddd !important;
}

.expanel-success {
  border: #2dce89 !important;
}

.expanel-danger {
  border: #dc0441 !important;
}

.expanel-success > .expanel-heading {
  background-color: #2dce89 !important;
  border-color: #2dce89 !important;
  color: #fff !important;
}

.expanel-danger > .expanel-heading {
  background-color: #dc0441 !important;
  border-color: #dc0441 !important;
  color: #fff !important;
}

.expanel-warning > .expanel-heading {
  background-color: #ecb403 !important;
  border-color: #ecb403 !important;
  color: #fff !important;
}

.expanel-title {
  color: inherit !important;
  font-size: 16px !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.expanel {
  background-color: #fff !important;
  border: 1px solid #e6ebf1 !important;
  border-radius: 4px !important;
  margin-bottom: 20px !important;
}

.expanel-default > .expanel-heading {
  background-color: #f0f0f2 !important;
  border: 1px solid #e6ebf1 !important;
}

.expanel-heading {
  border: 1px solid #ebecf1;
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
  padding: 10px 15px !important;
}

.expanel-footer {
  background-color: #f0f0f2 !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  border-top: 1px solid #e6ebf1 !important;
  padding: 10px 15px !important;
}

.expanel-body {
  padding: 15px !important;
}

.progress {
  border-radius: 3px;
  font-size: 0.703125rem;
  height: 1rem;
  overflow: hidden;
  position: relative;
}

.progress,
.progress-bar {
  background-color: #e2e2e9;
  display: flex;
}

.progress-bar {
  border-radius: 7px;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: width 0.6s ease;
  white-space: nowrap;
}

.current-progressbar {
  margin-top: 14.5px;
}

.progress-content {
  margin-bottom: 16.5px;
}

.progress-content:last-child {
  margin-bottom: 0;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.progress-xs,
.progress-xs .progress-bar {
  height: 0.25rem;
}

.progress-sm,
.progress-sm .progress-bar {
  height: 0.5rem;
}

.progress-lg,
.progress-lg .progress-bar {
  height: 1.25rem;
}

.progress-bar-indeterminate:after,
.progress-bar-indeterminate:before {
  background-color: inherit;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  will-change: left, right;
}

.progress-bar-indeterminate:before {
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
    infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progress-bar-indeterminate:after {
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

@-webkit-keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.tag {
  background-color: #f0f0f2;
  border-radius: 3px;
  color: #6b6f80;
  cursor: default;
  display: inline-flex;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 2em;
  padding: 0 0.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a.tag {
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s, background 0.3s;
}

a.tag:hover {
  background-color: rgba(110, 118, 135, 0.2);
  color: inherit;
}

.tag-addon {
  background: rgba(0, 0, 0, 0.06);
  color: inherit;
  display: inline-block;
  margin: 0 -0.5rem 0 0.5rem;
  min-width: 1.5rem;
  padding: 0 0.5rem;
  text-align: center;
  text-decoration: none;
}

.tag-addon:last-child {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.tag-addon i {
  margin: 0 -0.25rem;
  vertical-align: middle;
}

a.tag-addon {
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s, background 0.3s;
}

a.tag-addon:hover {
  background: rgba(0, 0, 0, 0.16);
  color: inherit;
}

.tag-avatar {
  border-radius: 3px 0 0 3px;
  height: 1.5rem;
  margin: 0 0.5rem 0 -0.5rem;
  width: 1.5rem;
}

.tag-blue {
  background-color: #083dc1;
  color: #fff;
}

.tag-indigo {
  background-color: #6574cd;
  color: #fff;
}

.tag-purple {
  background-color: #aa4cf2;
  color: #fff;
}

.tag-pink {
  background-color: #ec82ef;
  color: #fff;
}

.tag-red {
  background-color: #dc0441;
  color: #fff;
}

.tag-orange {
  background-color: #fc7303;
  color: #fff;
}

.tag-yellow {
  background-color: #ecb403;
  color: #fff;
}

.tag-green {
  background-color: #2dce89;
  color: #fff;
}

.tag-teal {
  background-color: #2bcbba;
  color: #fff;
}

.tag-cyan {
  background-color: #116e7c;
  color: #fff;
}

.tag-white {
  background-color: #fff;
  color: #fff;
}

.tag-gray {
  background-color: #868e96;
  color: #fff;
}

.tag-gray-dark {
  background-color: #343a40;
  color: #fff;
}

.tag-azure {
  background-color: #45aaf2;
  color: #fff;
}

.tag-lime {
  background-color: #7bd235;
  color: #fff;
}

.tag-success {
  background-color: #2dce89;
  color: #fff;
}

.tag-info {
  background-color: #45aaf2;
  color: #fff;
}

.tag-warning {
  background-color: #ecb403;
  color: #fff;
}

.tag-danger {
  background-color: #dc0441;
  color: #fff;
}

.tag-light {
  background-color: #f8f9fa;
  color: #fff;
}

.tag-dark {
  background-color: #343a40;
  color: #fff;
}

.tag-rounded,
.tag-rounded .tag-avatar {
  border-radius: 50px;
}

.tags {
  font-size: 0;
  margin-bottom: -0.5rem;
}

.tags > .tag {
  margin-bottom: 0.5rem;
}

.tags > .tag:not(:last-child) {
  margin-right: 0.5rem;
}

.highlight .hll {
  background-color: #ffc;
}

.highlight .c {
  color: #999;
}

.highlight .k {
  color: #069;
}

.highlight .o {
  color: #555;
}

.highlight .cm {
  color: #999;
}

.highlight .cp {
  color: #099;
}

.highlight .c1,
.highlight .cs {
  color: #999;
}

.highlight .gd {
  background-color: #fcc;
  border: 1px solid #c00;
}

.highlight .ge {
  font-style: italic;
}

.highlight .gr {
  color: red;
}

.highlight .gh {
  color: #030;
}

.highlight .gi {
  background-color: #cfc;
  border: 1px solid #0c0;
}

.highlight .go {
  color: #aaa;
}

.highlight .gp {
  color: #009;
}

.highlight .gu {
  color: #030;
}

.highlight .gt {
  color: #9c6;
}

.highlight .kc,
.highlight .kd,
.highlight .kn,
.highlight .kp,
.highlight .kr {
  color: #069;
}

.highlight .kt {
  color: #078;
}

.highlight .m {
  color: #f60;
}

.highlight .s {
  color: #c09;
}

.highlight .na {
  color: #00cc7a;
}

.highlight .nb {
  color: #366;
}

.highlight .nc {
  color: #0a8;
}

.highlight .no {
  color: #360;
}

.highlight .nd {
  color: #99f;
}

.highlight .ni {
  color: #999;
}

.highlight .ne {
  color: #c00;
}

.highlight .nf {
  color: #c0f;
}

.highlight .nl {
  color: #99f;
}

.highlight .nn {
  color: #0cf;
}

.highlight .nt {
  color: #6967ce;
}

.highlight .nv {
  color: #033;
}

.highlight .ow {
  color: #000;
}

.highlight .w {
  color: #bbb;
}

.highlight .mf,
.highlight .mh,
.highlight .mi,
.highlight .mo {
  color: #f60;
}

.highlight .sb,
.highlight .sc {
  color: #c30;
}

.highlight .sd {
  color: #c30;
  font-style: italic;
}

.highlight .s2,
.highlight .se,
.highlight .sh {
  color: #c30;
}

.highlight .si {
  color: #a00;
}

.highlight .sx {
  color: #c30;
}

.highlight .sr {
  color: #3aa;
}

.highlight .s1 {
  color: #c30;
}

.highlight .ss {
  color: #fc3;
}

.highlight .bp {
  color: #366;
}

.highlight .vc,
.highlight .vg,
.highlight .vi {
  color: #033;
}

.highlight .il {
  color: #f60;
}

.highlight .css .nt + .nt,
.highlight .css .o,
.highlight .css .o + .nt {
  color: #999;
}

.highlight .language-bash:before,
.highlight .language-sh:before {
  color: #009;
  content: "$ ";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.highlight .language-powershell:before {
  color: #009;
  content: "PM> ";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.thumbnail {
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 4px;
  display: block;
  line-height: 1.42857143;
  margin-bottom: 20px;
  padding: 4px;
  transition: border 0.2s ease-in-out;
}

.thumbnail > img,
.thumbnail a > img {
  margin-left: auto;
  margin-right: auto;
}

.thumbnail p:last-child {
  margin-bottom: 0;
}

.thumbimg {
  display: block;
  height: 100%;
  width: 100%;
}

.thumbnail .caption {
  padding: 15px;
}

.video-list-thumbs > li {
  margin-bottom: 12px;
}

.video-list-thumbs > li > a {
  border: 1px solid #e6ebf1;
  border-radius: 3px transition all 0.5s ease-in-out;
  border-radius: 4px;
  color: #6b6f80;
  display: block;
  padding: 8px;
  position: relative;
}

.video-list-thumbs h2 {
  bottom: 0;
  font-size: 14px;
  height: 33px;
  margin: 8px 0 0;
}

.video-list-thumbs .fa {
  font-size: 60px;
  opacity: 0.6;
  position: absolute;
  right: 39%;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  top: 31%;
  transition: all 0.5s ease-in-out;
}

.video-list-thumbs > li > a:hover .fa {
  color: #fff;
  opacity: 1;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
}

.video-list-thumbs .duration {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  bottom: 12px;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  left: 12px;
  line-height: 13px;
  padding: 2px 3px 1px;
  position: absolute;
  transition: all 0.5s ease;
}

.video-list-thumbs > li > a:hover .duration {
  background-color: #000;
}

@media (max-width: 768px) {
  .video-list-thumbs .fa {
    right: 44%;
    top: 44%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .video-list-thumbs .fa {
    font-size: 35px;
  }

  .video-list-thumbs h2 {
    bottom: 0;
    font-size: 12px;
    height: 22px;
    margin: 8px 0 0;
  }
}

.bd-example {
  border-width: 0.2rem 0 0;
  margin: 1rem -1rem;
  padding: 1rem;
  position: relative;
}

.bd-example-tooltip-static .tooltip {
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
  position: relative;
}

.tooltip.bs-tether-element-attached-bottom,
.tooltip.tooltip-top {
  margin-top: -3px;
  padding: 5px 0;
}

.tooltip {
  word-wrap: break-word;
  display: block;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  opacity: 0;
  position: absolute;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
}

.tooltip-inner {
  background-color: #000;
  border-radius: 0.25rem;
  border-radius: 3px;
  color: #fff;
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  text-align: center;
}

.tooltip.bs-tether-element-attached-left,
.tooltip.tooltip-right {
  margin-left: 3px;
  padding: 0 5px;
}

.tooltip.bs-tether-element-attached-bottom .tooltip-inner:before,
.tooltip.tooltip-top .tooltip-inner:before {
  border-top-color: #000;
  border-width: 5px 5px 0;
  bottom: 0;
  content: "";
  left: 50%;
  margin-left: -5px;
}

.tooltip.bs-tether-element-attached-left .tooltip-inner:before,
.tooltip.tooltip-right .tooltip-inner:before {
  border-right-color: #000;
  border-width: 5px 5px 5px 0;
  content: "";
  left: 0;
  margin-top: -5px;
  top: 50%;
}

.tooltip.bs-tether-element-attached-top,
.tooltip.tooltip-bottom {
  margin-top: 3px;
  padding: 5px 0;
}

.tooltip.bs-tether-element-attached-top .tooltip-inner:before,
.tooltip.tooltip-bottom .tooltip-inner:before {
  border-bottom-color: #000;
  border-width: 0 5px 5px;
  content: "";
  left: 50%;
  margin-left: -5px;
  top: 0;
}

.tooltip.bs-tether-element-attached-right,
.tooltip.tooltip-left {
  margin-left: -3px;
  padding: 0 5px;
}

.tooltip.bs-tether-element-attached-right .tooltip-inner:before,
.tooltip.tooltip-left .tooltip-inner:before {
  border-left-color: #000;
  border-width: 5px 0 5px 5px;
  content: "";
  margin-top: -5px;
  right: 0;
  top: 50%;
}

.tooltip-inner:before {
  border-color: transparent;
  border-style: solid;
  height: 0;
  position: absolute;
  width: 0;
}

.popinfo {
  color: #88c8f7;
}

.popsuccess {
  color: #bfff80;
}

.popdanger {
  color: #ea6262;
}

.popwarning {
  color: #f7db6e;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  display: block;
  height: 0.4rem;
  position: absolute;
  width: 0.8rem;
}

.tooltip .tooltip-arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  position: absolute;
}

.bs-tooltip-auto[data-popper-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before,
.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #000;
  border-width: 0.4rem 0.4rem 0;
  top: 0;
}

.bs-tooltip-auto[data-popper-placement^="right"],
.bs-tooltip-end {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  height: 0.8rem;
  left: 0;
  width: 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
  border-right-color: #000;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  right: 0;
}

.bs-tooltip-auto[data-popper-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before,
.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: #000;
  border-width: 0 0.4rem 0.4rem;
  bottom: 0;
}

.bs-tooltip-auto[data-popper-placement^="left"],
.bs-tooltip-start {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  height: 0.8rem;
  right: 0;
  width: 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before,
.bs-tooltip-start .tooltip-arrow:before {
  border-left-color: #000;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  left: 0;
}

.bs-popover-auto[data-popper-placement^="top"] .popover-arrow,
.bs-popover-top .popover-arrow {
  bottom: calc(-0.5rem + -1px);
}

.bs-popover-auto[data-popper-placement^="top"] .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="top"] .popover-arrow:before,
.bs-popover-top .popover-arrow:after,
.bs-popover-top .popover-arrow:before {
  border-width: 0.5rem 0.25rem 0;
}

.bs-popover-auto[data-popper-placement^="top"] .popover-arrow:before,
.bs-popover-top .popover-arrow:before {
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
}

.bs-popover-auto[data-popper-placement^="top"] .popover-arrow:after,
.bs-popover-top .popover-arrow:after {
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-auto[data-popper-placement^="right"],
.bs-popover-end {
  margin-left: 0.5rem;
}

.bs-popover-auto[data-popper-placement^="right"] .popover-arrow,
.bs-popover-end .popover-arrow {
  height: 0.5rem;
  left: calc(-0.5rem + -1px);
  margin: 3px 0;
  width: 0.5rem;
}

.bs-popover-auto[data-popper-placement^="right"] .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="right"] .popover-arrow:before,
.bs-popover-end .popover-arrow:after,
.bs-popover-end .popover-arrow:before {
  border-width: 0.25rem 0.5rem 0.25rem 0;
}

.bs-popover-auto[data-popper-placement^="right"] .popover-arrow:before,
.bs-popover-end .popover-arrow:before {
  border-right-color: #dee3eb;
  left: 0;
}

.bs-popover-auto[data-popper-placement^="right"] .popover-arrow:after,
.bs-popover-end .popover-arrow:after {
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow,
.bs-popover-bottom .popover-arrow {
  top: calc(-0.5rem + -1px);
}

.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow:before,
.bs-popover-bottom .popover-arrow:after,
.bs-popover-bottom .popover-arrow:before {
  border-width: 0 0.25rem 0.5rem;
}

.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow:before,
.bs-popover-bottom .popover-arrow:before {
  border-bottom-color: #dee3eb;
  top: 0;
}

.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow:after,
.bs-popover-bottom .popover-arrow:after {
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before,
.bs-popover-bottom .popover-header:before {
  border-bottom: 1px solid #fff;
  content: "";
  display: block;
  left: 50%;
  margin-left: -0.25rem;
  position: absolute;
  top: 0;
  width: 0.5rem;
}

.bs-popover-auto[data-popper-placement^="left"],
.bs-popover-start {
  margin-right: 0.5rem;
}

.bs-popover-auto[data-popper-placement^="left"] .popover-arrow,
.bs-popover-start .popover-arrow {
  height: 0.5rem;
  margin: 3px 0;
  right: calc(-0.5rem + -1px);
  width: 0.5rem;
}

.bs-popover-auto[data-popper-placement^="left"] .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="left"] .popover-arrow:before,
.bs-popover-start .popover-arrow:after,
.bs-popover-start .popover-arrow:before {
  border-width: 0.25rem 0 0.25rem 0.5rem;
}

.bs-popover-auto[data-popper-placement^="left"] .popover-arrow:before,
.bs-popover-start .popover-arrow:before {
  border-left-color: #dee3eb;
  right: 0;
}

.bs-popover-auto[data-popper-placement^="left"] .popover-arrow:after,
.bs-popover-start .popover-arrow:after {
  border-left-color: #fff;
  right: 1px;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #6e7687;
  padding: 0.75rem 1rem;
}

.popover.bs-popover-auto[data-popper-placement^="top"],
.popover.bs-popover-top {
  margin-bottom: 0.625rem;
}

.dropdown-item {
  color: #6c756e;
}

.dropdown-item .bd-example {
  border-width: 0.2rem 0 0;
  margin: 1rem -1rem;
  padding: 1rem;
  position: relative;
}

.dropdown-item .bd-example-tooltip-static .tooltip {
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
  position: relative;
}

.dropdown-item .tooltip {
  word-wrap: break-word;
  display: block;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  opacity: 0;
  position: absolute;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: 1070;
}

.dropdown-item .tooltip.bs-tether-element-attached-bottom,
.dropdown-item .tooltip.tooltip-top {
  margin-top: -3px;
  padding: 5px 0;
}

.dropdown-item .tooltip-inner {
  background-color: #000;
  border-radius: 0.25rem;
  border-radius: 3px;
  color: #fff;
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  text-align: center;
}

.dropdown-item .tooltip.bs-tether-element-attached-left,
.dropdown-item .tooltip.tooltip-right {
  margin-left: 3px;
  padding: 0 5px;
}

.dropdown-item .tooltip.bs-tether-element-attached-bottom .tooltip-inner:before,
.dropdown-item .tooltip.tooltip-top .tooltip-inner:before {
  border-top-color: #000;
  border-width: 5px 5px 0;
  bottom: 0;
  content: "";
  left: 50%;
  margin-left: -5px;
}

.dropdown-item .tooltip.bs-tether-element-attached-left .tooltip-inner:before,
.dropdown-item .tooltip.tooltip-right .tooltip-inner:before {
  border-right-color: #000;
  border-width: 5px 5px 5px 0;
  content: "";
  left: 0;
  margin-top: -5px;
  top: 50%;
}

.dropdown-item .tooltip.bs-tether-element-attached-top,
.dropdown-item .tooltip.tooltip-bottom {
  margin-top: 3px;
  padding: 5px 0;
}

.dropdown-item .tooltip.bs-tether-element-attached-top .tooltip-inner:before,
.dropdown-item .tooltip.tooltip-bottom .tooltip-inner:before {
  border-bottom-color: #000;
  border-width: 0 5px 5px;
  content: "";
  left: 50%;
  margin-left: -5px;
  top: 0;
}

.dropdown-item .tooltip.bs-tether-element-attached-right,
.dropdown-item .tooltip.tooltip-left {
  margin-left: -3px;
  padding: 0 5px;
}

.dropdown-item .tooltip.bs-tether-element-attached-right .tooltip-inner:before,
.dropdown-item .tooltip.tooltip-left .tooltip-inner:before {
  border-left-color: #000;
  border-width: 5px 0 5px 5px;
  content: "";
  margin-top: -5px;
  right: 0;
  top: 50%;
}

.dropdown-item .tooltip-inner:before {
  border-color: transparent;
  border-style: solid;
  height: 0;
  position: absolute;
  width: 0;
}

.dropdown-item .popinfo {
  color: #88c8f7;
}

.dropdown-item .popsuccess {
  color: #bfff80;
}

.dropdown-item .popdanger {
  color: #ea6262;
}

.dropdown-item .popwarning {
  color: #f7db6e;
}

.dropdown-item .tooltip.show {
  opacity: 0.9;
}

.dropdown-item .tooltip .tooltip-arrow {
  display: block;
  height: 0.4rem;
  position: absolute;
  width: 0.8rem;
}

.dropdown-item .tooltip .tooltip-arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  position: absolute;
}

.dropdown-item .bs-tooltip-auto[data-popper-placement^="top"],
.dropdown-item .bs-tooltip-top {
  padding: 0.4rem 0;
}

.dropdown-item .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.dropdown-item .bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}

.dropdown-item
  .bs-tooltip-auto[data-popper-placement^="top"]
  .tooltip-arrow:before,
.dropdown-item .bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #000;
  border-width: 0.4rem 0.4rem 0;
  top: 0;
}

.dropdown-item .bs-tooltip-auto[data-popper-placement^="right"],
.dropdown-item .bs-tooltip-end {
  padding: 0 0.4rem;
}

.dropdown-item .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.dropdown-item .bs-tooltip-end .tooltip-arrow {
  height: 0.8rem;
  left: 0;
  width: 0.4rem;
}

.dropdown-item
  .bs-tooltip-auto[data-popper-placement^="right"]
  .tooltip-arrow:before,
.dropdown-item .bs-tooltip-end .tooltip-arrow:before {
  border-right-color: #000;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  right: 0;
}

.dropdown-item .bs-tooltip-auto[data-popper-placement^="bottom"],
.dropdown-item .bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.dropdown-item .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
.dropdown-item .bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}

.dropdown-item
  .bs-tooltip-auto[data-popper-placement^="bottom"]
  .tooltip-arrow:before,
.dropdown-item .bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: #000;
  border-width: 0 0.4rem 0.4rem;
  bottom: 0;
}

.dropdown-item .bs-tooltip-auto[data-popper-placement^="left"],
.dropdown-item .bs-tooltip-start {
  padding: 0 0.4rem;
}

.dropdown-item .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.dropdown-item .bs-tooltip-start .tooltip-arrow {
  height: 0.8rem;
  right: 0;
  width: 0.4rem;
}

.dropdown-item
  .bs-tooltip-auto[data-popper-placement^="left"]
  .tooltip-arrow:before,
.dropdown-item .bs-tooltip-start .tooltip-arrow:before {
  border-left-color: #000;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  left: 0;
}

.dropdown-item .bs-popover-auto[data-popper-placement^="top"] .popover-arrow,
.dropdown-item .bs-popover-top .popover-arrow {
  bottom: calc(-0.5rem + -1px);
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="top"]
  .popover-arrow:after,
.dropdown-item
  .bs-popover-auto[data-popper-placement^="top"]
  .popover-arrow:before,
.dropdown-item .bs-popover-top .popover-arrow:after,
.dropdown-item .bs-popover-top .popover-arrow:before {
  border-width: 0.5rem 0.25rem 0;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="top"]
  .popover-arrow:before,
.dropdown-item .bs-popover-top .popover-arrow:before {
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="top"]
  .popover-arrow:after,
.dropdown-item .bs-popover-top .popover-arrow:after {
  border-top-color: #fff;
  bottom: 1px;
}

.dropdown-item .bs-popover-auto[data-popper-placement^="right"],
.dropdown-item .bs-popover-end {
  margin-left: 0.5rem;
}

.dropdown-item .bs-popover-auto[data-popper-placement^="right"] .popover-arrow,
.dropdown-item .bs-popover-end .popover-arrow {
  height: 0.5rem;
  left: calc(-0.5rem + -1px);
  margin: 3px 0;
  width: 0.5rem;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="right"]
  .popover-arrow:after,
.dropdown-item
  .bs-popover-auto[data-popper-placement^="right"]
  .popover-arrow:before,
.dropdown-item .bs-popover-end .popover-arrow:after,
.dropdown-item .bs-popover-end .popover-arrow:before {
  border-width: 0.25rem 0.5rem 0.25rem 0;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="right"]
  .popover-arrow:before,
.dropdown-item .bs-popover-end .popover-arrow:before {
  border-right-color: #dee3eb;
  left: 0;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="right"]
  .popover-arrow:after,
.dropdown-item .bs-popover-end .popover-arrow:after {
  border-right-color: #fff;
  left: 1px;
}

.dropdown-item .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow,
.dropdown-item .bs-popover-bottom .popover-arrow {
  top: calc(-0.5rem + -1px);
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="bottom"]
  .popover-arrow:after,
.dropdown-item
  .bs-popover-auto[data-popper-placement^="bottom"]
  .popover-arrow:before,
.dropdown-item .bs-popover-bottom .popover-arrow:after,
.dropdown-item .bs-popover-bottom .popover-arrow:before {
  border-width: 0 0.25rem 0.5rem;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="bottom"]
  .popover-arrow:before,
.dropdown-item .bs-popover-bottom .popover-arrow:before {
  border-bottom-color: #dee3eb;
  top: 0;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="bottom"]
  .popover-arrow:after,
.dropdown-item .bs-popover-bottom .popover-arrow:after {
  border-bottom-color: #fff;
  top: 1px;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="bottom"]
  .popover-header:before,
.dropdown-item .bs-popover-bottom .popover-header:before {
  border-bottom: 1px solid #fff;
  content: "";
  display: block;
  left: 50%;
  margin-left: -0.25rem;
  position: absolute;
  top: 0;
  width: 0.5rem;
}

.dropdown-item .bs-popover-auto[data-popper-placement^="left"],
.dropdown-item .bs-popover-start {
  margin-right: 0.5rem;
}

.dropdown-item .bs-popover-auto[data-popper-placement^="left"] .popover-arrow,
.dropdown-item .bs-popover-start .popover-arrow {
  height: 0.5rem;
  margin: 3px 0;
  right: calc(-0.5rem + -1px);
  width: 0.5rem;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="left"]
  .popover-arrow:after,
.dropdown-item
  .bs-popover-auto[data-popper-placement^="left"]
  .popover-arrow:before,
.dropdown-item .bs-popover-start .popover-arrow:after,
.dropdown-item .bs-popover-start .popover-arrow:before {
  border-width: 0.25rem 0 0.25rem 0.5rem;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="left"]
  .popover-arrow:before,
.dropdown-item .bs-popover-start .popover-arrow:before {
  border-left-color: #dee3eb;
  right: 0;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="left"]
  .popover-arrow:after,
.dropdown-item .bs-popover-start .popover-arrow:after {
  border-left-color: #fff;
  right: 1px;
}

.dropdown-item .popover-header:empty {
  display: none;
}

.dropdown-item .popover-body {
  color: #6e7687;
  padding: 0.75rem 1rem;
}

.dropdown-item .popover.bs-popover-auto[data-popper-placement^="top"],
.dropdown-item .popover.bs-popover-top {
  margin-bottom: 0.625rem;
}

.dropdown-item .dropdown-item {
  color: #6c756e;
}

.dropdown-item .jqstooltip {
  background: #333 !important;
  border: none !important;
  border-radius: 3px;
  box-sizing: content-box;
  font-family: inherit !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  padding: 6px !important;
}

.dropdown-item .jqstooltip .jqsfield {
  font: inherit !important;
}

.dropdown-item .tooltip-static-demo {
  background-color: #f3f4f7;
  padding: 20px;
  text-align: center;
}

.dropdown-item .tooltip-static-demo .tooltip {
  display: inline-block;
  margin: 0 10px;
  opacity: 1;
  position: relative;
  z-index: 0;
}

.dropdown-item
  .tooltip-static-demo
  .bs-tooltip-auto[data-popper-placement^="bottom"]
  .tooltip-arrow,
.dropdown-item
  .tooltip-static-demo
  .bs-tooltip-auto[data-popper-placement^="top"]
  .tooltip-arrow,
.dropdown-item .tooltip-static-demo .bs-tooltip-bottom .tooltip-arrow,
.dropdown-item .tooltip-static-demo .bs-tooltip-top .tooltip-arrow {
  left: 50%;
  margin-left: -2px;
}

.dropdown-item
  .tooltip-static-demo
  .bs-tooltip-auto[data-popper-placement^="left"],
.dropdown-item
  .tooltip-static-demo
  .bs-tooltip-auto[data-popper-placement^="right"],
.dropdown-item .tooltip-static-demo .bs-tooltip-end,
.dropdown-item .tooltip-static-demo .bs-tooltip-start {
  margin-top: 5px;
}

.dropdown-item
  .tooltip-static-demo
  .bs-tooltip-auto[data-popper-placement^="left"]
  .tooltip-arrow,
.dropdown-item
  .tooltip-static-demo
  .bs-tooltip-auto[data-popper-placement^="right"]
  .tooltip-arrow,
.dropdown-item .tooltip-static-demo .bs-tooltip-end .tooltip-arrow,
.dropdown-item .tooltip-static-demo .bs-tooltip-start .tooltip-arrow {
  margin-top: -3px;
  top: 50%;
}

.dropdown-item .popover-static-demo {
  background-color: #f0f0f2;
  border: 1px solid #e6ebf1;
  border-radius: 3px;
  padding-bottom: 40px;
  padding-top: 40px;
  text-align: center;
}

.dropdown-item .popover-static-demo .popover {
  display: inline-block;
  margin: 0 10px;
  opacity: 1;
  position: relative;
  z-index: 0;
}

.dropdown-item
  .popover-static-demo
  .bs-popover-auto[data-popper-placement^="bottom"]
  .popover-arrow,
.dropdown-item
  .popover-static-demo
  .bs-popover-auto[data-popper-placement^="top"]
  .popover-arrow,
.dropdown-item .popover-static-demo .bs-popover-bottom .popover-arrow,
.dropdown-item .popover-static-demo .bs-popover-top .popover-arrow {
  left: 50%;
  margin-left: -5px;
}

.dropdown-item
  .popover-static-demo
  .bs-popover-auto[data-popper-placement^="left"],
.dropdown-item
  .popover-static-demo
  .bs-popover-auto[data-popper-placement^="right"],
.dropdown-item .popover-static-demo .bs-popover-end,
.dropdown-item .popover-static-demo .bs-popover-start {
  margin-top: 5px;
}

.dropdown-item
  .popover-static-demo
  .bs-popover-auto[data-popper-placement^="left"]
  .popover-arrow,
.dropdown-item
  .popover-static-demo
  .bs-popover-auto[data-popper-placement^="right"]
  .popover-arrow,
.dropdown-item .popover-static-demo .bs-popover-end .popover-arrow,
.dropdown-item .popover-static-demo .bs-popover-start .popover-arrow {
  margin-top: -5px;
  top: 50%;
}

.dropdown-item .popover {
  word-wrap: break-word;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #dee3eb;
  border-radius: 3px;
  display: block;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  left: 0;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  max-width: 276px;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  top: 0;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: 99999;
}

.dropdown-item .popover .popover-arrow {
  display: block;
  height: 0.5rem;
  margin: 0 3px;
  position: absolute;
  width: 1rem;
  width: 0.5rem;
}

.dropdown-item .popover .popover-arrow:after,
.dropdown-item .popover .popover-arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
}

.dropdown-item .bs-popover-auto[data-popper-placement^="top"],
.dropdown-item .bs-popover-top {
  margin-bottom: 0.5rem;
}

.dropdown-item .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
.dropdown-item .bs-popover-top > .popover-arrow {
  bottom: calc(-0.5rem + -1px);
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="top"]
  > .popover-arrow:before,
.dropdown-item .bs-popover-top > .popover-arrow:before {
  border-top-color: #d3dfea;
  border-width: 0.5rem 0.5rem 0;
  bottom: 0;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="top"]
  > .popover-arrow:after,
.dropdown-item .bs-popover-top > .popover-arrow:after {
  border-top-color: #fff;
  border-width: 0.5rem 0.5rem 0;
  bottom: 1px;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="right"]
  > .popover-arrow,
.dropdown-item .bs-popover-end > .popover-arrow {
  height: 1rem;
  left: calc(-0.5rem + -1px);
  margin: 0.3rem 0;
  width: 0.5rem;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="right"]
  > .popover-arrow:before,
.dropdown-item .bs-popover-end > .popover-arrow:before {
  border-right-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 0;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="right"]
  > .popover-arrow:after,
.dropdown-item .bs-popover-end > .popover-arrow:after {
  border-right-color: #fff;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 1px;
}

.dropdown-item .bs-popover-auto[data-popper-placement^="bottom"],
.dropdown-item .bs-popover-bottom {
  margin-top: 0.5rem;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="bottom"]
  > .popover-arrow,
.dropdown-item .bs-popover-bottom > .popover-arrow {
  top: calc(-0.5rem + -1px);
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="bottom"]
  > .popover-arrow:before,
.dropdown-item .bs-popover-bottom > .popover-arrow:before {
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-width: 0 0.5rem 0.5rem;
  top: 0;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="bottom"]
  > .popover-arrow:after,
.dropdown-item .bs-popover-bottom > .popover-arrow:after {
  border-bottom-color: #fff;
  border-width: 0 0.5rem 0.5rem;
  top: 1px;
}

.dropdown-item .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
.dropdown-item .bs-popover-start > .popover-arrow {
  height: 1rem;
  margin: 0.3rem 0;
  right: calc(-0.5rem + -1px);
  width: 0.5rem;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="left"]
  > .popover-arrow:before,
.dropdown-item .bs-popover-start > .popover-arrow:before {
  border-left-color: #d3dfea;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 0;
}

.dropdown-item
  .bs-popover-auto[data-popper-placement^="left"]
  > .popover-arrow:after,
.dropdown-item .bs-popover-start > .popover-arrow:after {
  border-left-color: #fff;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 1px;
}

.dropdown-item .popover-header {
  background-color: #fff;
  background-color: #f7f7f7;
  border-color: #d3dfea;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-top-right-radius: calc(0.3rem - 1px);
  border-top-right-radius: 2px;
  color: #191621;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 0;
  padding: 0.5rem 0.75rem;
  text-transform: capitalize;
}

.dropdown-item .popover-header:before {
  display: none !important;
}

.dropdown-item .popover-body p:last-child {
  margin-bottom: 0;
}

.dropdown-item
  .popover-head-primary.bs-popover-auto[data-popper-placement^="top"]
  .popover-arrow,
.dropdown-item .popover-head-primary.bs-popover-top .popover-arrow {
  bottom: -7px;
}

.dropdown-item
  .popover-head-primary.bs-popover-auto[data-popper-placement^="left"]
  .popover-arrow,
.dropdown-item .popover-head-primary.bs-popover-start .popover-arrow {
  right: -7px;
}

.dropdown-item
  .popover-head-primary.bs-popover-auto[data-popper-placement^="right"]
  .popover-arrow,
.dropdown-item .popover-head-primary.bs-popover-end .popover-arrow {
  left: -7px;
}

.dropdown-item
  .popover-head-secondary.bs-popover-auto[data-popper-placement^="top"]
  .popover-arrow,
.dropdown-item .popover-head-secondary.bs-popover-top .popover-arrow {
  bottom: -7px;
}

.dropdown-item
  .popover-head-secondary.bs-popover-auto[data-popper-placement^="left"]
  .popover-arrow,
.dropdown-item .popover-head-secondary.bs-popover-start .popover-arrow {
  right: -7px;
}

.dropdown-item
  .popover-head-secondary.bs-popover-auto[data-popper-placement^="right"]
  .popover-arrow,
.dropdown-item .popover-head-secondary.bs-popover-end .popover-arrow {
  left: -7px;
}

.dropdown-item .popover-head-primary,
.dropdown-item .popover-head-primary .popover-header,
.dropdown-item .popover-head-secondary,
.dropdown-item .popover-head-secondary .popover-header {
  border: 0;
}

.dropdown-item .popover-head-primary .popover-body,
.dropdown-item .popover-head-secondary .popover-body {
  border: 1px solid rgba(20, 17, 45, 0.2);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top: 0 solid rgba(20, 17, 45, 0.2);
}

.dropdown-item
  .popover-head-primary.bs-popover-auto[data-popper-placement^="bottom"]
  .popover-arrow:before,
.dropdown-item .popover-head-primary.bs-popover-bottom .popover-arrow:before,
.dropdown-item
  .popover-head-secondary.bs-popover-auto[data-popper-placement^="bottom"]
  .popover-arrow:before,
.dropdown-item .popover-head-secondary.bs-popover-bottom .popover-arrow:before {
  display: none;
}

.dropdown-item .popover-primary,
.dropdown-item .popover-secondary {
  border-width: 0;
  padding: 15px;
}

.dropdown-item .popover-primary .popover-header,
.dropdown-item .popover-secondary .popover-header {
  background-color: transparent;
  border-bottom-width: 0;
  color: #fff;
  padding: 0 0 15px;
}

.dropdown-item .popover-primary .popover-body,
.dropdown-item .popover-secondary .popover-body {
  color: hsla(0, 0%, 100%, 0.75);
  padding: 0;
}

.dropdown-item .popover-primary .popover-arrow:before,
.dropdown-item .popover-secondary .popover-arrow:before {
  display: none;
}

.jqstooltip {
  background: #333 !important;
  border: none !important;
  border-radius: 3px;
  box-sizing: content-box;
  font-family: inherit !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  padding: 6px !important;
}

.jqstooltip .jqsfield {
  font: inherit !important;
}

.tooltip-static-demo {
  background-color: #f3f4f7;
  padding: 20px;
  text-align: center;
}

.tooltip-static-demo .tooltip {
  display: inline-block;
  margin: 0 10px;
  opacity: 1;
  position: relative;
  z-index: 0;
}

.tooltip-static-demo
  .bs-tooltip-auto[data-popper-placement^="bottom"]
  .tooltip-arrow,
.tooltip-static-demo
  .bs-tooltip-auto[data-popper-placement^="top"]
  .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-bottom .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-top .tooltip-arrow {
  left: 50%;
  margin-left: -2px;
}

.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="left"],
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="right"],
.tooltip-static-demo .bs-tooltip-end,
.tooltip-static-demo .bs-tooltip-start {
  margin-top: 5px;
}

.tooltip-static-demo
  .bs-tooltip-auto[data-popper-placement^="left"]
  .tooltip-arrow,
.tooltip-static-demo
  .bs-tooltip-auto[data-popper-placement^="right"]
  .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-end .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-start .tooltip-arrow {
  margin-top: -0.42rem;
  top: 50%;
}

.popover-static-demo {
  background-color: #f0f0f2;
  border: 1px solid #e6ebf1;
  border-radius: 3px;
  padding-bottom: 40px;
  padding-top: 40px;
  text-align: center;
}

.popover-static-demo .popover {
  display: inline-block;
  margin: 0 10px;
  opacity: 1;
  position: relative;
  z-index: 0;
}

.popover-static-demo
  .bs-popover-auto[data-popper-placement^="bottom"]
  .popover-arrow,
.popover-static-demo
  .bs-popover-auto[data-popper-placement^="top"]
  .popover-arrow,
.popover-static-demo .bs-popover-bottom .popover-arrow,
.popover-static-demo .bs-popover-top .popover-arrow {
  left: 50%;
  margin-left: -5px;
}

.popover-static-demo .bs-popover-auto[data-popper-placement^="left"],
.popover-static-demo .bs-popover-auto[data-popper-placement^="right"],
.popover-static-demo .bs-popover-end,
.popover-static-demo .bs-popover-start {
  margin-top: 5px;
}

.popover-static-demo
  .bs-popover-auto[data-popper-placement^="left"]
  .popover-arrow,
.popover-static-demo
  .bs-popover-auto[data-popper-placement^="right"]
  .popover-arrow,
.popover-static-demo .bs-popover-end .popover-arrow,
.popover-static-demo .bs-popover-start .popover-arrow {
  margin-top: -5px;
  top: 50%;
}

.popover {
  word-wrap: break-word;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #dee3eb;
  border-radius: 3px;
  display: block;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  left: 0;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  max-width: 276px;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  top: 0;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: 99999;
}

.popover .popover-arrow {
  display: block;
  height: 0.5rem;
  margin: 0 3px;
  position: absolute;
  width: 1rem;
  width: 0.5rem;
}

.popover .popover-arrow:after,
.popover .popover-arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
}

.bs-popover-auto[data-popper-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
.bs-popover-top > .popover-arrow {
  bottom: calc(-0.5rem + -1px);
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before,
.bs-popover-top > .popover-arrow:before {
  border-top-color: #d3dfea;
  border-width: 0.5rem 0.5rem 0;
  bottom: 0;
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after,
.bs-popover-top > .popover-arrow:after {
  border-top-color: #fff;
  border-width: 0.5rem 0.5rem 0;
  bottom: 1px;
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
.bs-popover-end > .popover-arrow {
  height: 1rem;
  left: calc(-0.5rem + -1px);
  margin: 0.3rem 0;
  width: 0.5rem;
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before,
.bs-popover-end > .popover-arrow:before {
  border-right-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 0;
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after,
.bs-popover-end > .popover-arrow:after {
  border-right-color: #fff;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 1px;
}

.bs-popover-auto[data-popper-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow,
.bs-popover-bottom > .popover-arrow {
  top: calc(-0.5rem + -1px);
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before,
.bs-popover-bottom > .popover-arrow:before {
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-width: 0 0.5rem 0.5rem;
  top: 0;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after,
.bs-popover-bottom > .popover-arrow:after {
  border-bottom-color: #fff;
  border-width: 0 0.5rem 0.5rem;
  top: 1px;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
.bs-popover-start > .popover-arrow {
  height: 1rem;
  margin: 0.3rem 0;
  right: calc(-0.5rem + -1px);
  width: 0.5rem;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before,
.bs-popover-start > .popover-arrow:before {
  border-left-color: #d3dfea;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 0;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after,
.bs-popover-start > .popover-arrow:after {
  border-left-color: #fff;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 1px;
}

.popover-header {
  background-color: #fff;
  background-color: #f7f7f7;
  border-color: #d3dfea;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-top-right-radius: calc(0.3rem - 1px);
  border-top-right-radius: 2px;
  color: #191621;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 0;
  padding: 0.5rem 0.75rem;
  text-transform: capitalize;
}

.popover-header:before {
  display: none !important;
}

.popover-body p:last-child {
  margin-bottom: 0;
}

.popover-head-primary.bs-popover-auto[data-popper-placement^="top"]
  .popover-arrow,
.popover-head-primary.bs-popover-top .popover-arrow {
  bottom: -7px;
}

.popover-head-primary.bs-popover-auto[data-popper-placement^="left"]
  .popover-arrow,
.popover-head-primary.bs-popover-start .popover-arrow {
  right: -7px;
}

.popover-head-primary.bs-popover-auto[data-popper-placement^="right"]
  .popover-arrow,
.popover-head-primary.bs-popover-end .popover-arrow {
  left: -7px;
}

.popover-head-secondary.bs-popover-auto[data-popper-placement^="top"]
  .popover-arrow,
.popover-head-secondary.bs-popover-top .popover-arrow {
  bottom: -7px;
}

.popover-head-secondary.bs-popover-auto[data-popper-placement^="left"]
  .popover-arrow,
.popover-head-secondary.bs-popover-start .popover-arrow {
  right: -7px;
}

.popover-head-secondary.bs-popover-auto[data-popper-placement^="right"]
  .popover-arrow,
.popover-head-secondary.bs-popover-end .popover-arrow {
  left: -7px;
}

.popover-head-primary,
.popover-head-primary .popover-header,
.popover-head-secondary,
.popover-head-secondary .popover-header {
  border: 0;
}

.popover-head-primary .popover-body,
.popover-head-secondary .popover-body {
  border: 1px solid rgba(20, 17, 45, 0.2);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top: 0 solid rgba(20, 17, 45, 0.2);
}

.popover-head-primary.bs-popover-auto[data-popper-placement^="bottom"]
  .popover-arrow:before,
.popover-head-primary.bs-popover-bottom .popover-arrow:before,
.popover-head-secondary.bs-popover-auto[data-popper-placement^="bottom"]
  .popover-arrow:before,
.popover-head-secondary.bs-popover-bottom .popover-arrow:before {
  display: none;
}

.popover-primary {
  border-width: 0;
  padding: 15px;
}

.popover-primary .popover-header {
  background-color: transparent;
  border-bottom-width: 0;
  color: #fff;
  padding: 0 0 15px;
}

.popover-primary .popover-body {
  color: hsla(0, 0%, 100%, 0.75);
  padding: 0;
}

.popover-primary .popover-arrow:before {
  display: none;
}

.popover-secondary {
  border-width: 0;
  padding: 15px;
}

.popover-secondary .popover-header {
  background-color: transparent;
  border-bottom-width: 0;
  color: #fff;
  padding: 0 0 15px;
}

.popover-secondary .popover-body {
  color: hsla(0, 0%, 100%, 0.75);
  padding: 0;
}

.popover-secondary .popover-arrow:before {
  display: none;
}

.heading-inverse {
  background-color: #333;
  color: #fff;
  padding: 5px;
}

.heading-primary {
  padding: 5px;
}

.heading-success {
  background-color: #38a01e;
  color: #fff;
  padding: 5px;
}

.heading-info {
  background-color: #45aaf2;
  color: #fff;
  padding: 5px;
}

.heading-warning {
  background-color: #ecb403;
  color: #fff;
  padding: 5px;
}

.heading-danger {
  background-color: #dc0441;
  color: #fff;
  padding: 5px;
}

.media {
  margin-top: 15px;
}

.media,
.media-body {
  zoom: 1;
  overflow: hidden;
}

.media-object {
  display: block;
}

.media img {
  height: 40px;
  width: 40px;
}

.media.media-lg img {
  height: 80px;
  width: 80px;
}

.media-right,
.media > .pull-right {
  padding-left: 10px;
}

.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top;
}

.media-left,
.media > .pull-left {
  padding-right: 10px;
}

.media-heading {
  margin-bottom: 5px;
  margin-top: 0;
}

.media-body h4 {
  font-size: 16px;
  font-weight: 600;
}

.media-middle {
  vertical-align: middle;
}

.media-bottom {
  vertical-align: bottom;
}

.fc-view.fc-listMonth-view,
.fc-view.fc-listWeek-view {
  border-width: 0;
}

.fc-view.fc-listMonth-view .fc-scroller,
.fc-view.fc-listWeek-view .fc-scroller {
  padding: 0 20px 20px;
}

.fc-view.fc-listMonth-view .fc-list-table,
.fc-view.fc-listWeek-view .fc-list-table {
  border-collapse: separate;
  display: block;
}

.fc-view.fc-listMonth-view .fc-list-table > tbody,
.fc-view.fc-listWeek-view .fc-list-table > tbody {
  display: flex;
  flex-wrap: wrap;
}

.fc-view.fc-listMonth-view .fc-list-heading,
.fc-view.fc-listWeek-view .fc-list-heading {
  flex: 0 0 20%;
  margin-top: 15px;
  max-width: 20%;
}

.fc-view.fc-listMonth-view .fc-list-heading:first-child,
.fc-view.fc-listMonth-view .fc-list-heading:first-child + .fc-list-item,
.fc-view.fc-listWeek-view .fc-list-heading:first-child,
.fc-view.fc-listWeek-view .fc-list-heading:first-child + .fc-list-item {
  margin-top: 0;
}

.fc-view.fc-listMonth-view .fc-list-heading td,
.fc-view.fc-listWeek-view .fc-list-heading td {
  background-color: transparent;
  border-width: 0;
  padding: 0;
}

.fc-view.fc-listMonth-view .fc-list-heading-main,
.fc-view.fc-listWeek-view .fc-list-heading-main {
  color: #737688;
  display: block;
  font-size: 11px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
}

.fc-view.fc-listMonth-view .fc-list-heading-main span:last-child,
.fc-view.fc-listWeek-view .fc-list-heading-main span:last-child {
  color: #495057;
  display: block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1;
}

.fc-view.fc-listMonth-view .fc-list-heading-main.now,
.fc-view.fc-listMonth-view .fc-list-heading-main.now span:last-child,
.fc-view.fc-listWeek-view .fc-list-heading-main.now,
.fc-view.fc-listWeek-view .fc-list-heading-main.now span:last-child {
  color: #4c84ff;
}

.fc-view.fc-listMonth-view .fc-list-item,
.fc-view.fc-listWeek-view .fc-list-item {
  display: flex;
  background-color: #fff;
  border-left: 4px solid transparent;
  flex: 0 0 calc(80% - 5px);
  flex-direction: column;
  margin-top: 15px;
  max-width: calc(80% - 5px);
}

.fc-view.fc-listMonth-view .fc-list-item:focus,
.fc-view.fc-listMonth-view .fc-list-item:hover,
.fc-view.fc-listWeek-view .fc-list-item:focus,
.fc-view.fc-listWeek-view .fc-list-item:hover {
  cursor: pointer;
}

.fc-view.fc-listMonth-view .fc-list-item:focus td,
.fc-view.fc-listMonth-view .fc-list-item:hover td,
.fc-view.fc-listWeek-view .fc-list-item:focus td,
.fc-view.fc-listWeek-view .fc-list-item:hover td {
  background-color: transparent;
}

.fc-view.fc-listMonth-view .fc-list-item > td,
.fc-view.fc-listWeek-view .fc-list-item > td {
  border-width: 0;
}

.fc-view.fc-listMonth-view .fc-list-item > td.fc-list-item-time,
.fc-view.fc-listWeek-view .fc-list-item > td.fc-list-item-time {
  border-right: 1px solid #e6ebf1;
  border-top: 1px solid #e6ebf1;
  box-sizing: border-box;
  color: #495057 !important;
}

.fc-view.fc-listMonth-view .fc-list-item > td.fc-list-item-title,
.fc-view.fc-listWeek-view .fc-list-item > td.fc-list-item-title {
  border-bottom: 1px solid #e9edf4;
  border-right: 1px solid #e9edf4;
  box-sizing: border-box;
}

.fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
.fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
  margin-left: 20%;
}

.fc-view.fc-listMonth-view .fc-list-item-marker,
.fc-view.fc-listWeek-view .fc-list-item-marker {
  display: none;
}

.fc-view.fc-listMonth-view .fc-list-item-time,
.fc-view.fc-listWeek-view .fc-list-item-time {
  font-size: 13px;
  padding: 12px 15px 2px;
  text-transform: capitalize;
  width: 100%;
}

.fc-view.fc-listMonth-view .fc-list-item-title,
.fc-view.fc-listWeek-view .fc-list-item-title {
  padding: 0 15px 12px;
}

.fc-view.fc-listMonth-view .fc-list-item-title a,
.fc-view.fc-listWeek-view .fc-list-item-title a {
  color: #14112d;
  display: block;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 5px;
}

.fc-view.fc-listMonth-view .fc-list-item-title .fc-desc,
.fc-view.fc-listWeek-view .fc-list-item-title .fc-desc {
  display: block;
  font-size: 13px;
  line-height: 1.375;
}

@media (min-width: 576px) {
  .fc-view.fc-listMonth-view .fc-list-heading,
  .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 15%;
    max-width: 15%;
  }

  .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
  .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
    margin-left: 15%;
  }

  .fc-view.fc-listMonth-view .fc-list-item,
  .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(85% - 5px);
    max-width: calc(85% - 5px);
  }
}

@media (min-width: 768px) {
  .fc-view.fc-listMonth-view .fc-list-heading,
  .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 12%;
    max-width: 12%;
  }

  .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
  .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
    margin-left: 12%;
  }

  .fc-view.fc-listMonth-view .fc-list-item,
  .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(88% - 5px);
    max-width: calc(88% - 5px);
  }
}

@media (min-width: 992px) {
  .fc-view.fc-listMonth-view .fc-list-heading,
  .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 10%;
    max-width: 10%;
  }

  .fc-view.fc-listMonth-view .fc-scroller,
  .fc-view.fc-listWeek-view .fc-scroller {
    padding: 0;
  }

  .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
  .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
    margin-left: 10%;
  }

  .fc-view.fc-listMonth-view .fc-list-item,
  .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(90% - 5px);
    max-width: calc(90% - 5px);
  }
}

@media (min-width: 1200px) {
  .fc-view.fc-listMonth-view .fc-list-heading,
  .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 8%;
    max-width: 8%;
  }

  .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
  .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
    margin-left: 8%;
  }

  .fc-view.fc-listMonth-view .fc-list-item,
  .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(92% - 5px);
    max-width: calc(92% - 5px);
  }
}

td.fc-today {
  background-color: #f3f7fd;
}

#messages-main {
  margin: 0 auto 1.5rem;
  position: relative;
}

#messages-main:after,
#messages-main:before {
  content: " ";
  display: table;
}

#messages-main .ms-user {
  background: #fff;
  border: 1px solid #eceff9;
  border-left: 0;
  padding: 15px;
}

#messages-main .ms-user > div {
  font-size: 11px;
  overflow: hidden;
  padding: 3px 5px 0 15px;
}

#messages-main #ms-compose {
  bottom: 120px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  position: fixed;
  right: 30px;
  z-index: 0;
}

@media (min-width: 992px) {
  #messages-main .ms-menu {
    border-right: 1px solid #e6ebf1;
    height: 100%;
    left: 0;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    width: 500px;
  }

  #messages-main .ms-body {
    padding-left: 500px;
  }

  .msb-reply {
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

@media (min-width: 480px) {
  .ah-actions.actions a.option-dots {
    line-height: normal;
  }
}

@media (max-width: 991px) {
  #messages-main .ms-menu {
    margin-bottom: 25px;
    z-index: 0;
  }

  #messages-main .ms-menu.toggled {
    display: block;
  }

  #messages-main .ms-body {
    overflow: hidden;
  }
}

@media (max-width: 480px) {
  .ah-actions {
    display: inline-block;
    float: none !important;
    text-align: center;
  }

  .d-flex.chat-user {
    display: none !important;
  }

  .ah-actions {
    margin-left: 10px !important;
  }

  .actions > li > a {
    padding: 0 5px !important;
  }
}

#ms-menu-trigger i {
  font-size: 21px;
}

#ms-menu-trigger.toggled i:before {
  content: "\f2ea";
}

.login-content:after {
  content: "";
}

.message-feed {
  padding: 20px;
}

#footer,
.fc-toolbar .ui-button,
.fileinput .thumbnail,
.four-zero,
.four-zero footer > a,
.ie-warning,
.login-content,
.login-navigation,
.pt-inner,
.pt-inner .pti-footer > a {
  text-align: center;
}

.message-feed.right > .pull-right {
  margin-left: 15px;
}

.message-feed:not(.right) .mf-content {
  background: rgba(102, 77, 201, 0.1);
  border-radius: 4px 12px 4px 12px;
  color: #000;
  margin-left: 15px;
  position: relative;
}

.message-feed.right .mf-content {
  color: #fff;
  margin-right: 15px;
  position: relative;
}

.mf-content {
  border-radius: 6px;
  display: inline-block;
  max-width: 100%;
  padding: 13px 20px;
}

.mf-date {
  color: #728096;
  display: block;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 7px;
}

.mf-date > i {
  font-size: 14px;
  line-height: 100%;
  position: relative;
  top: 1px;
}

.msb-reply {
  background: #fff;
  border: 1px solid #eceff9;
  border-bottom: 0;
  height: 50px;
  position: relative;
}

.four-zero,
.lc-block {
  box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
}

.msb-reply textarea {
  background: 0 0;
  border: 0;
  font-size: 13px;
  height: 50px;
  padding: 15px;
  resize: none;
  width: 100%;
}

.msb-reply button {
  background: 0 0;
  border: 1px solid #e6ebf1;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  color: #5c678f;
  font-size: 14px;
  height: 40px;
  position: absolute;
  right: 9px;
  top: 5px;
  width: 40px;
}

.ico-mt {
  margin-top: -0.5rem !important;
}

.msb-reply button:hover {
  background: #f2f2f2;
}

.list-group.lg-alt .list-group-item {
  border: 0;
}

.p-15 {
  padding: 15px !important;
}

.actions > li {
  display: inline-block;
}

.actions > li > a {
  border: 1px solid #e6ebf1;
  border-radius: 100%;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  display: block;
  font-size: 18px;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-right: 5px;
  text-align: center;
  vertical-align: middle;
  width: 2.5rem;
}

.actions > li > a > i {
  font-size: 16px;
}

#ms-menu-trigger {
  cursor: pointer;
  height: 100%;
  left: 0;
  padding-right: 10px;
  padding-top: 19px;
  position: absolute;
  text-align: right;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 50px;
}

.message-feed.right {
  text-align: right;
}

.ah-actions {
  float: right !important;
  margin-top: 7px;
  position: relative;
  z-index: 3;
}

.ms-body {
  background: #fff;
  border-radius: 7px;
}

.ms-menu-trigger {
  border: 1px solid #e6ebf1;
  border-radius: 100%;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  color: #5c678f;
  cursor: pointer;
  display: block;
  display: inline-block;
  float: left;
  font-size: 18px;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-top: 5px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 2.5rem;
}

.message-feed.media .media-body,
.message-feed.right .media-body {
  overflow: visible;
}

.amcharts-chart-div a,
.canvasjs-chart-container a {
  display: none !important;
}

.rotation {
  -webkit-animation: rotation 2s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

.amChartsLegright.amcharts-legright-div {
  display: none;
}

.input-icon .form-group.is-empty,
.input-icon .form-group.is-empty .form-control {
  margin-bottom: 0;
}

.inbox-message ul {
  margin: 0;
  padding: 0;
}

.inbox-message ul li {
  border-bottom: 1px solid #e6ebf1;
  list-style: none;
  padding: 15px 20px;
  position: relative;
}

.inbox-message ul li:focus,
.inbox-message ul li:hover {
  background: #f0f0f2;
  z-index: 0;
}

.chat-conatct-list .media:hover {
  z-index: inherit;
}

.inbox-message .message-avatar {
  left: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.message-avatar img {
  border-radius: 50%;
  display: inline-block;
  height: 54px;
  width: 54px;
}

.inbox-message .message-body {
  color: #6c756e;
  font-size: 15px;
  margin-left: 85px;
}

.message-body-heading h5 {
  color: #6c756e;
  display: inline-block;
  font-weight: 600;
  margin: 0 0 7px;
  padding: 0;
}

.message-body h5 span {
  border-radius: 50px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  line-height: 14px;
  margin-left: 5px;
  margin-top: -5px;
  padding: 4px 10px;
}

.message-body h5 span.unread {
  background: #07b107;
}

.message-body h5 span.important {
  background: #dd2027;
}

.message-body h5 span.prighting {
  background: #2196f3;
}

.message-body-heading span {
  float: right;
  font-size: 14px;
}

.messages-inbox .message-body p {
  font-size: 15px;
  line-height: 27px;
  margin: 0;
  padding: 0;
}

@media (max-width: 600px) {
  .job-box-filter label {
    text-align: center;
    width: 100%;
  }

  .message-body h5 span.prighting,
  .message-body h5 span.unread {
    display: none;
  }
}

.list-icons i {
  font-size: 56px;
}

.font-dot {
  margin-top: -12px;
}

.list-icons .card-footer i {
  font-size: 14px;
}

.chat-body-style {
  background-position: 50%;
  background-size: cover;
  max-height: 500px;
}

.chat-body-style:after {
  background: hsla(0, 0%, 100%, 0.2);
  position: absolute;
}

.chat-conatct-list {
  max-height: 555px;
  overflow-y: auto;
}

.chat-conatct-list .media {
  overflow: initial;
}

@media (min-width: 992px) {
  .ms-menu .tab-menu-heading {
    width: 499px;
  }
}

.ms-menu .tab-menu-heading {
  background: #fff;
  border-bottom: 1px solid #e6ebf1;
  border-left: 0;
  border-radius: 5px 0 0 0;
  border-right: 0;
  padding: 0 13px 0 17px;
  position: sticky;
  z-index: 9;
}

.ms-menu .tab-menu-heading .tabs-menu1 ul li {
  margin: 0 10px 0 0;
  width: 48%;
}

.ms-menu .tab-menu-heading .tabs-menu1 ul li .active {
  border: 0;
}

.ms-menu .tab-menu-heading .tabs-menu1 ul li a {
  border: 0 solid #e6ebf1;
  border-radius: 5px;
  font-weight: 600;
  padding: 28px 0;
  text-align: center;
}

.chat-conatct-list .list-group-item {
  position: relative;
}

.chat-conatct-list .list-group-item .chat-time {
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 15px;
}

.chat {
  margin-bottom: auto;
}

.chat .card {
  height: 600px;
}

.chat .card-header {
  padding: 1rem;
}

.contacts_body {
  white-space: nowrap;
}

.msg_card_body {
  overflow-y: auto;
}

.contacts_body .contacts {
  list-style: none;
  padding: 0;
}

.contacts_body .contacts li {
  border-bottom: 1px solid #ebeaf1;
  padding: 15px;
  width: 100% !important;
}

.contacts_body .active {
  background-color: #eff1f7;
}

.chatbox .user_img {
  border: 0 solid #f5f6fa;
  height: 50px;
  width: 50px;
}

.chatbox .user_img_msg {
  border: 1.5px solid #f5f6fa;
  height: 40px;
  width: 40px;
}

.chatbox .img_cont {
  height: 50px;
  position: relative;
  width: 50px;
}

.chatbox .img_cont_msg {
  display: contents;
  height: 40px;
  width: 40px;
}

.chat-box-single-line {
  margin: 7px 0 30px;
  position: relative;
  text-align: center;
}

.chat abbr.timestamp {
  background: #e3e2e5;
  border-radius: 4px;
  padding: 4px 14px;
}

.chatbox .user_info {
  margin-bottom: auto;
  margin-left: 15px;
  margin-top: auto;
}

.chatbox .user_info span {
  color: #464444;
}

.chatbox .action-header small {
  font-size: 10px;
}

.chat .video_cam {
  margin-left: 50px;
  margin-top: 5px;
}

.chat .video_cam span {
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  margin-right: 20px;
}

.chat .msg_cotainer {
  background-color: #e3def9;
  border-radius: 0 12px 0 12px;
  margin-bottom: auto;
  margin-left: 10px;
  margin-top: 5px;
  max-width: 70%;
  padding: 10px;
  position: relative;
}

.chat .msg_cotainer_sright {
  border-radius: 12px 0 12px 0;
  color: #fff;
  margin: 0 12px 10px 0;
  max-width: 70%;
  padding: 10px;
  position: relative;
}

.chat .msg_time {
  left: 0;
}

.chat .msg_time,
.chat .msg_time_sright {
  bottom: -18px;
  color: #3a374e;
  font-size: 10px;
  position: absolute;
}

.chat .msg_time_sright {
  right: 0;
}

.chat .msg_head {
  background: #60529f;
  box-shadow: 0 1px 15px 1px rgba(58, 58, 62, 0.4);
  position: relative;
}

#action_menu_btn {
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: 36px;
  top: 21px;
}

.action_menu {
  background-color: #fff;
  color: #646367;
  display: none;
  padding: 15px 0;
  position: absolute;
  right: 15px;
  top: 40px;
  z-index: 1;
}

.action_menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.chat .action-header {
  padding: 15px 13px 15px 17px;
  position: relative;
}

.chat .dropdown-menu.dropdown-menu-end.show {
  left: 0;
  margin-bottom: 5px;
  position: absolute;
  top: 10px !important;
  transform: translate3d(-176px, 22px, 0);
  width: 100%;
  will-change: transform;
}

.chat .msb-reply textarea {
  background: #faf9ff;
  border: 1px solid #ebeaf1;
  font-size: 13px;
  height: 44px;
  margin-left: -42px;
  padding: 12px 47px;
  resize: none;
  width: 100%;
}

.chat .dropdown-menu li {
  color: #5e629c;
  padding: 7px;
}

.chat .dropdown-menu li:hover {
  background: #faf9ff;
}

.chat .dropdown-menu li i {
  padding: 7px;
}

.action_menu ul li:hover {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.chat .actions > li {
  display: inline-block;
  padding: 0 0 0 2px;
}

.chat .actions:not(.a-alt) > li > a > i {
  color: #fff;
  line-height: unset;
}

.chat .justify-content-left,
.chat .justify-content-right {
  margin-bottom: 25px;
}

.construction .form-control {
  padding: 0.375rem 1rem;
}

.action-header {
  background: #f3f4f7;
  background: #e3e6ef;
  border-bottom: 1px solid #e6ebf1;
  padding: 15px 13px 15px 17px;
  position: relative;
}

.actions {
  list-style: none;
  margin: 4px 0 0;
  padding: 0;
}

.actions:not(.a-alt) > li > a > i {
  color: #664dc9;
}

.modal-content .actions > li > a {
  border-radius: 50%;
  color: #fff;
  display: block;
  height: 40px;
  line-height: 38px;
  margin: 0 auto;
  text-align: center;
  width: 40px;
}

.modal-content .actions > li > a:hover {
  background: rgba(0, 0, 0, 0.1);
}

.msb-reply-button .btn {
  line-height: 14px;
}

.modal-content.chat .card {
  border-radius: 4px 4px 0 0;
  box-shadow: none;
}

.ah-actions .option-dots {
  border-radius: 100%;
  color: #5c678f;
  display: block !important;
  font-size: 18px;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 10px 12px;
  text-align: center;
  width: 2.5rem;
}

.contact-href {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.chat-conatct-list li {
  position: relative;
}

.chat-conatct-list .option-dots {
  bottom: 0;
  line-height: normal;
  position: absolute;
  right: 10px;
  top: 18px;
  z-index: 100;
}

.modal-content .actions > li > a {
  border: 1px solid #e6ebf1;
  box-shadow: 0 2px 3px rgba(16, 16, 38, 0.1);
}

.ms-menu .tab-menu-heading .tabs-menu1 ul li .active {
  border-bottom: 2px solid #664dc9;
  border-radius: 0 !important;
}

.message-feed.right .mf-content {
  border-radius: 12px 4px 12px 4px;
}

@media (max-width: 991.98px) {
  .main-content-body-show .main-header-menu-icon {
    display: none;
  }

  .main-content-body-show .main-header-left .main-header-arrow {
    display: block;
  }

  .main-content-body-show .main-content-left-chat {
    display: none;
  }

  .main-content-body-show .main-content-body-chat {
    display: flex;
  }
}

@media (max-width: 767px) {
  .main-chat-footer .nav-link:first-child,
  .main-chat-footer .nav-link:nth-child(2) {
    display: none;
  }
}

.main-content-left-chat {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
}

@media (min-width: 992px) {
  .main-content-left-chat {
    border: 0;
    border-left: 0;
    width: auto;
  }

  .main-chat-footer {
    bottom: auto;
    left: auto;
    position: relative;
    right: auto;
  }

  .main-content-body-chat {
    display: flex;
  }

  .main-chat-list .media {
    margin: 0;
    padding: 12px 15px;
  }

  .main-chat-contacts-wrapper {
    padding: 10px 0 10px 15px;
  }

  .main-nav-line-chat {
    padding-right: 0;
  }
}

.main-nav-line-chat {
  border-bottom: 1px solid #e9edf4;
  flex-direction: row;
  flex-shrink: 0;
  padding: 10px;
}

.main-nav-line-chat .nav-link {
  background: #e9edf4;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
  margin-top: 0;
  padding: 6px 15px;
  text-transform: uppercase;
}

.main-nav-line-chat .nav-link:active,
.main-nav-line-chat .nav-link:focus,
.main-nav-line-chat .nav-link:hover {
  outline: none;
}

.main-nav-line-chat .nav-link.active:before {
  background-color: #4c84ff;
  bottom: -11px;
  left: 0;
  right: 0;
  top: auto;
  width: auto;
}

.main-chat-contacts-wrapper {
  border-bottom: 1px solid #e9edf4;
  flex-shrink: 0;
  overflow: hidden;
  padding: 10px 20px;
}

.card--eight .main-chat-contacts-wrapper .card-title,
.card-table-two .main-chat-contacts-wrapper .card-title,
.main-chat-contacts-wrapper .card--eight .card-title,
.main-chat-contacts-wrapper .card-table-two .card-title,
.main-chat-contacts-wrapper .main-content-label {
  line-height: 1;
  margin-bottom: 15px;
}

.main-chat-contacts-wrapper .lSSlideOuter,
.main-chat-contacts-wrapper .lSSlideWrapper {
  overflow: visible;
}

.main-chat-contacts {
  display: flex;
  padding-left: 20px;
}

.main-chat-contacts .lslide {
  align-items: center;
  display: flex;
  flex-direction: column;
  float: none;
}

.main-chat-contacts small {
  font-size: 11px;
  margin-top: 3px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 36px;
}

.main-chat-contacts-more {
  align-items: center;
  background-color: #ebeaed;
  border-radius: 100%;
  color: #6c756e;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 36px;
  justify-content: center;
  width: 36px;
}

.main-chat-list {
  max-height: 500px;
  position: relative;
}

.main-chat-list .media {
  border-bottom: 1px solid #e6ebf1;
  padding: 12px 20px;
  position: relative;
}

.main-chat-list .media .main-img-user {
  flex-shrink: 0;
  position: relative;
  top: 3px;
}

.main-chat-list .media:last-chid {
  border-bottom: 0;
}

.main-chat-list .media + .media:before {
  border-top: 1px solid #e9edf4;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: -1px;
}

.main-chat-list .media.new .main-img-user span {
  display: flex;
}

.main-chat-list .media.new .media-contact-name span:first-child {
  color: #14112d;
  font-weight: 700;
}

.main-chat-list .media.new .media-body p {
  color: #22252f;
}

.main-chat-list .media:focus,
.main-chat-list .media:hover {
  cursor: pointer;
}

.main-chat-list .media.selected,
.main-chat-list .media:focus,
.main-chat-list .media:hover {
  background-color: #f3f6f8;
  border-bottom-color: #e6ebf1;
  border-top-color: #e6ebf1;
}

.main-chat-list .media.selected:first-child {
  border-top-color: transparent;
}

.main-chat-list .media.selected:after {
  display: block;
}

.main-chat-list .media.selected .media-contact-name span:first-child {
  color: #14112d;
}

.main-chat-list .media.selected .media-body p {
  color: #22252f;
}

.main-chat-list .main-img-user span {
  align-items: center;
  background-color: #ff5c77;
  border-radius: 100%;
  box-shadow: 0 0 0 2px #fff;
  color: #fff;
  display: none;
  font-size: 9px;
  font-weight: 500;
  height: 14px;
  justify-content: center;
  left: -2px;
  position: absolute;
  top: 0;
  width: 14px;
}

.main-chat-list .media-body {
  margin-left: 15px;
}

.main-chat-list .media-body p {
  color: #728096;
  font-size: 13px;
  line-height: 1.35;
  margin-bottom: 0;
}

.main-chat-list .media-contact-name {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.main-chat-list .media-contact-name span:first-child {
  color: #14112d;
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.main-chat-list .media-contact-name span:last-child {
  color: #728096;
  display: block;
  font-size: 11px;
}

.main-content-body-chat {
  flex-direction: column;
}

.main-chat-header {
  align-items: center;
  border-bottom: 1px solid #e9edf4;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-left;
  padding: 14.2px 20px;
}

.main-chat-header .nav {
  align-items: center;
  margin-left: auto;
}

.main-chat-header .nav-link {
  border-radius: 50px;
  color: #5c678f;
  display: block;
  font-size: 18px;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-right: 5px;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 2.5rem;
}

.main-chat-header .nav-link:first-child {
  display: block;
}

.main-chat-header .nav-link i {
  line-height: 0;
}

.main-chat-header .nav-link .typcn {
  line-height: 0.9;
}

.main-chat-header .nav-link .typcn:before {
  width: auto;
}

@media (min-width: 576px) {
  .main-chat-header .nav-link {
    display: block;
  }

  .main-chat-header .nav-link:first-child {
    display: none;
  }

  .main-chat-footer .nav {
    margin-top: 5px;
  }

  .main-chat-footer .form-control {
    margin-left: 20px;
  }

  .main-chat-footer .nav-link {
    display: block;
  }

  .main-chat-footer .nav-link:last-child {
    display: none;
  }

  .main-chat-body .media-body {
    margin-right: 0;
  }

  .main-chat-body .media.flex-row-reverse .media-body {
    margin-left: 0;
  }
}

.main-chat-msg-name {
  margin-left: 15px;
}

.main-chat-msg-name h6 {
  color: #14112d;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 2px;
}

.main-chat-msg-name small {
  color: #728096;
  font-size: 12px;
}

.main-chat-body {
  max-height: 555px;
  overflow: hidden;
  padding-bottom: 0;
  position: relative;
}

.main-chat-body .content-inner {
  padding: 20px;
}

.main-chat-body .media + .media {
  margin-top: 20px;
}

.main-chat-body .media.flex-row-reverse .media-body {
  align-items: flex-end;
  margin-left: 0;
  margin-right: 5px;
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper {
  border-radius: 12px 4px 12px 4px;
  margin-right: 15px;
}

.main-chat-body .media-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.main-chat-body .media-body > div span {
  align-items: center;
  color: #728096;
  display: flex;
  font-size: 11px;
}

.main-chat-body .media.flex-row-reverse .media-body > div span {
  margin-left: 0;
  margin-right: 15px;
}

.main-chat-body .media .media-body > div span {
  margin-left: 15px;
  margin-right: 0;
}

.main-chat-time {
  display: block;
  margin: 20px 0;
  position: relative;
  text-align: center;
}

.main-chat-time:first-of-type {
  margin-top: 0;
}

.main-chat-time span {
  background: #e3e2e5;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 5px;
  text-transform: uppercase;
}

.main-msg-wrapper {
  background-color: rgba(102, 77, 201, 0.1);
  border-radius: 0;
  border-radius: 4px 12px 4px 12px;
  color: #000;
  display: inline-block;
  font-size: 13px;
  margin-bottom: 5px;
  margin-left: 10px;
  padding: 10px 15px;
  position: relative;
}

.main-chat-footer {
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  border-top: 1px solid #e9edf4;
  bottom: 0;
  display: flex;
  height: 50px;
  left: 0;
  padding: 0 20px;
  right: 0;
}

.main-chat-footer,
.main-chat-footer .nav {
  align-items: center;
}

.main-chat-footer .nav-link {
  border-radius: 100%;
  border-radius: 50px;
  color: #5c678f;
  font-size: 20px;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 2.5rem;
}

.main-chat-footer .nav-link:last-child {
  display: block;
}

.main-chat-footer .form-control {
  flex: 1;
  margin: 0 10px;
  padding-left: 10px;
  padding-right: 0;
}

.main-chat-footer .form-control:focus,
.main-chat-footer .form-control:hover {
  box-shadow: none;
}

@media (min-width: 768px) {
  .main-chat-footer .form-control {
    margin: 0 20px;
  }
}

.main-msg-sright {
  border: 1px solid #e6ebf1;
  border-radius: 100%;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  color: #5c678f;
  font-size: 18px;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-top: 3px;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 2.5rem;
}

.main-msg-sright:hover {
  background: #f0f0f2;
}

.main-img-user.online {
  position: relative;
}

.main-img-user.online:before {
  background: #2dce89;
  border-radius: 50%;
  bottom: 0;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  right: 2px;
  top: 32px;
  width: 8px;
  z-index: 9;
}

.action-header .ah-actions.actions {
  margin-top: 0 !important;
}

.msg_card_body .msg_cotainer_send {
  background-color: #664dc9;
  border-radius: 0 12px;
  color: #fff;
  margin-bottom: auto;
  margin-right: 10px;
  margin-top: 5px;
  max-width: 70%;
  padding: 10px;
  position: relative;
}

.chat .msg_time_send {
  bottom: -18px;
  color: #3a374e;
  font-size: 10px;
  position: absolute;
  right: 0;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .main-content-body-show .main-header-contacts .main-header-menu-icon {
    display: flex;
  }

  .main-content-body-show .main-content-left-contacts {
    display: block;
  }

  .app .main-contact-star {
    display: none;
  }

  .app .main-content-left-contacts .main-nav-line-chat .nav-link.active {
    display: block !important;
    font-size: 11px;
    padding: 0;
  }

  .app .main-content-left-contacts .main-img-user {
    margin: 0 auto;
  }
}

@media (max-width: 1200px) {
  .main-contact-info-header {
    border-top: 1px solid #e9edf4;
  }

  .main-contact-info-header .media-body {
    padding-top: 20px;
  }
}

@media (max-width: 400px) {
  .main-contact-action a span {
    display: none;
  }
}

@media (max-width: 576px) {
  .main-contact-info-header {
    padding-top: 60px !important;
  }

  .main-contact-action {
    left: 10px;
    right: 0 !important;
  }

  .main-contact-info-header .media-body .nav-link {
    padding-top: 5px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .main-contact-info-header .media {
    display: block !important;
  }

  .main-contact-info-header .media-body {
    padding-top: 20px;
  }
}

.main-content-left-contacts {
  display: block;
  padding: 0;
  width: 100%;
}

.main-content-left-contacts .main-content-breadcrumb,
.main-content-left-contacts .main-content-title {
  padding-left: 20px;
}

.main-contacts-list {
  height: 700px;
  position: relative;
}

.main-contact-label {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-top: 20px;
  position: relative;
}

.main-contact-label:after {
  border-bottom: 1px solid #e9edf4;
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}

.main-contact-item {
  border-end-width: 0;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  padding: 10px 20px;
  position: relative;
}

.main-contact-item + .main-contact-item {
  border-top-color: transparent;
  margin-top: -1px;
}

.main-contact-item + .main-contact-item:before {
  border-top: 1px solid #e9edf4;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: -1px;
}

.main-contact-item.selected,
.main-contact-item:focus,
.main-contact-item:hover {
  background-color: #f3f4f7;
  border-bottom-color: #e6ebf1;
  border-top-color: #e6ebf1;
}

.main-contact-item.selected {
  border-width: 2px;
  z-index: 0;
}

.main-contact-body {
  flex: 1;
  margin-left: 15px;
}

.main-contact-body h6 {
  color: #6c756e;
  font-weight: 600;
  margin-bottom: 2px;
}

.main-contact-body span {
  color: #728096;
  font-size: 13px;
}

.main-contact-star {
  color: #728096;
  font-size: 16px;
}

.main-contact-star:focus,
.main-contact-star:hover {
  color: #728096;
}

.main-contact-star.active {
  color: #fcb32d;
}

@media (min-width: 768px) {
  .main-content-body-contacts {
    display: block;
    overflow-y: auto;
  }

  .main-content-left-contacts {
    width: 100%;
  }
}

.main-contact-info-header {
  border-bottom: 1px solid #e9edf4;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-top: 40px;
  position: relative;
}

.main-contact-info-header .media {
  display: block;
}

.main-contact-info-header .main-img-user {
  height: 100px;
  width: 100px;
}

.main-contact-info-header .main-img-user:after {
  display: none;
}

.main-contact-info-header .main-img-user a {
  align-items: center;
  background-color: #7272c6;
  border-radius: 100%;
  bottom: 2px;
  box-shadow: 0 0 0 2px #fff;
  color: #fff;
  display: flex;
  font-size: 18px;
  height: 32px;
  justify-content: center;
  line-height: 0.9;
  right: 2px;
  width: 32px;
}

.main-contact-info-header .main-img-user a:focus,
.main-contact-info-header .main-img-user a:hover {
  fill: hsla(0, 0%, 100%, 0.5);
  color: #fff;
}

.main-contact-info-header .media-body {
  margin-top: 30px;
}

.main-contact-info-header .media-body h4 {
  color: #6c756e;
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 4px;
}

.main-contact-info-header .media-body p {
  margin-bottom: 15px;
}

.main-contact-info-header .media-body .nav-link {
  align-items: center;
  color: #728096;
  display: flex;
  font-size: 13px;
  padding: 0;
}

.main-contact-info-header .media-body .nav-link i {
  font-size: 14px;
  line-height: 0;
  margin-right: 5px;
}

.main-contact-info-header .media-body .nav-link i.typcn {
  line-height: 0.9;
}

.main-contact-info-header .media-body .nav-link i.typcn:before {
  width: auto;
}

.main-contact-info-header .media-body .nav-link + .nav-link {
  margin-left: 10px;
}

@media (min-width: 576px) {
  .main-contact-info-header {
    padding-top: 20px;
  }

  .main-contact-info-header .media-body .nav-link {
    font-size: 0.875rem;
  }

  .main-contact-info-header .media-body .nav-link + .nav-link {
    margin-left: 10px;
  }

  .main-contact-info-header .media-body .nav-link i {
    margin-right: 7px;
  }
}

.main-contact-action {
  display: flex;
  position: absolute;
  right: 20px;
  top: 20px;
}

.main-contact-action a {
  align-items: center;
  display: flex;
}

.main-contact-action a i {
  font-size: 17px;
  line-height: 0.9;
  margin-right: 5px;
}

.main-contact-action a + a {
  margin-left: 15px;
}

.main-contact-info-body {
  padding-left: 0;
}

.main-contact-info-body .media-list {
  padding: 20px 0;
}

.main-contact-info-body .media {
  align-items: center;
  position: relative;
}

.main-contact-info-body .media + .media {
  margin-top: 30px;
}

.main-contact-info-body .media + .media:before {
  border-top: 1px solid #e9edf4;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: -15px;
}

.main-contact-info-body .media:last-child {
  margin-bottom: 15px;
}

.main-contact-info-body .media-body {
  margin-left: 30px;
}

.main-contact-info-body .media-body > div + div {
  margin-top: 15px;
}

.main-contact-info-body .media-body label {
  color: #728096;
  display: block;
  font-size: 14px;
  margin-bottom: 0;
}

.main-contact-info-body .media-body span {
  color: #6c756e;
  display: block;
  font-weight: 500;
}

.main-contact-info-body .media:before {
  border-top: 1px solid #e9edf4;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: -15px;
}

@media (min-width: 992px) {
  .main-contact-info-body {
    height: calc(100% - 126px);
    position: relative;
  }

  .main-contact-info-body .media-body {
    display: flex;
  }

  .main-contact-info-body .media-body > div {
    flex: 1;
  }

  .main-contact-info-body .media-list {
    padding: 25px 0;
  }

  .main-contact-action {
    right: 20px;
  }

  .main-contact-info-header {
    padding-bottom: 25px;
    padding-left: 30px;
  }

  .main-contact-info-header .media-body {
    margin-left: 30px;
    margin-top: 0;
  }

  .main-contact-info-header .media {
    align-items: center;
    display: flex;
  }

  .main-content-body-contacts {
    overflow: visible;
  }

  .main-contact-item {
    padding: 10px 15px;
  }

  .main-contact-label {
    padding-left: 10px;
  }

  .main-content-left-contacts .main-content-breadcrumb,
  .main-content-left-contacts .main-content-title {
    padding-left: 0;
  }

  .main-contact-info-body .media-body > div + div {
    margin-top: 0;
  }
}

.main-contact-info-header .main-img-user {
  position: relative;
}

.main-contact-info-header .main-img-user a {
  position: absolute;
}

.jsr,
.jsr_bar,
.jsr_label,
.jsr_rail,
.jsr_rail-outer,
.jsr_slider {
  box-sizing: border-box;
}

.jsr {
  -webkit-touch-callout: none;
  display: block;
  font: 14px sans-serif;
  margin: 20px 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  width: 100%;
}

.jsr_rail-outer {
  cursor: pointer;
  padding: 10px 0;
  position: relative;
}

.jsr_slider:focus:before {
  background: #7036ff;
  background: #4575f1;
}

.jsr_rail {
  background: #e2e2e9;
  height: 12px;
  z-index: 1;
}

.jsr_bar {
  cursor: move;
  height: 12px;
  position: absolute;
}

.jsr_bar--limit {
  background-color: #d9dadc;
  cursor: auto;
  z-index: 1;
}

.jsr_slider {
  cursor: col-resize;
  left: 0;
  outline: 0;
  top: 16px;
  transition: background 0.1s ease-in-out;
}

.jsr_slider,
.jsr_slider:before {
  height: 25px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 25px;
}

.jsr_slider:before {
  border-radius: 50%;
  content: "";
  left: 50%;
  top: 50%;
}

.jsr_label {
  border-radius: 0.3em;
  font-size: 0.9em;
  padding: 0.2em 0.4em;
  position: absolute;
  top: 33px;
  white-space: nowrap;
}

.jsr_label,
.jsr_label--minmax {
  background: #d3d5da;
  color: #6c756e;
}

.jsr_label--minmax {
  transition: opacity 0.2s ease-in-out;
}

.jsr_label .jsr_label {
  display: inline-block;
  font-size: 1em;
  padding-bottom: 0;
  padding-right: 0;
  padding-top: 0;
  position: static;
}

.jsr_label .jsr_label:before {
  content: " - ";
}

.jsr_canvas {
  margin-top: 5px;
}

.jsr_lockscreen {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.mcs-horizontal-example {
  overflow-x: auto;
  white-space: nowrap;
}

.mcs-horizontal-example .item {
  display: inline-block;
}

.rating-stars {
  text-align: center;
  width: 100%;
}

.rating-stars .rating-stars-container {
  font-size: 0;
}

.rating-stars .rating-stars-container .rating-star {
  color: #f0f0f2;
  cursor: pointer;
  display: inline-block;
  font-size: 30px;
  padding: 0 5px;
}

.rating-stars .rating-stars-container .rating-star.sm {
  color: 1px solid #e6ebf1;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding: 5px;
}

.rating-stars .rating-stars-container .rating-star.is--active,
.rating-stars .rating-stars-container .rating-star.is--hover {
  color: #f1c40f;
}

.rating-stars .rating-stars-container .rating-star.is--no-hover {
  color: #3e4b5b;
}

.rating-stars .rating-stars-container .rating-star.is--active .fa-heart,
.rating-stars .rating-stars-container .rating-star.is--hover .fa-heart {
  color: #ef4b4b;
}

.rating-stars .rating-stars-container .rating-star .fa-heart .is--no-hover {
  color: #3e4b5b;
}

.rating-stars input {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 3px;
  color: #495057;
  display: block;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin: 0 auto;
  padding: 0.375rem 0.75rem;
  text-align: center;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.br-theme-bars-horizontal .br-widget a,
.br-theme-bars-pill .br-widget a {
  background-color: #f0f0f2;
}

.br-theme-bars-square .br-widget a {
  background-color: #fff;
  border: 2px solid #f0f0f2;
  color: #f0f0f2;
}

.br-theme-bars-1to10 .br-widget a,
.br-theme-bars-movie .br-widget a {
  background-color: #f0f0f2;
}

.br-theme-fontawesome-stars .br-widget a.br-active:after,
.br-theme-fontawesome-stars .br-widget a.br-selected:after {
  color: #ffab00;
}

.br-theme-fontawesome-stars-o .br-widget a.br-active:after,
.br-theme-fontawesome-stars-o .br-widget a.br-selected:after {
  color: #ffab00;
  content: "\f005";
}

.br-theme-fontawesome-stars-o .br-widget a.br-fractional:after {
  color: #ffab00;
  content: "\f123";
}

.br-theme-fontawesome-stars .br-widget a:after {
  color: #ffab00;
  content: "\f005";
}

.br-theme-fontawesome-stars-o .br-widget a:after {
  color: #ffab00;
  content: "\f006";
}

/* ============ Table Scroll ======== */

body ::-webkit-scrollbar {
  height: 3px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  width: 5px;
}

body ::-webkit-scrollbar-thumb,
body :hover::-webkit-scrollbar-thumb {
  background: #c7cfdf;
}

body ::-webkit-scrollbar-track {
  background: #fff;
}

/* ============ Main Body Scroll ======== */

/* scrollbar css */
body::-webkit-scrollbar {
  height: 1px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 3px;
  /* width of vertical scrollbar */
  border: 1px solid #2a3042;
}

body::-webkit-scrollbar-track {
  background: #fff;
}

body::-webkit-scrollbar-thumb {
  background: #664dc9;
}

.page-header {
  align-items: center;
  display: flex;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  margin: 1.5rem 0;
  padding: 0;
  position: relative;
}

.page-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 2.5rem;
  margin: 0;
  position: relative;
}

.page-title-icon {
  color: #9aa0ac;
  font-size: 1.25rem;
}

.page-subtitle {
  color: #6e7687;
  font-size: 0.8125rem;
  margin-left: 2rem;
  position: relative;
}

.page-subtitle a {
  color: inherit;
}

.page-options {
  margin-left: auto;
}

.page-description {
  color: #6e7687;
  margin: 0.25rem 0 0;
}

.page-description a {
  color: inherit;
}

.page-single,
.page-single-content {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
}

.content-heading {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
  margin: 2rem 0 1.5rem;
}

.content-heading:first-child {
  margin-top: 0;
}

.aside {
  background: #fff;
  border-left: 1px solid #e6ebf1;
  bottom: 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 22rem;
  z-index: 100;
}

@media (min-width: 1600px) {
  body.aside-opened .aside {
    visibility: visible;
  }
}

.aside-body {
  flex: 1 1 auto;
  overflow: auto;
  padding: 1.5rem;
}

.aside-footer {
  border-top: 1px solid #e6ebf1;
  padding: 1rem 1.5rem;
}

.aside-header {
  border-bottom: 1px solid #e6ebf1;
  padding: 1rem 1.5rem;
}

.header {
  background: #fff;
}

body.fixed-header .header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1030;
}

.header .dropdown-menu {
  border-radius: 0 0 4px 4px !important;
  margin-top: 0;
}

.header .mega-menu {
  width: 350px;
}

.nav-unread {
  background: #ecd938;
  height: 0.5rem;
  right: 1rem;
  top: 0.13rem;
  width: 0.5rem;
}

.nav-unread,
.nav-unread.badge {
  border-radius: 50%;
  position: absolute;
}

.nav-unread.badge {
  height: 1.2rem;
  right: 0;
  text-align: center;
  top: 0;
  width: 1.2rem;
}

.nav-link.icon {
  font-size: 1.2rem;
  height: 2.5rem;
  margin: 5px;
  position: relative;
  text-align: center;
}

.app-header.header .nav-link.icon {
  padding: 5px;
}

.nav-link.icon .nav-span {
  color: #576482;
  font-size: 0.9rem;
  font-weight: 400;
  margin-left: 0.5rem;
  vertical-align: middle;
}

.app-header1 .header-brand,
.header-brand {
  color: inherit;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2.9rem;
  margin-right: 0;
  padding: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.app-header1 .header-brand {
  min-width: 215px;
  text-align: center;
}

.app-header1 .header-brand .header-brand-img {
  margin-right: 0;
}

.header-brand:hover {
  color: inherit;
  text-decoration: none;
}

.header-brand-img {
  height: 3rem;
  line-height: 2rem;
  margin-right: 0;
  vertical-align: bottom;
  width: auto;
}

.header-avatar {
  border-radius: 50%;
  vertical-align: bottom;
}

.header-avatar,
.header-btn {
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.header-btn {
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
}

.center-block {
  float: inherit !important;
  margin-left: auto;
  margin-right: auto;
}

.header-btn.has-new {
  position: relative;
}

.header-btn.has-new:before {
  background: #dc0441;
  border-radius: 50%;
  content: "";
  height: 6px;
  position: absolute;
  right: 4px;
  top: 4px;
  width: 6px;
}

.header-toggler {
  color: #000;
  height: 3rem;
  position: relative;
  width: 3rem;
}

.header-toggler:hover {
  color: #32e448;
}

.header-toggler-icon {
  background: currentColor;
  border-radius: 3px;
  box-shadow: 0 5px currentColor, 0 -5px currentColor;
  color: inherit;
  height: 2px;
  left: 50%;
  margin: -2px 0 0 -0.5rem;
  position: absolute;
  top: 50%;
  width: 1rem;
}

.footer,
footer.footer {
  background: #fff;
  border-top: 1px solid #e6ebf1;
  font-size: 0.875rem;
  padding: 1.25rem 0;
}

footer.footer {
  box-shadow: 16px 0 16px rgba(24, 23, 38, 0.06);
  display: block;
  margin-top: auto;
}

.footer .social {
  margin: 0 auto;
}

.footer .social ul li {
  float: left;
  padding: 7px;
}

.footer .social ul li a {
  color: #abb8c3;
}

.footer a:not(.btn) {
  font-weight: 400;
}

@media print {
  .footer,
  f {
    display: none;
  }
}

.br-100 {
  border-radius: 100% !important;
}

.br-3 {
  border-radius: 3px !important;
}

.br-5 {
  border-radius: 5px !important;
}

.br-7 {
  border-radius: 7px !important;
}

.br-tl-7 {
  border-top-left-radius: 7px !important;
}

.br-bl-7 {
  border-bottom-left-radius: 7px !important;
}

.br-tr-7 {
  border-top-right-radius: 7px !important;
}

.br-br-7 {
  border-bottom-right-radius: 7px !important;
}

.br-0 {
  border-radius: 0;
}

.br-tl-0 {
  border-top-left-radius: 0 !important;
}

.br-bl-0 {
  border-bottom-left-radius: 0 !important;
}

.br-tr-0 {
  border-top-right-radius: 0 !important;
}

.br-br-0 {
  border-bottom-right-radius: 0 !important;
}

.list.d-flex img {
  width: 60px;
}

#popup {
  background: #fe6b1f;
  border-radius: 5px;
  color: #fff;
  display: none;
  height: 30px;
  position: absolute;
  width: auto;
}

#copy {
  background: none;
  border: 0;
  color: #fff;
  font-weight: 700;
  padding: 8px 25px;
}

.h-85 {
  height: 85px !important;
}

.chart-visitors {
  min-height: 18rem;
  overflow: hidden;
}

.chart-height {
  height: 20rem;
  overflow: hidden;
}

.chart-tasks {
  height: 15rem;
  overflow: hidden;
}

.chart-donut {
  height: 21rem;
  overflow: hidden;
}

.chart-pie {
  height: 22rem;
  overflow: hidden;
}

.chartsh {
  height: 16rem;
  overflow: hidden;
}

.chartwidget {
  height: 17rem;
  overflow: hidden;
}

.countdown span:first-child {
  font-size: 30px;
  font-weight: 500;
}

.social i {
  font-size: 40px;
}

.social .social-icon i {
  font-size: 14px;
}

.instagram {
  background: #de497b;
}

.linkedin {
  background: #0077b5;
}

.twitter {
  background: #1da1f2;
}

.facebook {
  background: #3b5998;
}

.widget-info i {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 100px;
  padding: 27px 0;
  text-align: center;
  width: 100px;
}

.widget-info a {
  border-bottom: 1px solid #fff;
}

.about-img {
  /* background: url(../images/about.jpg); */
  background-position: 50%;
  background-size: cover;
}

.login-img {
  /* background: url(../images/login.jpg); */
  background-size: cover;
}

.construction-img {
  /* background: url(../images/construction.jpg); */
  background-size: cover;
}

.z-index-10 {
  z-index: 10 !important;
}

.card-blog-overlay .card-header {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
}

.card-blog-overlay1 .card-footer,
.card-blog-overlay2 .card-footer {
  border-top: 1px solid hsla(0, 0%, 100%, 0.2);
}

.card-blog-overlay {
  /* background: url(../images/photos/thumb2.jpg) !important; */
  background-size: cover;
  position: relative;
}

.card-blog-overlay1:before,
.card-blog-overlay2:before,
.card-blog-overlay:before {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.card-blog-overlay1 {
  /* background: url(../images/photos/thumb3.jpg) !important; */
  background-size: cover;
}

.card-blog-overlay2,
.login-transparent {
  /* background: url(../images/photos/22.jpg) !important; */
  background-size: cover;
}

.login-transparent:before {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(159, 120, 255, 0.96)),
    to(rgba(50, 202, 254, 0.96))
  );
  border-radius: 7px;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.page-breadcrumb {
  background: none;
  flex-basis: 100%;
  font-size: 0.875rem;
  margin: 1rem 0 0;
  padding: 0;
}

.page-breadcrumb .breadcrumb-item {
  color: #9aa0ac;
}

.page-breadcrumb .breadcrumb-item.active {
  color: #6e7687;
}

@media (min-width: 768px) {
  .page-breadcrumb {
    margin: -0.5rem 0 0;
  }
}

@media (max-width: 320px) {
  .fc-toolbar .fc-right {
    float: right;
    margin: 10px 0;
    text-align: center;
    width: 100%;
  }
}

.pagination-simple .page-item .page-link {
  background: none;
  border: none;
}

.pagination-simple .page-item.active .page-link {
  color: #495057;
  font-weight: 700;
}

.pagination-pager .page-prev {
  margin-right: auto;
}

.pagination-pager .page-next {
  margin-left: auto;
}

.page-total-text {
  align-self: center;
  color: #6e7687;
  margin-right: 1rem;
}

.search-element {
  position: relative;
}

.header .form-inline .form-control {
  background: #fff;
  border: 0 solid #f6f6f6;
  border-radius: 10px;
  padding-left: 55px;
  padding-right: 40px;
}

.header .form-inline .form-control:focus {
  box-shadow: none;
}

.header .form-inline .form-control::-webkit-input-placeholder {
  font-size: 0.9375rem;
  opacity: 0.5;
}

.header .form-inline .btn {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: none;
  color: #fff;
  font-size: 15px;
  left: 0;
  opacity: 0.5;
  padding: 0 15px;
  position: absolute;
  top: 4px;
}

.header .form-inline .btn i {
  color: #818c9b;
}

@media (max-width: 767) {
  .header .form-inline .search-element {
    display: none;
    left: 10px;
    margin-left: 0;
    position: absolute;
    right: 10px;
    top: -4px !important;
    z-index: 892;
  }

  body.search-show .header .form-inline .search-element {
    display: block;
  }

  .header .navsearch i {
    color: #fff;
  }

  .header .form-inline .form-control::-webkit-input-placeholder {
    color: #6c756e;
  }

  .header .navsearch i {
    color: #6c756e;
    font-size: 20px;
    padding-top: 5px;
  }
}

@media (max-width: 320px) {
  .desktop-lgo {
    display: inline-block !important;
    margin-top: 3px !important;
  }
}

@media (max-width: 1024px) {
  .app.sidebar-gone.search-show .header.top-header {
    z-index: 1036;
  }

  body.sidebar-show {
    overflow: hidden;
  }

  body.search-show:before,
  body.sidebar-show:before {
    background-color: rgba(0, 0, 0, 0.4);
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 891;
  }
}

@media (min-width: 575.98px) and (max-width: 1200px) {
  .header .form-inline .form-control {
    min-width: 100% !important;
    width: 100% !important;
  }
}

@media (min-width: 767.98px) {
  .responsive-search {
    display: none !important;
  }
}

@media (max-width: 991px) and (min-width: 767px) {
  .animated-arrow.hor-toggle span {
    margin-left: 8px;
    margin-top: 2px;
    vertical-align: middle;
  }
}

@media (max-width: 991px) {
  .hor-header {
    border-bottom: 1px solid transparent !important;
    box-shadow: 16px 0 16px rgba(24, 23, 38, 0.06);
  }
}

@media (max-width: 575.98px) {
  body.search-show .header .form-inline .search-element {
    display: block;
  }

  .header .form-inline .search-element .form-control {
    background: #fff;
    border-radius: 4px;
    float: left;
    height: 40px;
    margin: 0 auto;
    width: 100% !important;
  }

  .form-control.header-search {
    color: #000;
  }

  .header .form-inline .search-element {
    display: none;
    position: absolute;
    z-index: 892;
  }

  .header .form-inline .btn {
    color: #46494a !important;
  }

  .header .form-inline .form-control::-webkit-input-placeholder {
    color: #6c756e !important;
  }

  .header .navsearch i {
    color: #6c756e;
    font-size: 20px;
    padding-top: 4px;
  }

  .hor-header .header-brand-img.desktop-lgo {
    display: none;
  }
}

.icons-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -1px -1px 0;
  padding: 0;
}

.icons-list > li {
  flex: 1 0 2.5rem;
}

.icons-list-wrap {
  overflow: hidden;
}

.flags-icons .icons-list-item {
  line-height: inherit;
}

.payment-icons .icons-list-item {
  line-height: 2rem;
}

.icons-list-item {
  border: 1px solid #e6ebf1;
  border-radius: 10px !important;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  color: #747fa7;
  display: block;
  font-size: 18px;
  height: 2.5rem;
  line-height: 2.5rem;
  margin: 5px;
  text-align: center;
  vertical-align: middle;
  width: 2.5rem;
}

.img-gallery {
  margin-bottom: -0.5rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.img-gallery > .col,
.img-gallery > [class*="col-"] {
  padding-bottom: 0.5rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.link-overlay {
  position: relative;
}

.link-overlay:hover .link-overlay-bg {
  opacity: 1;
}

.link-overlay-bg {
  align-items: center;
  background: rgba(70, 127, 207, 0.8);
  bottom: 0;
  color: #fff;
  display: flex;
  font-size: 1.25rem;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s;
}

.media-icon {
  border-radius: 100%;
  font-size: 18px;
  height: 2.2rem;
  line-height: 2.2rem;
  text-align: center;
  width: 2.2rem;
}

.media-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea[cols] {
  height: auto;
}

.wizard-card .moving-tab {
  margin-top: 5px;
}

.wizard-card.form-group .form-control {
  background-image: linear-gradient(
    #c4c4c4,
    #c4c4c4,
    linear-gradient(#e1e1e2, #e1e1e2)
  );
}

.wizard-container .wizard-navigation {
  background: #f0f0f2;
}

.richText {
  background-color: #f0f0f2;
  border: 1px solid #e6ebf1;
  border-bottom: 0;
  border-top: 0;
}

.richText .richText-toolbar {
  border-bottom: 1px solid #e6ebf1;
  border-top: 1px solid #e6ebf1;
}

.richText .richText-toolbar ul li a {
  border-right: 1px solid #e2e3e7;
}

.addui-slider .addui-slider-track .addui-slider-range {
  display: block;
}

.addui-slider .addui-slider-track .addui-slider-handle:hover {
  box-shadow: 0 0 5px rgba(159, 120, 255, 0.5);
}

.addui-slider
  .addui-slider-track
  .addui-slider-handle:hover
  .addui-slider-value {
  background: rgba(159, 120, 255, 0.5);
}

.addui-slider
  .addui-slider-track
  .addui-slider-handle.addui-slider-handle-active {
  background: rgba(159, 120, 255, 0.5);
  box-shadow: 0 0 5px rgba(159, 120, 255, 0.5);
}

.addui-slider
  .addui-slider-track
  .addui-slider-handle.addui-slider-handle-active
  .addui-slider-value {
  background: rgba(159, 120, 255, 0.75);
}

.cal1 {
  border: 1px solid #e6ebf1;
  font-size: 14px;
}

.cal1 .clndr .clndr-table .header-days .header-day {
  border-left: 1px solid rgba(23, 183, 148, 0.1);
  border-top: 1px solid #e2e3e4;
}

.cal1 .clndr .clndr-table tr .adjacent-month,
.cal1 .clndr .clndr-table tr .empty,
.cal1 .clndr .clndr-table tr .my-adjacent-month,
.cal1 .clndr .clndr-table tr .my-empty {
  background: #f0f0f2;
  border-left: 1px solid #e6ebf1;
  border-top: 1px solid #e6ebf1;
}

.cal1 .clndr .clndr-table tr .day {
  border-left: 1px solid #e6ebf1;
  border-top: 1px solid #e6ebf1;
}

.cal1 .clndr .clndr-table tr .day.event,
.cal1 .clndr .clndr-table tr .day.my-event {
  background: #eeeef5;
}

.cal1 .clndr .clndr-table tr .day.my-today.event,
.cal1 .clndr .clndr-table tr .day.today.event {
  background: #f2ecff;
}

.cal1 .clndr .clndr-table tr .day.event:hover,
.cal1 .clndr .clndr-table tr .day.my-event:hover,
.cal1 .clndr .clndr-table tr .day.my-today,
.cal1 .clndr .clndr-table tr .day.today {
  background: #e2e2e9 !important;
}

.cal1 .clndr .clndr-table tr .day:last-child {
  border-right: 1px solid #e6ebf1;
}

.cal1 .clndr .clndr-table tr .day:hover {
  background: #e2e2e9;
}

.cal1 .clndr .clndr-controls .clndr-control-button .clndr-next-button,
.cal1 .clndr .clndr-controls .clndr-control-button .clndr-previous-button {
  background: #f0f0f2;
}

.fc button {
  background: #f5f6f8;
  color: #efefef;
  font-size: 13px !important;
  font-weight: 500;
  padding: 5px;
  text-transform: uppercase;
}

.fc-unthemed .fc-divider {
  border-color: #e6ebf1;
}

.fc-event {
  border-radius: 3px;
  line-height: 1.4;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
  background: #f0f0f2;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  box-shadow: none;
  color: #fff;
  outline: 0;
}

.fc-today-button:focus {
  border: none !important;
  box-shadow: none !important;
  outline: 0;
}

.fc-unthemed .fc-list-item:hover td {
  background-color: #f0f0f2;
}

.conv-form-wrapper div#messages div.message.to {
  background: #eeeff6;
}

div.conv-form-wrapper div#messages div.message.from {
  border-top-right-radius: 0;
}

#sidebar ul li.active > a:hover {
  background: #6d4dbb;
  color: #fff;
}

.overflow-hidden {
  overflow: hidden;
}

.user-profile .user-pro-body .u-dropdown {
  color: #fff;
}

#sidebar ul li {
  position: relative;
}

#sidebar ul li a {
  color: #384056;
  font-weight: 400;
}

#sidebar ul li ul li:last-child {
  border-bottom: 0;
}

#sidebar li a[aria-expanded="true"]:hover {
  color: #fff !important;
}

.timeline__content {
  background-color: #eaf0f9;
}

.tab_wrapper > ul {
  border-bottom: 1px solid #e6ebf1;
}

.tab_wrapper.right_side .content_wrapper {
  border: 1px solid #e6ebf1;
}

.tab_wrapper.right_side > ul {
  border-bottom: 1px solid #e6ebf1;
}

.tab_wrapper.right_side > ul li.active {
  border-color: 1px solid #e6ebf1;
}

.tab_wrapper.right_side > ul li:after {
  background: 1px solid #e6ebf1;
}

.job-box-filter label {
  width: 100%;
}

.job-box-filter select.input-sm {
  height: 34px;
  margin: 0 5px;
  max-width: 120px;
}

.job-box-filter label input.form-control,
.job-box-filter select.input-sm {
  border: 1px solid #e6ebf1;
  border-radius: 2px;
  display: inline-block;
  font-size: 15px;
}

.job-box-filter label input.form-control {
  margin-left: 5px;
  max-width: 200px;
}

.text-right {
  text-align: right;
}

.job-box-filter {
  border-bottom: 1px solid #e6ebf1;
  padding: 12px 15px;
}

.job-box {
  display: inline-block;
  padding: 0;
  width: 100%;
}

.job-box-filter a.filtsec {
  background: #edf0f3;
  border: 1px solid #e2e8ef;
  border-radius: 50px;
  color: #81a0b1;
  display: inline-block;
  font-size: 13px;
  margin-right: 15px;
  margin-top: 8px;
  padding: 4px 10px;
  transition: all 0.4s ease;
}

.job-box-filter a.filtsec.active {
  background: #16262c;
  border-color: #16262c;
  color: #fff;
}

.job-box-filter a.filtsec i {
  margin-right: 5px;
}

.job-box-filter a.filtsec:focus,
.job-box-filter a.filtsec:hover {
  background: #07b107;
  border-color: #07b107;
  color: #fff;
}

.job-box-filter a.filtsec:focus i,
.job-box-filter a.filtsec:hover i {
  color: #fff;
}

.job-box-filter h4 i {
  margin-right: 10px;
}

.image_outer_container {
  border-radius: 50%;
  margin-bottom: auto;
  margin-top: auto;
  position: relative;
}

.image_inner_container {
  border-radius: 50%;
  padding: 5px;
}

.image_inner_container img {
  border: 5px solid #fff;
  border-radius: 50%;
  height: 150px;
  width: 150px;
}

.image_outer_container .green_icon {
  background-color: #4cd137;
  border: 5px solid #fff;
  border-radius: 50%;
  bottom: 10px;
  height: 30px;
  position: absolute;
  right: 10px;
  width: 30px;
}

.search {
  position: absolute;
  width: 320px;
}

.search svg {
  stroke-width: 8px;
  stroke: #fff;
  stroke-width: 1px;
  stroke-dashoffset: 0;
  stroke-dasharray: 64.6 206.305;
  height: auto;
  margin-top: 5px;
  transform: translateX(-126px);
  transition: all 0.5s ease-in-out;
  width: 180px;
}

.input-search {
  background-color: transparent;
  border: none;
  bottom: 0;
  color: #fff;
  font-size: 16px;
  height: 34px;
  left: 0;
  outline: none;
  padding: 20px;
  position: absolute;
  right: 20px;
  top: 0;
  width: calc(100% - 148px);
}

.search-label {
  display: block;
  height: 50px;
  left: 50%;
  margin-left: -54px;
  position: absolute;
  top: 0;
  transition: 0.5s ease-in-out;
  width: 50px;
  z-index: 100;
}

.isActive .search-label {
  transform: translateX(246px);
}

.isActive.full svg,
.isActive svg {
  stroke-dashoffset: -65;
  stroke-dasharray: 141.305 65;
  transform: translateX(0);
}

.full .search-label {
  transform: translateX(246px);
}

.full svg {
  stroke-dashoffset: 0;
  stroke-dasharray: 64.6 206.305;
  transform: translateX(0);
}

#drag-1,
#drag-2 {
  background-color: #29e;
  border-radius: 0.75em;
  color: #fff;
  height: 100%;
  margin: 10%;
  min-height: 6.5em;
  padding: 4%;
  transform: translate(0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 25%;
}

.search-inline {
  background-color: #fff;
  left: 0;
  opacity: 0;
  padding: 33px 0;
  position: absolute;
  top: 0;
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 9;
}

.search-inline.search-visible {
  -webkit-animation: fadeInDown 0.2s ease-in-out;
  animation: fadeInDown 0.2s ease-in-out;
  opacity: 1;
  visibility: visible;
}

.search-inline button[type="submit"] {
  background-color: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  height: 100%;
  padding: 0;
  position: absolute;
  right: 52px;
  right: 80px;
  top: 0;
  width: 80px;
}

.search-inline .form-control {
  border: 0;
  font-size: 20px;
  height: 100%;
  left: 1%;
  outline: none;
  padding-left: 0;
  position: absolute;
  top: 0;
  width: 99%;
}

.search-inline .form-control:focus {
  box-shadow: none;
}

.search-inline .search-close {
  background: #efefef;
  color: #616161;
  display: table;
  height: 100%;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  width: 80px;
}

.search-inline .search-close i {
  display: table-cell;
  vertical-align: middle;
}

.searching i {
  font-size: 18px;
}

.search-close i {
  padding: 24px 0;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.lockscreen {
  background: inherit;
  height: 280px;
  overflow: hidden;
  width: 300px;
}

.lockscreen:before {
  background: inherit;
  bottom: 0;
  box-shadow: inset 0 0 0 200px hsla(0, 0%, 100%, 0.4);
  content: "";
  filter: blur(1px);
  height: 400px;
  left: -25px;
  position: absolute;
  right: 0;
  top: -25px;
  width: 350px;
}

.table-stats table td,
.table-stats table th {
  border: none;
  border-bottom: 1px solid #e8e9ef;
  font-size: 12px;
  font-weight: 400;
  padding: 0.75em 1.25em;
  text-transform: uppercase;
}

.table-stats table td img,
.table-stats table th img {
  margin-right: 10px;
  max-width: 45px;
}

.table-stats table td,
.table-stats table th .name {
  font-size: 14px;
  text-transform: capitalize;
}

.table-stats table td {
  vertical-align: middle;
}

.table-stats table td .name {
  font-size: 14px;
  text-transform: capitalize;
}

.traffic-sales-content {
  padding: 14px;
}

.traffic-sales-amount {
  float: right;
}

.pulse {
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  background: #f72d66;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(247, 45, 102, 0.9);
  cursor: pointer;
  display: block;
  height: 7px;
  position: absolute;
  right: 23px;
  top: 23px;
  width: 7px;
}

.pulse:hover {
  -webkit-animation: none;
  animation: none;
}

@media (max-width: 992px) {
  .header.top-header .header-option,
  .header.top-header .header-setting {
    display: none;
  }

  .hor-header.header {
    position: fixed;
    width: 100%;
    z-index: 999;
  }

  .hor-content.main-content {
    margin-top: 6rem;
  }

  .horizontal-main.hor-menu {
    border-bottom: 0;
  }

  .page-body {
    padding-top: 1px;
  }

  .app-content.page-body .container,
  .header.top-header .container,
  .hor-content.main-content .container {
    max-width: none;
  }

  .active .header.top-header {
    z-index: 999;
  }

  .app-content.page-body .side-app {
    padding: 6.2rem 0.75rem 0 !important;
  }

  .navsearch {
    height: 2.5rem;
    padding: 0.3rem 0;
    position: relative;
  }

  .navsearch svg {
    background: transparent;
    font-size: 25px;
    height: 45px;
    line-height: 30px;
    margin-top: 0;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
    width: 45px;
  }

  .navsearch i {
    padding-top: 0 !important;
  }

  .app-content.page-body {
    margin-top: 4.7rem;
  }

  .comb-page .app-content.page-body {
    margin-top: 0;
  }
}

.header.top-header .search-element input {
  border-radius: 4px;
}

@media (max-width: 992px) {
  .media.profile-footer .media-user,
  .register1 .register-1 {
    display: none;
  }
}

@media (max-width: 767px) {
  .header-brand-img {
    display: none;
  }

  .header .form-inline .search-element .form-control {
    padding-top: 10px;
  }

  .header .form-inline .search-element .btn {
    left: 25px;
    margin-top: 0;
    padding: 0;
    position: absolute !important;
    top: 22px;
  }

  .dark-mode .theme-layout,
  .theme-layout {
    border-left: 0 !important;
  }

  .mobile-header .theme-layout {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }

  .header-message .nav-link {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .header-brand-img.dark-logo,
  .hor-header .header-brand-img.desktop-lgo {
    margin-left: 1rem;
  }

  .animated-arrow.hor-toggle {
    font-size: 1.2rem;
    height: 2.5rem;
    margin: 5px 20px 5px 5px;
    position: relative;
    text-align: center;
    top: 16px;
    width: 2.5rem;
  }

  .animated-arrow.hor-toggle span {
    margin-left: 8px;
    margin-top: 2px;
    vertical-align: middle;
  }
}

.activity {
  border-left: 3px solid #e6ebf1;
  margin-left: 16px;
  position: relative;
}

.activity .img-activity {
  border: 3px solid;
  border-radius: 50%;
  height: 40px;
  left: -20px;
  line-height: 44px;
  position: absolute;
  text-align: center;
  width: 40px;
}

.activity .item-activity {
  margin-bottom: 26px;
  margin-left: 40px;
}

#AreaChart2 {
  bottom: -20px;
  height: 88px !important;
  left: -7px;
  position: relative;
  width: 105% !important;
}

#CryptoChart,
#CryptoChart1,
#CryptoChart2,
#CryptoChart3 {
  bottom: -15px;
  height: 70px !important;
  left: -7px;
  position: relative;
  width: 105% !important;
}

[class*="js-conveyor-"] ul li {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-shadow: 0 0 10px -5px rgba(68, 84, 195, 0.5);
  margin: 15px 6px;
  padding: 9px 15px;
}

.news-crypto span {
  font-size: 0.8rem;
}

.latest-timeline {
  height: 355px;
}

.latest-timeline ul.timeline {
  list-style-type: none;
  position: relative;
}

.latest-timeline ul.timeline:before {
  border-left: 1px double #e6ebf1;
  content: " ";
  display: inline-block;
  height: 100%;
  left: 10px;
  position: absolute;
  z-index: 1;
}

.latest-timeline ul.timeline > li {
  margin: 20px 0;
  padding-left: 2.5rem;
}

.latest-timeline ul.timeline > li:before {
  background: #fff;
  border: 3px solid #1753fc;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 13px;
  left: 5px;
  position: absolute;
  width: 13px;
  z-index: 1;
}

.latest-timeline ul.timeline > li:first-child:before {
  border: 2px double #0dd157;
}

.latest-timeline ul.timeline > li:nth-child(2):before {
  border: 2px solid #2d9eef;
}

.latest-timeline ul.timeline > li:nth-child(3):before {
  border: 2px solid #f35e90;
}

.latest-timeline ul.timeline > li:nth-child(4):before {
  border: 2px solid #fc7303;
}

.latest-timeline ul.timeline > li:nth-child(5):before {
  border: 2px solid #05a5ba;
}

.latest-timeline ul.timeline > li:nth-child(6):before {
  border: 2px solid #920bfa;
}

.latest-timeline ul.timeline > li:last-child:before {
  border: 2px solid #ec2d38;
}

.app-content.page-body {
  min-height: 67vh;
}

.time-data {
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
}

div[data-acc-content] {
  display: none;
}

div[data-acc-step]:not(.open) {
  background: #f0f0f2;
}

div[data-acc-step]:not(.open) h5 {
  color: #8e9cad;
}

div[data-acc-step]:not(.open) .bg-primary {
  background: #afb4d8;
}

#form .list-group-item:hover {
  background: none;
}

#smartwizard .nav.nav-tabs.step-anchor {
  border-bottom: 0 !important;
  padding: 30px;
}

#smartwizard .nav-tabs {
  border-top: 0 !important;
}

#smartwizard .nav-link {
  padding: 15px 40px;
}

.sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
  background: transparent;
}

.fc-widget-content .fc-bgevent {
  background-color: #f0f0f2 !important;
}

.apexcharts-toolbar {
  display: none;
  z-index: 0 !important;
}

.card-bgimg {
  /* background: url(../images/photos/chart.png); */
  background-size: cover;
  border: 0;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.card-bgimg:before {
  border-radius: 3px;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.card-bgimg .card-body {
  z-index: 10;
}

@media (min-width: 992px) {
  .top-header.hor-top-header .header-brand-img {
    display: none;
  }

  .top-header.hor-top-header .header-brand.center-logo .desktop-lgo {
    display: block;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
  }

  .top-header.hor-top-header .form-inline .form-control {
    width: 175px;
  }

  .top-header.hor-top-header .header-brand {
    margin-right: 0;
  }

  .search-element {
    margin-left: 40px;
    margin-top: 0;
  }

  .sticky-pin {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
  }
}

@media (max-width: 991px) {
  .top-header.hor-top-header .header-brand.center-logo .desktop-lgo {
    display: none;
  }

  .header.top-header.hor-top-header {
    border-bottom: 0;
  }
}

.icon.icon-shape {
  font-size: 12px;
  height: 2.2rem;
  line-height: 2.2rem;
  text-align: center;
  width: 2.5rem;
}

.h-250 {
  height: 150px;
}

.latest-timeline1 li {
  position: relative;
}

.latest-timeline.latest-timeline1-icon ul.timeline > li:before {
  display: none;
}

.latest-timeline1-icon {
  border-radius: 7px;
  color: #fff;
  font-weight: 500;
  height: 2rem;
  left: 0;
  line-height: 2rem;
  position: absolute;
  text-align: center;
  width: 2rem;
  z-index: 1;
}

.h-330 {
  height: 330px;
}

.shadow {
  box-shadow: 0 2px 16px rgba(179, 186, 212, 0.7) !important;
}

.shadow3 {
  box-shadow: 0 0 6px rgba(179, 186, 212, 0.7) !important;
}

.apexcharts-tooltip.light {
  background: hsla(0, 0%, 100%, 0.96);
  border: 1px solid #e3e3e3;
  box-shadow: 0 0 17px -5px rgba(68, 84, 195, 0.5);
}

.chart-dropshadow-primary {
  filter: drop-shadow(-6px 12px 4px rgba(68, 84, 195, 0.2));
}

.icon-dropshadow {
  filter: drop-shadow(0 10px 4px #e5e9f2);
}

.icon-dropshadow-info {
  filter: drop-shadow(0 4px 4px rgba(69, 170, 242, 0.3));
}

.icon-dropshadow-success {
  filter: drop-shadow(0 4px 4px rgba(45, 206, 137, 0.3));
}

.icon-dropshadow-danger {
  filter: drop-shadow(0 4px 4px rgba(255, 91, 81, 0.3));
}

.icon-dropshadow-warning {
  filter: drop-shadow(0 4px 4px rgba(236, 180, 3, 0.3));
}

.chart-dropshadow {
  filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.5));
}

.GradientlineShadow {
  filter: drop-shadow(0 -8px 4px rgba(179, 186, 212, 0.2));
}

.BarChartShadow {
  filter: drop-shadow(0 1px 4px rgba(179, 186, 212, 0.3));
}

.donutShadow {
  filter: drop-shadow(-5px 0 10px rgba(179, 186, 212, 0.4));
}

.transaction-table.table td {
  vertical-align: middle;
}

.transaction-table.table .apexcharts-tooltip.light {
  display: none;
}

.transaction-table.table thead th {
  padding-bottom: 20px;
}

.inde4-table tr td:first-child {
  position: relative;
}

.inde4-table tr td:first-child:before {
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: 17px;
  width: 2px;
}

.inde4-table tr:first-child td:first-child:before {
  background: rgba(68, 84, 195, 0.7);
}

.inde4-table tr:nth-child(2) td:first-child:before {
  background: rgba(247, 45, 102, 0.7);
}

.inde4-table tr:nth-child(3) td:first-child:before {
  background: rgba(45, 206, 137, 0.7);
}

.inde4-table tr:nth-child(4) td:first-child:before {
  background: rgba(69, 170, 242, 0.7);
}

.inde4-table tr:nth-child(5) td:first-child:before {
  background: rgba(236, 180, 3, 0.7);
}

.inde4-table tr:nth-child(6) td:first-child:before {
  background: rgba(255, 91, 81, 0.7);
}

.inde4-table tr:nth-child(7) td:first-child:before {
  background: rgba(68, 84, 195, 0.7);
}

.inde4-table tr:nth-child(8) td:first-child:before {
  background: rgba(247, 45, 102, 0.7);
}

.inde4-table tr:nth-child(9) td:first-child:before {
  background: rgba(45, 206, 137, 0.7);
}

.inde4-table tr:nth-child(10) td:first-child:before {
  background: rgba(69, 170, 242, 0.7);
}

.crypto-icon {
  border-radius: 4px;
  font-size: 1.8rem;
  height: 3rem;
  line-height: 3rem;
  margin-right: 18px;
  text-align: center;
  width: 3rem;
}

.crypto-tabs {
  border: 0 !important;
  padding: 0 !important;
}

.crypto-tabs .tabs-menu1 ul li .active {
  background: #fff;
  border: 0 solid #e6ebf1;
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 10px 10px 20px rgba(179, 186, 212, 0.09),
    10px 10px 15px -5px #b0b8d6;
}

.crypto-tabs .tabs-menu1 ul li a {
  display: block;
  font-size: 16px;
}

table.table-bordered.dataTable td:first-child {
  border-left: 0 solid #e6ebf1 !important;
}

.js-conveyor-example:before {
  background: linear-gradient(90deg, #f0eff1, transparent);
  left: 0;
}

.js-conveyor-example:after,
.js-conveyor-example:before {
  content: "";
  height: 60px;
  position: absolute;
  top: 8px;
  width: 20%;
  z-index: 9;
}

.js-conveyor-example:after {
  background: linear-gradient(270deg, #f0eff1, transparent);
  right: 0;
}

.user-info .social li {
  display: inline-block;
}

.user-info .social .social-icon {
  background: hsla(0, 0%, 100%, 0.1);
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  border-radius: 50%;
  color: #fff;
  display: block;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 2.5rem;
}

.date-range-btn {
  font-size: 0.9375rem;
}

.svg-icon {
  fill: #6c757e;
  color: #6c757e;
}

.svg-icon1 {
  fill: #664dc9;
  background: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  color: #664dc9;
  font-size: 25px;
  height: 37.5px;
  line-height: 30px;
  margin-right: 5px;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  width: 37.5px;
}

.header-icon {
  fill: #555b6d;
  color: #555b6d;
}

.search-element .search-icon {
  fill: #555b6d !important;
}

.header-icon2 {
  fill: #6c756e;
  color: #6c756e;
}

.profile-dropdown .header-icon {
  height: 18px;
  width: 18px;
}

.header-option .header-icon {
  margin-top: -3px;
}

.header-option .nav-link {
  margin: 7px 0 0;
}

.card-custom-icon.text-success {
  fill: #2dce89;
}

.feature .feature-icon {
  background: rgba(68, 84, 195, 0.2);
  border-radius: 50%;
  font-size: 25px;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  width: 4rem;
}

.expenses-card {
  padding-top: 170px;
}

.expenses-card #Chart {
  bottom: -15px;
  height: 120px !important;
  left: -7px;
  position: relative;
  width: 105% !important;
}

.expenses-card .card-body {
  position: absolute;
  top: 0;
}

.page-rightheader .breadcrumb {
  background: transparent;
  padding: 0;
}

.under-countdown .countdown {
  border-radius: 5px;
  color: #fff;
  padding: 20px;
}

.clip-widget {
  position: relative;
}

.clipboard-icon {
  background: #fff;
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  border-radius: 0;
  border-radius: 5px;
  color: #212529;
  cursor: pointer;
  height: 35px;
  margin: 0 auto;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 10px;
  width: 35px;
}

.clipboard-icon i {
  font-size: 20px;
  margin-top: 5px;
}

.highlight {
  background: #f7f6fd;
  border-radius: 0 0 3px 3px;
  padding: 0;
  position: relative;
}

.highlight pre {
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  max-height: 15rem;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}

table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child:before {
  border: 2px solid #4757c4;
  border-radius: 14px;
  box-shadow: none;
  box-sizing: content-box;
  color: #fff;
  content: "+";
  display: block;
  height: 14px;
  left: 4px;
  line-height: 14px;
  position: absolute;
  text-align: center;
  text-indent: 0 !important;
  top: 12px;
  width: 14px;
}

.usertab-list li {
  margin: 5px 0;
}

.profile-media-img {
  border: 0 solid #efefef;
  display: block;
  position: relative;
}

.profile-media-img h6 {
  bottom: 15px;
  color: #fff;
  left: 15px;
  position: absolute;
}

.profile-media-img:before {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.profile-media-img h6 a {
  position: relative;
  z-index: 999;
}

.cover-image {
  background-size: cover !important;
}

.timelineleft {
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
  position: relative;
}

.timelineleft:before {
  background: rgba(152, 166, 173, 0.3);
  border-radius: 2px;
  bottom: 0;
  content: "";
  left: 31px;
  margin: 0;
  position: absolute;
  top: 0;
  width: 2px;
}

.timelineleft > li {
  margin-bottom: 15px;
  margin-right: 0;
  position: relative;
}

.timelineleft > li:after,
.timelinelft > li:before {
  content: " ";
  display: table;
}

.timelineleft > .timeleft-label > span {
  background-color: #fff;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 5px 10px;
}

.timelineleft > li:after {
  clear: both;
}

.timelineleft > li > .timelineleft-item {
  background: #fff;
  border: 1px solid #ebeaf1;
  border-radius: 7px;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  color: #444;
  margin-left: 60px;
  margin-right: 0;
  margin-top: 0;
  padding: 0;
  position: relative;
}

.timelineleft > li > .timelineleft-item > .time {
  color: #999;
  float: right;
  font-size: 12px;
  padding: 12px;
}

.timelineleft > li > .timelineleft-item > .timelineleft-header {
  border-bottom: 1px solid #ebeaf1;
  color: #555;
  font-size: 16px;
  line-height: 1.1;
  margin: 0;
  padding: 0.75rem 1.25rem;
}

.timelineleft > li > .timelineleft-item > .timelineleft-header.no-border {
  border-bottom: 0 solid #ebeaf1;
}

.timelineleft > li > .timelineleft-item > .timelineleft-header > a {
  font-size: 14px;
  font-weight: 500;
}

.timelineleft-body img {
  height: 100px;
  width: 150px;
}

.timelineleft > li > .timelineleft-item > .timelineleft-body,
.timelineleft > li > .timelineleft-item > .timelineleft-footer {
  padding: 0.75rem 1.25rem;
}

.timelineleft-body {
  font-size: 14px;
}

.timelineleft > li > .fa,
.timelineleft > li > .glyphicon,
.timelineleft > li > .ion {
  background: #d2d6de;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  height: 30px;
  left: 18px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 30px;
}

.acc-step-number {
  height: 25px;
  line-height: 25px;
  padding: 0;
  width: 25px;
}

#form .list-group-item {
  padding: 25px;
}

.custom-switch-input:checked ~ .custom-switch-indicator-xl:before {
  left: calc(1.96rem + 1px) !important;
}

.header-icon2 {
  left: 10px;
  position: absolute;
  top: 0;
  width: 25px;
}

.page-rightheader .date-range-btn {
  padding-left: 40px;
  position: relative;
}

.main-proifle {
  background: #fff;
  border-radius: 8px;
  border-top: 1px solid #e6ebf1;
  margin-bottom: 30px;
  overflow: hidden;
  padding: 25px 25px 0;
  position: relative;
}

.main-proifle .tab-menu-heading {
  border-left: 0;
  border-right: 0;
  margin: 25px -30px 0;
}

.profile-edit {
  position: relative;
}

.profile-share {
  background: #fff;
  border-radius: 0 0 5px 5px;
  padding: 10px;
  width: 100%;
}

.profile-share a {
  margin-right: 5px;
}

.main-proifle .profile-icon,
.pro-option,
.profile-footer .new,
.profile-share a {
  background: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 100%;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  color: #5c678f;
  display: inline-block;
  font-size: 18px;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  vertical-align: middle;
  width: 2.5rem;
}

.main-proifle .profile-icon,
.pro-option,
.profile-footer .new {
  margin-right: 10px;
}

.pro-details li {
  float: left;
  margin-bottom: 1rem;
  width: 50%;
}

.profile-edit textarea {
  background: #fff;
  border-radius: 5px 5px 0 0;
}

.profile-edit textarea:focus {
  background: #e6ebf1;
  border: 1px solid #e6ebf1;
}

.social-profile-buttons li {
  display: block;
  float: left;
}

.social-profile-buttons li a {
  background: #d2d6f1;
  border: 2px solid #6c756e;
  border-radius: 50%;
  display: block;
  height: 2rem;
  line-height: 2rem;
  margin: 2px;
  width: 2rem;
}

.fill-white {
  fill: transparent;
}

.page-header .breadcrumb-icon {
  margin-left: 4px;
  margin-top: 3px;
}

.page-header .breadcrumb-item .svg-icon {
  width: 20px;
}

.page-header .breadcrumb-item:first-child {
  margin-top: 0;
}

.main-nav-calender-event .nav-link {
  height: 30px;
  line-height: 30px;
  padding: 0;
}

.main-nav-calender-event .nav-link.exclude {
  opacity: 0.3;
  position: relative;
}

.main-nav-calender-event .nav-link.exclude:before {
  border-top: 1px solid #737688;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
}

.form-wizard-title {
  line-height: 25px;
  margin-left: 10px;
}

.footer .social .social-icon {
  background: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 50%;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  display: block;
  height: 40px;
  line-height: 40px;
  width: 40px;
}

.richText .richText-redo,
.richText .richText-undo {
  border-right: 1px solid #e6ebf1 !important;
}

.modal-open .select2-dropdown {
  z-index: 10000 !important;
}

.page-leftheader ol {
  background: none;
  padding: 0;
}

.page-leftheader .breadcrumb-item {
  margin-top: 0;
}

.page-leftheader .breadcrumb-item + .breadcrumb-item:before {
  color: #664dc9;
  content: "";
  font-family: Material Design Icons;
  opacity: inherit;
  padding-left: 0;
}

.page-leftheader .breadcrumb-item a {
  color: #6c756e;
}

.sub-pageheader {
  display: flex;
  font-size: 14px;
  margin-bottom: 2.3rem;
}

.sub-pageheader .nav-link:first-child {
  padding-left: 0;
}

.sub-pageheader .nav-link:last-child {
  padding-right: 0;
}

.sub-pageheader .nav-link.border-end {
  border-right: 1px solid #cfd1e1 !important;
}

hr.pageheader-border {
  border-top: 1px solid #dfe0e7;
  margin-bottom: 0.9rem;
  margin-top: 0.9rem;
}

@media (max-width: 992px) {
  .sub-pageheader .nav-link {
    display: none;
  }

  .page-header,
  .sub-pageheader .left-nav .nav-link:first-child,
  .sub-pageheader .left-nav .nav-link:nth-child(2),
  .sub-pageheader .right-nav .nav-link.d-md-none {
    display: block;
  }

  .page-header .page-leftheader ol {
    margin-bottom: 12px;
    padding-top: 0;
  }

  .page-rightheader h6 {
    font-size: 10px;
  }
}

.dash1-card {
  position: absolute;
}

.dash1-card p {
  font-size: 14px;
}

.dash1-card .ratio {
  background-color: #222;
  border-radius: 15px 0 0 15px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  padding: 5px 15px;
  position: absolute;
  right: -10px;
  top: 20px;
}

.dash1-card .ratio-text {
  font-size: 13px;
  position: absolute;
  right: 5px;
  top: 55px;
}

.sales-bar {
  bottom: -21px;
  left: -10px;
  min-height: 280px !important;
  position: relative;
}

.dot-label {
  border-radius: 100%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
}

.header-dropdown-list .ps__thumb-y {
  opacity: 0;
}

.list-bar {
  border-radius: 0;
  bottom: 0;
  height: auto;
  left: 0;
  position: absolute;
  right: auto;
  top: -1px;
  width: 3px;
}

.page-header.main-pageheader {
  margin-bottom: 0;
}

.page-rightheader .breadcrumb-item a {
  color: #6c756e;
}

.page-rightheader .breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

.page-rightheader .breadcrumb-item + .breadcrumb-item:before {
  color: #6c756e;
  content: "\e933";
  font-family: feather !important;
  opacity: inherit;
}

.card-over {
  background: #fff;
  border: 1px solid #e6ebf1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
  margin-bottom: 1.5rem !important;
  position: relative;
  top: -20px;
}

.bread-select {
  display: flex;
}

.bread-select
  .select2-container
  .select2-selection--single
  .select2-selection__rrightered {
  padding-left: 10px !important;
}

.emp-circle-chart {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 100px;
  font-size: 20px;
  font-weight: 500;
}

.dash1-iocns {
  border: 2px dotted;
  border-radius: 50px;
  padding: 6px 14px;
}

.country-card {
  height: 400px;
  overflow: auto;
}

#talent-chart {
  filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.2));
}

.custom-btns .btn {
  background: 0 0;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  color: hsla(0, 0%, 100%, 0.9);
  font-size: 20px;
  height: 3rem;
  line-height: 2rem;
  margin: 0 5px;
  padding: 0.5rem;
  text-align: center;
  width: 3rem;
}

.widget-user-image {
  border-radius: 100%;
  box-shadow: 0 3px 10px 0 rgba(4, 4, 7, 0.1);
  -moz-box-shadow: 0 3px 10px 0 rgba(4, 4, 7, 0.1);
  -webkit-box-shadow: 0 3px 10px 0 rgba(4, 4, 7, 0.1);
  -ms-box-shadow: 0 3px 10px 0 rgba(4, 4, 7, 0.1);
  clear: both;
  display: block;
  height: 100px;
  margin: 0 auto;
  position: relative;
  width: 100px;
}

.header .nav-link.icon i {
  color: #5c678f;
}

.mail-inbox .list-group-item {
  border: 0 !important;
}

.user-details .user-icon {
  background: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 100%;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  color: #5c678f;
  display: inline-block;
  font-size: 18px;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
  width: 2.5rem;
}

.user-details span.h6 {
  vertical-align: middle;
}

.item7-card-desc .fe {
  color: #5c678f;
}

.nav-link.icon .header-icon {
  background: transparent;
  font-size: 25px;
  height: 36px;
  line-height: 30px;
  padding: 6px;
  text-align: center;
  vertical-align: middle;
  width: 36px;
}

.profile-dropdown img {
  border: 2px solid #f0f0f2;
  height: 40px;
  width: 40px;
}

.hor-header {
  border-bottom: 1px solid #e6ebf1;
}

.hor-header .search-element {
  margin-left: 1rem;
}

@media (min-width: 992px) {
  .horizontalMenucontainer .header.top-header {
    box-shadow: none;
  }
}

@media (min-width: 991px) {
  .top-header1 .header-brand2 .header-brand-img {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 18px;
  }

  .top-header1 .header-brand {
    display: none;
  }

  .top-header1 .form-inline .form-control {
    width: 250px;
  }
}

.img-height,
::-ms-backdrop {
  height: 100%;
}

.img-height1,
::-ms-backdrop {
  height: 333px;
}

.social-profile-buttons li,
::-ms-backdrop {
  display: inline-block;
  float: none;
}

.social-profile-buttons ul,
::-ms-backdrop {
  margin-bottom: 0;
}

.btn-icon.btn-svg .svg-icon,
::-ms-backdrop {
  height: 16px;
}

.price-svg .price-title,
::-ms-backdrop {
  top: 0 !important;
}

.price-svg svg,
::-ms-backdrop {
  height: 65px !important;
}

.item7-card-img,
.main-content,
::-ms-backdrop {
  height: 100%;
}

.app-sidebar-help,
::-ms-backdrop {
  display: none;
}

.list-card .media-body,
.media-body.time-title1,
::-ms-backdrop {
  display: block !important;
}

.media .media-body,
::-ms-backdrop {
  display: table-cell;
}

.chat-widget-header .option-dots new-list2 .fe,
::-ms-backdrop {
  display: block;
}

.message-feed.right .media-body,
::-ms-backdrop {
  display: table-cell;
}

.message-feed.media .media-body,
::-ms-backdrop {
  display: block;
}

.main-chat-body .media .media-body,
::-ms-backdrop {
  display: -ms-flexbox;
}

.chat-widget-header .option-dots.new-list2 .fe,
.main-chat-list .media-body,
::-ms-backdrop {
  display: block;
}

.card-img-top,
.image10,
::-ms-backdrop {
  height: 250px;
}

.card-image1,
::-ms-backdrop {
  height: 250px;
}

.sweet-alert .sa-info.sa-icon:after,
::-ms-backdrop {
  margin-left: 37px !important;
  top: 17px !important;
}

.main-contact-info-header .media-body,
::-ms-backdrop {
  display: block;
}

.main-contact-info-body .media-body,
::-ms-backdrop {
  display: -ms-flexbox;
}

#media1 .media-body,
#media3 .media-body,
::-ms-backdrop {
  display: block;
}

.page-style1,
::-ms-backdrop {
  background-attachment: fixed;
  background-position: 50%;
  background-size: cover;
}

.page-single,
.page-single-content,
::-ms-backdrop {
  display: block !important;
}

.page-style1 .page-single-content,
::-ms-backdrop {
  display: block !important;
}

.page,
.page-content,
::-ms-backdrop {
  display: flex !important;
}

.page-style1 .page-single,
::-ms-backdrop {
  display: block !important;
}

.page-content img,
::-ms-backdrop {
  min-width: 50%;
}

.chat-conatct-list .option-dots,
::-ms-backdrop {
  padding: 2px 12px !important;
}

.sidenav-toggled .header.py-4 .header-brand .header-brand-img.desktop-lgo {
  display: flex !important;
  margin: 0;
}

.chat-conatct-list .media,
::-ms-backdrop {
  overflow: visible;
}

#flotChart3 {
  bottom: -8px;
  height: 100px;
  margin-top: -50px;
  max-width: none;
}

#flotChart3 canvas {
  left: -12px !important;
  width: 120% !important;
}

#flotChart1 {
  bottom: -8px;
  height: 100px;
  margin-top: -50px;
  max-width: none;
}

#flotChart1 canvas {
  left: -12px !important;
  width: 120% !important;
}

#flotChart5 {
  bottom: -8px;
  height: 100px;
  margin-top: -50px;
  max-width: none;
}

#flotChart5 canvas {
  left: -12px !important;
  width: 120% !important;
}

#flotChart6 {
  bottom: -8px !important;
  height: 100px;
  margin-top: -50px;
  max-width: none;
}

#flotChart6 canvas {
  left: -12px !important;
  width: 120% !important;
}

.customer {
  background-color: #deb504;
}

.customer-sales {
  background-color: #df379a;
}

.fa-envelope,
.fa-shopping-cart,
.fa-windows {
  font-size: 18px !important;
}

.dash1 {
  background-image: linear-gradient(90deg, #664dc9, #9884ea);
}

.dash1,
.dash2 {
  color: #fff !important;
  content: "";
  position: absolute;
  width: 100%;
}

.dash2 {
  background-image: linear-gradient(90deg, #1d976c, #2fd38a);
}

.dash3 {
  background-image: linear-gradient(90deg, #fa5420, #f6a800);
}

.dash3,
.dash4 {
  color: #fff !important;
  content: "";
  position: absolute;
  width: 100%;
}

.dash4 {
  background-image: linear-gradient(90deg, #5b73e8, #44c4fa);
}

/* 
.dash2 {
  background-image: linear-gradient(90deg, #971D15, #d32f2f)!important;
   
} */

.dash-icon {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: block;
  height: 3rem;
  line-height: 3.5rem;
  text-align: center;
  width: 3rem;
}

.dash-icon i {
  font-size: 24px !important;
}

.activity-timeline {
  background-color: #f0f3fa;
  border: 1px solid transparent;
  border-radius: 50%;
  display: inline-block;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  position: absolute;
  text-align: center;
  width: 36px;
}

.recent-subcontent {
  font-size: 16px;
  font-weight: 600;
}

.activity-timeline-content {
  margin-left: 52px;
}

.sub-content {
  color: #9f9fa0 !important;
}

.activity-timeline-content span a:hover {
  text-decoration: underline;
}

ul.recent-activity {
  position: relative;
}

.recent-activity li {
  padding: 0 1.5rem;
}

.header .main-header-end .nav-link.icon {
  border-right: 1px solid #e6ebf1;
  margin: 0;
  /* padding: 2.2rem 0.75rem !important;
   */
  padding: 2.1rem 0.75rem !important;
}

.header .profile-dropdown {
  border-right: 1px solid #e6ebf1 !important;
  border-right: 0;
  /* padding: 0.95rem; */
}

.header .profile-dropdown .nav-link {
  padding: 0;
}

#fullscreen-button {
  border-left: 1px solid #e6ebf1;
}

@media (min-width: 768px) {
  .mobile-main-header {
    display: none !important;
  }

  .navbar-toggler {
    display: none;
  }
}

.navbar-toggler {
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1.125rem;
  line-height: 1;
  padding: 0.25rem 0.75rem;
}

.navbar-toggler-icon {
  background: no-repeat 50%;
  background-size: 100% 100%;
  color: #664dc9;
  content: "";
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}

.demo-icon {
  border-right: 0 !important;
}

@media (max-width: 568px) {
  .header-brand-img {
    height: 2rem;
    top: 22px;
  }

  .dark-mode .header-brand-img.dark-logo {
    margin-top: 0 !important;
  }
}

@media (max-width: 767.98px) {
  .register-2 .header-brand-img {
    display: none !important;
  }

  .navbar-toggler.navresponsive-toggler {
    padding: 0 !important;
  }

  .navbar-toggler.navresponsive-toggler:focus {
    box-shadow: none;
  }

  .navbar-toggler.navresponsive-toggler i {
    margin-top: 13px !important;
  }

  /* .demo-icon .header-icon {
    margin-top: 18px !important;
  } */

  .responsive-navbar .dropdown-menu.show {
    padding: 0.5rem 0 !important;
  }

  .app-header .form-control:focus {
    border-color: #dfdfef;
  }

  .responsive-navbar .collapsing .dropdown-menu {
    display: none !important;
  }

  .responsive-navbar .collapse.show .dropdown-menu.show {
    display: block !important;
  }

  .header .main-header-end .nav-link.icon {
    padding: 1.8rem 0.3rem !important;
  }

  .header .profile-dropdown {
    border-right: 1px solid #e6ebf1 !important;
    padding: 0.7rem 0.5rem;
  }

  .header-message,
  .header-notify,
  .navsearch,
  .profile-dropdown,
  .search-element {
    display: none !important;
  }

  .mobile-main-header .header-message,
  .mobile-main-header .header-notify,
  .mobile-main-header .navsearch,
  .mobile-main-header .profile-dropdown {
    display: block !important;
    position: inherit;
  }

  .search-show .mobile-main-header .search-element {
    background: #fff !important;
    display: block !important;
    left: 0;
    padding: 12px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 998;
  }

  .search-show .mobile-main-header .search-element .mobile-view-search {
    border: 0;
    margin-top: 1px;
    padding-bottom: 6px;
    padding-left: 55px;
    padding-right: 40px;
  }

  .search-show .mobile-main-header .search-element .btn {
    left: 7px;
    position: absolute;
    top: 10px;
  }

  .search-show .mobile-main-header .search-element .btn:focus {
    box-shadow: none;
  }

  .search-show .mobile-main-header .search-element .close-btn {
    top: 16px !important;
  }

  .mobile-main-header .header-avatar1 {
    margin-top: 4px !important;
  }

  .app-header .header-brand .header-brand-img,
  .mobile-logo .dark-logo {
    display: none;
  }

  .desktop-lgo {
    display: block !important;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
  }

  .app-sidebar__toggle {
    border-right: 0 !important;
  }

  #fullscreen-button {
    display: none;
  }

  .app-header {
    padding-bottom: 0 !important;
  }

  .nav-link.icon .header-icon {
    background: transparent;
    font-size: 25px;
    height: 36px;
    line-height: 30px;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
    width: 36px;
  }

  .app-header .header-brand {
    margin-right: 0;
  }

  .page-header {
    margin-top: 10px !important;
  }

  #message-menu1 {
    height: 300px;
  }

  .app-sidebar__toggle {
    border-right: 1px solid #e6ebf1;
    font-size: 1.2rem;
    margin: 0;
    padding: 1rem 0.75rem !important;
    position: relative;
  }

  .pulse {
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    background: #f72d66;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 7px;
    position: absolute;
    right: 17px;
    top: 18px;
    width: 7px;
  }

  .side-badge {
    top: 13px !important;
  }

  .app-header .header-brand {
    padding-top: 12px;
  }

  .header-notify .show.dropdown-menu {
    top: 45px;
  }

  .app-header {
    z-index: 9999;
  }

  .dropdown-menu.show {
    border: 1px solid #e6ebf1;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    box-shadow: -8px 12px 18px 0 rgba(15, 34, 58, 0.1);
    left: 0;
    padding: 0 !important;
    top: 59px;
  }

  .dropdown-menu-arrow.dropdown-menu-start:after,
  .dropdown-menu-arrow.dropdown-menu-start:before {
    display: none !important;
  }

  .close-btn {
    color: #728096;
    font-size: 20px;
    position: absolute;
    right: 25px;
    top: 12px;
  }

  .navbar-toggler {
    margin-top: 8px !important;
  }
}

.main-header-right {
  margin-right: 0 !important;
}

@media (min-width: 768px) {
  .search-element {
    margin-left: 10px;
    top: 16px;
  }

  .search-bar-escape {
    display: none;
  }
}

@media (max-width: 320px) {
  .cntTxt {
    font-size: "15px";
  }
}

.widget-user-image img {
  border: 3px solid #f5f6f8 !important;
  box-shadow: 0 0 0 4px #eeeef3 !important;
}

.zoom img:hover {
  transform: scale(0.96) !important;
  transition: 0.3s ease;
}

.no-border input[type="text"] {
  background-color: none;
  color: #664dc9;
  font-size: 14px;
  height: 30px;
  outline: 0;
  width: 150px;
}

.shopping-carttable .input-group .input-group-btn:first-child {
  border: 1px solid #e6ebf1;
  border-radius: 7px 0 0 7px;
  border-right: 0;
  height: 37px;
}

.shopping-carttable .input-group .input-group-btn:last-child {
  border: 1px solid #e6ebf1;
  border-left: 0;
  border-radius: 0 7px 7px 0;
  height: 37px;
}

.register-3 {
  /* background-image: url(https://laravel.spruko.com/azea/azea/assets/images/photos/register.jpg) !important; */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.register-3 hr {
  border-top: 1px solid hsla(0, 0%, 100%, 0.3) !important;
}

.register-3 .hrregister3 {
  background-color: transparent;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}

.register-3 .form-control {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid hsla(0, 0%, 100%, 0.1) !important;
  color: #fff;
}

.register-3 .form-control:focus {
  background-color: #5c45b5;
  border-color: hsla(0, 0%, 100%, 0.2);
  color: #fff;
}

.register-3 .input-group-text {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid #755fce !important;
  border-right: 0 !important;
  color: hsla(0, 0%, 100%, 0.5);
}

.register-3 input::-moz-placeholder {
  color: hsla(0, 0%, 100%, 0.5);
}

.register-3 input:-ms-input-placeholder {
  color: hsla(0, 0%, 100%, 0.5);
}

.register-3 input::placeholder {
  color: hsla(0, 0%, 100%, 0.5);
}

.register-3 .custom-control-label:before {
  background-color: rgba(0, 0, 0, 0.15);
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  color: hsla(0, 0%, 100%, 0.5);
}

.register-3
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label:before {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid hsla(0, 0%, 100%, 0.1);
}

.register-3 .btn-list .btn {
  background-color: hsla(0, 0%, 100%, 0.1);
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  color: hsla(0, 0%, 100%, 0.82);
}

.register-2 {
  /* background-image: url(https://laravel.spruko.com/azea/azea/assets/images/photos/register2.png); */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.register-2:before {
  background-color: rgba(102, 77, 201, 0.8);
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.register-2 .input-group-prepend .input-group-text {
  border: 1px solid #e6ebf1;
  border-radius: 5px 0 0 5px;
}

.register-2 .form-control:focus {
  border-color: #e6ebf1 !important;
}

.register-2 hr.divider:after {
  background: #755fce;
  border: 1px solid #cecddc;
  border-radius: 50%;
  color: #fff;
  content: "OR";
  display: block;
  font-size: 11px;
  font-weight: 500;
  height: 30px;
  left: 0;
  line-height: 30px;
  margin: 0 auto;
  padding: 0 0.25em;
  position: relative;
  right: 0;
  text-align: center;
  top: -1.5em;
  width: 30px;
  z-index: 9;
}

.register-2 .divider {
  border-top: 1px solid hsla(0, 0%, 100%, 0.3);
}

.register-2 .input-group-text {
  border-right: 0 !important;
}

.register1 {
  position: relative;
}

.register1 .input-group-prepend .input-group-text {
  border: 1px solid #e6ebf1;
  border-radius: 5px 0 0 5px;
}

.register1 .form-control:focus {
  border-color: #e6ebf1 !important;
}

.register1 .input-group-text {
  border-right: 0 !important;
}

.register1 .custom-content .text-white-50 {
  color: #fff !important;
}

.register-1 {
  background: url(https://laravel.spruko.com/azea/azea/assets/images/photos/register3.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 7px 0 0 7px;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 999;
}

.register-1:before {
  background: rgba(102, 77, 201, 0.85);
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.register-1 .custom-content {
  position: relative;
}

.construction {
  background-image: url(https://laravel.spruko.com/azea/azea/assets/images/photos/register4.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 999;
}

.construction:before {
  background: linear-gradient(#2c3e50, #bdc3c7);
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.construction .custom-content {
  position: relative;
}

.construction .countdown li:first-child {
  background-image: linear-gradient(90deg, #664dc9, #876eef);
}

.construction .countdown li:nth-child(2) {
  background-image: linear-gradient(90deg, #1d976c, #47b182);
}

.construction .countdown li:nth-child(3) {
  background-image: linear-gradient(90deg, #fa5420, #ea7f5d);
}

.construction .countdown li:nth-child(4) {
  background-image: linear-gradient(90deg, #5b73e8, #43a0c7);
}

.bg-contacthead {
  /* background-image: url(../images/photos/contact-3.jpg); */
  border-radius: 0 8px 0 0;
  position: relative;
}

.bg-contacthead:before {
  background: linear-gradient(
    to right top,
    #664dc9,
    #6357d1,
    #6161d9,
    #5e6ae1,
    #5b73e8
  );
  border-radius: 0 8px 0 0 !important;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 100%;
}

.bg-gradient,
.main-contact-info-header .media {
  position: relative;
}

.bg-gradient {
  /* background: url(../images/photos/contact-2.jpg) !important; */
  background-position: 50%;
  background-size: cover;
}

.bg-gradient:before {
  background: linear-gradient(
    to right top,
    #664dc9,
    #6357d1,
    #6161d9,
    #5e6ae1,
    #5b73e8
  ) !important;
  border-radius: 0.3rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
}

.bg-gradient p,
.bg-gradient small {
  position: relative;
}

.bg-gradient .option-dots {
  color: #fff !important;
  position: relative;
}

.headerstyle-1 .header .nav-unread {
  right: 0.95rem;
  top: 0.17rem;
}

.headerstyle-1 .header .notifyimg {
  height: 40px;
  line-height: 40px;
}

.headerstyle-1 .header .dropdown-menu {
  -webkit-animation-name: none;
  animation-name: none;
  -webkit-animation: none;
  animation: none;
  left: auto !important;
  top: 0 !important;
}

.headerstyle-2 .header .header-brand .header-brand-img {
  margin-top: 2px;
}

.headerstyle-2 .header .notifyimg {
  height: 40px;
  line-height: 40px;
}

.headerstyle-2 .header .form-inline .form-control {
  margin-top: -5px;
}

.headerstyle-2 .header .form-inline .btn {
  padding-top: 18px !important;
  top: -16px;
}

.headerstyle-2 .header .dropdown-menu {
  -webkit-animation-name: none;
  animation-name: none;
  -webkit-animation: none;
  animation: none;
  left: auto !important;
  top: 0 !important;
}

.headerstyle-2 .header .search-element {
  top: 0 !important;
}

.headerstyle-2 .header .nav-unread {
  right: 0.9rem;
  top: 0.2rem;
}

.headerstyle-2 .header .bg-warning {
  right: 6px;
}

.headerstyle-2 .header .form-inline .form-control {
  padding-top: 7px;
}

@media (max-width: 767px) {
  .headerstyle-2 .header .header-brand-img {
    display: none;
  }
}

.headerstyle-3 .header .header-brand .header-brand-img {
  margin-top: 12px;
}

.headerstyle-3 .header .profile-dropdown {
  border-right: 0 solid #e6ebf1 !important;
}

.headerstyle-3 .header .nav-link.icon .header-icon {
  margin-top: 30px;
}

.headerstyle-3 .header .pulse {
  right: 8px;
  top: 19px;
}

.headerstyle-3 .header .dropdown-menu {
  -webkit-animation-name: none;
  animation-name: none;
  -webkit-animation: none;
  animation: none;
  left: auto !important;
  top: 0 !important;
}

.headerstyle-3
  .header
  .profile-dropdown
  .show.dropdown-menu[data-popper-placement^="bottom"] {
  left: 0 !important;
}

@media (max-width: 767px) {
  .headerstyle-3 .header .header-brand-img {
    display: none;
  }
}

.app-header .container-fluid {
  width: 100%;
}

.example-popover {
  z-index: 99 !important;
}

@media (max-width: 1440px) and (min-width: 1024px) {
  .recent-activity {
    height: 420px !important;
  }
}

.table-inbox tr:last-child {
  border-bottom-color: #e6ebf1 !important;
}

.register-icons .btn:hover .fa {
  color: #fff !important;
}

@media (min-width: 768px) {
  .dark-mode .sidenav-toggled .app-sidebar__logo {
    padding: 13px 9px !important;
  }
}

.btn-close {
  background-image: none !important;
  font-size: 25px;
  margin-top: -6px;
}

.dark-layout {
  display: none;
}

@media (min-width: 768px) {
  .dark-mode .theme-layout,
  .theme-layout {
    border-right: 0 !important;
  }
}

.responsive-search-icon .header-icon.search-icon {
  fill: #fff !important;
}

@media (min-width: 768px) and (max-width: 992px) {
  .search-element {
    display: none;
  }

  .responsive-navbar .collapse,
  .responsive-search {
    display: block !important;
  }

  .theme-layout {
    border-left: 0 solid #e6ebf1 !important;
  }

  .responsive-search .search-icon {
    margin-top: 5px;
  }
}

.btn-primary-color:focus {
  box-shadow: none !important;
}

@media (max-width: 480px) {
  .responsive-navbar .badge {
    margin: 0;
  }
}
.btn-primary {
  background-color: #0077b5;
  color: #fff;
}
@media (min-width: 768px) {
  #checkoutsteps .checkoutline {
    background-color: #eaedf7;
    height: 3px;
    margin: 0 11%;
    position: relative;
    top: 28px;
    transform: translateY(1.7rem);
    z-index: 0;
  }
}

#checkoutsteps ul {
  display: flex;
  list-style: none !important;
  margin: 0;
  padding: 0;
}

#checkoutsteps ul[role="tablist"] li {
  align-items: center;
  display: flex;
  flex: 1;
  flex-basis: auto;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 1.1rem;
  font-weight: 500;
  z-index: 0;
}

#checkoutsteps > .steps a {
  background-color: transparent;
  border-radius: 30px;
  color: #2d2c40;
  cursor: default;
  display: block;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  padding: 14px 0;
  text-align: center;
  text-decoration: none;
  width: auto;
}

#checkoutsteps > .steps .current a .number {
  background: #664dc9;
  color: #fff;
}

#checkoutsteps .item .thumb {
  align-items: center;
  border: 1px solid #f2f2f2;
  display: inline-flex;
  height: 90px;
  justify-content: center;
  width: 100px;
}

#checkoutsteps .item,
#checkoutsteps .item .left {
  align-items: center;
  display: flex;
}

#checkoutsteps .item {
  border-bottom: 1px solid #e6e6e6;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

#checkoutsteps .item .purchase {
  display: inline-block;
  margin-left: 21px;
  text-decoration: none;
}

#checkoutsteps .total {
  color: #664dc9;
  float: right;
}

#checkoutsteps > .steps {
  border-radius: 3px;
  display: block;
  padding: 0;
  position: relative;
  width: 100%;
}

#checkoutsteps.wizard {
  border-radius: 10px;
}

.wizard > .content > .title {
  color: #8f9cc0;
  margin-bottom: 5px;
}

.wizard > .content > .title.current {
  display: none !important;
}

.card-pay .tabs-menu.nav li {
  border-right: 1px solid #e6ebf1;
}

.card-pay .tabs-menu.nav li:last-child {
  border-right: 0;
}

.dark-mode .card-pay .tabs-menu.nav li {
  border-right: 1px solid #32394e;
}

.dark-mode .card-pay .tabs-menu.nav li:last-child {
  border-right: 0;
}

.card-pay .tabs-menu li {
  display: block;
  width: 33.3%;
}

.card-pay .tabs-menu li a.active {
  background: #664dc9;
  color: #fff;
}

.card-pay .tabs-menu li a {
  display: block;
  padding: 0.7rem 1rem;
  text-align: center;
}

.card-pay .tabs-menu {
  border-radius: 5px;
  margin-bottom: 25px;
  overflow: hidden;
}

@media (min-width: 768px) {
  #checkoutsteps > .steps .number {
    align-items: center;
    background-color: #eaedf7;
    border-radius: 50%;
    color: #9197ab;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    height: 45px;
    justify-content: center;
    margin: 15px auto;
    width: 45px;
  }
}

@media (max-width: 767px) {
  #checkoutsteps > .steps .number {
    align-items: center;
    background-color: #eaedf7;
    border-radius: 50%;
    color: #9197ab;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    height: 35px;
    justify-content: center;
    margin: 15px auto;
    width: 35px;
  }

  #checkoutsteps > .steps a {
    font-size: 12px !important;
  }

  #checkoutsteps .checkoutline {
    background-color: #eaedf7;
    height: 3px;
    margin: 0 11%;
    position: relative;
    top: 20px;
    transform: translateY(1.7rem);
    z-index: 0;
  }
}

.panel-default > .panel-heading + .panel-collapse > .panel-body,
.panel-group1 .panel-body {
  padding: 15px;
}

.header .profile-dropdown .dropdown-menu {
  min-width: 10rem;
}

.apexcharts-legend {
  margin: -11px;
}

.apexcharts-xaxis text,
.apexcharts-yaxis text {
  fill: #8897a5;
  font-family: Poppins, sans-serif;
  font-size: 12px;
}

.Recent-transactions-img {
  height: 37px;
  justify-content: center;
  line-height: 37px;
  margin-right: 12px;
  text-align: center;
  width: 37px;
}

@media (min-width: 992px) {
  .theme-layout {
    border-left: 1px solid #e6ebf1;
  }
}

.page-rightheader .btn-pill {
  border-radius: 0.25rem;
}

.cart-items-remove {
  position: relative;
}

.img-options-remove {
  font-size: 16px;
  left: 16px;
  margin-top: -10px;
  position: absolute;
}

.tabs-menu .shop-des-tabs li .active {
  background: #664dc933;
  border: 1px solid #664dc9;
  color: #664dc9 !important;
}

.user-contact-list .option-dots:focus {
  background-color: #f0f0f2;
}

.user-contact-list .option-dots:focus i {
  color: #664dc9;
}

.user-contact-list .dropdown-menu {
  border-radius: 0.25rem;
}

.user-contact-list .card-header.bg-gradient {
  border-radius: 0.3rem !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.media {
  margin-top: 0 !important;
}

.calendar-image {
  margin-top: 2.5rem;
}

.dataTables_length .form-select {
  border: 1px solid #e6ebf1;
  color: #495047;
}

table.table-bordered.dataTable td:first-child,
table.table-bordered.dataTable th:first-child {
  border-left-width: 0 !important;
}

#delete-datatable tr {
  cursor: pointer;
}

#delete-datatable tr.selected {
  background: #f3f6f8;
}

.progress-bar-custom {
  background-color: #6563ff;
}

.ql-bubble .ql-tooltip {
  background-color: #fff !important;
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
  border-bottom: 6px solid #fff !important;
}

#validationCustom04 {
  border: 1px solid #e6ebf1;
}

hr:not([size]) {
  height: 0 !important;
}

.example .btn:hover .badge {
  background-color: #fff !important;
  color: #495047;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 32px !important;
}

.multi-wrapper .item-1:hover {
  color: #664dc9 !important;
}

@media (max-width: 992px) {
  .invoicedetailspage {
    margin-top: 10px;
  }
}

.product-details .tabs-menu ul li .active {
  background-color: transparent !important;
}

.product-details .tabs-menu ul li a {
  border: 0 !important;
}

@media (max-width: 500px) {
  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-top: 5px;
  }
}

.purchase {
  color: #495047 !important;
}

.register1:before {
  background-color: #ebebf3;
}

.register1:before,
.register-3:before {
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.register-3:before {
  background-color: rgba(102, 77, 201, 0.1);
}

@media (max-width: 992px) {
  .register1 .bg-white {
    border-bottom-left-radius: 7px !important;
    border-top-left-radius: 7px !important;
  }
}

@media (max-width: 767px) {
  .register-3 .header-brand-img {
    display: none !important;
  }
}

#left-defaults,
#left-events,
#right-defaults {
  touch-action: none;
}

#spark1 .apexcharts-area-series .apexcharts-area,
#spark1 .apexcharts-line,
#spark1 .apexcharts-tooltip .apexcharts-marker,
#spark2 .apexcharts-area-series .apexcharts-area,
#spark2 .apexcharts-line,
#spark2 .apexcharts-tooltip .apexcharts-marker,
#spark3 .apexcharts-area-series .apexcharts-area,
#spark3 .apexcharts-line,
#spark3 .apexcharts-tooltip .apexcharts-marker,
#spark4 .apexcharts-area-series .apexcharts-area,
#spark4 .apexcharts-line,
#spark4 .apexcharts-tooltip .apexcharts-marker {
  fill: transparent;
  pointer-events: none;
}

.legend {
  border-radius: 2px;
  display: block;
  height: 8px;
  margin-right: 10px;
  margin-top: 6px;
  width: 8px;
}

.morris-legends {
  list-style: none;
  margin-bottom: 0;
  padding-left: 10px;
  text-align: center;
}

.bg-primary-light-1 {
  background-color: #8976d5;
}

.bg-primary-light-2 {
  background-color: #ab9de1;
}

.bg-success-light-1 {
  background-color: #5dd59f;
}

.bg-success-light-2 {
  background-color: #85e0b7;
}

.bg-warning-light-1 {
  background-color: #fb3;
}

.bg-warning-light-2 {
  background-color: #fc6;
}

.bg-danger-light-1 {
  background-color: #f37272;
}

.bg-danger-light-2 {
  background-color: #f7a1a1;
}

.btn-animation .btn-loaders:after {
  -webkit-animation: loader 0.5s linear infinite;
  animation: loader 0.5s linear infinite;
  border: 2px solid #fff;
  border-radius: 50%;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  content: "";
  display: block;
  height: 1.4em;
  position: absolute;
  position: absolute !important;
  right: 20px !important;
  top: calc(50% - 0.7em);
  transform-origin: center;
  width: 1.4em;
}

.btn-animation .loading {
  margin-right: 20px;
}

.btn-animation .btn-loaders {
  color: #fff !important;
  padding-right: 46px;
  pointer-events: none;
  position: relative;
}

.mr-negative-16 {
  margin-right: -18px;
}

.pos-static {
  position: static;
}

#tabs-style4 .tabs-menu ul li a {
  margin: 3px 3px 3px 40px;
}

@media (max-width: 767px) {
  .register-icons {
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .btn-close {
    margin-top: 6px;
  }
}

@media (max-width: 480px) {
  .badge {
    margin: 0 !important;
  }
}

.nav-link {
  color: #664dc9;
}

@media (max-width: 480px) {
  .tabs-menu ul li {
    margin: 0;
  }

  #tabs-style4 .tabs-menu ul li a {
    margin: 3px 0;
  }

  .pro-details li {
    margin-bottom: 1.5rem;
    width: 100%;
  }
}

@media (max-width: 568px) {
  .card-pay .tabs-menu li {
    width: 100%;
  }

  .construction .display-3 {
    font-size: 2.5rem;
  }

  .pro-user-username {
    margin-top: 1.5rem;
  }
}

.widget-user-image1 .avatar {
  height: 9rem;
  width: 9rem;
}

@media (max-width: 1025px) {
  .pro-user-username {
    margin-top: 1.5rem;
  }
}

@media (max-width: 327px) {
  .profile-share button.pull-right {
    float: none;
  }

  .comming .display-2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 991px) {
  .main-profile-contact-list .media {
    margin-top: 15px !important;
  }
}

#spark1 .apexcharts-canvas,
#spark2 .apexcharts-canvas,
#spark3 .apexcharts-canvas,
#spark4 .apexcharts-canvas {
  width: auto !important;
}

.apexcharts-tooltip.light {
  background: hsla(0, 0%, 100%, 0.1) !important;
  border: 1px solid transparent !important;
}

.apexcharts-tooltip.light .apexcharts-tooltip-title {
  background: hsla(0, 0%, 100%, 0.1) !important;
  border-bottom: 1px solid transparent !important;
}

.apexcharts-yaxis-texts-g {
  color: #fff !important;
}

.country {
  height: 0.8rem !important;
  line-height: 1.4rem !important;
  width: 1.1rem !important;
}

.country-selector {
  border-right: 1px solid #e6ebf1 !important;
  padding: 0.3rem;
}

.country-selector .nav-link {
  color: #495046;
}

.country-selector .nav-link:hover {
  color: #495046 !important;
}

.search-bar-escape {
  display: none !important;
}

.flag-dropdown .selected-flag {
  border-radius: 4px 0 0 4px !important;
  padding: 9px 24px 11px 16px !important;
}

@media (min-width: 767.98px) and (max-width: 992px) {
  .header .main-header-end .nav-link.icon {
    padding: 2.2rem 0.3rem !important;
  }
}

@media (min-width: 991.98px) and (max-width: 1400px) {
  .hor-header .header-search {
    border: 1px solid #e6ebf1 !important;
    min-width: 14rem !important;
  }
}

@media (max-width: 767.98px) {
  .hor-header .header-search .btn {
    top: 5px !important;
  }
}

@media (min-width: 768px) {
  .hor-header.header.top-header {
    padding-left: 0 !important;
  }
}

.demo-icon .switcher-icon {
  fill: #fff !important;
  color: #fff !important;
}

.table-bordered thead th {
  border-bottom-width: 1px !important;
}

.mail-box {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.mail-box aside {
  display: table-cell;
  float: none;
  height: 100%;
  padding: 0;
  vertical-align: top;
}

.mail-box .sm-side {
  background: none repeat scroll 0 0 #e5e8ef;
  border-radius: 4px 0 0 4px;
  width: 25%;
}

.mail-box .lg-side {
  background: none repeat scroll 0 0 #fff;
  border-radius: 0 4px 4px 0;
  width: 75%;
}

.mail-box .sm-side .user-head {
  background: none repeat scroll 0 0 #00a8b3;
  border-radius: 4px 0 0;
  color: #fff;
  min-height: 80px;
  padding: 10px;
}

.user-head .inbox-avatar {
  float: left;
  width: 65px;
}

.user-head .inbox-avatar img {
  border-radius: 4px;
}

.user-head .user-name {
  display: inline-block;
  margin: 0 0 0 10px;
}

.user-head .user-name h5 {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 15px;
}

.user-head .user-name h5 a {
  color: #fff;
}

.user-head .user-name span a {
  color: #87e2e7;
  font-size: 12px;
}

a.mail-dropdown {
  background: none repeat scroll 0 0 #80d3d9;
  border-radius: 2px;
  color: #01a7b3;
  font-size: 10px;
  margin-top: 20px;
  padding: 3px 5px;
}

.btn-compose {
  background: none repeat scroll 0 0 #ff6c60;
  color: #fff;
  padding: 12px 0;
  text-align: center;
  width: 100%;
}

.btn-compose:hover {
  background: none repeat scroll 0 0 #f5675c;
  color: #fff;
}

ul.inbox-nav {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
}

.inbox-divider {
  border-bottom: 1px solid #d5d8df;
}

ul.inbox-nav li,
ul.inbox-nav li a {
  display: inline-block;
  line-height: 45px;
  width: 100%;
}

ul.inbox-nav li a {
  color: #6a6a6a;
  padding: 0 20px;
}

ul.inbox-nav li.active a,
ul.inbox-nav li a:focus,
ul.inbox-nav li a:hover {
  background: none repeat scroll 0 0 #d5d7de;
  color: #6a6a6a;
}

ul.inbox-nav li a i {
  color: #6a6a6a;
  font-size: 16px;
  padding-right: 10px;
}

ul.inbox-nav li a span.label {
  margin-top: 13px;
}

ul.labels-info li {
  margin: 0;
}

ul.labels-info li h4 {
  color: #5c5c5e;
  font-size: 13px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  text-transform: uppercase;
}

ul.labels-info li a {
  border-radius: 0;
  color: #6a6a6a;
}

ul.labels-info li a:focus,
ul.labels-info li a:hover {
  background: none repeat scroll 0 0 #d5d7de;
  color: #6a6a6a;
}

ul.labels-info li a i {
  padding-right: 10px;
}

.nav.nav-pills.nav-stacked.labels-info p {
  color: #9d9f9e;
  font-size: 11px;
  margin-bottom: 0;
  padding: 0 22px;
}

.inbox-head {
  border-radius: 0 4px 0 0;
  border-radius: 3px;
  padding: 10px;
}

.inbox-head h3 {
  display: inline-block;
  font-weight: 300;
  margin: 0;
}

.inbox-head .sr-input {
  border: none;
  border-radius: 4px 0 0 4px;
  box-shadow: none;
  color: #8a8a8a;
  float: left;
  height: 30px;
  padding: 0 10px;
}

.inbox-head .sr-btn {
  background: none repeat scroll 0 0 #38a01e;
  border: none;
  border-radius: 0 4px 4px 0;
  color: #fff;
  height: 30px;
  padding: 0 20px;
}

.table-inbox {
  border: 1px solid #e6ebf1;
  margin-bottom: 0;
}

.table-inbox tr {
  border-bottom: 1px solid #e6ebf1;
}

.table-inbox tr:last-child {
  border-bottom: 0;
}

.table-inbox tr td {
  padding: 12px !important;
  vertical-align: middle;
}

.table-inbox tr td:hover {
  cursor: pointer;
}

.table-inbox tr td .fa-star {
  color: #e4e4ed;
  font-size: 16px;
}

.table-inbox tr td .fa-star.inbox-lefted,
.table-inbox tr td .fa-star:hover {
  color: #e6e9f1;
}

.table-inbox tr td .fa-bookmark {
  color: #e4e4ed;
}

.table-inbox tr td.view-message i {
  border: 1px solid #e6ebf1;
  border-radius: 100%;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  color: #5c678f;
  display: block;
  font-size: 16px !important;
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  width: 40px;
}

.table-inbox tr.unread td {
  background: #f0f0f2;
  border-bottom: 1px solid #e6ebf1;
  font-weight: 600;
}

ul.inbox-pagination {
  float: right;
}

ul.inbox-pagination li {
  float: left;
}

.mail-option {
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;
}

.mail-option .btn-group,
.mail-option .chk-all {
  margin-right: 5px;
}

.mail-option .btn-group a.btn,
.mail-option .chk-all {
  border: 1px solid #e6ebf1;
  border-radius: 3px !important;
  display: inline-block;
  padding: 5px 10px;
}

.inbox-pagination a.np-btn {
  border: 1px solid #e6ebf1;
  border-radius: 3px !important;
  display: inline-block;
  margin-left: 5px;
  padding: 5px 15px;
}

.mail-option .chk-all input[type="checkbox"] {
  margin-top: 0;
}

.mail-option .btn-group a.all {
  border: none;
  padding: 0;
}

.inbox-pagination li span {
  display: inline-block;
  margin-right: 5px;
  margin-top: 7px;
}

.inbox-body .modal .modal-body input,
.inbox-body .modal .modal-body textarea {
  border: 1px solid #e6e6e6;
  box-shadow: none;
}

.btn-sright,
.btn-sright:hover {
  background: none repeat scroll 0 0 #00a8b3;
  color: #fff;
}

.btn-sright:hover {
  background: none repeat scroll 0 0 #009da7;
}

.modal-header h4.modal-title {
  font-weight: 300;
}

.modal-body label {
  font-weight: 400;
}

.heading-inbox h4 {
  border-bottom: 1px solid #ddd;
  color: #444;
  font-size: 18px;
  margin-top: 20px;
  padding-bottom: 10px;
}

.srighter-info {
  margin-bottom: 20px;
}

.srighter-info img {
  height: 30px;
  width: 30px;
}

.srighter-dropdown {
  background: none repeat scroll 0 0 1px solid #e6ebf1;
  color: #777;
  font-size: 10px;
  padding: 0 3px;
}

.view-mail a {
  color: #ff6c60;
}

.attachment-mail {
  margin-top: 30px;
}

.attachment-mail ul {
  display: inline-block;
  margin-bottom: 30px;
  width: 100%;
}

.attachment-mail ul li {
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 150px;
}

.attachment-mail ul li img {
  width: 100%;
}

.attachment-mail ul li span {
  float: right;
}

.attachment-mail .file-name {
  float: left;
}

.attachment-mail .links {
  display: inline-block;
  width: 100%;
}

.fileinput-button {
  float: left;
  margin-right: 4px;
  overflow: hidden;
  position: relative;
}

.fileinput-button input {
  cursor: pointer;
  direction: ltr;
  font-size: 23px;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-300px) scale(4);
}

.fileupload-buttonbar .btn,
.fileupload-buttonbar .toggle {
  margin-bottom: 5px;
}

.files .progress {
  width: 200px;
}

.fileupload-processing .fileupload-loading {
  display: block;
}

.mail-chats {
  border-top: 1px solid hsla(0, 0%, 50%, 0.16);
  display: flex !important;
  flex-direction: column !important;
  height: 100%;
  list-style-type: none;
  margin-top: 10px;
  min-height: 0;
  padding: 10px 50px 10px 0;
  width: 100%;
}

.mail-chats li.chat-persons {
  display: block;
  padding: 10px;
}

li.chat-persons a {
  text-decoration: none;
}

.mail-chats li.chat-persons a span.pro-pic {
  display: inline-block;
  float: left;
  margin-right: 20px;
  max-width: 40px;
  padding: 0;
  width: 20%;
}

.mail-chats li.chat-persons a span.pro-pic img {
  border-radius: 100%;
  flex-direction: column !important;
  max-width: 100%;
  width: 100%;
}

.mail-chats li.chat-persons a div.user {
  flex-direction: column !important;
}

ul.mail-chats li.chat-persons a div.user {
  display: flex !important;
}

.mail-chats li.chat-persons.user {
  display: flex !important;
  flex-direction: column !important;
  padding: 5px 10px 0 15px;
  width: 80%;
}

.user p.u-name {
  color: #1f2127;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
}

.user p.u-designation {
  color: #6b6f80;
  font-size: 11px;
  margin: 0;
}

div.online-status .status.online {
  background: rgba(0, 128, 0, 0.68);
}

.online-status .status {
  border-radius: 100%;
  display: inline-flex;
  height: 10px;
  justify-content: flex-left;
  transform: translateX(-60px) translateY(2px);
  width: 10px;
}

.online-status .status.offline {
  background: rgba(255, 0, 0, 0.7);
}

.email-icon {
  border: 1px solid #e6ebf1;
  border-radius: 100%;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  display: block;
  font-size: 16px !important;
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  width: 40px;
}

.email-icon,
.email-icon i {
  color: #5c678f;
}

.card .list-group.mail-inbox-elements {
  border-bottom: 1px solid #e6ebf1 !important;
}

@font-face {
  font-family: feather;
}

.fe {
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: feather !important;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  margin-top: 2px;
  text-transform: none;
}

.fe-activity:before {
  content: "\e900";
}

.fe-airplay:before {
  content: "\e901";
}

.fe-alert-circle:before {
  content: "\e902";
}

.fe-alert-octagon:before {
  content: "\e903";
}

.fe-alert-triangle:before {
  content: "\e904";
}

.fe-align-center:before {
  content: "\e905";
}

.fe-align-justify:before {
  content: "\e906";
}

.fe-align-left:before {
  content: "\e907";
}

.fe-align-right:before {
  content: "\e908";
}

.fe-anchor:before {
  content: "\e909";
}

.fe-aperture:before {
  content: "\e90a";
}

.fe-arrow-down:before {
  content: "\e90b";
}

.fe-arrow-down-circle:before {
  content: "\e90c";
}

.fe-arrow-down-left:before {
  content: "\e90d";
}

.fe-arrow-down-right:before {
  content: "\e90e";
}

.fe-arrow-left:before {
  content: "\e90f";
}

.fe-arrow-left-circle:before {
  content: "\e910";
}

.fe-arrow-right:before {
  content: "\e911";
}

.fe-arrow-right-circle:before {
  content: "\e912";
}

.fe-arrow-up:before {
  content: "\e913";
}

.fe-arrow-up-circle:before {
  content: "\e914";
}

.fe-arrow-up-left:before {
  content: "\e915";
}

.fe-arrow-up-right:before {
  content: "\e916";
}

.fe-at-sign:before {
  content: "\e917";
}

.fe-award:before {
  content: "\e918";
}

.fe-bar-chart:before {
  content: "\e919";
}

.fe-bar-chart-2:before {
  content: "\e91a";
}

.fe-battery:before {
  content: "\e91b";
}

.fe-battery-charging:before {
  content: "\e91c";
}

.fe-bell:before {
  content: "\e91d";
}

.fe-bell-off:before {
  content: "\e91e";
}

.fe-bluetooth:before {
  content: "\e91f";
}

.fe-bold:before {
  content: "\e920";
}

.fe-book:before {
  content: "\e921";
}

.fe-book-open:before {
  content: "\e922";
}

.fe-bookmark:before {
  content: "\e923";
}

.fe-box:before {
  content: "\e924";
}

.fe-briefcase:before {
  content: "\e925";
}

.fe-calender:before {
  content: "\e926";
}

.fe-camera:before {
  content: "\e927";
}

.fe-camera-off:before {
  content: "\e928";
}

.fe-cast:before {
  content: "\e929";
}

.fe-check:before {
  content: "\e92a";
}

.fe-check-circle:before {
  content: "\e92b";
}

.fe-check-square:before {
  content: "\e92c";
}

.fe-chevron-down:before {
  content: "\e92d";
}

.fe-chevron-left:before {
  content: "\e92e";
}

.fe-chevron-right:before {
  content: "\e92f";
}

.fe-chevron-up:before {
  content: "\e930";
}

.fe-chevrons-down:before {
  content: "\e931";
}

.fe-chevrons-left:before {
  content: "\e932";
}

.fe-chevrons-right:before {
  content: "\e933";
}

.fe-chevrons-up:before {
  content: "\e934";
}

.fe-chrome:before {
  content: "\e935";
}

.fe-circle:before {
  content: "\e936";
}

.fe-clipboard:before {
  content: "\e937";
}

.fe-clock:before {
  content: "\e938";
}

.fe-cloud:before {
  content: "\e939";
}

.fe-cloud-drizzle:before {
  content: "\e93a";
}

.fe-cloud-lightning:before {
  content: "\e93b";
}

.fe-cloud-off:before {
  content: "\e93c";
}

.fe-cloud-rain:before {
  content: "\e93d";
}

.fe-cloud-snow:before {
  content: "\e93e";
}

.fe-code:before {
  content: "\e93f";
}

.fe-codepen:before {
  content: "\e940";
}

.fe-command:before {
  content: "\e941";
}

.fe-compass:before {
  content: "\e942";
}

.fe-copy:before {
  content: "\e943";
}

.fe-corner-down-left:before {
  content: "\e944";
}

.fe-corner-down-right:before {
  content: "\e945";
}

.fe-corner-left-down:before {
  content: "\e946";
}

.fe-corner-left-up:before {
  content: "\e947";
}

.fe-corner-right-down:before {
  content: "\e948";
}

.fe-corner-right-up:before {
  content: "\e949";
}

.fe-corner-up-left:before {
  content: "\e94a";
}

.fe-corner-up-right:before {
  content: "\e94b";
}

.fe-cpu:before {
  content: "\e94c";
}

.fe-credit-card:before {
  content: "\e94d";
}

.fe-crop:before {
  content: "\e94e";
}

.fe-crosshair:before {
  content: "\e94f";
}

.fe-database:before {
  content: "\e950";
}

.fe-delete:before {
  content: "\e951";
}

.fe-disc:before {
  content: "\e952";
}

.fe-dollar-sign:before {
  content: "\e953";
}

.fe-download:before {
  content: "\e954";
}

.fe-download-cloud:before {
  content: "\e955";
}

.fe-droplet:before {
  content: "\e956";
}

.fe-edit:before {
  content: "\e957";
}

.fe-edit-2:before {
  content: "\e958";
}

.fe-edit-3:before {
  content: "\e959";
}

.fe-external-link:before {
  content: "\e95a";
}

.fe-eye:before {
  content: "\e95b";
}

.fe-eye-off:before {
  content: "\e95c";
}

.fe-facebook:before {
  content: "\e95d";
}

.fe-fast-forward:before {
  content: "\e95e";
}

.fe-feather:before {
  content: "\e95f";
}

.fe-file:before {
  content: "\e960";
}

.fe-file-minus:before {
  content: "\e961";
}

.fe-file-plus:before {
  content: "\e962";
}

.fe-file-text:before {
  content: "\e963";
}

.fe-film:before {
  content: "\e964";
}

.fe-filter:before {
  content: "\e965";
}

.fe-flag:before {
  content: "\e966";
}

.fe-folder:before {
  content: "\e967";
}

.fe-folder-minus:before {
  content: "\e968";
}

.fe-folder-plus:before {
  content: "\e969";
}

.fe-git-branch:before {
  content: "\e96a";
}

.fe-git-commit:before {
  content: "\e96b";
}

.fe-git-merge:before {
  content: "\e96c";
}

.fe-git-pull-request:before {
  content: "\e96d";
}

.fe-github:before {
  content: "\e96e";
}

.fe-gitlab:before {
  content: "\e96f";
}

.fe-globe:before {
  content: "\e970";
}

.fe-grid:before {
  content: "\e971";
}

.fe-hard-drive:before {
  content: "\e972";
}

.fe-hash:before {
  content: "\e973";
}

.fe-headphones:before {
  content: "\e974";
}

.fe-heart:before {
  content: "\e975";
}

.fe-help-circle:before {
  content: "\e976";
}

.fe-home:before {
  content: "\e977";
}

.fe-image:before {
  content: "\e978";
}

.fe-inbox:before {
  content: "\e979";
}

.fe-info:before {
  content: "\e97a";
}

.fe-instagram:before {
  content: "\e97b";
}

.fe-italic:before {
  content: "\e97c";
}

.fe-layers:before {
  content: "\e97d";
}

.fe-layout:before {
  content: "\e97e";
}

.fe-life-buoy:before {
  content: "\e97f";
}

.fe-link:before {
  content: "\e980";
}

.fe-link-2:before {
  content: "\e981";
}

.fe-linkedin:before {
  content: "\e982";
}

.fe-list:before {
  content: "\e983";
}

.fe-loader:before {
  content: "\e984";
}

.fe-lock:before {
  content: "\e985";
}

.fe-log-in:before {
  content: "\e986";
}

.fe-log-out:before {
  content: "\e987";
}

.fe-mail:before {
  content: "\e988";
}

.fe-map:before {
  content: "\e989";
}

.fe-map-pin:before {
  content: "\e98a";
}

.fe-maximize:before {
  content: "\e98b";
}

.fe-maximize-2:before {
  content: "\e98c";
}

.fe-menu:before {
  content: "\e98d";
}

.fe-message-circle:before {
  content: "\e98e";
}

.fe-message-square:before {
  content: "\e98f";
}

.fe-mic:before {
  content: "\e990";
}

.fe-mic-off:before {
  content: "\e991";
}

.fe-minimize:before {
  content: "\e992";
}

.fe-minimize-2:before {
  content: "\e993";
}

.fe-minus:before {
  content: "\e994";
}

.fe-minus-circle:before {
  content: "\e995";
}

.fe-minus-square:before {
  content: "\e996";
}

.fe-monitor:before {
  content: "\e997";
}

.fe-moon:before {
  content: "\e998";
}

.fe-more-horizontal:before {
  content: "\e999";
}

.fe-more-vertical:before {
  content: "\e99a";
}

.fe-move:before {
  content: "\e99b";
}

.fe-music:before {
  content: "\e99c";
}

.fe-navigation:before {
  content: "\e99d";
}

.fe-navigation-2:before {
  content: "\e99e";
}

.fe-octagon:before {
  content: "\e99f";
}

.fe-package:before {
  content: "\e9a0";
}

.fe-paperclip:before {
  content: "\e9a1";
}

.fe-pause:before {
  content: "\e9a2";
}

.fe-pause-circle:before {
  content: "\e9a3";
}

.fe-percent:before {
  content: "\e9a4";
}

.fe-phone:before {
  content: "\e9a5";
}

.fe-phone-call:before {
  content: "\e9a6";
}

.fe-phone-forwarded:before {
  content: "\e9a7";
}

.fe-phone-incoming:before {
  content: "\e9a8";
}

.fe-phone-missed:before {
  content: "\e9a9";
}

.fe-phone-off:before {
  content: "\e9aa";
}

.fe-phone-outgoing:before {
  content: "\e9ab";
}

.fe-pie-chart:before {
  content: "\e9ac";
}

.fe-play:before {
  content: "\e9ad";
}

.fe-play-circle:before {
  content: "\e9ae";
}

.fe-plus:before {
  content: "\e9af";
}

.fe-plus-circle:before {
  content: "\e9b0";
}

.fe-plus-square:before {
  content: "\e9b1";
}

.fe-pocket:before {
  content: "\e9b2";
}

.fe-power:before {
  content: "\e9b3";
}

.fe-printer:before {
  content: "\e9b4";
}

.fe-radio:before {
  content: "\e9b5";
}

.fe-refresh-ccw:before {
  content: "\e9b6";
}

.fe-refresh-cw:before {
  content: "\e9b7";
}

.fe-repeat:before {
  content: "\e9b8";
}

.fe-rewind:before {
  content: "\e9b9";
}

.fe-rotate-ccw:before {
  content: "\e9ba";
}

.fe-rotate-cw:before {
  content: "\e9bb";
}

.fe-rss:before {
  content: "\e9bc";
}

.fe-save:before {
  content: "\e9bd";
}

.fe-scissors:before {
  content: "\e9be";
}

.fe-search:before {
  content: "\e9bf";
}

.fe-sright:before {
  content: "\e9c0";
}

.fe-server:before {
  content: "\e9c1";
}

.fe-settings:before {
  content: "\e9c2";
}

.fe-share:before {
  content: "\e9c3";
}

.fe-share-2:before {
  content: "\e9c4";
}

.fe-shield:before {
  content: "\e9c5";
}

.fe-shield-off:before {
  content: "\e9c6";
}

.fe-shopping-bag:before {
  content: "\e9c7";
}

.fe-shopping-cart:before {
  content: "\e9c8";
}

.fe-shuffle:before {
  content: "\e9c9";
}

.fe-sidebar:before {
  content: "\e9ca";
}

.fe-skip-back:before {
  content: "\e9cb";
}

.fe-skip-forward:before {
  content: "\e9cc";
}

.fe-slack:before {
  content: "\e9cd";
}

.fe-slash:before {
  content: "\e9ce";
}

.fe-sliders:before {
  content: "\e9cf";
}

.fe-smartphone:before {
  content: "\e9d0";
}

.fe-speaker:before {
  content: "\e9d1";
}

.fe-square:before {
  content: "\e9d2";
}

.fe-star:before {
  content: "\e9d3";
}

.fe-stop-circle:before {
  content: "\e9d4";
}

.fe-sun:before {
  content: "\e9d5";
}

.fe-sunrise:before {
  content: "\e9d6";
}

.fe-sunset:before {
  content: "\e9d7";
}

.fe-tablet:before {
  content: "\e9d8";
}

.fe-tag:before {
  content: "\e9d9";
}

.fe-target:before {
  content: "\e9da";
}

.fe-terminal:before {
  content: "\e9db";
}

.fe-thermometer:before {
  content: "\e9dc";
}

.fe-thumbs-down:before {
  content: "\e9dd";
}

.fe-thumbs-up:before {
  content: "\e9de";
}

.fe-toggle-left:before {
  content: "\e9df";
}

.fe-toggle-right:before {
  content: "\e9e0";
}

.fe-trash:before {
  content: "\e9e1";
}

.fe-trash-2:before {
  content: "\e9e2";
}

.fe-trrighting-down:before {
  content: "\e9e3";
}

.fe-trrighting-up:before {
  content: "\e9e4";
}

.fe-triangle:before {
  content: "\e9e5";
}

.fe-truck:before {
  content: "\e9e6";
}

.fe-tv:before {
  content: "\e9e7";
}

.fe-twitter:before {
  content: "\e9e8";
}

.fe-type:before {
  content: "\e9e9";
}

.fe-umbrella:before {
  content: "\e9ea";
}

.fe-underline:before {
  content: "\e9eb";
}

.fe-unlock:before {
  content: "\e9ec";
}

.fe-upload:before {
  content: "\e9ed";
}

.fe-upload-cloud:before {
  content: "\e9ee";
}

.fe-user:before {
  content: "\e9ef";
}

.fe-user-check:before {
  content: "\e9f0";
}

.fe-user-minus:before {
  content: "\e9f1";
}

.fe-user-plus:before {
  content: "\e9f2";
}

.fe-user-x:before {
  content: "\e9f3";
}

.fe-users:before {
  content: "\e9f4";
}

.fe-video:before {
  content: "\e9f5";
}

.fe-video-off:before {
  content: "\e9f6";
}

.fe-voicemail:before {
  content: "\e9f7";
}

.fe-volume:before {
  content: "\e9f8";
}

.fe-volume-1:before {
  content: "\e9f9";
}

.fe-volume-2:before {
  content: "\e9fa";
}

.fe-volume-x:before {
  content: "\e9fb";
}

.fe-watch:before {
  content: "\e9fc";
}

.fe-wifi:before {
  content: "\e9fd";
}

.fe-wifi-off:before {
  content: "\e9fe";
}

.fe-wind:before {
  content: "\e9ff";
}

.fe-x:before {
  content: "\ea00";
}

.fe-x-circle:before {
  content: "\ea01";
}

.fe-x-square:before {
  content: "\ea02";
}

.fe-zap:before {
  content: "\ea03";
}

.fe-zap-off:before {
  content: "\ea04";
}

.fe-zoom-in:before {
  content: "\ea05";
}

.fe-zoom-out:before {
  content: "\ea06";
}

.browser {
  background: no-repeat 50%/100% 100%;
  display: inline-block;
  font-style: normal;
  height: 1.25rem;
  vertical-align: bottom;
  width: 1.25rem;
}

.browser-android-browser {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/android-browser.svg);
}

.browser-aol-explorer {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/aol-explorer.svg);
}

.browser-blackberry {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/blackberry.svg);
}

.browser-camino {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/camino.svg);
}

.browser-chrome {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/chrome.svg);
}

.browser-chromium {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/chromium.svg);
}

.browser-dolphin {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/dolphin.svg);
}

.browser-edge {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/edge.svg);
}

.browser-firefox {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/firefox.svg);
}

.browser-ie {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/ie.svg);
}

.browser-maxthon {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/maxthon.svg);
}

.browser-mozilla {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/mozilla.svg);
}

.browser-netscape {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/netscape.svg);
}

.browser-opera {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/opera.svg);
}

.browser-safari {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/safari.svg);
}

.browser-sleipnir {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/sleipnir.svg);
}

.browser-uc-browser {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/uc-browser.svg);
}

.browser-vivaldi {
  background-image: url(https://laravel.spruko.com/azea/azea/images/browsers/vivaldi.svg);
}

.flag {
  background: no-repeat 50%/100% 100%;
  display: inline-block;
  font-style: normal;
  height: 1.2rem;
  vertical-align: bottom;
  width: 1.6rem;
}

.flag-ad {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ad.svg);
}

.flag-ae {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ae.svg);
}

.flag-af {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/af.svg);
}

.flag-ag {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ag.svg);
}

.flag-ai {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ai.svg);
}

.flag-al {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/al.svg);
}

.flag-am {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/am.svg);
}

.flag-ao {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ao.svg);
}

.flag-aq {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/aq.svg);
}

.flag-ar {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ar.svg);
}

.flag-as {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/as.svg);
}

.flag-at {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/at.svg);
}

.flag-au {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/au.svg);
}

.flag-aw {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/aw.svg);
}

.flag-ax {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ax.svg);
}

.flag-az {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/az.svg);
}

.flag-ba {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ba.svg);
}

.flag-bb {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bb.svg);
}

.flag-bd {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bd.svg);
}

.flag-be {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/be.svg);
}

.flag-bf {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bf.svg);
}

.flag-bg {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bg.svg);
}

.flag-bh {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bh.svg);
}

.flag-bi {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bi.svg);
}

.flag-bj {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bj.svg);
}

.flag-bl {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bl.svg);
}

.flag-bm {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bm.svg);
}

.flag-bn {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bn.svg);
}

.flag-bo {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bo.svg);
}

.flag-bq {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bq.svg);
}

.flag-br {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/br.svg);
}

.flag-bs {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bs.svg);
}

.flag-bt {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bt.svg);
}

.flag-bv {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bv.svg);
}

.flag-bw {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bw.svg);
}

.flag-by {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/by.svg);
}

.flag-bz {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/bz.svg);
}

.flag-ca {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ca.svg);
}

.flag-cc {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cc.svg);
}

.flag-cd {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cd.svg);
}

.flag-cf {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cf.svg);
}

.flag-cg {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cg.svg);
}

.flag-ch {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ch.svg);
}

.flag-ci {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ci.svg);
}

.flag-ck {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ck.svg);
}

.flag-cl {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cl.svg);
}

.flag-cm {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cm.svg);
}

.flag-cn {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cn.svg);
}

.flag-co {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/co.svg);
}

.flag-cr {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cr.svg);
}

.flag-cu {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cu.svg);
}

.flag-cv {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cv.svg);
}

.flag-cw {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cw.svg);
}

.flag-cx {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cx.svg);
}

.flag-cy {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cy.svg);
}

.flag-cz {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/cz.svg);
}

.flag-de {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/de.svg);
}

.flag-dj {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/dj.svg);
}

.flag-dk {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/dk.svg);
}

.flag-dm {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/dm.svg);
}

.flag-do {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/do.svg);
}

.flag-dz {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/dz.svg);
}

.flag-ec {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ec.svg);
}

.flag-ee {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ee.svg);
}

.flag-eg {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/eg.svg);
}

.flag-eh {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/eh.svg);
}

.flag-er {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/er.svg);
}

.flag-es {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/es.svg);
}

.flag-et {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/et.svg);
}

.flag-eu {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/eu.svg);
}

.flag-fi {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/fi.svg);
}

.flag-fj {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/fj.svg);
}

.flag-fk {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/fk.svg);
}

.flag-fm {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/fm.svg);
}

.flag-fo {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/fo.svg);
}

.flag-fr {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/fr.svg);
}

.flag-ga {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ga.svg);
}

.flag-gb-eng {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gb-eng.svg);
}

.flag-gb-nir {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gb-nir.svg);
}

.flag-gb-sct {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gb-sct.svg);
}

.flag-gb-wls {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gb-wls.svg);
}

.flag-gb {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gb.svg);
}

.flag-gd {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gd.svg);
}

.flag-ge {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ge.svg);
}

.flag-gf {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gf.svg);
}

.flag-gg {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gg.svg);
}

.flag-gh {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gh.svg);
}

.flag-gi {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gi.svg);
}

.flag-gl {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gl.svg);
}

.flag-gm {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gm.svg);
}

.flag-gn {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gn.svg);
}

.flag-gp {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gp.svg);
}

.flag-gq {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gq.svg);
}

.flag-gr {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gr.svg);
}

.flag-gs {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gs.svg);
}

.flag-gt {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gt.svg);
}

.flag-gu {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gu.svg);
}

.flag-gw {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gw.svg);
}

.flag-gy {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/gy.svg);
}

.flag-hk {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/hk.svg);
}

.flag-hm {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/hm.svg);
}

.flag-hn {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/hn.svg);
}

.flag-hr {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/hr.svg);
}

.flag-ht {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ht.svg);
}

.flag-hu {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/hu.svg);
}

.flag-id {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/id.svg);
}

.flag-ie {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ie.svg);
}

.flag-il {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/il.svg);
}

.flag-im {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/im.svg);
}

.flag-in {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/in.svg);
}

.flag-io {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/io.svg);
}

.flag-iq {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/iq.svg);
}

.flag-ir {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ir.svg);
}

.flag-is {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/is.svg);
}

.flag-it {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/it.svg);
}

.flag-je {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/je.svg);
}

.flag-jm {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/jm.svg);
}

.flag-jo {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/jo.svg);
}

.flag-jp {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/jp.svg);
}

.flag-ke {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ke.svg);
}

.flag-kg {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/kg.svg);
}

.flag-kh {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/kh.svg);
}

.flag-ki {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ki.svg);
}

.flag-km {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/km.svg);
}

.flag-kn {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/kn.svg);
}

.flag-kp {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/kp.svg);
}

.flag-kr {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/kr.svg);
}

.flag-kw {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/kw.svg);
}

.flag-ky {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ky.svg);
}

.flag-kz {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/kz.svg);
}

.flag-la {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/la.svg);
}

.flag-lb {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/lb.svg);
}

.flag-lc {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/lc.svg);
}

.flag-li {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/li.svg);
}

.flag-lk {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/lk.svg);
}

.flag-lr {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/lr.svg);
}

.flag-ls {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ls.svg);
}

.flag-lt {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/lt.svg);
}

.flag-lu {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/lu.svg);
}

.flag-lv {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/lv.svg);
}

.flag-ly {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ly.svg);
}

.flag-ma {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ma.svg);
}

.flag-mc {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mc.svg);
}

.flag-md {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/md.svg);
}

.flag-me {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/me.svg);
}

.flag-mf {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mf.svg);
}

.flag-mg {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mg.svg);
}

.flag-mh {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mh.svg);
}

.flag-mk {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mk.svg);
}

.flag-ml {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ml.svg);
}

.flag-mm {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mm.svg);
}

.flag-mn {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mn.svg);
}

.flag-mo {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mo.svg);
}

.flag-mp {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mp.svg);
}

.flag-mq {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mq.svg);
}

.flag-mr {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mr.svg);
}

.flag-ms {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ms.svg);
}

.flag-mt {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mt.svg);
}

.flag-mu {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mu.svg);
}

.flag-mv {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mv.svg);
}

.flag-mw {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mw.svg);
}

.flag-mx {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mx.svg);
}

.flag-my {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/my.svg);
}

.flag-mz {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/mz.svg);
}

.flag-na {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/na.svg);
}

.flag-nc {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/nc.svg);
}

.flag-ne {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ne.svg);
}

.flag-nf {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/nf.svg);
}

.flag-ng {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ng.svg);
}

.flag-ni {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ni.svg);
}

.flag-nl {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/nl.svg);
}

.flag-no {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/no.svg);
}

.flag-np {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/np.svg);
}

.flag-nr {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/nr.svg);
}

.flag-nu {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/nu.svg);
}

.flag-nz {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/nz.svg);
}

.flag-om {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/om.svg);
}

.flag-pa {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/pa.svg);
}

.flag-pe {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/pe.svg);
}

.flag-pf {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/pf.svg);
}

.flag-pg {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/pg.svg);
}

.flag-ph {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ph.svg);
}

.flag-pk {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/pk.svg);
}

.flag-pl {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/pl.svg);
}

.flag-pm {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/pm.svg);
}

.flag-pn {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/pn.svg);
}

.flag-pr {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/pr.svg);
}

.flag-ps {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ps.svg);
}

.flag-pt {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/pt.svg);
}

.flag-pw {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/pw.svg);
}

.flag-py {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/py.svg);
}

.flag-qa {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/qa.svg);
}

.flag-re {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/re.svg);
}

.flag-ro {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ro.svg);
}

.flag-rs {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/rs.svg);
}

.flag-ru {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ru.svg);
}

.flag-rw {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/rw.svg);
}

.flag-sa {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sa.svg);
}

.flag-sb {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sb.svg);
}

.flag-sc {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sc.svg);
}

.flag-sd {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sd.svg);
}

.flag-se {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/se.svg);
}

.flag-sg {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sg.svg);
}

.flag-sh {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sh.svg);
}

.flag-si {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/si.svg);
}

.flag-sj {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sj.svg);
}

.flag-sk {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sk.svg);
}

.flag-sl {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sl.svg);
}

.flag-sm {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sm.svg);
}

.flag-sn {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sn.svg);
}

.flag-so {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/so.svg);
}

.flag-sr {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sr.svg);
}

.flag-ss {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ss.svg);
}

.flag-st {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/st.svg);
}

.flag-sv {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sv.svg);
}

.flag-sx {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sx.svg);
}

.flag-sy {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sy.svg);
}

.flag-sz {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/sz.svg);
}

.flag-tc {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/tc.svg);
}

.flag-td {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/td.svg);
}

.flag-tf {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/tf.svg);
}

.flag-tg {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/tg.svg);
}

.flag-th {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/th.svg);
}

.flag-tj {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/tj.svg);
}

.flag-tk {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/tk.svg);
}

.flag-tl {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/tl.svg);
}

.flag-tm {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/tm.svg);
}

.flag-tn {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/tn.svg);
}

.flag-to {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/to.svg);
}

.flag-tr {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/tr.svg);
}

.flag-tt {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/tt.svg);
}

.flag-tv {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/tv.svg);
}

.flag-tw {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/tw.svg);
}

.flag-tz {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/tz.svg);
}

.flag-ua {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ua.svg);
}

.flag-ug {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ug.svg);
}

.flag-um {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/um.svg);
}

.flag-un {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/un.svg);
}

.flag-us {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/us.svg);
}

.flag-uy {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/uy.svg);
}

.flag-uz {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/uz.svg);
}

.flag-va {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/va.svg);
}

.flag-vc {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/vc.svg);
}

.flag-ve {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ve.svg);
}

.flag-vg {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/vg.svg);
}

.flag-vi {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/vi.svg);
}

.flag-vn {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/vn.svg);
}

.flag-vu {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/vu.svg);
}

.flag-wf {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/wf.svg);
}

.flag-ws {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ws.svg);
}

.flag-ye {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/ye.svg);
}

.flag-yt {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/yt.svg);
}

.flag-za {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/za.svg);
}

.flag-zm {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/zm.svg);
}

.flag-zw {
  background-image: url(https://laravel.spruko.com/azea/azea/images/flags/zw.svg);
}

.payment {
  background: no-repeat 50%/100% 100%;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  font-style: normal;
  height: 1.5rem;
  vertical-align: bottom;
  width: 1.5rem;
}

.payment-2checkout-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/2checkout-dark.svg);
}

.payment-2checkout {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/2checkout.svg);
}

.payment-alipay-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/alipay-dark.svg);
}

.payment-alipay {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/alipay.svg);
}

.payment-amazon-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/amazon-dark.svg);
}

.payment-amazon {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/amazon.svg);
}

.payment-americanexpress-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/americanexpress-dark.svg);
}

.payment-americanexpress {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/americanexpress.svg);
}

.payment-applepay-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/applepay-dark.svg);
}

.payment-applepay {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/applepay.svg);
}

.payment-bancontact-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/bancontact-dark.svg);
}

.payment-bancontact {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/bancontact.svg);
}

.payment-bitcoin-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/bitcoin-dark.svg);
}

.payment-bitcoin {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/bitcoin.svg);
}

.payment-bitpay-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/bitpay-dark.svg);
}

.payment-bitpay {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/bitpay.svg);
}

.payment-cirrus-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/cirrus-dark.svg);
}

.payment-cirrus {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/cirrus.svg);
}

.payment-clickandbuy-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/clickandbuy-dark.svg);
}

.payment-clickandbuy {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/clickandbuy.svg);
}

.payment-coinkite-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/coinkite-dark.svg);
}

.payment-coinkite {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/coinkite.svg);
}

.payment-dinersclub-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/dinersclub-dark.svg);
}

.payment-dinersclub {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/dinersclub.svg);
}

.payment-directdebit-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/directdebit-dark.svg);
}

.payment-directdebit {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/directdebit.svg);
}

.payment-discover-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/discover-dark.svg);
}

.payment-discover {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/discover.svg);
}

.payment-dwolla-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/dwolla-dark.svg);
}

.payment-dwolla {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/dwolla.svg);
}

.payment-ebay-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/ebay-dark.svg);
}

.payment-ebay {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/ebay.svg);
}

.payment-eway-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/eway-dark.svg);
}

.payment-eway {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/eway.svg);
}

.payment-giropay-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/giropay-dark.svg);
}

.payment-giropay {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/giropay.svg);
}

.payment-googlewallet-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/googlewallet-dark.svg);
}

.payment-googlewallet {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/googlewallet.svg);
}

.payment-ingenico-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/ingenico-dark.svg);
}

.payment-ingenico {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/ingenico.svg);
}

.payment-jcb-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/jcb-dark.svg);
}

.payment-jcb {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/jcb.svg);
}

.payment-klarna-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/klarna-dark.svg);
}

.payment-klarna {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/klarna.svg);
}

.payment-laser-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/laser-dark.svg);
}

.payment-laser {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/laser.svg);
}

.payment-maestro-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/maestro-dark.svg);
}

.payment-maestro {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/maestro.svg);
}

.payment-mastercard-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/mastercard-dark.svg);
}

.payment-mastercard {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/mastercard.svg);
}

.payment-monero-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/monero-dark.svg);
}

.payment-monero {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/monero.svg);
}

.payment-neteller-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/neteller-dark.svg);
}

.payment-neteller {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/neteller.svg);
}

.payment-ogone-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/ogone-dark.svg);
}

.payment-ogone {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/ogone.svg);
}

.payment-okpay-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/okpay-dark.svg);
}

.payment-okpay {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/okpay.svg);
}

.payment-paybox-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/paybox-dark.svg);
}

.payment-paybox {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/paybox.svg);
}

.payment-paymill-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/paymill-dark.svg);
}

.payment-paymill {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/paymill.svg);
}

.payment-payone-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/payone-dark.svg);
}

.payment-payone {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/payone.svg);
}

.payment-payoneer-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/payoneer-dark.svg);
}

.payment-payoneer {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/payoneer.svg);
}

.payment-paypal-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/paypal-dark.svg);
}

.payment-paypal {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/paypal.svg);
}

.payment-paysafecard-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/paysafecard-dark.svg);
}

.payment-paysafecard {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/paysafecard.svg);
}

.payment-payu-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/payu-dark.svg);
}

.payment-payu {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/payu.svg);
}

.payment-payza-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/payza-dark.svg);
}

.payment-payza {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/payza.svg);
}

.payment-ripple-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/ripple-dark.svg);
}

.payment-ripple {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/ripple.svg);
}

.payment-sage-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/sage-dark.svg);
}

.payment-sage {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/sage.svg);
}

.payment-sepa-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/sepa-dark.svg);
}

.payment-sepa {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/sepa.svg);
}

.payment-shopify-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/shopify-dark.svg);
}

.payment-shopify {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/shopify.svg);
}

.payment-skrill-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/skrill-dark.svg);
}

.payment-skrill {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/skrill.svg);
}

.payment-solo-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/solo-dark.svg);
}

.payment-solo {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/solo.svg);
}

.payment-square-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/square-dark.svg);
}

.payment-square {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/square.svg);
}

.payment-stripe-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/stripe-dark.svg);
}

.payment-stripe {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/stripe.svg);
}

.payment-switch-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/switch-dark.svg);
}

.payment-switch {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/switch.svg);
}

.payment-ukash-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/ukash-dark.svg);
}

.payment-ukash {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/ukash.svg);
}

.payment-unionpay-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/unionpay-dark.svg);
}

.payment-unionpay {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/unionpay.svg);
}

.payment-verifone-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/verifone-dark.svg);
}

.payment-verifone {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/verifone.svg);
}

.payment-verisign-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/verisign-dark.svg);
}

.payment-verisign {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/verisign.svg);
}

.payment-visa-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/visa-dark.svg);
}

.payment-visa {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/visa.svg);
}

.payment-webmoney-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/webmoney-dark.svg);
}

.payment-webmoney {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/webmoney.svg);
}

.payment-westernunion-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/westernunion-dark.svg);
}

.payment-westernunion {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/westernunion.svg);
}

.payment-worldpay-dark {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/worldpay-dark.svg);
}

.payment-worldpay {
  background-image: url(https://laravel.spruko.com/azea/azea/images/payments/worldpay.svg);
}

svg {
  touch-action: none;
}

.dimmer {
  position: relative;
}

.dimmer .loader {
  display: none;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dimmer.active .loader {
  display: block;
}

.dimmer.active .dimmer-content {
  opacity: 0.04;
  pointer-events: none;
}

.spinner3 {
  -webkit-animation: sk-rotate 2s linear infinite;
  animation: sk-rotate 2s linear infinite;
  height: 40px;
  margin: 100px auto;
  position: relative;
  text-align: center;
  width: 40px;
}

.dot1,
.dot2 {
  -webkit-animation: sk-bounce 2s ease-in-out infinite;
  animation: sk-bounce 2s ease-in-out infinite;
  border-radius: 100%;
  display: inline-block;
  height: 60%;
  position: absolute;
  top: 0;
  width: 60%;
}

.dot2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  bottom: 0;
  top: auto;
}

@-webkit-keyframes sk-rotate {
  to {
    -webkit-transform: rotate(1turn);
  }
}

@keyframes sk-rotate {
  to {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
  }
}

.spinner4 {
  margin: 100px auto;
  text-align: center;
  width: 70px;
}

.spinner4 > div {
  -webkit-animation: sk-bouncedelay 1.4s ease-in-out infinite both;
  animation: sk-bouncedelay 1.4s ease-in-out infinite both;
  border-radius: 100%;
  display: inline-block;
  height: 18px;
  width: 18px;
}

.spinner4 .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner4 .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  to {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  to {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.lds-ring {
  height: 64px;
  margin: 100px auto;
  position: relative;
  width: 64px;
}

.lds-ring div {
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 51px;
  margin: 6px;
  position: absolute;
  width: 51px;
}

.lds-ring div:first-child {
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.lds-hourglass {
  height: 40px;
  margin: 100px auto;
  position: relative;
  width: 40px;
}

.lds-hourglass:after {
  -webkit-animation: lds-hourglass 1.2s infinite;
  animation: lds-hourglass 1.2s infinite;
  border-radius: 50%;
  box-sizing: border-box;
  content: " ";
  display: block;
  height: 0;
  margin: 6px;
  width: 0;
}

@-webkit-keyframes lds-hourglass {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transform: rotate(0);
  }

  50% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: rotate(900deg);
  }

  to {
    transform: rotate(5turn);
  }
}

@keyframes lds-hourglass {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transform: rotate(0);
  }

  50% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: rotate(900deg);
  }

  to {
    transform: rotate(5turn);
  }
}

.loader7 {
  height: 40px;
  margin: 100px auto;
  position: relative;
  width: 100px;
}

.circles {
  -webkit-animation: loading 1.5s cubic-bezier(0.8, 0.5, 0.2, 1.4) infinite;
  animation: loading 1.5s cubic-bezier(0.8, 0.5, 0.2, 1.4) infinite;
  background-color: #664dc9;
  border-radius: 50%;
  display: inline-block;
  height: 15px;
  position: relative;
  transform-origin: bottom center;
  width: 15px;
}

@-webkit-keyframes loading {
  0% {
    background-color: #664dc9;
    transform: translateY(0);
  }

  50% {
    background-color: #4d30c5;
    transform: translateY(50px);
  }

  to {
    background-color: #3917c2;
    transform: translateY(0);
  }
}

@keyframes loading {
  0% {
    background-color: #664dc9;
    transform: translateY(0);
  }

  50% {
    background-color: #4d30c5;
    transform: translateY(50px);
  }

  to {
    background-color: #3917c2;
    transform: translateY(0);
  }
}

.circle-1 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.circle-2 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.circle-3 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.circle-4 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.circle-5 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.loader8 {
  height: 40px;
  margin: 100px auto;
  overflow: hidden;
}

.circle1,
.loader8 {
  align-items: center;
  display: flex;
  justify-content: center;
}

.circle1 {
  margin: 7px;
}

.circle1,
.circle1:before {
  border-radius: 50%;
  height: 15px;
  width: 15px;
}

.circle1:before {
  -webkit-animation: scale 2s cubic-bezier(0, 0, 0.49, 1.02) infinite;
  animation: scale 2s cubic-bezier(0, 0, 0.49, 1.02) infinite;
  content: "";
  opacity: 0.7;
}

.circle-1,
.circle-1:before {
  background-color: #49b8e5;
}

.circle-1:before {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.circle-2,
.circle-2:before {
  background-color: #1e98d4;
}

.circle-2:before {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.circle-3,
.circle-3:before {
  background-color: #2a92d0;
}

.circle-3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.circle-4,
.circle-4:before {
  background-color: #3a88c8;
}

.circle-4:before {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.circle-5,
.circle-5:before {
  background-color: #507cbe;
}

.circle-5:before {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes scale {
  0% {
    transform: scale(1);
  }

  50%,
  75% {
    transform: scale(2.5);
  }

  78%,
  to {
    opacity: 0;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50%,
  75% {
    transform: scale(2.5);
  }

  78%,
  to {
    opacity: 0;
  }
}

.table {
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
}

.table,
.text-wrap table {
  margin-bottom: 1rem;
  max-width: 100%;
  width: 100%;
}

.table td,
.table th,
.text-wrap table td,
.text-wrap table th {
  border-top: 1px solid #e6ebf1;
  padding: 0.75rem;
  vertical-align: top;
}

.table td:focus,
.table td:hover {
  box-shadow: none;
  outline: 0;
}

.border {
  border: 1px solid #e6ebf1;
  vertical-align: top;
}

.table thead th,
.text-wrap table thead th {
  border-bottom: 1px solid #e6ebf1;
  border-top: 0;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  vertical-align: bottom;
}

.table tbody + tbody,
.text-wrap table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table,
.table .text-wrap table,
.text-wrap .table table,
.text-wrap table .table,
.text-wrap table table {
  background-color: #f0f0f270;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table-bordered,
.table-bordered td,
.table-bordered th,
.text-wrap table,
.text-wrap table td,
.text-wrap table th {
  border: 1px solid #e6ebf1;
}

.table-bordered thead td,
.table-bordered thead th,
.text-wrap table thead td,
.text-wrap table thead th {
  border-bottom-width: 1px;
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f0f0f2;
  background: transparent;
}

.table-hover tbody tr:hover {
  background-color: hsla(240, 7%, 95%, 0.5);
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #38cb89;
}

.table-hover .table-success:hover,
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #c5e7a4;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #cbe7fb;
}

.table-hover .table-info:hover,
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b3dcf9;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #fbeebc;
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fae8a4;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #ef4b4b;
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ecacab;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover,
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > td,
.table-active > th,
.table-hover .table-active:hover,
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.04);
}

.table .thead-dark th,
.text-wrap table .thead-dark th {
  background-color: #212529;
  border-color: #32383e;
  color: #f0f0f2;
}

.table .thead-light th,
.text-wrap table .thead-light th {
  background-color: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
}

.table-dark {
  background-color: #212529;
  color: #f0f0f2;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered,
.text-wrap table.table-dark {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: hsla(0, 0%, 100%, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: hsla(0, 0%, 100%, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
    width: 100%;
  }

  .table-responsive-sm > .table-bordered,
  .text-wrap .table-responsive-sm > table {
    border: 0;
  }

  .header-brand-img.dark-logo {
    display: none;
  }

  .header-brand {
    margin-right: 0;
  }

  .header-brand-img.mobile-logo {
    display: block;
    margin-left: 0.5rem;
  }

  .header-brand-img.darkmobile-logo {
    display: none;
    margin-left: 0.5rem;
  }

  .animated-arrow.hor-toggle {
    border-radius: 3px;
    font-size: 1.2rem;
    height: 2.5rem;
    padding: 5px;
    position: relative;
    text-align: center;
    top: 0;
    width: 2.5rem;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
    width: 100%;
  }

  .table-responsive-md > .table-bordered,
  .text-wrap .table-responsive-md > table {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
    width: 100%;
  }

  .table-responsive-lg > .table-bordered,
  .text-wrap .table-responsive-lg > table {
    border: 0;
  }
}

@media (max-width: 1279.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
    width: 100%;
  }

  .table-responsive-xl > .table-bordered,
  .text-wrap .table-responsive-xl > table {
    border: 0;
  }
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  display: block;
  overflow-x: auto;
  width: 100%;
}

.table-responsive > .table-bordered,
.text-wrap .table-responsive > table {
  border: 0;
}

.table th,
.text-wrap table th {
  color: #384b6e;
  font-size: 0.81rem;
  font-weight: 400;
  text-transform: capitalize;
}

.table-md td,
.table-md th {
  padding: 0.5rem;
}

.table-vcenter td,
.table-vcenter th {
  border-bottom: none;
  border-top: 1px solid #e6ebf1;
  vertical-align: middle;
}

.table-center td,
.table-center th {
  text-align: center;
}

.table-striped tbody tr:nth-of-type(2n) {
  background-color: hsla(240, 7%, 95%, 0.5);
}

.table-calender {
  margin: 0 0 0.75rem;
}

.table-calender td,
.table-calender th {
  border: 0;
  line-height: 2.5rem;
  padding: 0 !important;
  text-align: center;
  width: 14.28571429%;
}

.table-calender td {
  border-top: 0;
}

.table-calender-link {
  background: #f8f9fa;
  border-radius: 3px;
  color: #495057;
  display: inline-block;
  font-weight: 600;
  line-height: 2rem;
  min-width: calc(2rem + 2px);
  position: relative;
  transition: background 0.3s, color 0.3s;
}

.table-calender-link:before {
  background: #467fcf;
  border-radius: 50px;
  content: "";
  height: 4px;
  left: 0.25rem;
  position: absolute;
  top: 0.25rem;
  width: 4px;
}

.table-calender-link:hover {
  background: #467fcf;
  color: #fff;
  text-decoration: none;
  transition: background 0.3s;
}

.table-calender-link:hover:before {
  background: #fff;
}

.table-header {
  cursor: pointer;
  transition: color 0.3s;
}

.table-header:hover {
  color: #495057 !important;
}

.table-header:after {
  content: "\f0dc";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 0.75rem;
  margin-left: 0.5rem;
}

.table-header-asc {
  color: #495057 !important;
}

.table-header-asc:after {
  content: "\f0de";
}

.table-header-desc {
  color: #495057 !important;
}

.table-header-desc:after {
  content: "\f0dd";
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #e6ebf1;
}

.table > :not(caption) > * > * {
  background-color: 1px;
  box-shadow: none;
  padding: 0.75rem;
}

.table-bordered > :not(caption) > * {
  border-width: 0;
}

table {
  border-collapse: collapse !important;
}

.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  font-weight: 500;
  -webkit-transition: all 0.3s ease;
}

.table,
.table-responsive {
  overflow-y: visible !important;
}

.widgets {
  font-size: 35px;
  padding: 20px;
}

.widgetstext {
  bottom: 0;
  padding: 20px 40px;
  top: 0;
}

.widget-line h4 {
  font-size: 24px;
  font-weight: 600;
}

.fs {
  font-size: 25px;
}

.widget-line p {
  font-size: 16px;
}

.widget-line-list li {
  display: inline-block;
  font-size: 16px;
  line-height: 27px;
  list-style-type: none;
  padding: 5px 20px 0 15px;
}

.media {
  align-items: flex-left;
  display: flex;
}

.mediaicon {
  border-radius: 20px;
  color: #fff;
  font-size: 18px;
  height: 40px;
  padding: 5px 0;
  text-align: center;
  width: 40px;
}

.media-body {
  flex: 1;
}

.mediafont {
  font-size: 16px;
  font-weight: 600;
}

.defaultheader .form-control.header-search::-moz-placeholder {
  color: #fff !important;
}

.defaultheader .form-control.header-search:-ms-input-placeholder {
  color: #fff !important;
}

.defaultheader .form-control.header-search::placeholder,
.defaultheader .input-icon-addon {
  color: #fff !important;
}

.input-icon {
  position: relative;
}

.input-icon .form-control:not(:first-child),
.input-icon .form-control:not(:last-child) {
  padding-left: 2.5rem;
}

.input-icon-addon {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  min-width: 2.5rem;
  pointer-events: none;
  position: absolute;
  top: 0;
}

.input-icon-addon:last-child {
  left: auto;
}

.form-fieldset {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 1rem;
}

.form-required {
  color: #dc0441;
}

.form-required:before {
  content: " ";
}

.state-valid {
  background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%235eba00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>")
    no-repeat center right 0.5rem/1rem;
  padding-right: 2rem;
}

.state-invalid {
  background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23cd201f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>")
    no-repeat center right 0.5rem/1rem;
  padding-right: 2rem;
}

.form-help {
  background: #f6f6fa;
  border-radius: 50%;
  color: #664dc9;
  cursor: pointer;
  display: inline-block;
  font-size: 0.75rem;
  height: 1rem;
  line-height: 1rem;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1rem;
}

.form-help:hover,
.form-help[aria-describedby] {
  background: #664dc9;
  color: #fff;
}

.sparkline {
  display: inline-block;
  height: 2rem;
}

.social-links li a {
  background: #f8f8f8;
  border-radius: 50%;
  color: #9aa0ac;
  display: inline-block;
  height: 1.75rem;
  line-height: 1.75rem;
  text-align: center;
  width: 1.75rem;
}

.vmap-width {
  height: 300px;
  width: 100%;
}

.chart,
.map {
  padding-top: 56.25%;
  position: relative;
}

.chart-square,
.map-square {
  padding-top: 100%;
}

.chart-content,
.map-content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.map-header {
  height: 15rem;
  margin-bottom: -1.5rem;
  position: relative;
}

.map-header:before {
  background: linear-gradient(180deg, rgba(245, 247, 251, 0) 5%, #f0f0f2 95%);
  bottom: 0;
  content: "";
  height: 10rem;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.map-header-layer {
  height: 100%;
}

.map-static {
  background-position: 50%;
  background-size: 640px 120px;
  height: 120px;
  max-width: 640px;
  width: 100%;
}

@-webkit-keyframes status-pulse {
  0%,
  to {
    opacity: 1;
  }

  50% {
    opacity: 0.32;
  }
}

@keyframes status-pulse {
  0%,
  to {
    opacity: 1;
  }

  50% {
    opacity: 0.32;
  }
}

.status-icon {
  background: currentColor;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 0.5rem;
  margin-right: 0.375rem;
  transform: translateY(-1px);
  vertical-align: middle;
  width: 0.5rem;
}

.status-animated {
  -webkit-animation: status-pulse 1s ease infinite;
  animation: status-pulse 1s ease infinite;
}

.chart-circle {
  display: block;
  height: 8rem;
  margin: 0 auto;
  position: relative;
  width: 8rem;
}

.chart-circle canvas {
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
}

.chart-circle-xs {
  font-size: 0.8rem;
  height: 2.5rem;
  width: 2.5rem;
}

.chart-circle-sm {
  font-size: 0.8rem;
  height: 4rem;
  width: 4rem;
}

.chart-circle-md {
  font-size: 0.8rem;
  height: 6rem;
  width: 6rem;
}

.chart-circle-lg {
  font-size: 0.8rem;
  height: 10rem;
  width: 10rem;
}

.chart-circle-value {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  line-height: 1;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.chart-circle-value small {
  color: #9aa0ac;
  display: block;
  font-size: 0.9375rem;
}

.chips {
  margin: 0 0 -0.5rem;
}

.team i {
  float: right;
  margin-left: 10px;
}

.chips .chip {
  margin: 0 0.5rem 0.5rem 0;
}

.chip {
  background-color: #e8ebf9;
  border-radius: 1rem;
  color: #6e7687;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.75rem;
  transition: background 0.3s;
}

.chip .avatar {
  border-radius: 50%;
  float: left;
  height: 2rem;
  margin: 0 0.5rem 0 -0.75rem;
  width: 2rem;
}

a.chip:hover {
  color: #fff;
  text-decoration: none;
}

.emp-tab table {
  border: 0;
  margin-bottom: 0;
}

.stamp {
  background: #868e96;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  height: 2rem;
  line-height: 2rem;
  min-width: 2rem;
  padding: 0 0.25rem;
  text-align: center;
}

.stamp-md {
  height: 2.5rem;
  line-height: 2.5rem;
  min-width: 2.5rem;
}

.stamp-lg {
  height: 3rem;
  line-height: 3rem;
  min-width: 3rem;
}

.chat {
  flex-direction: column;
  justify-content: flex-right;
  list-style-type: none;
  margin: 0;
  min-height: 100%;
  outline: 0;
}

.chat,
.chat-line {
  display: flex;
  padding: 0;
}

.chat-line {
  flex-direction: row-reverse;
  position: relative;
  text-align: right;
}

.chat-line + .chat-line {
  padding-top: 1rem;
}

.chat-message {
  background-color: #467fcf;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.4;
  margin: 0 0.5rem 0 2.5rem;
  padding: 0.375rem 0.5rem;
  position: relative;
  text-align: left;
  white-space: normal;
}

.chat-message > :last-child {
  margin-bottom: 0 !important;
}

.chat-message:after {
  border-bottom: 6px solid transparent;
  border-left: 6px solid #467fcf;
  border-top: 6px solid transparent;
  content: "";
  position: absolute;
  right: -5px;
  top: 7px;
}

.chat-message img {
  max-width: 100%;
}

.chat-message p {
  margin-bottom: 1em;
}

.chat-line-friright {
  flex-direction: row;
}

.chat-line-friright + .chat-line-friright {
  margin-top: -0.5rem;
}

.chat-line-friright + .chat-line-friright .chat-author {
  visibility: hidden;
}

.chat-line-friright + .chat-line-friright .chat-message:after {
  display: none;
}

.chat-line-friright .chat-message {
  background-color: #f3f3f3;
  color: #495057;
  margin-left: 0.5rem;
  margin-right: 2.5rem;
}

.chat-line-friright .chat-message:after {
  border-start-width: 0;
  border-right: 5px solid #f3f3f3;
  left: -5px;
  right: auto;
}

.example {
  border-radius: 3px 3px 0 0;
  font-size: 0.9375rem;
  padding: 1.5rem;
}

.example-bg {
  background: #f0f0f2;
}

.example + .highlight {
  border-radius: 0 0 3px 3px;
  border-top: none;
  margin-top: 0;
}

.highlight {
  background: #fcfcfc;
  border: 1px solid #e6ebf1;
  border-radius: 3px;
  font-size: 0.9375rem;
  /* margin: 0 0 2rem; */
  max-height: 40rem;
  overflow: auto;
}

.highlight pre {
  background-color: #e3e2e5;
  margin-bottom: 0;
}

.example-column {
  margin: 0 auto;
}

.example-column > .card:last-of-type {
  margin-bottom: 0;
}

.example-column-1 {
  max-width: 20rem;
}

.example-column-2 {
  max-width: 40rem;
}

.timeline__item:after {
  background: #fff !important;
}

.timeline--horizontal .timeline-divider {
  background: #f0f0f2 !important;
}

.timeline__content {
  background-color: #fff;
  border-radius: 10px;
  display: block;
  padding: 1.25rem;
  position: relative;
}

#back-to-top {
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 4px !important;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  -o-border-radius: 2px;
  bottom: 20px;
  color: #fff;
  display: none;
  height: 50px;
  line-height: 4;
  position: fixed;
  right: 20px;
  text-align: center;
  transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  width: 50px;
  z-index: 999;
}

#back-to-top i {
  font-size: 16px;
  padding-top: 15px;
}

.tabs-menu ul li a {
  border: 0 solid #e6ebf1;
  border-radius: 4px;
  display: block;
  font-weight: 500;
  margin: 3px;
  padding: 10px 20px 11px;
}

.tabs-menu ul li .active {
  color: #664dc9 !important;
}

.tabs-menu1 ul li a {
  display: block;
  font-weight: 500;
  padding: 10px 20px 11px;
}

.tabs-menu-body {
  border: 1px solid #e6ebf1;
  border-radius: 0 0 5px 5px;
  padding: 15px;
}

.tabs-menu-body p:last-child {
  margin-bottom: 0;
}

.tab-menu-heading {
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  padding: 20px 0;
}

.tab_wrapper .content_wrapper .tab_content.active p:last-child {
  margin-bottom: 0;
}

.item-card .cardbody {
  border-radius: 0 0 0.2rem 0.2rem;
  padding: 30px 15px;
  position: relative;
}

.item-card .cardtitle span {
  display: block;
  font-size: 0.75rem;
}

.item-card .cardtitle a {
  font-weight: 400;
  text-decoration: none;
}

.item-card .cardprice {
  position: absolute;
  right: 0;
}

.item-card .cardprice span {
  display: block;
  font-size: 18px;
  font-weight: 500;
}

.item-card .cardprice span.type--strikethrough {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
  text-decoration: line-through;
}

.features {
  overflow: hidden;
}

.features h2 {
  font-size: 2.2em;
  font-weight: 600;
  margin-bottom: 12px;
  text-align: center;
}

.features h3 {
  font-size: 20px;
  font-weight: 600;
}

.features span {
  color: #43414e;
  display: block;
  font-weight: 400;
  text-align: center;
}

.feature .feature-svg {
  width: 25%;
}

.feature .feature-svg3 {
  width: 12%;
}

.col-sm-2 .feature {
  border: 0;
  box-shadow: none;
  padding: 0;
}

.feature .border {
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  height: 3em;
  line-height: 2em;
  padding-top: 8px;
  position: relative;
  vertical-align: middle;
  width: 3em;
}

.feature p {
  margin-bottom: 0;
}

.sub-panel-heading .tabs-menu ul li {
  display: block;
  width: 100%;
}

.sub-panel-heading .tabs-menu ul li a.active {
  background: #fff;
  border-radius: 0;
}

.sub-panel-heading .tabs-menu ul li:last-child {
  border-bottom: 0;
}

.sub-panel-heading .tabs-menu ul li a {
  padding: 15px 20px;
}

.invoicedetailspage,
.invoicelist {
  height: 600px;
  max-height: 600px;
  overflow: auto;
}

.receipts-inline-table .tabs-menu1 ul li .active {
  background: #f8f8f9;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
}

.card-custom-icon {
  color: #e2e2e9;
  height: 40px;
  position: absolute;
  right: 25px;
  top: 15px;
  width: 40px;
}

.custom-control {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-control-inline {
  margin-right: 1rem;
}

.custom-control-input {
  opacity: 0;
  z-index: 100;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(70, 127, 207, 0.25);
}

.custom-control-input:active ~ .custom-control-label:before {
  background-color: #d4e1f4;
  color: #fff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #868e96;
}

.custom-control-input:disabled ~ .custom-control-label:before {
  background-color: #e9e9eb;
}

.custom-control-label {
  display: block;
  line-height: 25px;
  margin-top: 2px;
  vertical-align: middle;
}

.custom-control-label:before {
  background-color: #dee2e6;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-control-label:after,
.custom-control-label:before {
  content: "";
  display: block;
  height: 1rem;
  left: 0;
  position: absolute;
  top: 0.25rem;
  width: 1rem;
}

.custom-control-label:after {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
}

.custom-control-label-md {
  padding-left: 15px;
  padding-top: 6px;
}

.custom-control-label-md:after,
.custom-control-label-md:before {
  height: 1.5rem !important;
  top: 0.25rem !important;
  width: 1.5rem !important;
}

.custom-control-md {
  min-height: 2rem !important;
}

.custom-control-lg {
  min-height: 2.5rem;
}

.custom-control-label-lg {
  padding-left: 15px;
  padding-top: 10px;
}

.custom-control-label-lg:after,
.custom-control-label-lg:before {
  height: 2rem !important;
  top: 0.25rem !important;
  width: 2rem !important;
}

.custom-checkbox .custom-control-label:before {
  border-radius: 3px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  box-shadow: none;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label:before {
  background-color: #4454c3;
  opacity: 0.5;
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label:before {
  background-color: rgba(70, 127, 207, 0.5);
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  box-shadow: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label:before {
  background-color: #4454c3;
  opacity: 0.5;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='#999' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #e6ebf1;
  border-radius: 3px;
  color: #6b6f80;
  display: inline-block;
  height: 2.375rem;
  line-height: 1.5;
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;
  vertical-align: middle;
  width: 100%;
}

.custom-select:focus {
  box-shadow: none;
  outline: 0;
}

.custom-select:focus::-ms-value {
  background-color: #fff;
  color: #495057;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  background-image: none;
  height: auto;
  padding-right: 0.75rem;
}

.custom-select:disabled {
  background-color: #e9ecef;
  color: #868e96;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  font-size: 75%;
  height: calc(1.8125rem + 2px);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

div.dataTables_wrapper div.dataTables_filter input {
  display: inline-block;
  height: calc(1.8125rem + 2px);
  margin-left: 0.5em;
  width: auto;
}

.custom-select-lg {
  font-size: 125%;
  height: calc(2.6875rem + 2px);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.custom-file {
  display: inline-block;
  margin-bottom: 0;
}

.custom-file,
.custom-file-input {
  height: 2.375rem;
  position: relative;
  width: 100%;
}

.custom-file-input {
  margin: 0;
  opacity: 0;
  z-index: 2;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #1991eb;
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
}

.custom-file-input:focus ~ .custom-file-label:after {
  border-color: #1991eb;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-label {
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 3px;
  color: #495057;
  height: 2.375rem;
  left: 0;
  z-index: 1;
}

.custom-file-label,
.custom-file-label:after {
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  position: absolute;
  right: 0;
  top: 0;
}

.custom-file-label:after {
  border-radius: 0 3px 3px 0;
  bottom: 0;
  color: #fff;
  content: "Browse";
  display: block;
  font-weight: 500;
  height: calc(2.375rem - 2px);
  z-index: 3;
}

.custom-range::-webkit-slider-thumb:focus {
  box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(70, 127, 207, 0.25);
  outline: none;
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #d4e1f4;
}

.custom-range::-webkit-slider-runnable-track {
  background-color: #dee2e6;
  background: #467fcf;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  content: "";
  cursor: pointer;
  height: 0.5rem;
  height: 2px;
  pointer-events: none;
  width: 100%;
}

.custom-range::-moz-range-thumb:focus {
  box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(70, 127, 207, 0.25);
  outline: none;
}

.custom-range::-moz-range-thumb:active {
  background-color: #d4e1f4;
}

.custom-range::-moz-range-track {
  background-color: #dee2e6;
  background: rgba(0, 50, 126, 0.12);
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  height: 2px;
  width: 100%;
  width: 240px;
}

.custom-range::-ms-thumb {
  appearance: none;
  background: #fff;
  border: 0;
  border: 1px solid rgba(0, 30, 75, 0.12);
  border-radius: 1rem;
  border-radius: 50px;
  height: 1rem;
  height: 2px;
  -ms-transition: border-color 0.3s, background-color 0.3s;
  transition: border-color 0.3s, background-color 0.3s;
  width: 1rem;
  width: 240px;
}

.custom-range::-ms-thumb:focus {
  box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(70, 127, 207, 0.25);
  outline: none;
}

.custom-range::-ms-thumb:active {
  background-color: #d4e1f4;
}

.form-group,
.form-label {
  display: block;
}

.form-label {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.375rem;
}

.form-label-small {
  float: right;
  font-size: 77.5%;
  font-weight: 400;
}

.form-footer {
  margin-top: 2rem;
}

.custom-controls-stacked .custom-control {
  margin-bottom: 0.25rem;
}

.custom-control-label:before {
  background-color: #fff;
  background-size: 0.5rem;
  border: 1px solid #cccdd3;
}

.custom-control-description {
  line-height: 1.5rem;
}

.input-group-append,
.input-group-btn,
.input-group-prepend {
  font-size: 0.9375rem;
}

.input-group-append > .btn,
.input-group-btn > .btn,
.input-group-prepend > .btn {
  border-color: rgba(0, 40, 100, 0.12);
  height: 100%;
}

.input-group-prepend > .input-group-text {
  border-right: 0;
}

.input-group-append > .input-group-text {
  border-left: 0;
}

.custom-range {
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  height: 100%;
  min-height: 2.375rem;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.custom-range:focus {
  box-shadow: none;
  outline: none;
}

.custom-range:focus::-moz-range-thumb,
.custom-range:focus::-ms-thumb,
.custom-range:focus::-webkit-slider-thumb {
  background-color: #467fcf;
  border-color: #467fcf;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: 0;
  border-radius: 50px;
  border-radius: 1rem;
  box-shadow: 1px 0 0 -6px rgba(0, 50, 126, 0.12),
    6px 0 0 -6px rgba(0, 50, 126, 0.12), 7px 0 0 -6px rgba(0, 50, 126, 0.12),
    8px 0 0 -6px rgba(0, 50, 126, 0.12), 9px 0 0 -6px rgba(0, 50, 126, 0.12),
    10px 0 0 -6px rgba(0, 50, 126, 0.12), 11px 0 0 -6px rgba(0, 50, 126, 0.12),
    12px 0 0 -6px rgba(0, 50, 126, 0.12), 13px 0 0 -6px rgba(0, 50, 126, 0.12),
    14px 0 0 -6px rgba(0, 50, 126, 0.12), 15px 0 0 -6px rgba(0, 50, 126, 0.12),
    16px 0 0 -6px rgba(0, 50, 126, 0.12), 17px 0 0 -6px rgba(0, 50, 126, 0.12),
    18px 0 0 -6px rgba(0, 50, 126, 0.12), 19px 0 0 -6px rgba(0, 50, 126, 0.12),
    20px 0 0 -6px rgba(0, 50, 126, 0.12), 21px 0 0 -6px rgba(0, 50, 126, 0.12),
    22px 0 0 -6px rgba(0, 50, 126, 0.12), 23px 0 0 -6px rgba(0, 50, 126, 0.12),
    24px 0 0 -6px rgba(0, 50, 126, 0.12), 25px 0 0 -6px rgba(0, 50, 126, 0.12),
    26px 0 0 -6px rgba(0, 50, 126, 0.12), 27px 0 0 -6px rgba(0, 50, 126, 0.12),
    28px 0 0 -6px rgba(0, 50, 126, 0.12), 29px 0 0 -6px rgba(0, 50, 126, 0.12),
    30px 0 0 -6px rgba(0, 50, 126, 0.12), 31px 0 0 -6px rgba(0, 50, 126, 0.12),
    32px 0 0 -6px rgba(0, 50, 126, 0.12), 33px 0 0 -6px rgba(0, 50, 126, 0.12),
    34px 0 0 -6px rgba(0, 50, 126, 0.12), 35px 0 0 -6px rgba(0, 50, 126, 0.12),
    36px 0 0 -6px rgba(0, 50, 126, 0.12), 37px 0 0 -6px rgba(0, 50, 126, 0.12),
    38px 0 0 -6px rgba(0, 50, 126, 0.12), 39px 0 0 -6px rgba(0, 50, 126, 0.12),
    40px 0 0 -6px rgba(0, 50, 126, 0.12), 41px 0 0 -6px rgba(0, 50, 126, 0.12),
    42px 0 0 -6px rgba(0, 50, 126, 0.12), 43px 0 0 -6px rgba(0, 50, 126, 0.12),
    44px 0 0 -6px rgba(0, 50, 126, 0.12), 45px 0 0 -6px rgba(0, 50, 126, 0.12),
    46px 0 0 -6px rgba(0, 50, 126, 0.12), 47px 0 0 -6px rgba(0, 50, 126, 0.12),
    48px 0 0 -6px rgba(0, 50, 126, 0.12), 49px 0 0 -6px rgba(0, 50, 126, 0.12),
    50px 0 0 -6px rgba(0, 50, 126, 0.12), 51px 0 0 -6px rgba(0, 50, 126, 0.12),
    52px 0 0 -6px rgba(0, 50, 126, 0.12), 53px 0 0 -6px rgba(0, 50, 126, 0.12),
    54px 0 0 -6px rgba(0, 50, 126, 0.12), 55px 0 0 -6px rgba(0, 50, 126, 0.12),
    56px 0 0 -6px rgba(0, 50, 126, 0.12), 57px 0 0 -6px rgba(0, 50, 126, 0.12),
    58px 0 0 -6px rgba(0, 50, 126, 0.12), 59px 0 0 -6px rgba(0, 50, 126, 0.12),
    60px 0 0 -6px rgba(0, 50, 126, 0.12), 61px 0 0 -6px rgba(0, 50, 126, 0.12),
    62px 0 0 -6px rgba(0, 50, 126, 0.12), 63px 0 0 -6px rgba(0, 50, 126, 0.12),
    64px 0 0 -6px rgba(0, 50, 126, 0.12), 65px 0 0 -6px rgba(0, 50, 126, 0.12),
    66px 0 0 -6px rgba(0, 50, 126, 0.12), 67px 0 0 -6px rgba(0, 50, 126, 0.12),
    68px 0 0 -6px rgba(0, 50, 126, 0.12), 69px 0 0 -6px rgba(0, 50, 126, 0.12),
    70px 0 0 -6px rgba(0, 50, 126, 0.12), 71px 0 0 -6px rgba(0, 50, 126, 0.12),
    72px 0 0 -6px rgba(0, 50, 126, 0.12), 73px 0 0 -6px rgba(0, 50, 126, 0.12),
    74px 0 0 -6px rgba(0, 50, 126, 0.12), 75px 0 0 -6px rgba(0, 50, 126, 0.12),
    76px 0 0 -6px rgba(0, 50, 126, 0.12), 77px 0 0 -6px rgba(0, 50, 126, 0.12),
    78px 0 0 -6px rgba(0, 50, 126, 0.12), 79px 0 0 -6px rgba(0, 50, 126, 0.12),
    80px 0 0 -6px rgba(0, 50, 126, 0.12), 81px 0 0 -6px rgba(0, 50, 126, 0.12),
    82px 0 0 -6px rgba(0, 50, 126, 0.12), 83px 0 0 -6px rgba(0, 50, 126, 0.12),
    84px 0 0 -6px rgba(0, 50, 126, 0.12), 85px 0 0 -6px rgba(0, 50, 126, 0.12),
    86px 0 0 -6px rgba(0, 50, 126, 0.12), 87px 0 0 -6px rgba(0, 50, 126, 0.12),
    88px 0 0 -6px rgba(0, 50, 126, 0.12), 89px 0 0 -6px rgba(0, 50, 126, 0.12),
    90px 0 0 -6px rgba(0, 50, 126, 0.12), 91px 0 0 -6px rgba(0, 50, 126, 0.12),
    92px 0 0 -6px rgba(0, 50, 126, 0.12), 93px 0 0 -6px rgba(0, 50, 126, 0.12),
    94px 0 0 -6px rgba(0, 50, 126, 0.12), 95px 0 0 -6px rgba(0, 50, 126, 0.12),
    96px 0 0 -6px rgba(0, 50, 126, 0.12), 97px 0 0 -6px rgba(0, 50, 126, 0.12),
    98px 0 0 -6px rgba(0, 50, 126, 0.12), 99px 0 0 -6px rgba(0, 50, 126, 0.12),
    100px 0 0 -6px rgba(0, 50, 126, 0.12), 101px 0 0 -6px rgba(0, 50, 126, 0.12),
    102px 0 0 -6px rgba(0, 50, 126, 0.12), 103px 0 0 -6px rgba(0, 50, 126, 0.12),
    104px 0 0 -6px rgba(0, 50, 126, 0.12), 105px 0 0 -6px rgba(0, 50, 126, 0.12),
    106px 0 0 -6px rgba(0, 50, 126, 0.12), 107px 0 0 -6px rgba(0, 50, 126, 0.12),
    108px 0 0 -6px rgba(0, 50, 126, 0.12), 109px 0 0 -6px rgba(0, 50, 126, 0.12),
    110px 0 0 -6px rgba(0, 50, 126, 0.12), 111px 0 0 -6px rgba(0, 50, 126, 0.12),
    112px 0 0 -6px rgba(0, 50, 126, 0.12), 113px 0 0 -6px rgba(0, 50, 126, 0.12),
    114px 0 0 -6px rgba(0, 50, 126, 0.12), 115px 0 0 -6px rgba(0, 50, 126, 0.12),
    116px 0 0 -6px rgba(0, 50, 126, 0.12), 117px 0 0 -6px rgba(0, 50, 126, 0.12),
    118px 0 0 -6px rgba(0, 50, 126, 0.12), 119px 0 0 -6px rgba(0, 50, 126, 0.12),
    120px 0 0 -6px rgba(0, 50, 126, 0.12), 121px 0 0 -6px rgba(0, 50, 126, 0.12),
    122px 0 0 -6px rgba(0, 50, 126, 0.12), 123px 0 0 -6px rgba(0, 50, 126, 0.12),
    124px 0 0 -6px rgba(0, 50, 126, 0.12), 125px 0 0 -6px rgba(0, 50, 126, 0.12),
    126px 0 0 -6px rgba(0, 50, 126, 0.12), 127px 0 0 -6px rgba(0, 50, 126, 0.12),
    128px 0 0 -6px rgba(0, 50, 126, 0.12), 129px 0 0 -6px rgba(0, 50, 126, 0.12),
    130px 0 0 -6px rgba(0, 50, 126, 0.12), 131px 0 0 -6px rgba(0, 50, 126, 0.12),
    132px 0 0 -6px rgba(0, 50, 126, 0.12), 133px 0 0 -6px rgba(0, 50, 126, 0.12),
    134px 0 0 -6px rgba(0, 50, 126, 0.12), 135px 0 0 -6px rgba(0, 50, 126, 0.12),
    136px 0 0 -6px rgba(0, 50, 126, 0.12), 137px 0 0 -6px rgba(0, 50, 126, 0.12),
    138px 0 0 -6px rgba(0, 50, 126, 0.12), 139px 0 0 -6px rgba(0, 50, 126, 0.12),
    140px 0 0 -6px rgba(0, 50, 126, 0.12), 141px 0 0 -6px rgba(0, 50, 126, 0.12),
    142px 0 0 -6px rgba(0, 50, 126, 0.12), 143px 0 0 -6px rgba(0, 50, 126, 0.12),
    144px 0 0 -6px rgba(0, 50, 126, 0.12), 145px 0 0 -6px rgba(0, 50, 126, 0.12),
    146px 0 0 -6px rgba(0, 50, 126, 0.12), 147px 0 0 -6px rgba(0, 50, 126, 0.12),
    148px 0 0 -6px rgba(0, 50, 126, 0.12), 149px 0 0 -6px rgba(0, 50, 126, 0.12),
    150px 0 0 -6px rgba(0, 50, 126, 0.12), 151px 0 0 -6px rgba(0, 50, 126, 0.12),
    152px 0 0 -6px rgba(0, 50, 126, 0.12), 153px 0 0 -6px rgba(0, 50, 126, 0.12),
    154px 0 0 -6px rgba(0, 50, 126, 0.12), 155px 0 0 -6px rgba(0, 50, 126, 0.12),
    156px 0 0 -6px rgba(0, 50, 126, 0.12), 157px 0 0 -6px rgba(0, 50, 126, 0.12),
    158px 0 0 -6px rgba(0, 50, 126, 0.12), 159px 0 0 -6px rgba(0, 50, 126, 0.12),
    160px 0 0 -6px rgba(0, 50, 126, 0.12), 161px 0 0 -6px rgba(0, 50, 126, 0.12),
    162px 0 0 -6px rgba(0, 50, 126, 0.12), 163px 0 0 -6px rgba(0, 50, 126, 0.12),
    164px 0 0 -6px rgba(0, 50, 126, 0.12), 165px 0 0 -6px rgba(0, 50, 126, 0.12),
    166px 0 0 -6px rgba(0, 50, 126, 0.12), 167px 0 0 -6px rgba(0, 50, 126, 0.12),
    168px 0 0 -6px rgba(0, 50, 126, 0.12), 169px 0 0 -6px rgba(0, 50, 126, 0.12),
    170px 0 0 -6px rgba(0, 50, 126, 0.12), 171px 0 0 -6px rgba(0, 50, 126, 0.12),
    172px 0 0 -6px rgba(0, 50, 126, 0.12), 173px 0 0 -6px rgba(0, 50, 126, 0.12),
    174px 0 0 -6px rgba(0, 50, 126, 0.12), 175px 0 0 -6px rgba(0, 50, 126, 0.12),
    176px 0 0 -6px rgba(0, 50, 126, 0.12), 177px 0 0 -6px rgba(0, 50, 126, 0.12),
    178px 0 0 -6px rgba(0, 50, 126, 0.12), 179px 0 0 -6px rgba(0, 50, 126, 0.12),
    180px 0 0 -6px rgba(0, 50, 126, 0.12), 181px 0 0 -6px rgba(0, 50, 126, 0.12),
    182px 0 0 -6px rgba(0, 50, 126, 0.12), 183px 0 0 -6px rgba(0, 50, 126, 0.12),
    184px 0 0 -6px rgba(0, 50, 126, 0.12), 185px 0 0 -6px rgba(0, 50, 126, 0.12),
    186px 0 0 -6px rgba(0, 50, 126, 0.12), 187px 0 0 -6px rgba(0, 50, 126, 0.12),
    188px 0 0 -6px rgba(0, 50, 126, 0.12), 189px 0 0 -6px rgba(0, 50, 126, 0.12),
    190px 0 0 -6px rgba(0, 50, 126, 0.12), 191px 0 0 -6px rgba(0, 50, 126, 0.12),
    192px 0 0 -6px rgba(0, 50, 126, 0.12), 193px 0 0 -6px rgba(0, 50, 126, 0.12),
    194px 0 0 -6px rgba(0, 50, 126, 0.12), 195px 0 0 -6px rgba(0, 50, 126, 0.12),
    196px 0 0 -6px rgba(0, 50, 126, 0.12), 197px 0 0 -6px rgba(0, 50, 126, 0.12),
    198px 0 0 -6px rgba(0, 50, 126, 0.12), 199px 0 0 -6px rgba(0, 50, 126, 0.12),
    200px 0 0 -6px rgba(0, 50, 126, 0.12), 201px 0 0 -6px rgba(0, 50, 126, 0.12),
    202px 0 0 -6px rgba(0, 50, 126, 0.12), 203px 0 0 -6px rgba(0, 50, 126, 0.12),
    204px 0 0 -6px rgba(0, 50, 126, 0.12), 205px 0 0 -6px rgba(0, 50, 126, 0.12),
    206px 0 0 -6px rgba(0, 50, 126, 0.12), 207px 0 0 -6px rgba(0, 50, 126, 0.12),
    208px 0 0 -6px rgba(0, 50, 126, 0.12), 209px 0 0 -6px rgba(0, 50, 126, 0.12),
    210px 0 0 -6px rgba(0, 50, 126, 0.12), 211px 0 0 -6px rgba(0, 50, 126, 0.12),
    212px 0 0 -6px rgba(0, 50, 126, 0.12), 213px 0 0 -6px rgba(0, 50, 126, 0.12),
    214px 0 0 -6px rgba(0, 50, 126, 0.12), 215px 0 0 -6px rgba(0, 50, 126, 0.12),
    216px 0 0 -6px rgba(0, 50, 126, 0.12), 217px 0 0 -6px rgba(0, 50, 126, 0.12),
    218px 0 0 -6px rgba(0, 50, 126, 0.12), 219px 0 0 -6px rgba(0, 50, 126, 0.12),
    220px 0 0 -6px rgba(0, 50, 126, 0.12), 221px 0 0 -6px rgba(0, 50, 126, 0.12),
    222px 0 0 -6px rgba(0, 50, 126, 0.12), 223px 0 0 -6px rgba(0, 50, 126, 0.12),
    224px 0 0 -6px rgba(0, 50, 126, 0.12), 225px 0 0 -6px rgba(0, 50, 126, 0.12),
    226px 0 0 -6px rgba(0, 50, 126, 0.12), 227px 0 0 -6px rgba(0, 50, 126, 0.12),
    228px 0 0 -6px rgba(0, 50, 126, 0.12), 229px 0 0 -6px rgba(0, 50, 126, 0.12),
    230px 0 0 -6px rgba(0, 50, 126, 0.12), 231px 0 0 -6px rgba(0, 50, 126, 0.12),
    232px 0 0 -6px rgba(0, 50, 126, 0.12), 233px 0 0 -6px rgba(0, 50, 126, 0.12),
    234px 0 0 -6px rgba(0, 50, 126, 0.12), 235px 0 0 -6px rgba(0, 50, 126, 0.12),
    236px 0 0 -6px rgba(0, 50, 126, 0.12), 237px 0 0 -6px rgba(0, 50, 126, 0.12),
    238px 0 0 -6px rgba(0, 50, 126, 0.12), 239px 0 0 -6px rgba(0, 50, 126, 0.12),
    240px 0 0 -6px rgba(0, 50, 126, 0.12);
  height: 14px;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-transition: border-color 0.3s, background-color 0.3s;
  transition: border-color 0.3s, background-color 0.3s;
  width: 14px;
  width: 1rem;
}

.custom-range::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border: 0;
  border-radius: 50px;
  border-radius: 1rem;
  height: 14px;
  height: 1rem;
  position: relative;
  -moz-transition: border-color 0.3s, background-color 0.3s;
  transition: border-color 0.3s, background-color 0.3s;
  width: 14px;
  width: 1rem;
}

.custom-range::-moz-range-progress {
  background: #467fcf;
  border: 0;
  height: 2px;
  margin-top: 0;
}

.custom-range::-ms-track {
  background: transparent;
  background-color: transparent;
  border: 0;
  border-color: transparent;
  border-radius: 0;
  border-width: 0.5rem;
  color: transparent;
  cursor: pointer;
  height: 2px;
  height: 0.5rem;
  margin-top: 10px;
  width: 240px;
  width: 100%;
}

.custom-range::-ms-fill-lower {
  background: #467fcf;
  background-color: #dee2e6;
  border-radius: 0;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  background: rgba(0, 50, 126, 0.12);
  background-color: #dee2e6;
  border-radius: 0;
  border-radius: 1rem;
  margin-right: 15px;
}

.custom-range::-ms-tooltip {
  display: none;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  background-color: #e6ebf1;
  border-color: #e6ebf1;
  color: #fff;
}

.form-select:focus {
  box-shadow: none !important;
}

.ui-datepicker {
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 4px;
  box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
  display: none;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding: 10px;
  width: auto !important;
  z-index: 5 !important;
}

.ui-datepicker .ui-datepicker-header {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #473b52;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;
  letter-spacing: 1px;
  padding: 0 0 5px;
  position: relative;
  text-transform: uppercase;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  color: #6c757d;
  text-indent: -99999px;
  top: 1px;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
  font-family: FontAwesome;
  font-size: 16px;
  position: absolute;
  text-indent: 0;
  top: -4px;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:focus:before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover:before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:focus:before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:hover:before {
  color: #343a40;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  order: 3;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
  content: "\f105";
  right: 5px;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
  content: "\f104";
  left: 5px;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next-hover,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev-hover {
  background-color: transparent;
  border: 0;
  color: #495057;
  cursor: pointer;
  top: 1px;
}

.ui-datepicker .ui-datepicker-calender {
  background-color: transparent;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin: 0;
}

.ui-datepicker .ui-datepicker-calender th {
  color: #98a1b5;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 6px 10px;
  text-transform: uppercase;
}

.ui-datepicker .ui-datepicker-calender td {
  background-color: #fff;
  border: 1px solid transparent;
  padding: 0;
  text-align: right;
}

.ui-datepicker .ui-datepicker-calender td:last-child {
  border-right: 0;
}

.ui-datepicker
  .ui-datepicker-calender
  td.ui-datepicker-other-month
  .ui-state-default {
  color: #ced1da;
}

.ui-datepicker .ui-datepicker-calender td a,
.ui-datepicker .ui-datepicker-calender td span {
  background-color: #fff;
  border: 0;
  border-radius: 50px;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  display: block;
  font-size: 12px;
  font-weight: 400;
  padding: 8px 10px;
  transition: all 0.2s ease-in-out;
}

.ui-datepicker .ui-datepicker-calender .ui-datepicker-today a,
.ui-datepicker .ui-datepicker-calender td a:hover {
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  color: #fff;
}

@media (max-width: 320px) {
  .ui-datepicker .ui-datepicker-calender th {
    letter-spacing: normal;
    padding: 4px 0;
  }
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
  padding-right: 15px;
  width: auto;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-title {
  margin: auto;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev:before {
  left: 10px;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next:before {
  right: 10px;
}

.ui-datepicker-multi .ui-datepicker-group table {
  margin: 0;
}

.ui-datepicker-multi .ui-datepicker-group-last {
  padding-right: 0;
}

.ui-datepicker-inline {
  border-radius: 12px;
  max-width: 270px;
}

.model-wrapper-demo {
  background: #f0f0f2;
  padding: 50px 0;
}

.custom-checkbox:before {
  background-color: transparent;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox:after,
.custom-checkbox:before {
  content: "";
  display: block;
  height: 1rem;
  left: 0;
  position: absolute;
  top: 0.25rem;
  width: 1rem;
}

.custom-checkbox:after {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
}

.form-control.fc-datepicker {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

#ui-datepicker-div tbody,
#ui-datepicker-div td,
#ui-datepicker-div tfoot,
#ui-datepicker-div th,
#ui-datepicker-div thead,
#ui-datepicker-div tr {
  padding: 8px;
}

.form-control {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  color: #6b6f80;
  display: block;
  font-size: 0.9375rem;
  line-height: 1.6;
  padding: 0.375rem 0.75rem;
  padding-right: 30px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

.form-control:disabled::-moz-placeholder {
  opacity: 0.5;
}

.form-control:disabled:-ms-input-placeholder {
  opacity: 0.5;
}

.form-control:disabled::placeholder {
  opacity: 0.5;
}

.form-control::-moz-placeholder {
  color: #b3bdca;
}

.form-control:-ms-input-placeholder {
  color: #b3bdca;
}

.form-control::placeholder {
  color: #b3bdca;
}

.select2-container .select2-selection--single .select2-selection__rrightered {
  padding-left: 20px !important;
  padding-right: 20px;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #e6ebf1 !important;
  border-radius: 4px;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  background-color: #fff;
  border-color: #4eceb2;
  box-shadow: none;
  color: #495057;
  outline: 0;
}

.select2-container--default .select2-selection--multiple {
  background-color: #fff;
  border: 1px solid #e6ebf1 !important;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #e6ebf1 !important;
  border-radius: 4px;
  outline: 0;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #f0f0f2 !important;
  border: 1px solid #ebeef4 !important;
  outline: 0;
}

.form-control,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single:focus {
  outline: 0;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  background-color: #fff;
  box-shadow: none;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  outline: 0;
}

.form-control:-ms-input-placeholder,
.form-control::-moz-placeholder,
.form-control::-ms-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #b3bdca;
  opacity: 1;
}

.search-input:-ms-input-placeholder,
.search-input::-moz-placeholder,
.search-input::-ms-input-placeholder,
.search-input::-webkit-input-placeholder {
  color: #b3bdca;
  opacity: 1;
}

input:-ms-input-placeholder,
input::-moz-placeholder,
input::-ms-input-placeholder,
input::-webkit-input-placeholder {
  color: #b3bdca;
  opacity: 1;
}

.form-control {
  color: #728096;
  opacity: 1;
}

.form-control:disabled {
  background-color: #e9e9eb;
  opacity: 1;
}

.form-control[readonly] {
  background-color: #f3f3f7;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: 2.375rem;
}

.select2-container .select2-selection--single {
  height: 2rem !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rrightered {
  color: #444;
  line-height: 35px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 2rem !important;
}

select.form-control:focus::-ms-value {
  background-color: #fff;
  color: #495057;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  font-size: inherit;
  line-height: 1.6;
  margin-bottom: 0;
  padding-bottom: calc(0.375rem + 1px);
  padding-top: calc(0.375rem + 1px);
}

.col-form-label-lg {
  font-size: 1.125rem;
  line-height: 1.44444444;
  padding-bottom: calc(0.5rem + 1px);
  padding-top: calc(0.5rem + 1px);
}

.col-form-label-sm {
  font-size: 0.875rem;
  line-height: 1.14285714;
  padding-bottom: calc(0.25rem + 1px);
  padding-top: calc(0.25rem + 1px);
}

.form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
  color: #495057;
  display: block;
  line-height: 1.6;
  margin-bottom: 0;
  padding-bottom: 0.375rem;
  padding-top: 0.375rem;
  width: 100%;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm,
.input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-append > .form-control-plaintext.btn,
.input-group-lg
  > .input-group-append
  > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg
  > .input-group-prepend
  > .form-control-plaintext.input-group-text,
.input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-append > .form-control-plaintext.btn,
.input-group-sm
  > .input-group-append
  > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm
  > .input-group-prepend
  > .form-control-plaintext.input-group-text {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  border-radius: 3px;
  font-size: 0.875rem;
  line-height: 1.14285714;
  padding: 0.25rem 0.5rem;
}

.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-sm
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  border-radius: 3px;
  font-size: 1.125rem;
  line-height: 1.44444444;
  padding: 0.5rem 1rem;
}

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-lg
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.6875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-check {
  display: block;
  padding-left: 1.25rem;
  position: relative;
}

.custom-control-input {
  margin-left: -1.25rem;
  margin-top: 0.3rem;
  position: absolute;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #505662;
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-inline {
  align-items: center;
  display: inline-flex;
  margin-right: 0.75rem;
  padding-left: 0;
}

.custom-control-inline .custom-control-input {
  margin-left: 0;
  margin-right: 0.3125rem;
  margin-top: 0;
  position: static;
}

.valid-feedback {
  color: #2dce89;
  display: none;
  font-size: 87.5%;
  margin-top: 0.25rem;
  width: 100%;
}

.valid-tooltip {
  background-color: rgba(94, 186, 0, 0.8);
  border-radius: 0.2rem;
  color: #fff;
  display: none;
  font-size: 0.875rem;
  line-height: 1;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #198754;
}

.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #2dce89;
  box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}

.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip {
  display: block;
}

.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #2dce89;
}

.custom-control-input.is-valid ~ .custom-control-label:before,
.was-validated .custom-control-input:valid ~ .custom-control-label:before {
  background-color: #9eff3b;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid:checked ~ .custom-control-label:before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label:before {
  background-color: #78ed00;
}

.custom-control-input.is-valid:focus ~ .custom-control-label:before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(94, 186, 0, 0.25);
}

.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #2dce89;
}

.custom-file-input.is-valid ~ .custom-file-label:before,
.was-validated .custom-file-input:valid ~ .custom-file-label:before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
}

.invalid-feedback {
  color: #dc0441;
  display: none;
  font-size: 87.5%;
  margin-top: 0.25rem;
  width: 100%;
}

.invalid-tooltip {
  background-color: rgba(205, 32, 31, 0.8);
  border-radius: 0.2rem;
  color: #fff;
  display: none;
  font-size: 0.875rem;
  line-height: 1;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #e04e5c;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc0441;
  box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc0441;
}

.custom-control-input.is-invalid ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid ~ .custom-control-label:before {
  background-color: #ec8080;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label:before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label:before {
  background-color: #e23e3d;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label:before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(205, 32, 31, 0.25);
}

.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc0441;
}

.custom-file-input.is-invalid ~ .custom-file-label:before,
.was-validated .custom-file-input:invalid ~ .custom-file-label:before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
}

.form-inline {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
  }

  .form-inline .form-group,
  .form-inline label {
    align-items: center;
    display: flex;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    flex: 0 0 auto;
    flex-flow: row wrap;
  }

  .form-inline .form-control {
    display: inline-block;
    vertical-align: middle;
    width: auto;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: 0;
    width: auto;
  }

  .form-inline .custom-control-input {
    margin-left: 0;
    margin-right: 0.25rem;
    margin-top: 0;
    position: relative;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.select2-container--focus {
  outline: 1px auto #e6ebf1;
}

.ms-choice,
.ms-choice:focus {
  outline: 0;
}

.select2-lg .select2-container .select2-selection--single {
  height: 2.875rem !important;
}

.select2-lg
  .select2-container--default
  .select2-selection--single
  .select2-selection__rrightered {
  line-height: 32px !important;
}

.select2-sm .select2-container .select2-selection--single {
  height: 1.775rem !important;
}

.select2-sm
  .select2-container--default
  .select2-selection--single
  .select2-selection__rrightered {
  line-height: 27px !important;
}

.select2-sm
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  left: 60%;
  top: 35% !important;
}

select.form-control.select-lg {
  border-radius: 4px !important;
  font-size: 17px !important;
  height: 46px !important;
  line-height: 1.3333333 !important;
  padding: 10px 16px !important;
}

select.form-control.select-sm {
  border-radius: 4px !important;
  height: 30px !important;
  line-height: 1 !important;
  padding: 0 10px 1px !important;
}

input[type="date"].form-control:focus,
input[type="date"].form-control:hover,
input[type="datetime-local"].form-control:focus,
input[type="datetime-local"].form-control:hover,
input[type="month"].form-control:focus,
input[type="month"].form-control:hover,
input[type="time"].form-control:focus,
input[type="time"].form-control:hover {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  outline: 0;
}

.wizard {
  background-color: #fff;
  border: 1px solid #e9edf4;
}

.wizard > .steps {
  padding: 20px;
}

.wizard > .steps > ul {
  display: flex;
  margin-bottom: 0;
  padding: 0;
}

.wizard > .steps > ul li {
  display: block;
  float: none;
  width: auto;
}

.wizard > .steps > ul li .current-info {
  display: none;
}

.wizard > .steps > ul li .title {
  display: none;
  margin: 0;
  min-height: auto;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}

.wizard > .steps > ul li + li {
  margin-left: 5px;
}

.wizard > .steps a,
.wizard > .steps a:active,
.wizard > .steps a:hover {
  align-items: center;
  color: #14112d;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
}

.wizard > .steps a .number,
.wizard > .steps a:active .number,
.wizard > .steps a:hover .number {
  background-color: #f0f0f2;
  border-radius: 8px;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  display: block;
  flex-shrink: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 600;
  height: 40px;
  line-height: 2.5;
  margin-right: 5px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  width: 40px;
}

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:active,
.wizard > .steps .disabled a:hover {
  color: #737688;
}

.wizard > .steps .current a .title,
.wizard > .steps .current a:active .title,
.wizard > .steps .current a:hover .title {
  display: inline-block;
}

.wizard > .steps .done a,
.wizard > .steps .done a:active,
.wizard > .steps .done a:hover {
  color: rgba(112, 94, 200, 0.7);
}

.wizard > .steps .done a .number,
.wizard > .steps .done a:active .number,
.wizard > .steps .done a:hover .number {
  background: rgba(112, 94, 200, 0.7);
  color: #fff;
}

.wizard > .content {
  border-bottom: 1px solid #e9edf4;
  border-top: 1px solid #e9edf4;
  min-height: 150px;
  padding: 20px;
}

.wizard > .content > .title {
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  display: none;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.wizard > .content > .title.current {
  display: block;
}

.wizard > .content > .body {
  float: none;
  height: auto;
  position: static;
  width: auto;
}

.wizard > .content > .body input.parsley-error {
  border-color: #ff5c77;
}

.wizard > .content > .body input.parsley-error + ul {
  list-style: none !important;
}

.wizard > .actions {
  padding: 20px;
}

.wizard > .actions > ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

.wizard > .actions > ul > li:last-child a {
  background-color: #2dce89;
}

.wizard > .actions a,
.wizard > .actions a:active,
.wizard > .actions a:hover {
  border-radius: 4px;
  color: #fff;
  display: block;
  font-weight: 500;
  line-height: 1.573;
  padding: 9px 25px;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:active,
.wizard > .actions .disabled a:hover {
  background-color: #5b73e8;
  color: #fff;
}

.wizard.vertical > .steps {
  padding: 20px;
}

.wizard.vertical > .steps ul li .title {
  display: none;
}

.wizard.vertical > .steps .current a .title {
  display: inline-block;
}

.wizard.vertical > .content {
  margin: 0;
  padding: 20px;
}

.wizard.vertical > .actions {
  padding: 20px;
}

.wizard.vertical > .actions ul {
  float: none;
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  .wizard > .steps {
    padding: 25px;
  }

  .wizard > .steps > ul li .title {
    display: inline-block;
  }

  .wizard.vertical > .actions,
  .wizard.vertical > .content {
    width: 85%;
  }

  .wizard.vertical > .steps {
    width: 15%;
  }

  .wizard.vertical > .steps ul li + li {
    margin-top: 20px;
  }

  .wizard > .actions,
  .wizard > .content {
    padding: 25px;
  }

  .wizard > .steps .done a .title,
  .wizard > .steps .done a:active .title,
  .wizard > .steps .done a:hover .title {
    display: inline-block;
  }
}

@media (min-width: 576px) {
  .wizard > .steps > ul li + li {
    margin-left: 20px;
  }

  .wizard > .steps > ul li .title {
    display: none;
    margin-left: 10px;
  }

  .wizard.vertical > .actions {
    border-left: 1px solid #e9edf4;
    float: right;
    width: 80%;
  }

  .wizard.vertical > .content {
    border-left: 1px solid #e9edf4;
    border-top-width: 0;
    float: right;
    width: 80%;
  }

  .wizard.vertical > .steps {
    float: left;
    width: 20%;
  }

  .wizard.vertical > .steps .current a .title {
    display: none;
  }

  .wizard.vertical > .steps ul {
    flex-direction: column;
  }

  .wizard.vertical > .steps ul li + li {
    margin-left: 0;
    margin-top: 10px;
  }

  .wizard > .steps a .number,
  .wizard > .steps a:active .number,
  .wizard > .steps a:hover .number {
    font-size: 18px;
    font-weight: 600;
    height: 40px;
    line-height: 2.1;
    width: 40px;
  }
}

@media (min-width: 1200px) {
  .wizard > .steps a,
  .wizard > .steps a:active,
  .wizard > .steps a:hover {
    justify-content: flex-start;
  }
}

@media (prefers-reduced-motion: reduce) {
  .wizard > .steps > ul li .title,
  .wizard > .steps a .number,
  .wizard > .steps a:active .number,
  .wizard > .steps a:hover .number {
    transition: none;
  }
}

@media (max-width: 991px) {
  .wizard > .steps .disabled {
    display: none;
  }
}

@media (min-width: 992px) {
  .wizard.vertical > .actions,
  .wizard.vertical > .content {
    padding: 25px;
    width: 70%;
  }

  .wizard.vertical > .steps {
    padding: 20px;
    width: 30%;
  }

  .wizard.vertical > .steps .current a .title {
    display: inline-block;
  }

  .wizard.vertical > .steps a {
    justify-content: flex-left;
  }

  .wizard.vertical > .steps ul li .title {
    display: block;
  }

  .wizard > .actions,
  .wizard > .content,
  .wizard > .steps {
    padding: 20px;
  }

  .wizard > .steps .disabled {
    display: block;
  }

  .wizard > .steps > ul li + li {
    margin-left: 30px;
  }
}

.step-equal-width > .steps > ul {
  display: flex;
}

.step-equal-width > .steps > ul > li {
  flex: 1;
  float: none;
  width: auto;
}

.wizard-style-1 > .steps > ul a,
.wizard-style-1 > .steps > ul a:active,
.wizard-style-1 > .steps > ul a:hover {
  height: 50px;
  padding: 0;
}

.wizard-style-1 > .steps > ul a .number,
.wizard-style-1 > .steps > ul a:active .number,
.wizard-style-1 > .steps > ul a:hover .number {
  background-color: #f3f7fd;
  border: 0;
  border-radius: 0;
  color: #737688;
  font-size: 18px;
  font-weight: 700;
  height: 100%;
  width: 50px;
}

.wizard-style-1 > .steps > ul a .title,
.wizard-style-1 > .steps > ul a:active .title,
.wizard-style-1 > .steps > ul a:hover .title {
  margin-left: 20px;
  margin-right: 20px;
}

.wizard-style-1 > .steps > ul .done a .number,
.wizard-style-1 > .steps > ul .done a:active .number,
.wizard-style-1 > .steps > ul .done a:hover .number {
  background-color: #643ab0;
  color: #fff;
}

.wizard-style-2 > .steps > ul a,
.wizard-style-2 > .steps > ul a:active,
.wizard-style-2 > .steps > ul a:hover {
  border-radius: 50px;
  height: 50px;
  padding: 0;
}

.wizard-style-2 > .steps > ul a .number,
.wizard-style-2 > .steps > ul a:active .number,
.wizard-style-2 > .steps > ul a:hover .number {
  background-color: #fff;
  border: 2px solid #f3f7fd;
  color: #737688;
  font-size: 18px;
  font-weight: 700;
  height: 100%;
  width: 50px;
}

.wizard-style-2 > .steps > ul a .title,
.wizard-style-2 > .steps > ul a:active .title,
.wizard-style-2 > .steps > ul a:hover .title {
  margin-right: 20px;
}

.wizard-style-2 > .steps > ul .done a .number,
.wizard-style-2 > .steps > ul .done a:active .number,
.wizard-style-2 > .steps > ul .done a:hover .number {
  border-color: #8c3feb;
  color: #8c3feb;
}

.parsley-required {
  color: #ff5c77;
  font-size: 12px;
  margin-top: 3px;
}

.wizard.vertical > .actions {
  margin-top: 0;
}

.input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.input-group-addon {
  border: 1px solid #e6ebf1;
  border-radius: 0.25rem 0 0 0.2rem;
  border-right: 0;
  color: #b7bec5;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 0;
  padding: 0.5rem 0.75rem;
  text-align: center;
}

.box-shadow-0 {
  box-shadow: none;
}

.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control {
  flex: 1 1 auto;
  margin-bottom: 0;
  position: relative;
  width: 1%;
}

.input-group > .custom-file:focus,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 2;
}

.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control {
  margin-left: -1px;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.input-group > .custom-file {
  align-items: center;
  display: flex;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label:after {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label:after {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.input-group-append,
.input-group-prepend {
  display: flex;
}

.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 1;
}

.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  align-items: center;
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 5px;
  color: #8f8c9e;
  display: flex;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 0;
  padding: 0.375rem 0.75rem;
  text-align: center;
  white-space: nowrap;
}

.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
  margin-top: 0;
}

.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.selectgroup {
  display: inline-flex;
}

.selectgroup-item {
  flex-grow: 1;
  position: relative;
}

.selectgroup-item + .selectgroup-item {
  margin-left: -1px;
}

.selectgroup-item:not(:first-child) .selectgroup-button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.selectgroup-item:not(:last-child) .selectgroup-button {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.selectgroup-input {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}

.selectgroup-button {
  border: 1px solid #e6ebf1;
  border-radius: 3px;
  color: #9aa0ac;
  cursor: pointer;
  display: block;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  min-width: 2.375rem;
  padding: 0.375rem 1rem;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectgroup-button-icon {
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.selectgroup-input:checked + .selectgroup-button {
  background: #f0f0f2;
  z-index: 1;
}

.selectgroup-input:focus + .selectgroup-button {
  box-shadow: none;
  z-index: 2;
}

.selectgroup-pills {
  align-items: flex-left;
  flex-wrap: wrap;
}

.selectgroup-pills .selectgroup-item {
  flex-grow: 0;
  margin-right: 0.5rem;
}

.selectgroup-pills .selectgroup-button {
  border-radius: 4px !important;
}

.custom-switch {
  align-items: center;
  cursor: default;
  display: inline-flex;
  margin: 0;
  padding-left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-switch-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.custom-switches-stacked {
  display: flex;
  flex-direction: column;
}

.custom-switches-stacked .custom-switch {
  margin-bottom: 0.5rem;
}

.custom-switch-indicator {
  background: #f0f0f2;
  border-radius: 50px;
  display: inline-block;
  height: 1.25rem;
  position: relative;
  transition: border-color 0.3s, background-color 0.3s;
  vertical-align: bottom;
  width: 2.25rem;
}

.custom-switch-indicator:before {
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  content: "";
  height: calc(1.25rem - 4px);
  left: 1px;
  position: absolute;
  top: 1px;
  transition: left 0.3s;
  width: calc(1.25rem - 4px);
}

.custom-switch-input:checked ~ .custom-switch-indicator:before {
  left: calc(1rem + 1px);
}

.custom-switch-indicator-lg {
  background: #f5f6fb;
  border: 1px solid #ebeaf1;
  border-radius: 50px;
  display: inline-block;
  height: 1.5rem;
  position: relative;
  transition: border-color 0.3s, background-color 0.3s;
  vertical-align: bottom;
  width: 3rem;
}

.custom-switch-indicator-lg:before {
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  content: "";
  height: calc(1.5rem - 4px);
  left: 1px;
  position: absolute;
  top: 1px;
  transition: left 0.3s;
  width: calc(1.5rem - 4px);
}

.custom-switch-input:checked ~ .custom-switch-indicator-lg:before {
  left: calc(1.46rem + 1px);
}

.custom-switch-indicator-xl {
  background: #f5f6fb;
  border: 1px solid #ebeaf1;
  border-radius: 50px;
  display: inline-block;
  height: 2rem;
  position: relative;
  transition: border-color 0.3s, background-color 0.3s;
  vertical-align: bottom;
  width: 4rem;
}

.custom-switch-indicator-xl:before {
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  content: "";
  height: calc(2rem - 4px);
  left: 1px;
  position: absolute;
  top: 1px;
  transition: left 0.3s;
  width: calc(2rem - 4px);
}

.custom-square,
.custom-square:before {
  border-radius: 0 !important;
}

.custom-radius,
.custom-radius:before {
  border-radius: 4px !important;
}

.custom-switch-input:focus ~ .custom-switch-indicator {
  box-shadow: none;
}

.custom-switch-description {
  color: #6e7687;
  margin-left: 0.5rem;
  transition: color 0.3s;
}

.custom-switch-input:checked ~ .custom-switch-description {
  color: #495057;
}

.material-switch > input[type="checkbox"] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0;
  position: relative;
  width: 40px;
}

.material-switch > label:before {
  background: #f3f4f7;
  border-radius: 8px;
  box-shadow: inset 0 0 10px #6574cd;
  content: "";
  height: 16px;
  margin-top: -8px;
  opacity: 0.3;
  position: absolute;
  transition: all 0.4s ease-in-out;
  width: 40px;
}

.material-switch > label:after {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  content: "";
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}

.material-switch > input[type="checkbox"]:checked + label:before {
  background: inherit;
  opacity: 0.5;
}

.material-switch > input[type="checkbox"]:checked + label:after {
  background: inherit;
  left: 20px;
}

.label-default {
  background: #f3f4f7;
}

.label-success {
  background: #2dce89;
}

.label-danger {
  background: #dc0441;
}

.label-warning {
  background: #ecb403;
}

.label-info {
  background: #45aaf2;
}

.imagecheck {
  cursor: pointer;
  margin: 0;
  position: relative;
}

.imagecheck-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.imagecheck-figure {
  border: 1px solid #e6ebf1;
  border-radius: 3px;
  margin: 0;
  position: relative;
}

.imagecheck-input:focus ~ .imagecheck-figure {
  border-color: #467fcf;
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
}

.imagecheck-input:checked ~ .imagecheck-figure {
  border-color: rgba(0, 40, 100, 0.24);
}

.imagecheck-figure:before {
  background: #467fcf
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
    no-repeat 50%/50% 50%;
  border-radius: 3px;
  color: #fff;
  content: "";
  display: block;
  height: 1rem;
  left: 0.25rem;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0.25rem;
  transition: opacity 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1rem;
  z-index: 1;
}

.imagecheck-input:checked ~ .imagecheck-figure:before {
  opacity: 1;
}

.imagecheck-image {
  max-width: 100%;
  opacity: 0.64;
  transition: opacity 0.3s;
}

.imagecheck-image:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.imagecheck-image:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.imagecheck-input:checked ~ .imagecheck-figure .imagecheck-image,
.imagecheck-input:focus ~ .imagecheck-figure .imagecheck-image,
.imagecheck:hover .imagecheck-image {
  opacity: 1;
}

.imagecheck-caption {
  color: #9aa0ac;
  font-size: 0.875rem;
  padding: 0.25rem;
  text-align: center;
  transition: color 0.3s;
}

.imagecheck-input:checked ~ .imagecheck-figure .imagecheck-caption,
.imagecheck-input:focus ~ .imagecheck-figure .imagecheck-caption,
.imagecheck:hover .imagecheck-caption {
  color: #495057;
}

.colorinput {
  cursor: pointer;
  margin: 0;
  position: relative;
}

.colorinput-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.colorinput-color {
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  height: 1.75rem;
  width: 1.75rem;
}

.colorinput-color:before {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
    no-repeat 50%/50% 50%;
  content: "";
  height: 1.25rem;
  left: 0.25rem;
  opacity: 0;
  position: absolute;
  top: 0.25rem;
  transition: opacity 0.3s;
  width: 1.25rem;
}

.colorinput-input:checked ~ .colorinput-color:before {
  opacity: 1;
}

.colorinput-input:focus ~ .colorinput-color {
  border-color: #467fcf;
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
}

.selectize-control.plugin-drag_drop.multi
  > .selectize-input
  > div.ui-sortable-placeholder {
  background: #f2f2f2 !important;
  background: rgba(0, 0, 0, 0.06) !important;
  border: 0 !important;
  box-shadow: inset 0 0 12px 4px #fff;
  visibility: visible !important;
}

.selectize-control.plugin-drag_drop .ui-sortable-placeholder:after {
  content: "!";
  visibility: hidden;
}

.selectize-control.plugin-drag_drop .ui-sortable-helper {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.selectize-dropdown-header {
  background: #f8f8f8;
  border-bottom: 1px solid #d0d0d0;
  border-radius: 3px 3px 0 0;
  padding: 5px 8px;
  position: relative;
}

.selectize-dropdown-header-close {
  color: #495057;
  font-size: 20px !important;
  line-height: 20px;
  margin-top: -12px;
  opacity: 0.4;
  position: absolute;
  right: 8px;
  top: 50%;
}

.selectize-dropdown-header-close:hover {
  color: #000;
}

.selectize-dropdown.plugin-optgroup_columns .optgroup {
  border-right: 1px solid #f2f2f2;
  border-top: 0;
  box-sizing: border-box;
  float: left;
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
  border-right: 0;
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
  display: none;
}

.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0;
}

.selectize-control {
  border: 0;
  padding: 0;
  position: relative;
}

.selectize-control.plugin-remove_button [data-value] {
  padding-right: 24px !important;
  position: relative;
}

.selectize-control.plugin-remove_button [data-value] .remove {
  border-left: 1px solid #d0d0d0;
  border-radius: 0 2px 2px 0;
  bottom: 0;
  box-sizing: border-box;
  color: inherit;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  padding: 2px 0 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  vertical-align: middle;
  width: 17px;
  z-index: 1;
}

.selectize-control.plugin-remove_button [data-value] .remove:hover {
  background: rgba(0, 0, 0, 0.05);
}

.selectize-control.plugin-remove_button [data-value].active .remove {
  border-start-color: #cacaca;
}

.selectize-control.plugin-remove_button .disabled [data-value] .remove {
  border-start-color: #fff;
}

.selectize-control.plugin-remove_button .disabled [data-value] .remove:hover {
  background: none;
}

.selectize-control.plugin-remove_button .remove-single {
  font-size: 23px;
  position: absolute;
  right: 28px;
  top: 6px;
}

.selectize-dropdown,
.selectize-input {
  -webkit-font-smoothing: inherit;
  color: #495057;
  font-family: inherit;
  font-size: 15px;
  line-height: 18px;
}

.selectize-input {
  background: #fff;
  cursor: text;
}

.selectize-input input {
  -webkit-font-smoothing: inherit;
  color: #495057;
  font-family: inherit;
  font-size: 15px;
  line-height: 18px;
}

.selectize-control.single .selectize-input.input-active {
  background: #fff;
  display: inline-block;
}

.selectize-input {
  border: 1px solid #e6ebf1;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-block;
  display: block;
  overflow: hidden;
  padding: 0.5625rem 0.75rem;
  position: relative;
  transition: border-color 0.3s, box-shadow 0.3s;
  width: 100%;
  z-index: 1;
}

.selectize-control.multi .selectize-input.has-items {
  padding: 7px 0.75rem 4px 7px;
}

.selectize-input.full {
  background-color: #fff;
}

.selectize-input.disabled,
.selectize-input.disabled * {
  cursor: default !important;
}

.selectize-input.focus {
  border-color: #467fcf;
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
}

.selectize-input.dropdown-active {
  border-radius: 3px 3px 0 0;
}

.selectize-input > * {
  zoom: 1;
  display: -moz-inline-stack;
  display: inline-block;
  display: inline;
  vertical-align: baseline;
}

.selectize-control.multi .selectize-input > div {
  background: #e9ecef;
  border: 0 solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  color: #495057;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  margin: 0 3px 3px 0;
  padding: 2px 6px;
}

.selectize-control.multi .selectize-input > div.active {
  background: #e8e8e8;
  border: 0 solid #cacaca;
  color: #303030;
}

.selectize-control.multi .selectize-input.disabled > div,
.selectize-control.multi .selectize-input.disabled > div.active {
  background: #fff;
  border: 0 solid #fff;
  color: #7d7d7d;
}

.selectize-input > input {
  background: none !important;
  border: 0 !important;
  box-shadow: none !important;
  display: inline-block !important;
  line-height: inherit !important;
  margin: 0 2px 0 0 !important;
  max-height: none !important;
  max-width: 100% !important;
  min-height: 0 !important;
  padding: 0 !important;
  text-indent: 0 !important;
}

.selectize-input > input::-ms-clear {
  display: none;
}

.selectize-input > input:focus {
  outline: none !important;
}

.selectize-input:after {
  clear: left;
  content: " ";
  display: block;
}

.selectize-input.dropdown-active:before {
  background: #f0f0f0;
  bottom: 0;
  content: " ";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}

.selectize-dropdown {
  background: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 0 0 3px 3px;
  border-top: 0;
  box-sizing: border-box;
  height: auto;
  margin: -1px 0 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}

.selectize-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
  padding: 6px 0.75rem;
}

.selectize-dropdown [data-selectable] .highlight {
  background: rgba(125, 168, 208, 0.2);
  border-radius: 1px;
}

.selectize-dropdown .optgroup-header {
  padding: 6px 0.75rem;
}

.selectize-dropdown .optgroup:first-child .optgroup-header {
  border-top: 0;
}

.selectize-dropdown .optgroup-header {
  background: #fff;
  color: #495057;
  cursor: default;
}

.selectize-dropdown .active {
  background-color: #f1f4f8;
  color: #467fcf;
}

.selectize-dropdown .active.create {
  color: #495057;
}

.selectize-dropdown .create {
  color: rgba(48, 48, 48, 0.5);
}

.selectize-dropdown-content {
  -webkit-overflow-scrolling: touch;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input input {
  cursor: pointer;
}

.selectize-control.single .selectize-input.input-active,
.selectize-control.single .selectize-input.input-active input {
  cursor: text;
}

.selectize-control.single .selectize-input:after {
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='#999' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E")
    no-repeat 50%;
  background-size: 8px 10px;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  right: 12px;
  top: 13px;
  transition: transform 0.3s;
  width: 8px;
}

.selectize-control.single .selectize-input.dropdown-active:after {
  transform: rotate(180deg);
}

.selectize-control .selectize-input.disabled {
  background-color: #fafafa;
  opacity: 0.5;
}

.selectize-dropdown .image,
.selectize-input .image {
  align-items: center;
  background-size: contain;
  display: flex;
  float: left;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  margin: -1px 0.5rem -1px -4px;
  width: 1.25rem;
}

.selectize-dropdown .image img {
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  max-width: 100%;
}

.selectize-input .image {
  height: 1.5rem;
  margin: -3px 0.75rem -3px -5px;
  width: 1.5rem;
}

.selectize-input .image img {
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  max-width: 100%;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-success {
  background-color: #38cb89 !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #15ca7c !important;
}

.bg-info {
  background-color: #3e80eb !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #1594ef !important;
}

.bg-warning {
  background-color: #ffab00 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #c29d0b !important;
}

.bg-danger {
  background-color: #ef4b4b !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #a11918 !important;
}

.bg-light-transparent {
  background-color: #f7f7f7 !important;
}

.bg-light,
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #f0f0f2 !important;
}

.bg-light2 {
  background-color: #f8f8fc !important;
}

a.bg-light2:focus,
a.bg-light2:hover,
button.bg-light2:focus,
button.bg-light2:hover {
  background-color: #f0f0f2 !important;
}

.bg-light3 {
  background-color: #e6ebf1 !important;
}

.bg-light4 {
  background-color: #f7f7f7 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient-success {
  background-image: linear-gradient(270deg, #43c47a 0, #10ae23);
}

.bg-gradient-info {
  background-image: linear-gradient(270deg, #8caef3 0, #3e80eb);
}

.bg-gradient-warning {
  background-image: linear-gradient(270deg, #f8be8d 0, #ff9a44);
}

.bg-gradient-teal {
  background-image: linear-gradient(270deg, #60c1db 0, #0698a7);
}

.bg-gradient-blue {
  background-image: linear-gradient(270deg, #07aedb 0, #055ee9);
}

.bg-gradient-danger {
  background-image: linear-gradient(270deg, #ff90a0 0, #fc6076);
}

.bg-gray-100 {
  background-color: #f2f2f2;
}

.bg-gray-200 {
  background-color: #e6e6e6;
}

.bg-gray-300 {
  background-color: #d9d9d9;
}

.bg-gray-400 {
  background-color: #ccc;
}

.bg-gray-500 {
  background-color: #bfbfbf;
}

.bg-gray-600 {
  background-color: #b3b3b3;
}

.bg-gray-700 {
  background-color: #a9a9a9;
}

.bg-gray-800 {
  background-color: #a6a6a6;
}

.bg-gray-900 {
  background-color: #999;
}

.bg-white-1 {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.bg-white-2 {
  background-color: hsla(0, 0%, 100%, 0.2);
}

.bg-white-3 {
  background-color: hsla(0, 0%, 100%, 0.3);
}

.bg-white-4 {
  background-color: hsla(0, 0%, 100%, 0.4);
}

.bg-white-5 {
  background-color: hsla(0, 0%, 100%, 0.5);
}

.bg-white-6 {
  background-color: hsla(0, 0%, 100%, 0.6);
}

.bg-white-7 {
  background-color: hsla(0, 0%, 100%, 0.7);
}

.bg-white-8 {
  background-color: hsla(0, 0%, 100%, 0.8);
}

.bg-white-9 {
  background-color: hsla(0, 0%, 100%, 0.9);
}

.bg-black-1 {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-black-2 {
  background-color: rgba(0, 0, 0, 0.2);
}

.bg-black-3 {
  background-color: rgba(0, 0, 0, 0.3);
}

.bg-black-4 {
  background-color: rgba(0, 0, 0, 0.4);
}

.bg-black-5 {
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-black-6 {
  background-color: rgba(0, 0, 0, 0.6);
}

.bg-black-7 {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-black-8 {
  background-color: rgba(0, 0, 0, 0.8);
}

.bg-black-9 {
  background-color: rgba(0, 0, 0, 0.9);
}

.border {
  border: 1px solid #e6ebf1 !important;
}

.border-top {
  border-top: 1px solid #e6ebf1 !important;
}

.border-end {
  border-right: 1px solid #e6ebf1 !important;
}

.border-bottom {
  border-bottom: 1px solid #e6ebf1 !important;
}

.border-start {
  border-left: 1px solid #e6ebf1 !important;
}

.border-top-bottom {
  border-bottom: 1px solid #e6ebf1 !important;
  border-top: 1px solid #e6ebf1 !important;
}

.border-start-right {
  border-left: 1px solid #e6ebf1 !important;
  border-right: 1px solid #e6ebf1 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-top-bottom-0 {
  border-bottom: 0 solid #e6ebf1 !important;
  border-top: 0 solid #e6ebf1 !important;
}

.border-start-right-0 {
  border-left: 0 solid #e6ebf1 !important;
  border-right: 0 solid #e6ebf1 !important;
}

.border-primary {
  border: 1px solid #664dc9 !important;
}

.border-secondary {
  border: 1px solid #5b73e8 !important;
}

.border-success {
  border: 1px solid #38cb89 !important;
}

.border-info {
  border: 1px solid #3e80eb !important;
}

.border-warning {
  border: 1px solid #ffab00 !important;
}

.border-danger {
  border: 1px solid #ef4b4b !important;
}

.border-light {
  border: 1px solid #f8f9fa !important;
}

.border-teal {
  border: 1px solid #2bcbba !important;
}

.border-dark {
  border: 1px solid #343a40 !important;
}

.border-transparent {
  border: 1px solid hsla(0, 0%, 100%, 0.15) !important;
}

.border-dark-transparent {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}

.border-light2 {
  border: 1px solid #e6ebf1 !important;
}

.border-white {
  border: 1px solid #fff !important;
}

.border-wd-1 {
  border-width: 1px !important;
}

.border-wd-2 {
  border-width: 2px !important;
}

.border-wd-3 {
  border-width: 3px !important;
}

.border-wd-4 {
  border-width: 4px !important;
}

.border-wd-5 {
  border-width: 5px !important;
}

.rounded {
  border-radius: 3px !important;
}

.rounded-top {
  border-top-left-radius: 3px !important;
}

.rounded-end,
.rounded-top {
  border-top-right-radius: 3px !important;
}

.rounded-bottom,
.rounded-end {
  border-bottom-right-radius: 3px !important;
}

.rounded-bottom,
.rounded-start {
  border-bottom-left-radius: 3px !important;
}

.rounded-start {
  border-top-left-radius: 3px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.clearfix .fab {
  font-size: 25px;
}

.clearfix h5 {
  font-size: 20px;
  font-weight: 700;
}

i.fa.round {
  border-radius: 50%;
  padding: 5px;
  width: 15%;
}

.list-icon span p {
  border-radius: 15px;
  margin: 0 auto;
  width: 50%;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.embed-responsive {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  border: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.embed-responsive-21by9:before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-left {
  justify-content: flex-left !important;
}

.justify-content-right {
  justify-content: flex-right !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-left {
  align-items: flex-left !important;
}

.align-items-right {
  align-items: flex-right !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-left {
  align-content: flex-left !important;
}

.align-content-right {
  align-content: flex-right !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-left {
  align-self: flex-left !important;
}

.align-self-right {
  align-self: flex-right !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .me-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ms-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .me-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ms-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .me-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ms-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.75rem !important;
  }

  .me-sm-3,
  .mx-sm-3 {
    margin-right: 0.75rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .ms-sm-3,
  .mx-sm-3 {
    margin-left: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1rem !important;
  }

  .me-sm-4,
  .mx-sm-4 {
    margin-right: 1rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1rem !important;
  }

  .ms-sm-4,
  .mx-sm-4 {
    margin-left: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.5rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1.5rem !important;
  }

  .me-sm-5,
  .mx-sm-5 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1.5rem !important;
  }

  .ms-sm-5,
  .mx-sm-5 {
    margin-left: 1.5rem !important;
  }

  .m-sm-6 {
    margin: 2rem !important;
  }

  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2rem !important;
  }

  .me-sm-6,
  .mx-sm-6 {
    margin-right: 2rem !important;
  }

  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2rem !important;
  }

  .ms-sm-6,
  .mx-sm-6 {
    margin-left: 2rem !important;
  }

  .m-sm-7 {
    margin: 3rem !important;
  }

  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3rem !important;
  }

  .me-sm-7,
  .mx-sm-7 {
    margin-right: 3rem !important;
  }

  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3rem !important;
  }

  .ms-sm-7,
  .mx-sm-7 {
    margin-left: 3rem !important;
  }

  .m-sm-8 {
    margin: 4rem !important;
  }

  .mt-sm-8,
  .my-sm-8 {
    margin-top: 4rem !important;
  }

  .me-sm-8,
  .mx-sm-8 {
    margin-right: 4rem !important;
  }

  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 4rem !important;
  }

  .ms-sm-8,
  .mx-sm-8 {
    margin-left: 4rem !important;
  }

  .m-sm-9 {
    margin: 6rem !important;
  }

  .mt-sm-9,
  .my-sm-9 {
    margin-top: 6rem !important;
  }

  .me-sm-9,
  .mx-sm-9 {
    margin-right: 6rem !important;
  }

  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 6rem !important;
  }

  .ms-sm-9,
  .mx-sm-9 {
    margin-left: 6rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pe-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .ps-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pe-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .ps-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pe-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .ps-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pe-sm-3,
  .px-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .ps-sm-3,
  .px-sm-3 {
    padding-left: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1rem !important;
  }

  .pe-sm-4,
  .px-sm-4 {
    padding-right: 1rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1rem !important;
  }

  .ps-sm-4,
  .px-sm-4 {
    padding-left: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.5rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1.5rem !important;
  }

  .pe-sm-5,
  .px-sm-5 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1.5rem !important;
  }

  .ps-sm-5,
  .px-sm-5 {
    padding-left: 1.5rem !important;
  }

  .p-sm-6 {
    padding: 2rem !important;
  }

  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2rem !important;
  }

  .pe-sm-6,
  .px-sm-6 {
    padding-right: 2rem !important;
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2rem !important;
  }

  .ps-sm-6,
  .px-sm-6 {
    padding-left: 2rem !important;
  }

  .p-sm-7 {
    padding: 3rem !important;
  }

  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3rem !important;
  }

  .pe-sm-7,
  .px-sm-7 {
    padding-right: 3rem !important;
  }

  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-7,
  .px-sm-7 {
    padding-left: 3rem !important;
  }

  .p-sm-8 {
    padding: 4rem !important;
  }

  .pt-sm-8,
  .py-sm-8 {
    padding-top: 4rem !important;
  }

  .pe-sm-8,
  .px-sm-8 {
    padding-right: 4rem !important;
  }

  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 4rem !important;
  }

  .ps-sm-8,
  .px-sm-8 {
    padding-left: 4rem !important;
  }

  .p-sm-9 {
    padding: 6rem !important;
  }

  .pt-sm-9,
  .py-sm-9 {
    padding-top: 6rem !important;
  }

  .pe-sm-9,
  .px-sm-9 {
    padding-right: 6rem !important;
  }

  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 6rem !important;
  }

  .ps-sm-9,
  .px-sm-9 {
    padding-left: 6rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }

  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-left {
    justify-content: flex-left !important;
  }

  .justify-content-sm-right {
    justify-content: flex-right !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-left {
    align-items: flex-left !important;
  }

  .align-items-sm-right {
    align-items: flex-right !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-left {
    align-content: flex-left !important;
  }

  .align-content-sm-right {
    align-content: flex-right !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-left {
    align-self: flex-left !important;
  }

  .align-self-sm-right {
    align-self: flex-right !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .page-content {
    margin: 1.5rem 0;
  }

  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .me-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ms-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .me-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ms-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .me-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ms-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 0.75rem !important;
  }

  .me-md-3,
  .mx-md-3 {
    margin-right: 0.75rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .ms-md-3,
  .mx-md-3 {
    margin-left: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1rem !important;
  }

  .me-md-4,
  .mx-md-4 {
    margin-right: 1rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1rem !important;
  }

  .ms-md-4,
  .mx-md-4 {
    margin-left: 1rem !important;
  }

  .m-md-5 {
    margin: 1.5rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 1.5rem !important;
  }

  .me-md-5,
  .mx-md-5 {
    margin-right: 1.5rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.5rem !important;
  }

  .ms-md-5,
  .mx-md-5 {
    margin-left: 1.5rem !important;
  }

  .m-md-6 {
    margin: 2rem !important;
  }

  .mt-md-6,
  .my-md-6 {
    margin-top: 2rem !important;
  }

  .me-md-6,
  .mx-md-6 {
    margin-right: 2rem !important;
  }

  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2rem !important;
  }

  .ms-md-6,
  .mx-md-6 {
    margin-left: 2rem !important;
  }

  .m-md-7 {
    margin: 3rem !important;
  }

  .mt-md-7,
  .my-md-7 {
    margin-top: 3rem !important;
  }

  .me-md-7,
  .mx-md-7 {
    margin-right: 3rem !important;
  }

  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3rem !important;
  }

  .ms-md-7,
  .mx-md-7 {
    margin-left: 3rem !important;
  }

  .m-md-8 {
    margin: 4rem !important;
  }

  .mt-md-8,
  .my-md-8 {
    margin-top: 4rem !important;
  }

  .me-md-8,
  .mx-md-8 {
    margin-right: 4rem !important;
  }

  .mb-md-8,
  .my-md-8 {
    margin-bottom: 4rem !important;
  }

  .ms-md-8,
  .mx-md-8 {
    margin-left: 4rem !important;
  }

  .m-md-9 {
    margin: 6rem !important;
  }

  .mt-md-9,
  .my-md-9 {
    margin-top: 6rem !important;
  }

  .me-md-9,
  .mx-md-9 {
    margin-right: 6rem !important;
  }

  .mb-md-9,
  .my-md-9 {
    margin-bottom: 6rem !important;
  }

  .ms-md-9,
  .mx-md-9 {
    margin-left: 6rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pe-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .ps-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pe-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .ps-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pe-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .ps-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 0.75rem !important;
  }

  .pe-md-3,
  .px-md-3 {
    padding-right: 0.75rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .ps-md-3,
  .px-md-3 {
    padding-left: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1rem !important;
  }

  .pe-md-4,
  .px-md-4 {
    padding-right: 1rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1rem !important;
  }

  .ps-md-4,
  .px-md-4 {
    padding-left: 1rem !important;
  }

  .p-md-5 {
    padding: 1.5rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 1.5rem !important;
  }

  .pe-md-5,
  .px-md-5 {
    padding-right: 1.5rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.5rem !important;
  }

  .ps-md-5,
  .px-md-5 {
    padding-left: 1.5rem !important;
  }

  .p-md-6 {
    padding: 2rem !important;
  }

  .pt-md-6,
  .py-md-6 {
    padding-top: 2rem !important;
  }

  .pe-md-6,
  .px-md-6 {
    padding-right: 2rem !important;
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2rem !important;
  }

  .ps-md-6,
  .px-md-6 {
    padding-left: 2rem !important;
  }

  .p-md-7 {
    padding: 3rem !important;
  }

  .pt-md-7,
  .py-md-7 {
    padding-top: 3rem !important;
  }

  .pe-md-7,
  .px-md-7 {
    padding-right: 3rem !important;
  }

  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3rem !important;
  }

  .ps-md-7,
  .px-md-7 {
    padding-left: 3rem !important;
  }

  .p-md-8 {
    padding: 4rem !important;
  }

  .pt-md-8,
  .py-md-8 {
    padding-top: 4rem !important;
  }

  .pe-md-8,
  .px-md-8 {
    padding-right: 4rem !important;
  }

  .pb-md-8,
  .py-md-8 {
    padding-bottom: 4rem !important;
  }

  .ps-md-8,
  .px-md-8 {
    padding-left: 4rem !important;
  }

  .p-md-9 {
    padding: 6rem !important;
  }

  .pt-md-9,
  .py-md-9 {
    padding-top: 6rem !important;
  }

  .pe-md-9,
  .px-md-9 {
    padding-right: 6rem !important;
  }

  .pb-md-9,
  .py-md-9 {
    padding-bottom: 6rem !important;
  }

  .ps-md-9,
  .px-md-9 {
    padding-left: 6rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .me-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-left {
    justify-content: flex-left !important;
  }

  .justify-content-md-right {
    justify-content: flex-right !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-left {
    align-items: flex-left !important;
  }

  .align-items-md-right {
    align-items: flex-right !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-left {
    align-content: flex-left !important;
  }

  .align-content-md-right {
    align-content: flex-right !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-left {
    align-self: flex-left !important;
  }

  .align-self-md-right {
    align-self: flex-right !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .me-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ms-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .me-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ms-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .me-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ms-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.75rem !important;
  }

  .me-lg-3,
  .mx-lg-3 {
    margin-right: 0.75rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .ms-lg-3,
  .mx-lg-3 {
    margin-left: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1rem !important;
  }

  .me-lg-4,
  .mx-lg-4 {
    margin-right: 1rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1rem !important;
  }

  .ms-lg-4,
  .mx-lg-4 {
    margin-left: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.5rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.5rem !important;
  }

  .me-lg-5,
  .mx-lg-5 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.5rem !important;
  }

  .ms-lg-5,
  .mx-lg-5 {
    margin-left: 1.5rem !important;
  }

  .m-lg-6 {
    margin: 2rem !important;
  }

  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2rem !important;
  }

  .me-lg-6,
  .mx-lg-6 {
    margin-right: 2rem !important;
  }

  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2rem !important;
  }

  .ms-lg-6,
  .mx-lg-6 {
    margin-left: 2rem !important;
  }

  .m-lg-7 {
    margin: 3rem !important;
  }

  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3rem !important;
  }

  .me-lg-7,
  .mx-lg-7 {
    margin-right: 3rem !important;
  }

  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3rem !important;
  }

  .ms-lg-7,
  .mx-lg-7 {
    margin-left: 3rem !important;
  }

  .m-lg-8 {
    margin: 4rem !important;
  }

  .mt-lg-8,
  .my-lg-8 {
    margin-top: 4rem !important;
  }

  .me-lg-8,
  .mx-lg-8 {
    margin-right: 4rem !important;
  }

  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 4rem !important;
  }

  .ms-lg-8,
  .mx-lg-8 {
    margin-left: 4rem !important;
  }

  .m-lg-9 {
    margin: 6rem !important;
  }

  .mt-lg-9,
  .my-lg-9 {
    margin-top: 6rem !important;
  }

  .me-lg-9,
  .mx-lg-9 {
    margin-right: 6rem !important;
  }

  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 6rem !important;
  }

  .ms-lg-9,
  .mx-lg-9 {
    margin-left: 6rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pe-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .ps-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pe-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .ps-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pe-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .ps-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pe-lg-3,
  .px-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .ps-lg-3,
  .px-lg-3 {
    padding-left: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1rem !important;
  }

  .pe-lg-4,
  .px-lg-4 {
    padding-right: 1rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1rem !important;
  }

  .ps-lg-4,
  .px-lg-4 {
    padding-left: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.5rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.5rem !important;
  }

  .pe-lg-5,
  .px-lg-5 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.5rem !important;
  }

  .ps-lg-5,
  .px-lg-5 {
    padding-left: 1.5rem !important;
  }

  .p-lg-6 {
    padding: 2rem !important;
  }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2rem !important;
  }

  .pe-lg-6,
  .px-lg-6 {
    padding-right: 2rem !important;
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2rem !important;
  }

  .ps-lg-6,
  .px-lg-6 {
    padding-left: 2rem !important;
  }

  .p-lg-7 {
    padding: 3rem !important;
  }

  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3rem !important;
  }

  .pe-lg-7,
  .px-lg-7 {
    padding-right: 3rem !important;
  }

  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-7,
  .px-lg-7 {
    padding-left: 3rem !important;
  }

  .p-lg-8 {
    padding: 4rem !important;
  }

  .pt-lg-8,
  .py-lg-8 {
    padding-top: 4rem !important;
  }

  .pe-lg-8,
  .px-lg-8 {
    padding-right: 4rem !important;
  }

  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 4rem !important;
  }

  .ps-lg-8,
  .px-lg-8 {
    padding-left: 4rem !important;
  }

  .p-lg-9 {
    padding: 6rem !important;
  }

  .pt-lg-9,
  .py-lg-9 {
    padding-top: 6rem !important;
  }

  .pe-lg-9,
  .px-lg-9 {
    padding-right: 6rem !important;
  }

  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 6rem !important;
  }

  .ps-lg-9,
  .px-lg-9 {
    padding-left: 6rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-left {
    justify-content: flex-left !important;
  }

  .justify-content-lg-right {
    justify-content: flex-right !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-left {
    align-items: flex-left !important;
  }

  .align-items-lg-right {
    align-items: flex-right !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-left {
    align-content: flex-left !important;
  }

  .align-content-lg-right {
    align-content: flex-right !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-left {
    align-self: flex-left !important;
  }

  .align-self-lg-right {
    align-self: flex-right !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1280px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .me-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ms-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .me-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ms-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .me-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ms-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.75rem !important;
  }

  .me-xl-3,
  .mx-xl-3 {
    margin-right: 0.75rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .ms-xl-3,
  .mx-xl-3 {
    margin-left: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1rem !important;
  }

  .me-xl-4,
  .mx-xl-4 {
    margin-right: 1rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1rem !important;
  }

  .ms-xl-4,
  .mx-xl-4 {
    margin-left: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.5rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.5rem !important;
  }

  .me-xl-5,
  .mx-xl-5 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.5rem !important;
  }

  .ms-xl-5,
  .mx-xl-5 {
    margin-left: 1.5rem !important;
  }

  .m-xl-6 {
    margin: 2rem !important;
  }

  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2rem !important;
  }

  .me-xl-6,
  .mx-xl-6 {
    margin-right: 2rem !important;
  }

  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2rem !important;
  }

  .ms-xl-6,
  .mx-xl-6 {
    margin-left: 2rem !important;
  }

  .m-xl-7 {
    margin: 3rem !important;
  }

  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3rem !important;
  }

  .me-xl-7,
  .mx-xl-7 {
    margin-right: 3rem !important;
  }

  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3rem !important;
  }

  .ms-xl-7,
  .mx-xl-7 {
    margin-left: 3rem !important;
  }

  .m-xl-8 {
    margin: 4rem !important;
  }

  .mt-xl-8,
  .my-xl-8 {
    margin-top: 4rem !important;
  }

  .me-xl-8,
  .mx-xl-8 {
    margin-right: 4rem !important;
  }

  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 4rem !important;
  }

  .ms-xl-8,
  .mx-xl-8 {
    margin-left: 4rem !important;
  }

  .m-xl-9 {
    margin: 6rem !important;
  }

  .mt-xl-9,
  .my-xl-9 {
    margin-top: 6rem !important;
  }

  .me-xl-9,
  .mx-xl-9 {
    margin-right: 6rem !important;
  }

  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 6rem !important;
  }

  .ms-xl-9,
  .mx-xl-9 {
    margin-left: 6rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pe-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .ps-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pe-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .ps-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pe-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .ps-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pe-xl-3,
  .px-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .ps-xl-3,
  .px-xl-3 {
    padding-left: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1rem !important;
  }

  .pe-xl-4,
  .px-xl-4 {
    padding-right: 1rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1rem !important;
  }

  .ps-xl-4,
  .px-xl-4 {
    padding-left: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.5rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.5rem !important;
  }

  .pe-xl-5,
  .px-xl-5 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.5rem !important;
  }

  .ps-xl-5,
  .px-xl-5 {
    padding-left: 1.5rem !important;
  }

  .p-xl-6 {
    padding: 2rem !important;
  }

  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2rem !important;
  }

  .pe-xl-6,
  .px-xl-6 {
    padding-right: 2rem !important;
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2rem !important;
  }

  .ps-xl-6,
  .px-xl-6 {
    padding-left: 2rem !important;
  }

  .p-xl-7 {
    padding: 3rem !important;
  }

  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3rem !important;
  }

  .pe-xl-7,
  .px-xl-7 {
    padding-right: 3rem !important;
  }

  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-7,
  .px-xl-7 {
    padding-left: 3rem !important;
  }

  .p-xl-8 {
    padding: 4rem !important;
  }

  .pt-xl-8,
  .py-xl-8 {
    padding-top: 4rem !important;
  }

  .pe-xl-8,
  .px-xl-8 {
    padding-right: 4rem !important;
  }

  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 4rem !important;
  }

  .ps-xl-8,
  .px-xl-8 {
    padding-left: 4rem !important;
  }

  .p-xl-9 {
    padding: 6rem !important;
  }

  .pt-xl-9,
  .py-xl-9 {
    padding-top: 6rem !important;
  }

  .pe-xl-9,
  .px-xl-9 {
    padding-right: 6rem !important;
  }

  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 6rem !important;
  }

  .ps-xl-9,
  .px-xl-9 {
    padding-left: 6rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-left {
    justify-content: flex-left !important;
  }

  .justify-content-xl-right {
    justify-content: flex-right !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-left {
    align-items: flex-left !important;
  }

  .align-items-xl-right {
    align-items: flex-right !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-left {
    align-content: flex-left !important;
  }

  .align-content-xl-right {
    align-content: flex-right !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-left {
    align-self: flex-left !important;
  }

  .align-self-xl-right {
    align-self: flex-right !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-end {
  float: right !important;
}

.float-start {
  float: left !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  top: 0;
}

.fixed-bottom,
.fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1030;
}

.fixed-bottom {
  bottom: 0;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  white-space: normal;
  width: auto;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.wd-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-48 {
  width: 48% !important;
}

.w-49 {
  width: 49% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-150 {
  width: 150px !important;
}

.w-auto {
  width: auto !important;
}

.w-0 {
  width: 0 !important;
}

.w-1 {
  width: 0.25rem !important;
}

.w-2 {
  width: 0.5rem !important;
}

.w-3 {
  width: 0.75rem !important;
}

.w-4 {
  width: 1rem !important;
}

.w-5 {
  width: 1.5rem !important;
}

.w-6 {
  width: 2rem !important;
}

.w-7 {
  width: 3rem !important;
}

.w-8 {
  width: 4rem !important;
}

.w-9 {
  width: 6rem !important;
}

.w-100h {
  width: 100px !important;
}

.w-200 {
  width: 200px !important;
}

.w-250 {
  width: 250px !important;
}

.w-300 {
  width: 300px !important;
}

.w-350 {
  width: 350px !important;
}

.w-400 {
  width: 400px !important;
}

.w-450 {
  width: 450px !important;
}

.w-500 {
  width: 500px !important;
}

.w-xs {
  min-width: 90px;
}

.w-sm {
  min-width: 110px;
}

.w-md {
  min-width: 130px;
}

.w-lg {
  min-width: 150px;
}

.ht-5 {
  height: 5% !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-50 {
  height: 50% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.h-100vh {
  height: 100vh !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-0 {
  height: 0 !important;
}

.h-1 {
  height: 0.25rem !important;
}

.h-2 {
  height: 0.5rem !important;
}

.h-3 {
  height: 0.75rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-5 {
  height: 1.5rem !important;
}

.h-6 {
  height: 2rem !important;
}

.h-7 {
  height: 3rem !important;
}

.h-8 {
  height: 4rem !important;
}

.h-9 {
  height: 6rem !important;
}

.h-100h {
  height: 100px !important;
}

.h-130 {
  height: 130px !important;
}

.h-140 {
  height: 140px !important;
}

.h-150 {
  height: 150px !important;
}

.h-160 {
  height: 160px !important;
}

.h-170 {
  height: 170px !important;
}

.h-180 {
  height: 180px !important;
}

.h-200 {
  height: 200px !important;
}

.h-250 {
  height: 250px !important;
}

.h-300 {
  height: 300px !important;
}

.h-350 {
  height: 350px !important;
}

.h-370 {
  height: 370px !important;
}

.h-400 {
  height: 400px !important;
}

.h-420 {
  height: 420px !important;
}

.h-450 {
  height: 450px !important;
}

.h-500 {
  height: 500px !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.mh-200 {
  max-height: 200px !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.me-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ms-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.me-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ms-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.me-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ms-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

/* .mt-3,
.my-3 {
  margin-top: 0.75rem !important;
} */

.me-3,
.mx-3 {
  margin-right: 0.75rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important;
}

.ms-3,
.mx-3 {
  margin-left: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1rem !important;
}

.me-4,
.mx-4 {
  margin-right: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1rem !important;
}

.ms-4,
.mx-4 {
  margin-left: 1rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 1.5rem !important;
}

.me-5,
.mx-5 {
  margin-right: 1.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 1.5rem !important;
}

.ms-5,
.mx-5 {
  margin-left: 1.5rem !important;
}

.m-6 {
  margin: 2rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2rem !important;
}

.me-6,
.mx-6 {
  margin-right: 2rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2rem !important;
}

.ms-6,
.mx-6 {
  margin-left: 2rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.me-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ms-7,
.mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 4rem !important;
}

.mt-8,
.my-8 {
  margin-top: 4rem !important;
}

.me-8,
.mx-8 {
  margin-right: 4rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 4rem !important;
}

.ms-8,
.mx-8 {
  margin-left: 4rem !important;
}

.m-9 {
  margin: 6rem !important;
}

.mt-9,
.my-9 {
  margin-top: 6rem !important;
}

.me-9,
.mx-9 {
  margin-right: 6rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 6rem !important;
}

.ms-9,
.mx-9 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pe-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.ps-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pe-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.ps-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pe-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.ps-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.75rem !important;
}

.pe-3,
.px-3 {
  padding-right: 0.75rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.75rem !important;
}

.ps-3,
.px-3 {
  padding-left: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1rem !important;
}

.pe-4,
.px-4 {
  padding-right: 1rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1rem !important;
}

.ps-4,
.px-4 {
  padding-left: 1rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 1.5rem !important;
}

.pe-5,
.px-5 {
  padding-right: 1.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 1.5rem !important;
}

.ps-5,
.px-5 {
  padding-left: 1.5rem !important;
}

.p-6 {
  padding: 2rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2rem !important;
}

.pe-6,
.px-6 {
  padding-right: 2rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2rem !important;
}

.ps-6,
.px-6 {
  padding-left: 2rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3rem !important;
}

.pe-7,
.px-7 {
  padding-right: 3rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}

.ps-7,
.px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 4rem !important;
}

.pt-8,
.py-8 {
  padding-top: 4rem !important;
}

.pe-8,
.px-8 {
  padding-right: 4rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 4rem !important;
}

.ps-8,
.px-8 {
  padding-left: 4rem !important;
}

.p-9 {
  padding: 6rem !important;
}

.pt-9,
.py-9 {
  padding-top: 6rem !important;
}

.pe-9,
.px-9 {
  padding-right: 6rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 6rem !important;
}

.ps-9,
.px-9 {
  padding-left: 6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.me-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ms-auto,
.mx-auto {
  margin-left: auto !important;
}

.text-monospace {
  font-family: Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-normal1 {
  font-weight: 600 !important;
}

.font-weight-semibold {
  font-weight: 700 !important;
}

.font-weight-bold {
  font-weight: 800 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #f0f0f0 !important;
}

.text-success {
  color: #38cb89 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #15ca7c !important;
}

.text-info {
  color: #3e80eb !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #25a1c0 !important;
}

.text-warning {
  color: #ffab00 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #c29d0b !important;
}

.text-danger {
  color: #ef4b4b !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #df0812 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #384b6e !important;
}

.text-body {
  color: #495057 !important;
}

.text-muted {
  color: #98a6be !important;
}

.icon-muted {
  color: #e2e2e9 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: hsla(0, 0%, 100%, 0.5) !important;
}

.text-white-80 {
  color: hsla(0, 0%, 100%, 0.8) !important;
}

.text-hide {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: 0/0 a;
  text-shadow: none;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .container {
    max-width: none;
  }

  *,
  :after,
  :before {
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  .container,
  body {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table,
  .text-wrap table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th,
  .text-wrap table td,
  .text-wrap table th {
    background-color: #fff !important;
  }

  .table-bordered td,
  .table-bordered th,
  .text-wrap table td,
  .text-wrap table th {
    border: 1px solid #e6ebf1 !important;
  }
}

.lead {
  line-height: 1.4;
}

a {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip: ink;
}

.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

b,
strong {
  font-weight: 600;
}

/* blockquote,
ol,
p,
ul {
  margin-bottom: 1em;
} */

blockquote {
  border-left: 2px solid rgba(0, 40, 100, 0.12);
  color: #6e7687;
  font-style: italic;
  padding-left: 2rem;
}

blockquote p {
  margin-bottom: 1rem;
}

blockquote cite {
  display: block;
  text-align: right;
}

blockquote cite:before {
  content: "â€” ";
}

code {
  background: #f0f0f2;
  border: 1px solid #e6ebf1;
  border-radius: 3px;
  padding: 3px;
}

pre code {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
}

hr {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

pre {
  background-color: #f8fafc;
  border-radius: 3px;
  color: #3e80eb;
  font-size: 85%;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  line-height: 1.45;
  overflow: auto;
  padding: 1.5rem;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}

img {
  max-width: 100%;
}

.text-wrap {
  font-size: 14px;
  line-height: 1.66;
}

.text-wrap > :first-child {
  margin-top: 0;
}

.text-wrap > :last-child {
  margin-bottom: 0;
}

.text-wrap > h1,
.text-wrap > h2,
.text-wrap > h3,
.text-wrap > h4,
.text-wrap > h5,
.text-wrap > h6 {
  margin-top: 1em;
}

.section-nav {
  background-color: #f8f9fa;
  border: 1px solid #e6ebf1;
  border-radius: 3px;
  list-style: none;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
}

.section-nav:before {
  content: "Table of contents:";
  display: block;
  font-weight: 600;
}

.row-cards > .col,
.row-cards > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

.row-deck > .col,
.row-deck > [class*="col-"] {
  align-items: stretch;
  display: flex;
}

.row-deck > .col .card,
.row-deck > [class*="col-"] .card {
  flex: 1 1 auto;
}

.col-text {
  max-width: 48rem;
}

.col-login {
  max-width: 24rem;
}

.gutters-0 {
  margin-left: 0;
  margin-right: 0;
}

.gutters-0 > .col,
.gutters-0 > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.gutters-0 .card {
  margin-bottom: 0;
}

.gutters-xs {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.gutters-xs > .col,
.gutters-xs > [class*="col-"] {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.gutters-xs .card {
  margin-bottom: 0.5rem;
}

.gutters-sm {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.gutters-sm > .col,
.gutters-sm > [class*="col-"] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.gutters-sm .card {
  margin-bottom: 1rem;
}

.gutters-lg {
  margin-left: -1rem;
  margin-right: -1rem;
}

.gutters-lg > .col,
.gutters-lg > [class*="col-"] {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gutters-lg .card {
  margin-bottom: 2rem;
}

.gutters-xl {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.gutters-xl > .col,
.gutters-xl > [class*="col-"] {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gutters-xl .card {
  margin-bottom: 3rem;
}

.page {
  min-height: 100vh;
}

.page,
.page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-content {
  margin: 0.75rem 0;
  min-height: 100%;
}

body.fixed-header .page {
  padding-top: 4.5rem;
}

@media (min-width: 1600px) {
  body.aside-opened .page {
    margin-right: 22rem;
  }
}

.page-main {
  flex: 1 1 auto;
}

.pos-absolute {
  border: 0;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.bg-blue-lightest {
  background-color: #edf2fa !important;
}

a.bg-blue-lightest:focus,
a.bg-blue-lightest:hover,
button.bg-blue-lightest:focus,
button.bg-blue-lightest:hover {
  background-color: #c5d5ef !important;
}

.bg-blue-lighter {
  background-color: #c8d9f1 !important;
}

a.bg-blue-lighter:focus,
a.bg-blue-lighter:hover,
button.bg-blue-lighter:focus,
button.bg-blue-lighter:hover {
  background-color: #9fbde7 !important;
}

.bg-blue-light {
  background-color: #7ea5dd !important;
}

a.bg-blue-light:focus,
a.bg-blue-light:hover,
button.bg-blue-light:focus,
button.bg-blue-light:hover {
  background-color: #5689d2 !important;
}

.bg-blue-dark {
  background-color: #3866a6 !important;
}

a.bg-blue-dark:focus,
a.bg-blue-dark:hover,
button.bg-blue-dark:focus,
button.bg-blue-dark:hover {
  background-color: #2b4f80 !important;
}

.bg-blue-darker {
  background-color: #1c3353 !important;
}

a.bg-blue-darker:focus,
a.bg-blue-darker:hover,
button.bg-blue-darker:focus,
button.bg-blue-darker:hover {
  background-color: #0f1c2d !important;
}

.bg-blue-darkest {
  background-color: #0e1929 !important;
}

a.bg-blue-darkest:focus,
a.bg-blue-darkest:hover,
button.bg-blue-darkest:focus,
button.bg-blue-darkest:hover {
  background-color: #010203 !important;
}

.bg-purssianblue {
  background-color: #362f71;
}

a.bg-purssianblue-lightest:focus,
a.bg-purssianblue-lightest:hover,
button.bg-purssianblue-lightest:focus,
button.bg-purssianblue-lightest:hover {
  background-color: #3f3688 !important;
}

.bg-indigo-lightest {
  background-color: #f0f1fa !important;
}

a.bg-indigo-lightest:focus,
a.bg-indigo-lightest:hover,
button.bg-indigo-lightest:focus,
button.bg-indigo-lightest:hover {
  background-color: #cacded !important;
}

.bg-indigo-lighter {
  background-color: #d1d5f0 !important;
}

a.bg-indigo-lighter:focus,
a.bg-indigo-lighter:hover,
button.bg-indigo-lighter:focus,
button.bg-indigo-lighter:hover {
  background-color: #abb2e3 !important;
}

.bg-indigo-light {
  background-color: #939edc !important;
}

a.bg-indigo-light:focus,
a.bg-indigo-light:hover,
button.bg-indigo-light:focus,
button.bg-indigo-light:hover {
  background-color: #6c7bd0 !important;
}

.bg-indigo-dark {
  background-color: #515da4 !important;
}

a.bg-indigo-dark:focus,
a.bg-indigo-dark:hover,
button.bg-indigo-dark:focus,
button.bg-indigo-dark:hover {
  background-color: #404a82 !important;
}

.bg-indigo-darker {
  background-color: #282e52 !important;
}

a.bg-indigo-darker:focus,
a.bg-indigo-darker:hover,
button.bg-indigo-darker:focus,
button.bg-indigo-darker:hover {
  background-color: #171b30 !important;
}

.bg-indigo-darkest {
  background-color: #141729 !important;
}

a.bg-indigo-darkest:focus,
a.bg-indigo-darkest:hover,
button.bg-indigo-darkest:focus,
button.bg-indigo-darkest:hover {
  background-color: #030407 !important;
}

.bg-purple-lightest {
  background-color: #f6effd !important;
}

a.bg-purple-lightest:focus,
a.bg-purple-lightest:hover,
button.bg-purple-lightest:focus,
button.bg-purple-lightest:hover {
  background-color: #ddc2f7 !important;
}

.bg-purple-lighter {
  background-color: #e4cff9 !important;
}

a.bg-purple-lighter:focus,
a.bg-purple-lighter:hover,
button.bg-purple-lighter:focus,
button.bg-purple-lighter:hover {
  background-color: #cba2f3 !important;
}

.bg-purple-light {
  background-color: #c08ef0 !important;
}

a.bg-purple-light:focus,
a.bg-purple-light:hover,
button.bg-purple-light:focus,
button.bg-purple-light:hover {
  background-color: #a761ea !important;
}

.bg-purple-dark {
  background-color: #844bbb !important;
}

a.bg-purple-dark:focus,
a.bg-purple-dark:hover,
button.bg-purple-dark:focus,
button.bg-purple-dark:hover {
  background-color: #6a3a99 !important;
}

.bg-purple-darker {
  background-color: #42265e !important;
}

a.bg-purple-darker:focus,
a.bg-purple-darker:hover,
button.bg-purple-darker:focus,
button.bg-purple-darker:hover {
  background-color: #29173a !important;
}

.bg-purple-darkest {
  background-color: #21132f !important;
}

a.bg-purple-darkest:focus,
a.bg-purple-darkest:hover,
button.bg-purple-darkest:focus,
button.bg-purple-darkest:hover {
  background-color: #08040b !important;
}

.bg-pink-lightest {
  background-color: #fef0f5 !important;
}

a.bg-pink-lightest:focus,
a.bg-pink-lightest:hover,
button.bg-pink-lightest:focus,
button.bg-pink-lightest:hover {
  background-color: #fbc0d5 !important;
}

.bg-pink-lighter {
  background-color: #fcd3e1 !important;
}

a.bg-pink-lighter:focus,
a.bg-pink-lighter:hover,
button.bg-pink-lighter:focus,
button.bg-pink-lighter:hover {
  background-color: #f9a3c0 !important;
}

.bg-pink-light {
  background-color: #f999b9 !important;
}

a.bg-pink-light:focus,
a.bg-pink-light:hover,
button.bg-pink-light:focus,
button.bg-pink-light:hover {
  background-color: #f66998 !important;
}

.bg-pink-dark {
  background-color: #c5577c !important;
}

a.bg-pink-dark:focus,
a.bg-pink-dark:hover,
button.bg-pink-dark:focus,
button.bg-pink-dark:hover {
  background-color: #ad3c62 !important;
}

.bg-pink-darker {
  background-color: #622c3e !important;
}

a.bg-pink-darker:focus,
a.bg-pink-darker:hover,
button.bg-pink-darker:focus,
button.bg-pink-darker:hover {
  background-color: #3f1c28 !important;
}

.bg-pink-darkest {
  background-color: #31161f !important;
}

a.bg-pink-darkest:focus,
a.bg-pink-darkest:hover,
button.bg-pink-darkest:focus,
button.bg-pink-darkest:hover {
  background-color: #0e0609 !important;
}

.bg-red-lightest {
  background-color: #fae9e9 !important;
}

a.bg-red-lightest:focus,
a.bg-red-lightest:hover,
button.bg-red-lightest:focus,
button.bg-red-lightest:hover {
  background-color: #f1bfbf !important;
}

.bg-red-lighter {
  background-color: #f0bcbc !important;
}

a.bg-red-lighter:focus,
a.bg-red-lighter:hover,
button.bg-red-lighter:focus,
button.bg-red-lighter:hover {
  background-color: #e79292 !important;
}

.bg-red-light {
  background-color: #dc6362 !important;
}

a.bg-red-light:focus,
a.bg-red-light:hover,
button.bg-red-light:focus,
button.bg-red-light:hover {
  background-color: #d33a38 !important;
}

.bg-red-dark {
  background-color: #a41a19 !important;
}

a.bg-red-dark:focus,
a.bg-red-dark:hover,
button.bg-red-dark:focus,
button.bg-red-dark:hover {
  background-color: #781312 !important;
}

.bg-red-darker {
  background-color: #520d0c !important;
}

a.bg-red-darker:focus,
a.bg-red-darker:hover,
button.bg-red-darker:focus,
button.bg-red-darker:hover {
  background-color: #260605 !important;
}

.bg-red-darkest {
  background-color: #290606 !important;
}

a.bg-red-darkest:focus,
a.bg-red-darkest:hover,
button.bg-red-darkest:focus,
button.bg-red-darkest:hover {
  background-color: #000 !important;
}

.bg-orange-lightest {
  background-color: #fff !important;
}

a.bg-orange-lightest:focus,
a.bg-orange-lightest:hover,
button.bg-orange-lightest:focus,
button.bg-orange-lightest:hover {
  background-color: #ffdab9 !important;
}

.bg-orange-lighter {
  background-color: #fee0c7 !important;
}

a.bg-orange-lighter:focus,
a.bg-orange-lighter:hover,
button.bg-orange-lighter:focus,
button.bg-orange-lighter:hover {
  background-color: #fdc495 !important;
}

.bg-orange-light {
  background-color: #feb67c !important;
}

a.bg-orange-light:focus,
a.bg-orange-light:hover,
button.bg-orange-light:focus,
button.bg-orange-light:hover {
  background-color: #fe9a49 !important;
}

.bg-orange-dark {
  background-color: #ca7836 !important;
}

a.bg-orange-dark:focus,
a.bg-orange-dark:hover,
button.bg-orange-dark:focus,
button.bg-orange-dark:hover {
  background-color: #a2602b !important;
}

.bg-orange-darker {
  background-color: #653c1b !important;
}

a.bg-orange-darker:focus,
a.bg-orange-darker:hover,
button.bg-orange-darker:focus,
button.bg-orange-darker:hover {
  background-color: #3d2410 !important;
}

.bg-orange-darkest {
  background-color: #331e0e !important;
}

a.bg-orange-darkest:focus,
a.bg-orange-darkest:hover,
button.bg-orange-darkest:focus,
button.bg-orange-darkest:hover {
  background-color: #0b0603 !important;
}

.bg-yellow-lightest {
  background-color: #fef9e7 !important;
}

a.bg-yellow-lightest:focus,
a.bg-yellow-lightest:hover,
button.bg-yellow-lightest:focus,
button.bg-yellow-lightest:hover {
  background-color: #fcedb6 !important;
}

.bg-yellow-lighter {
  background-color: #fbedb7 !important;
}

a.bg-yellow-lighter:focus,
a.bg-yellow-lighter:hover,
button.bg-yellow-lighter:focus,
button.bg-yellow-lighter:hover {
  background-color: #f8e187 !important;
}

.bg-yellow-light {
  background-color: #f5d657 !important;
}

a.bg-yellow-light:focus,
a.bg-yellow-light:hover,
button.bg-yellow-light:focus,
button.bg-yellow-light:hover {
  background-color: #f2ca27 !important;
}

.bg-yellow-dark {
  background-color: #c19d0c !important;
}

a.bg-yellow-dark:focus,
a.bg-yellow-dark:hover,
button.bg-yellow-dark:focus,
button.bg-yellow-dark:hover {
  background-color: #917609 !important;
}

.bg-yellow-darker {
  background-color: #604e06 !important;
}

.bg-yellow-darkest,
a.bg-yellow-darker:focus,
a.bg-yellow-darker:hover,
button.bg-yellow-darker:focus,
button.bg-yellow-darker:hover {
  background-color: #302703 !important;
}

a.bg-yellow-darkest:focus,
a.bg-yellow-darkest:hover,
button.bg-yellow-darkest:focus,
button.bg-yellow-darkest:hover {
  background-color: #000 !important;
}

.bg-green-lightest {
  background-color: #eff8e6 !important;
}

a.bg-green-lightest:focus,
a.bg-green-lightest:hover,
button.bg-green-lightest:focus,
button.bg-green-lightest:hover {
  background-color: #d6edbe !important;
}

.bg-green-lighter {
  background-color: #cfeab3 !important;
}

a.bg-green-lighter:focus,
a.bg-green-lighter:hover,
button.bg-green-lighter:focus,
button.bg-green-lighter:hover {
  background-color: #b6df8b !important;
}

.bg-green-light {
  background-color: #8ecf4d !important;
}

a.bg-green-light:focus,
a.bg-green-light:hover,
button.bg-green-light:focus,
button.bg-green-light:hover {
  background-color: #75b831 !important;
}

.bg-green-dark {
  background-color: #4b9500 !important;
}

a.bg-green-dark:focus,
a.bg-green-dark:hover,
button.bg-green-dark:focus,
button.bg-green-dark:hover {
  background-color: #316200 !important;
}

.bg-green-darker {
  background-color: #264a00 !important;
}

a.bg-green-darker:focus,
a.bg-green-darker:hover,
button.bg-green-darker:focus,
button.bg-green-darker:hover {
  background-color: #0c1700 !important;
}

.bg-green-darkest {
  background-color: #132500 !important;
}

a.bg-green-darkest:focus,
a.bg-green-darkest:hover,
button.bg-green-darkest:focus,
button.bg-green-darkest:hover {
  background-color: #000 !important;
}

.bg-teal-lightest {
  background-color: #eafaf8 !important;
}

a.bg-teal-lightest:focus,
a.bg-teal-lightest:hover,
button.bg-teal-lightest:focus,
button.bg-teal-lightest:hover {
  background-color: #c1f0ea !important;
}

.bg-teal-lighter {
  background-color: #bfefea !important;
}

a.bg-teal-lighter:focus,
a.bg-teal-lighter:hover,
button.bg-teal-lighter:focus,
button.bg-teal-lighter:hover {
  background-color: #96e5dd !important;
}

.bg-teal-light {
  background-color: #6bdbcf !important;
}

a.bg-teal-light:focus,
a.bg-teal-light:hover,
button.bg-teal-light:focus,
button.bg-teal-light:hover {
  background-color: #42d1c2 !important;
}

.bg-teal-dark {
  background-color: #22a295 !important;
}

a.bg-teal-dark:focus,
a.bg-teal-dark:hover,
button.bg-teal-dark:focus,
button.bg-teal-dark:hover {
  background-color: #19786e !important;
}

.bg-teal-darker {
  background-color: #11514a !important;
}

a.bg-teal-darker:focus,
a.bg-teal-darker:hover,
button.bg-teal-darker:focus,
button.bg-teal-darker:hover {
  background-color: #082723 !important;
}

.bg-teal-darkest {
  background-color: #092925 !important;
}

a.bg-teal-darkest:focus,
a.bg-teal-darkest:hover,
button.bg-teal-darkest:focus,
button.bg-teal-darkest:hover {
  background-color: #000 !important;
}

.bg-cyan-lightest {
  background-color: #e8f6f8 !important;
}

a.bg-cyan-lightest:focus,
a.bg-cyan-lightest:hover,
button.bg-cyan-lightest:focus,
button.bg-cyan-lightest:hover {
  background-color: #c1e7ec !important;
}

.bg-cyan-lighter {
  background-color: #b9e3ea !important;
}

a.bg-cyan-lighter:focus,
a.bg-cyan-lighter:hover,
button.bg-cyan-lighter:focus,
button.bg-cyan-lighter:hover {
  background-color: #92d3de !important;
}

.bg-cyan-light {
  background-color: #5dbecd !important;
}

a.bg-cyan-light:focus,
a.bg-cyan-light:hover,
button.bg-cyan-light:focus,
button.bg-cyan-light:hover {
  background-color: #3aabbd !important;
}

.bg-cyan-dark {
  background-color: #128293 !important;
}

a.bg-cyan-dark:focus,
a.bg-cyan-dark:hover,
button.bg-cyan-dark:focus,
button.bg-cyan-dark:hover {
  background-color: #0c5a66 !important;
}

.bg-cyan-darker {
  background-color: #09414a !important;
}

a.bg-cyan-darker:focus,
a.bg-cyan-darker:hover,
button.bg-cyan-darker:focus,
button.bg-cyan-darker:hover {
  background-color: #03191d !important;
}

.bg-cyan-darkest {
  background-color: #052025 !important;
}

a.bg-cyan-darkest:focus,
a.bg-cyan-darkest:hover,
button.bg-cyan-darkest:focus,
button.bg-cyan-darkest:hover {
  background-color: #000 !important;
}

.bg-white-lightest {
  background-color: #fff !important;
}

a.bg-white-lightest:focus,
a.bg-white-lightest:hover,
button.bg-white-lightest:focus,
button.bg-white-lightest:hover {
  background-color: #e6e5e5 !important;
}

.bg-white-lighter {
  background-color: #fff !important;
}

a.bg-white-lighter:focus,
a.bg-white-lighter:hover,
button.bg-white-lighter:focus,
button.bg-white-lighter:hover {
  background-color: #e6e5e5 !important;
}

.bg-white-light {
  background-color: #fff !important;
}

a.bg-white-light:focus,
a.bg-white-light:hover,
button.bg-white-light:focus,
button.bg-white-light:hover {
  background-color: #e6e5e5 !important;
}

.bg-white-dark {
  background-color: #ccc !important;
}

a.bg-white-dark:focus,
a.bg-white-dark:hover,
button.bg-white-dark:focus,
button.bg-white-dark:hover {
  background-color: #b3b2b2 !important;
}

.bg-white-darker {
  background-color: #666 !important;
}

a.bg-white-darker:focus,
a.bg-white-darker:hover,
button.bg-white-darker:focus,
button.bg-white-darker:hover {
  background-color: #4d4c4c !important;
}

.bg-white-darkest {
  background-color: #333 !important;
}

a.bg-white-darkest:focus,
a.bg-white-darkest:hover,
button.bg-white-darkest:focus,
button.bg-white-darkest:hover {
  background-color: #1a1919 !important;
}

.bg-gray-lightest {
  background-color: #f3f4f5 !important;
}

a.bg-gray-lightest:focus,
a.bg-gray-lightest:hover,
button.bg-gray-lightest:focus,
button.bg-gray-lightest:hover {
  background-color: #d7dbde !important;
}

.bg-gray-lighter {
  background-color: #dbdde0 !important;
}

a.bg-gray-lighter:focus,
a.bg-gray-lighter:hover,
button.bg-gray-lighter:focus,
button.bg-gray-lighter:hover {
  background-color: #c0c3c8 !important;
}

.bg-gray-light {
  background-color: #aab0b6 !important;
}

a.bg-gray-light:focus,
a.bg-gray-light:hover,
button.bg-gray-light:focus,
button.bg-gray-light:hover {
  background-color: #8f979e !important;
}

.bg-gray-dark {
  background-color: #6b7278 !important;
  background-color: #343a40 !important;
}

a.bg-gray-dark:focus,
a.bg-gray-dark:hover,
button.bg-gray-dark:focus,
button.bg-gray-dark:hover {
  background-color: #53585d !important;
  background-color: #1d2124 !important;
}

.bg-gray-darker {
  background-color: #36393c !important;
}

a.bg-gray-darker:focus,
a.bg-gray-darker:hover,
button.bg-gray-darker:focus,
button.bg-gray-darker:hover {
  background-color: #1e2021 !important;
}

.bg-gray-darkest {
  background-color: #1b1c1e !important;
}

a.bg-gray-darkest:focus,
a.bg-gray-darkest:hover,
button.bg-gray-darkest:focus,
button.bg-gray-darkest:hover {
  background-color: #030303 !important;
}

.bg-gray-dark-lightest {
  background-color: #ebebec !important;
}

a.bg-gray-dark-lightest:focus,
a.bg-gray-dark-lightest:hover,
button.bg-gray-dark-lightest:focus,
button.bg-gray-dark-lightest:hover {
  background-color: #d1d1d3 !important;
}

.bg-gray-dark-lighter {
  background-color: #c2c4c6 !important;
}

a.bg-gray-dark-lighter:focus,
a.bg-gray-dark-lighter:hover,
button.bg-gray-dark-lighter:focus,
button.bg-gray-dark-lighter:hover {
  background-color: #a8abad !important;
}

.bg-gray-dark-light {
  background-color: #717579 !important;
}

a.bg-gray-dark-light:focus,
a.bg-gray-dark-light:hover,
button.bg-gray-dark-light:focus,
button.bg-gray-dark-light:hover {
  background-color: #585c5f !important;
}

.bg-gray-dark-dark {
  background-color: #2a2e33 !important;
}

a.bg-gray-dark-dark:focus,
a.bg-gray-dark-dark:hover,
button.bg-gray-dark-dark:focus,
button.bg-gray-dark-dark:hover {
  background-color: #131517 !important;
}

.bg-gray-dark-darker {
  background-color: #15171a !important;
}

a.bg-gray-dark-darker:focus,
a.bg-gray-dark-darker:hover,
button.bg-gray-dark-darker:focus,
button.bg-gray-dark-darker:hover {
  background-color: #000 !important;
}

.bg-gray-dark-darkest {
  background-color: #0a0c0d !important;
}

a.bg-gray-dark-darkest:focus,
a.bg-gray-dark-darkest:hover,
button.bg-gray-dark-darkest:focus,
button.bg-gray-dark-darkest:hover {
  background-color: #000 !important;
}

.bg-azure-lightest {
  background-color: #ecf7fe !important;
}

a.bg-azure-lightest:focus,
a.bg-azure-lightest:hover,
button.bg-azure-lightest:focus,
button.bg-azure-lightest:hover {
  background-color: #bce3fb !important;
}

.bg-azure-lighter {
  background-color: #c7e6fb !important;
}

a.bg-azure-lighter:focus,
a.bg-azure-lighter:hover,
button.bg-azure-lighter:focus,
button.bg-azure-lighter:hover {
  background-color: #97d1f8 !important;
}

.bg-azure-light {
  background-color: #7dc4f6 !important;
}

a.bg-azure-light:focus,
a.bg-azure-light:hover,
button.bg-azure-light:focus,
button.bg-azure-light:hover {
  background-color: #4daef3 !important;
}

.bg-azure-dark {
  background-color: #3788c2 !important;
}

a.bg-azure-dark:focus,
a.bg-azure-dark:hover,
button.bg-azure-dark:focus,
button.bg-azure-dark:hover {
  background-color: #2c6c9a !important;
}

.bg-azure-darker {
  background-color: #1c4461 !important;
}

a.bg-azure-darker:focus,
a.bg-azure-darker:hover,
button.bg-azure-darker:focus,
button.bg-azure-darker:hover {
  background-color: #112839 !important;
}

.bg-azure-darkest {
  background-color: #0e2230 !important;
}

a.bg-azure-darkest:focus,
a.bg-azure-darkest:hover,
button.bg-azure-darkest:focus,
button.bg-azure-darkest:hover {
  background-color: #020609 !important;
}

.bg-lime-lightest {
  background-color: #f2fbeb !important;
}

a.bg-lime-lightest:focus,
a.bg-lime-lightest:hover,
button.bg-lime-lightest:focus,
button.bg-lime-lightest:hover {
  background-color: #d6f3c1 !important;
}

.bg-lime-lighter {
  background-color: #d7f2c2 !important;
}

a.bg-lime-lighter:focus,
a.bg-lime-lighter:hover,
button.bg-lime-lighter:focus,
button.bg-lime-lighter:hover {
  background-color: #bbe998 !important;
}

.bg-lime-light {
  background-color: #a3e072 !important;
}

a.bg-lime-light:focus,
a.bg-lime-light:hover,
button.bg-lime-light:focus,
button.bg-lime-light:hover {
  background-color: #88d748 !important;
}

.bg-lime-dark {
  background-color: #62a82a !important;
}

a.bg-lime-dark:focus,
a.bg-lime-dark:hover,
button.bg-lime-dark:focus,
button.bg-lime-dark:hover {
  background-color: #4a7f20 !important;
}

.bg-lime-darker {
  background-color: #315415 !important;
}

a.bg-lime-darker:focus,
a.bg-lime-darker:hover,
button.bg-lime-darker:focus,
button.bg-lime-darker:hover {
  background-color: #192b0b !important;
}

.bg-lime-darkest {
  background-color: #192a0b !important;
}

a.bg-lime-darkest:focus,
a.bg-lime-darkest:hover,
button.bg-lime-darkest:focus,
button.bg-lime-darkest:hover {
  background-color: #010200 !important;
}

.display-1 i,
.display-2 i,
.display-3 i,
.display-4 i {
  font-size: 0.815em;
  vertical-align: baseline;
}

.text-inherit {
  color: inherit !important;
}

.text-default {
  color: #4e5761 !important;
}

.text-muted-dark {
  color: #6e7687 !important;
}

.tracking-tight {
  letter-spacing: -0.05em !important;
}

.tracking-normal {
  letter-spacing: 0 !important;
}

.tracking-wide {
  letter-spacing: 0.05em !important;
}

.leading-none {
  line-height: 1 !important;
}

.leading-tight {
  line-height: 1.25 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

.leading-loose {
  line-height: 2 !important;
}

.bg-blue {
  background-color: #083dc1 !important;
  color: #fff !important;
}

a.bg-blue:focus,
a.bg-blue:hover,
button.bg-blue:focus,
button.bg-blue:hover {
  background-color: #1747bd !important;
}

.text-blue {
  color: #083dc1 !important;
}

.bg-indigo {
  background-color: #5323a7 !important;
  color: #fff !important;
}

a.bg-indigo:focus,
a.bg-indigo:hover,
button.bg-indigo:focus,
button.bg-indigo:hover {
  background-color: #3f51c1 !important;
}

.text-indigo {
  color: #5323a7 !important;
}

.bg-purple {
  background-color: #920bfa !important;
  color: #fff !important;
}

a.bg-purple:focus,
a.bg-purple:hover,
button.bg-purple:focus,
button.bg-purple:hover {
  background-color: #8c31e4 !important;
}

.text-purple {
  color: #920bfa !important;
}

.text-lightpink-red {
  color: #ff7088 !important;
}

.text-lightgreen {
  color: #26eda2 !important;
}

.bg-pink {
  background-color: #bb08c0 !important;
  color: #fff !important;
}

.bg-darkpink {
  background-color: #14a485 !important;
  color: #fff !important;
}

a.bg-pink:focus,
a.bg-pink:hover,
button.bg-pink:focus,
button.bg-pink:hover {
  background-color: #f33d7a !important;
}

.text-pink {
  color: #bb08c0 !important;
}

.bg-red {
  background-color: #dc0441 !important;
  color: #fff !important;
}

a.bg-red:focus,
a.bg-red:hover,
button.bg-red:focus,
button.bg-red:hover {
  background-color: #a11918 !important;
}

.text-red {
  color: #dc0441 !important;
}

.bg-orange {
  background-color: #fc7303 !important;
  color: #fff !important;
}

a.bg-orange:focus,
a.bg-orange:hover,
button.bg-orange:focus,
button.bg-orange:hover {
  background-color: #fc7a12 !important;
}

.text-orange {
  color: #fc7303 !important;
}

.bg-yellow {
  background-color: #ecb403 !important;
  color: #fff !important;
}

a.bg-yellow:focus,
a.bg-yellow:hover,
button.bg-yellow:focus,
button.bg-yellow:hover {
  background-color: #c29d0b !important;
}

.text-yellow {
  color: #ecb403 !important;
}

.bg-green {
  background-color: #2dce89 !important;
  color: #fff !important;
}

a.bg-green:focus,
a.bg-green:hover,
button.bg-green:focus,
button.bg-green:hover {
  background-color: #15ca7c !important;
}

.text-green {
  color: #2dce89 !important;
}

.bg-teal {
  background-color: #06c0d9 !important;
}

a.bg-teal:focus,
a.bg-teal:hover,
button.bg-teal:focus,
button.bg-teal:hover {
  background-color: #22a193 !important;
}

.text-teal {
  color: #06c0d9 !important;
}

.bg-cyan {
  background-color: #116e7c !important;
  color: #fff !important;
}

a.bg-cyan:focus,
a.bg-cyan:hover,
button.bg-cyan:focus,
button.bg-cyan:hover {
  background-color: #117a8b !important;
}

.text-cyan {
  color: #116e7c !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:focus,
a.bg-white:hover,
button.bg-white:focus,
button.bg-white:hover {
  background-color: #f3f4f5 !important;
}

.text-white {
  color: #fff !important;
}

.bg-gray {
  background-color: #868e96 !important;
}

a.bg-gray:focus,
a.bg-gray:hover,
button.bg-gray:focus,
button.bg-gray:hover {
  background-color: #6c757d !important;
}

.text-gray {
  color: #969696 !important;
}

.bg-lightpink-red {
  color: #ff7088 !important;
}

.text-gray-dark {
  color: #343a40 !important;
}

.bg-azure {
  background-color: #45aaf2 !important;
}

a.bg-azure:focus,
a.bg-azure:hover,
button.bg-azure:focus,
button.bg-azure:hover {
  background-color: #1594ef !important;
}

.text-azure {
  color: #45aaf2 !important;
}

.bg-lime {
  background-color: #1e74a0 !important;
}

a.bg-lime:focus,
a.bg-lime:hover,
button.bg-lime:focus,
button.bg-lime:hover {
  background-color: #63ad27 !important;
}

.text-lime {
  color: #1e74a0 !important;
}

.icon {
  color: #fff !important;
}

.icon i {
  color: #5c678f;
  vertical-align: -1px;
}

a.icon {
  cursor: pointer;
  text-decoration: none;
}

a.icon:hover {
  color: #fff !important;
}

.o-auto {
  overflow: auto !important;
}

.o-hidden {
  overflow: hidden !important;
}

.shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.shadow2 {
  box-shadow: 0 10px 10px 20px rgba(179, 186, 212, 0),
    10px 10px 15px -5px #b0b8d6 !important;
}

.shadow-none {
  box-shadow: none !important;
}

.bg-success-transparent-2 {
  background-color: rgba(56, 203, 137, 0.15) !important;
}

.bg-info-transparent-2 {
  background-color: rgba(34, 133, 204, 0.15) !important;
}

.bg-warning-transparent-2 {
  background-color: rgba(252, 191, 9, 0.15) !important;
}

.bg-danger-transparent-2 {
  background-color: rgba(250, 96, 96, 0.15) !important;
}

.bg-facebook {
  background: #2b4170 !important;
}

.bg-google {
  background: linear-gradient(to bottom right, #e64522, #c33219) !important;
  color: #fff;
}

.bg-dribbble {
  background: linear-gradient(to bottom right, #ea4c89, #c93764) !important;
}

.card {
  word-wrap: break-word;
  background-clip: border-box;
  /* background-color: #fff; */
  border: 1px solid transparent;
  border-radius: 0.3rem;
  /* box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03); */
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  min-width: 0;
  position: relative;
  width: 100%;
}

.allTxt {
  background-color: #fff !important;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.14);
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.card-subtitle {
  margin-top: -0.75rem;
}

.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-header:first-child {
  border-radius: 2px 2px 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #e6ebf1;
  color: #6e7687;
  padding: 1.5rem;
}

.card-footer:last-child {
  border-radius: 0 0 2px 2px;
}

.card-header-tabs {
  border-bottom: 0;
  line-height: 2rem;
  margin: -1.25rem 0;
}

.card-img-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
}

.card-img {
  border-radius: 2px;
  width: 100%;
}

.card-img-top {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  width: 100%;
}

.card-img-bottom {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  width: 100%;
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 0.75rem;
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }

  .card-deck {
    flex-flow: row wrap;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-bottom: 0;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .horizontalMenucontainer .header-brand-img.dark-logo,
  .horizontalMenucontainer .header-brand-img.darkmobile-logo,
  .horizontalMenucontainer .header-brand-img.mobile-logo {
    display: none;
  }

  .card-group {
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .card-group > .card:first-child .card-header,
  .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:first-child .card-footer,
  .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .card-group > .card:last-child .card-header,
  .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:last-child .card-footer,
  .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }

  .card-group > .card:only-child {
    border-radius: 3px;
  }

  .card-group > .card:only-child .card-header,
  .card-group > .card:only-child .card-img-top {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .card-group > .card:only-child .card-footer,
  .card-group > .card:only-child .card-img-bottom {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child),
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-footer,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-header,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-bottom,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-top {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1.5rem;
}

.rhombus {
  background-color: #ff0;
  height: 80px;
  transform: rotate(67.5deg);
  width: 350px;
}

@media print {
  .card {
    border: none;
    box-shadow: none;
  }

  .card-header {
    display: none;
  }

  .card-body {
    padding: 0;
  }
}

.card-body {
  flex: 1 1 auto;
  margin: 0;
  padding: 1.5rem;
  position: relative;
}

.card-body + .card-body {
  border-top: 1px solid #e6ebf1;
}

.tilebox-one i {
  font-size: 62px;
}

.card-body > :last-child {
  margin-bottom: 0;
}

.card-body-scrollable {
  overflow: auto;
}

.card-bottom,
.card-footer {
  background: none;
  padding: 1rem 1.5rem;
}

.log {
  margin-top: -26px;
}

.card-header {
  align-items: center;
  background: transparent;
  border-bottom: 1px solid #e6ebf1;
  display: flex;
  min-height: 3.5rem;
  padding: 0.5rem 1.5rem;
  position: relative;
}

.card-header,
.card-header .card-title {
  margin-bottom: 0;
}

.card-header.border-0 + .card-body {
  padding-top: 0;
}

.card-title {
  font-size: 13px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: capitalize;
  text-transform: uppercase;
}

.reg {
  color: #2e1170;
  float: right;
  font-size: 50px;
  text-align: center;
}

.card-title a {
  color: inherit;
}

.card-title:only-child {
  margin-bottom: 0;
}

.card-subtitle,
.card-title small {
  color: #9aa0ac;
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.1;
  margin: -0.75rem 0 1rem;
}

.card-table {
  margin-bottom: 0;
}

.card-table tr:first-child td,
.card-table tr:first-child th {
  border-top: 0;
}

.card-table tr td:first-child,
.card-table tr th:first-child {
  padding-left: 1.5rem;
}

.card-table tr td:last-child,
.card-table tr th:last-child {
  padding-right: 1.5rem;
}

.card-body + .card-table {
  border-top: 1px solid #e6ebf1;
}

.card-profile .card-header {
  background-size: cover;
  border: 0;
  height: 5rem;
}

.profie-img .flex-md-row img {
  width: 60px;
}

.card-profile-img {
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  max-width: 6rem;
}

.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-body + .card-list-group {
  border-top: 1px solid #e6ebf1;
}

.card-list-group .list-group-item {
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.card-list-group .list-group-item:last-child {
  border-bottom: 0;
}

.card-list-group .list-group-item:first-child {
  border-top: 0;
}

.card-header-tabs .nav-item {
  margin-bottom: 1px;
}

.card-header-pills {
  margin: -0.75rem;
}

.card-aside {
  flex-direction: row;
}

.card-aside-column {
  background: no-repeat 50% / cover;
  flex: 0 0 20%;
  min-width: 5rem;
  width: 20%;
}

.card-value {
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  height: 3.4rem;
  line-height: 3.4rem;
}

.card-value i {
  vertical-align: middle;
}

.card-chart-bg {
  height: 4rem;
  margin-top: -1rem;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.list-media .media-img {
  float: left;
  margin-right: 20px;
  position: relative;
  width: 48px;
}

.list-media .info {
  height: auto;
  min-height: 40px;
  padding-left: 55px;
  position: relative;
}

.list-media .info h4 a {
  font-size: 16px;
  margin-top: 10px;
}

.list-media .info p {
  font-size: 13px;
}

.list-media .info .text-right {
  color: #8a8a8a;
  font-size: 12px;
  margin-top: -28px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.card-options {
  align-self: center;
  color: #9aa0ac;
  display: flex;
  margin-left: auto;
  margin-right: -0.5rem;
  order: 100;
}

.card-options a:not(.btn) {
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
}

.card-options a:not(.btn):hover {
  text-decoration: none;
}

.card-options a:not(.btn) i {
  font-size: 1rem;
}

.card-options a.option-dots {
  border: 1px solid #e6ebf1;
  border-radius: 100%;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  color: #5c678f;
  display: inline-block;
  display: block;
  font-size: 18px;
  height: 2.5rem;
  line-height: 2.8rem;
  margin-right: 5px;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 2.5rem;
}

.card-options a.option-dots:hover {
  background: #f0f0f2;
  color: #5c678f !important;
  text-decoration: none;
}

.card-options .dropdown-toggle:after {
  display: none;
}

.widgets-cards .wrp.icon-circle {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 73px;
  justify-content: center;
  margin-right: 15px;
  padding: 0;
  width: 73px;
}

.widgets-cards .wrp.icon-circle i {
  color: #fff;
  font-size: 1.6rem;
}

.widgets-cards .wrp p {
  line-height: 1;
  margin-bottom: 10px;
}

.widgets-cards .wrp p:first-child {
  font-size: 36px;
  font-weight: 400;
}

ul li .legright-dots {
  border-radius: 100%;
  display: inline-block;
  height: 1rem;
  margin-right: 0.5rem;
  vertical-align: text-bottom;
  width: 1rem;
}

.legright li {
  float: left;
  padding: 5px;
}

.stretch-card {
  align-items: stretch;
  display: flex;
  justify-content: stretch;
}

.stretch-card > .card {
  min-width: 100%;
  width: 100%;
}

.icon-size {
  font-size: 2.5rem !important;
}

.card-img-absolute {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.statistics-box {
  min-height: 80px;
  padding-left: 80px;
  padding-top: 14px;
  position: relative;
  text-align: right;
  text-align: center;
}

.statistics-box .ico {
  font-size: 70px;
  height: 80px;
  left: 0;
  line-height: 80px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 80px;
}

.statistics-box .ico.small {
  font-size: 42px;
  font-weight: 900;
}

.statistics-box h2 {
  font-weight: 600 !important;
}

.card-collapsed > :not(.card-header):not(.card-status) {
  display: none;
}

.card-collapsed .card-options-collapse i:before {
  content: "\e92d";
}

.card-fullscreen .card-options-fullscreen i:before {
  content: "\e992";
}

.card-fullscreen .card-options-remove {
  display: none;
}

.card-map {
  background: #e9ecef;
  height: 15rem;
}

.card-map-placeholder {
  background: no-repeat 50%;
}

.card-tabs {
  display: flex;
}

.card-tabs-bottom .card-tabs-item {
  border: 0;
}

.card-tabs-bottom .card-tabs-item.active {
  border-top-color: #fff;
}

.card-tabs-item {
  color: inherit;
  display: block;
  flex: 1 1 auto;
  overflow: hidden;
  padding: 1.4rem 1.5rem;
}

a.card-tabs-item {
  background: #fafbfc;
}

a.card-tabs-item:hover {
  color: inherit;
  text-decoration: none;
}

a.card-tabs-item:focus {
  z-index: 1;
}

a.card-tabs-item.active {
  background: #fff;
  border-bottom-color: #fff;
}

.card-status {
  background: rgba(0, 40, 100, 0.12);
  border-radius: 7px 7px 0 0;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;
  top: -1px;
}

.card-status-left {
  border-radius: 3px 0 0 3px;
  bottom: 0;
  height: auto;
  right: auto;
  width: 3px;
}

.card-icon {
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
  width: 3rem;
}

.card-fullscreen {
  bottom: 0;
  left: 0;
  margin: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999999;
}

.card-alert {
  border-radius: 0;
  margin: -1px -1px 0;
}

.card-category {
  background: #f3f3f3;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin: 0 0 0.5rem;
  text-align: center;
  text-transform: uppercase;
}

.list-card {
  background: #fff;
  border-radius: 0;
  box-shadow: 0 2px 10px 0 rgba(4, 4, 7, 0.1);
  margin-bottom: 12px;
  padding: 14px 25px;
  position: relative;
}

.card > .list-group {
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.header-search .search-element {
  margin-left: 15px;
  position: relative;
}

.header-search .btn {
  color: #8499c4;
  position: absolute;
  right: 0;
  top: 0;
}

.hor-header {
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
}

.horizontal-main {
  padding: 8px;
  position: relative;
  width: 100%;
}

.horizontalMenu body,
.horizontalMenu h1,
.horizontalMenu h2,
.horizontalMenu h3,
.horizontalMenu h4,
.horizontalMenu h5,
.horizontalMenu h6,
.horizontalMenu html,
.horizontalMenu iframe {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  border: 0;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  font: inherit;
  font-size: 14px;
  font-weight: 400;
  font-weight: 500 !important;
  line-height: 18px;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

.horizontalMenu .cl {
  clear: both;
}

.horizontalMenu img,
embed,
object,
video {
  border: 0;
  max-width: 100%;
}

.horizontalMenu a:focus {
  outline: none;
}

.horizontalMenu:after,
.horizontalMenu:before {
  content: "";
  display: table;
}

.horizontalMenu:after {
  clear: both;
}

.horizontalMenucontainer {
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.horizontal-mainwrapper {
  margin: 0 auto;
}

.desktoplogo {
  float: left;
  margin: 0;
  padding: 0.75rem;
}

.desktoplogo img {
  width: 100%;
}

.horizontalMenu {
  color: #fff;
  display: block;
  font-size: 14px;
  padding: 0;
}

.horizontalMenu > .horizontalMenu-list {
  display: block;
  margin: 0 auto;
  padding: 0;
  text-align: left;
  width: 100%;
}

.horizontalMenu > .horizontalMenu-list > li {
  display: block;
  float: left;
  margin: 0;
  padding: 0;
  text-align: center;
}

.horizontalMenu > .horizontalMenu-list > li > a {
  border-radius: 0.25rem;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin: 0 2px;
  padding: 0 12px 0 0;
  position: relative;
  text-decoration: none;
  vertical-align: middle;
}

.horizontalMenu > .horizontalMenu-list > li:last-child > a {
  border-right: 0;
  margin-right: 0;
}

.horizontalMenu > .horizontalMenu-list > li:first-child > a {
  margin-left: 0;
}

.horizontalMenu > .horizontalMenu-list > li a.menuhomeicon {
  padding-left: 25px;
  padding-right: 25px;
}

.horizontalMenu > .horizontalMenu-list > li > a i.horizontal-icon {
  display: inline-block;
  font-size: 13px;
  line-height: inherit;
  margin-left: 3px;
  vertical-align: middle;
}

.horizontalMenu > .horizontalMenu-list > li > a .arrow:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid;
  content: "";
  float: right;
  height: 0;
  margin: 0 0 0 14px;
  position: absolute;
  right: 11px;
  text-align: right;
  top: 33px;
  width: 0;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu {
  zoom: 1;
  float: right;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu a {
  border-right: none;
  padding: 0 30px 0 20px;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu a i {
  font-size: 15px;
}

.horizontalMenu-list > li.rightmenu:after,
.horizontalMenu > .horizontalMenu-list > li.rightmenu:before {
  content: "";
  display: table;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu:after {
  clear: both;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch {
  float: right;
  height: 39px;
  margin: 16px 0 0;
  position: relative;
  width: 210px;
}

.horizontalMenu
  > .horizontalMenu-list
  > li.rightmenu
  > .topmenusearch
  .searchicon {
  transition: all 0.7s ease 0s;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch input {
  background-color: #efefef;
  border: 0;
  bottom: 0;
  color: #333;
  float: right;
  font-size: 12px;
  height: 39px;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  right: 0;
  text-indent: 15px;
  top: 0;
  transition: all 0.7s ease 0s;
  width: 100%;
  z-index: 2;
}

.horizontalMenu
  > .horizontalMenu-list
  > li.rightmenu
  > .topmenusearch
  input::-moz-placeholder {
  color: #a9a9a9;
}

.horizontalMenu
  > .horizontalMenu-list
  > li.rightmenu
  > .topmenusearch
  input:-ms-input-placeholder {
  color: #a9a9a9;
}

.horizontalMenu
  > .horizontalMenu-list
  > li.rightmenu
  > .topmenusearch
  input::placeholder {
  color: #a9a9a9;
}

.horizontalMenu
  > .horizontalMenu-list
  > li.rightmenu
  > .topmenusearch
  input:focus {
  color: #333;
  width: 230px;
}

.horizontalMenu
  > .horizontalMenu-list
  > li.rightmenu
  > .topmenusearch
  input:focus
  ~ .btnstyle
  .fa {
  color: #000;
  opacity: 1;
}

.horizontalMenu
  > .horizontalMenu-list
  > li.rightmenu
  > .topmenusearch
  input:focus
  ~ .searchicon {
  color: #fff;
  opacity: 1;
  z-index: 3;
}

.horizontalMenu
  > .horizontalMenu-list
  > li.rightmenu
  > .topmenusearch
  .btnstyle {
  background-color: transparent;
  border: 0 solid;
  bottom: 0;
  color: #333;
  cursor: pointer;
  line-height: 30px;
  opacity: 0.3;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.7s ease 0s;
  width: 40px;
  z-index: 1;
  z-index: 1000;
}

.horizontalMenu
  > .horizontalMenu-list
  > li.rightmenu
  > .topmenusearch
  .btnstyle
  .fa {
  line-height: 37px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.horizontalMenu
  > .horizontalMenu-list
  > li.rightmenu
  > .topmenusearch
  .btnstyle:hover
  .fa {
  opacity: 1;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 4px;
  box-shadow: 0 16px 18px rgba(4, 4, 7, 0.1);
  margin: 0;
  min-width: 190px;
  padding: 10px 0 10px 15px;
  position: absolute;
  z-index: 99999;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a {
  background-image: none;
  border-right: 0 solid;
  color: #576482;
  display: block;
  font-size: 13px;
  letter-spacing: normal;
  line-height: 22px;
  padding: 6px 7px;
  position: relative;
  text-align: left;
  text-transform: none;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover {
  text-decoration: none;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a > i {
  margin-right: 9px;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu {
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 0 4px 4px 0;
  box-shadow: 20px 0 18px rgba(4, 4, 7, 0.1);
  left: 100%;
  list-style: none;
  margin: 0;
  min-width: 220px;
  padding: 10px 25px;
  position: absolute;
  top: 0;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > a {
  background-image: none;
  border-right: 0 solid;
  color: #576482;
  display: block;
  font-size: 13px;
  letter-spacing: normal;
  line-height: 22px;
  padding: 6px 7px;
  text-align: left;
  text-transform: none;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > a:hover {
  text-decoration: none;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > a
  > i {
  margin-right: 9px;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu {
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 4px;
  box-shadow: 0 16px 18px rgba(4, 4, 7, 0.1);
  left: 115%;
  list-style: none;
  margin: 0;
  min-width: 220px;
  padding: 10px 25px;
  position: absolute;
  top: 0;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > a {
  background-image: none;
  border-right: 0 solid;
  color: #576482;
  display: block;
  font-size: 13px;
  letter-spacing: normal;
  line-height: 22px;
  padding: 8px 12px;
  text-align: left;
  text-transform: none;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > a:hover {
  text-decoration: none;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > a
  > i {
  margin-right: 9px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu {
  color: #576482;
  font-size: 15px;
  left: 0;
  margin: 0;
  position: absolute;
  text-align: left;
  top: 54px;
  width: 100%;
  z-index: 1000;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .megamenu-content {
  background-color: #fff;
  border: 1px solid #e8ebf3;
  border-radius: 2px;
  box-shadow: 0 5px 25px rgba(227, 228, 238, 0.2);
  padding: 14px 20px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .title {
  color: #2c2c2c;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px;
  padding: 5px 5px 5px 0;
  text-align: left;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .wsmwnutxt {
  color: #2c2c2c;
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
  text-align: justify;
  width: 100%;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .link-list
  li {
  display: block;
  padding: 5px 10px 5px 30px;
  text-align: center;
  text-align: left;
  white-space: nowrap;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .link-list
  li
  h3 {
  color: #6a7388;
  font-size: 0.75rem !important;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .link-list
  li
  a {
  background: transparent;
  background-image: none;
  border-right: 0;
  color: #747d94;
  display: block;
  font-size: 12px;
  line-height: 18px;
  margin-left: 10px;
  padding: 6px 7px;
  position: relative;
  text-align: left;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu li i {
  margin-right: 5px;
  text-align: center;
  width: 18px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu li a:hover {
  background: transparent;
  text-decoration: none;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .link-list
  li
  i {
  font-size: 12px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .mrgtop {
  margin-top: 15px;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .show-grid
  div {
  background-color: #dbdbdb;
  border: 1px solid #e7e7e7;
  color: #6a6a6a;
  font-size: 13px;
  margin: 2px 0;
  padding-bottom: 10px;
  padding-top: 10px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu.halfmenu {
  left: auto;
  right: auto;
  width: 30%;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu.halfdiv {
  left: auto;
  right: auto;
  width: 20%;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .menu_form {
  display: block;
  width: 100%;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .menu_form
  input[type="text"],
.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .menu_form
  textarea {
  border: 1px solid #e2e2e2;
  color: #000;
  font-size: 13px;
  margin-bottom: 8px;
  padding: 8px 5px;
  width: 100%;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .menu_form
  textarea {
  min-height: 122px;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .menu_form
  input[type="submit"] {
  margin-right: 15px;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .menu_form
  input[type="button"],
.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .menu_form
  input[type="submit"] {
  background-color: #e1e1e1;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  float: right;
  height: 32px;
  width: 25%;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .carousel-inner
  .item
  img {
  width: 100%;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .carousel-control-next,
.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .carousel-control-prev {
  opacity: 0.8;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .carousel-caption {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  font-size: 13px;
  height: 31px;
  left: 0;
  padding: 7px 0;
  right: 0;
  width: 100%;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .typography-text {
  font-size: 14px;
  padding: 0;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .typography-text
  ul {
  margin: 0;
  padding: 0;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .typography-text
  p {
  color: #656565;
  line-height: 24px;
  text-align: justify;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .typography-text
  ul
  li {
  display: block;
  line-height: 22px;
  padding: 2px 0;
}

.horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .typography-text
  ul
  li
  a {
  color: #656565;
}

@media (min-width: 1024px) {
  .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .link-list:first-child {
    border: 0;
  }
}

.horizontal-header {
  display: none;
}

.horizontal-overlapbg {
  opacity: 0;
  visibility: hidden;
}

.hometext,
.horizontalMenu .horizontalMenu-click,
.horizontalMenu .horizontalMenu-click02 {
  display: none;
}

@media only screen and (max-width: 991px) {
  body.active {
    overflow: hidden;
  }

  .desktoplogo {
    display: none;
  }

  .horizontal-main {
    height: 0;
    padding: 0;
  }

  .horizontalMenucontainer {
    background-attachment: local;
    background-position: 33% 0;
  }

  .horizontalMenu {
    background: transparent none repeat scroll 0 0;
    left: 0;
    margin: 0;
    overflow-y: hidden;
    padding: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
  }

  .horizontalMenu > .horizontalMenu-list {
    background: #fff;
    display: block;
    height: auto;
    margin-left: -240px;
    min-height: 100%;
    padding-bottom: 0;
    text-align: center;
    width: 240px;
  }

  .horizontalMenu > .horizontalMenu-list > li {
    background-color: transparent;
    border-right: none;
    clear: right;
    display: block;
    float: none;
    padding: 0;
    position: relative;
    white-space: inherit;
    width: 100%;
  }

  .horizontalMenu > .horizontalMenu-list > li span a {
    margin: 0 auto;
    width: 85%;
  }

  .horizontalMenu > .horizontalMenu-list > li > a {
    border-bottom: 1px solid transparent;
    border-radius: 0;
    border-right: 0 solid;
    color: #576482;
    font-size: 14px;
    line-height: 25px;
    margin: 0;
    padding: 2px 32px 2px 0;
    position: static;
    text-align: left;
  }

  .horizontalMenu > .horizontalMenu-list > li > a span.i {
    display: none;
  }

  .horizontalMenu > .horizontalMenu-list > li a.menuhomeicon {
    border-top: 1px solid rgba(0, 0, 0, 0.13);
    padding-left: 17px;
    padding-right: 17px;
  }

  .horizontalMenu > .horizontalMenu-list > li > a > .fa {
    color: #fff;
    display: none !important;
    font-size: 16px;
  }

  .horizontalMenu > .horizontalMenu-list > li > a .arrow:after {
    display: none;
  }

  .horizontalMenu > .horizontalMenu-list > li:hover > a {
    text-decoration: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > a > .hometext {
    display: inline-block;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    background-color: #f0f0f8;
    border: none;
    box-shadow: none;
    display: none;
    margin: 0;
    opacity: 1;
    padding: 0 25px;
    position: relative;
    top: 0;
    transform: none;
    -webkit-transform-style: flat;
    transition: inherit;
    visibility: visible;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a {
    color: #747d94;
    font-size: 12px;
    line-height: 20px;
    padding: 10px 0 10px 30px;
    position: static;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li span + a {
    padding-right: 30px;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a > .fa {
    display: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover {
    background-color: transparent;
    color: #576482;
    text-decoration: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu li:hover > a {
    color: #576482;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu {
    border: 0 solid;
    display: none;
    left: 100%;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: static;
    top: 0;
    transform: none;
    -webkit-transform-style: flat;
    transition: inherit;
    visibility: visible;
    width: 100%;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li {
    margin: 0;
    padding: 0;
    position: relative;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > a {
    color: #747d94;
    font-size: 12px;
    line-height: 20px;
    padding: 10px 0 10px 34px;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    span
    + a {
    padding-right: 30px;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > a
    > .fa {
    display: none;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > a:hover {
    background-color: transparent;
    color: #576482;
    text-decoration: none;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li:hover
    > a,
  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > a.active {
    color: #000;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu {
    border: 0 solid;
    display: none;
    left: 100%;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: static;
    top: 0;
    transform: none;
    -webkit-transform-style: flat;
    transition: inherit;
    visibility: visible;
    width: 100%;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li {
    margin: 0;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > a {
    font-size: 12px;
    line-height: 20px;
    padding: 10px 0 10px 34px;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    span
    + a {
    padding-right: 30px;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > a
    > .fa {
    display: none;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > a:hover {
    background-color: transparent;
    color: #576482;
    text-decoration: none;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > a.active {
    color: #000;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu {
    border: 0 solid;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: #576482;
    display: none;
    opacity: 1;
    padding: 0;
    position: relative;
    top: 0;
    transform: none;
    -webkit-transform-style: flat;
    transition: inherit;
    visibility: visible;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .container {
    padding: 0;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu.halfmenu {
    margin: 0;
    padding: 5px 0 10px;
    width: 100%;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .title {
    color: #576482;
    font-size: 15px;
    padding: 10px 8px 10px 0;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu > ul {
    font-size: 13px;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    > ul
    > li
    > a {
    background-color: #e7e7e7;
    color: #576482;
    font-size: 12px !important;
    line-height: normal;
    padding: 9px 14px;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    > ul
    > li
    > a:hover {
    background-color: #000;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    ul
    li.title {
    background-color: transparent;
    color: #576482;
    font-size: 15px;
    line-height: 26px;
    margin: 0;
    padding: 7px 0;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu.halfdiv {
    width: 100%;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .menu_form {
    padding: 5px 0 62px;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .show-grid
    div {
    margin: 0;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .menu_form
    input[type="button"],
  .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .menu_form
    input[type="submit"] {
    width: 46%;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .menu_form
    textarea {
    min-height: 100px;
  }

  .horizontal-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: block;
    height: 54px;
    left: 0;
    position: relative;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .active .horizontal-header,
  .horizontal-header {
    transition: all 0.25s ease-in-out;
  }

  .horizontal-header .smllogo {
    display: inline-block;
    margin-top: 11px;
  }

  .horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch {
    margin: 7%;
    padding: 0;
    width: 86%;
  }

  .horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch input {
    border-radius: 50px;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li.rightmenu
    > .topmenusearch
    input:focus {
    width: 100%;
  }

  .callusbtn {
    color: #a9a9a9;
    font-size: 21px;
    padding: 11px 14px;
    position: absolute;
    right: 5px;
    top: 0;
    transition: all 0.4s ease-in-out 0s;
    z-index: 102;
  }

  .callusbtn .fa {
    margin-top: 4px;
    vertical-align: top;
  }

  .callusbtn:hover .fa {
    color: #a9a9a9;
  }

  .animated-arrow {
    cursor: pointer;
    left: 0;
    margin: 6px 0 0 8px;
    padding: 16px 35px 16px 0;
    position: absolute;
    top: 0;
    transition: all 0.4s ease-in-out;
    z-index: 102;
  }

  .animated-arrow span {
    transition: all 0.5s ease-in-out;
  }

  .animated-arrow span,
  .animated-arrow span:after,
  .animated-arrow span:before {
    background: #5c678f;
    content: "";
    cursor: pointer;
    display: block;
    height: 2px;
    position: absolute;
    width: 17px;
  }

  .animated-arrow span:before {
    top: -7px;
    width: 23px;
  }

  .animated-arrow span:after {
    bottom: -7px;
    width: 17px;
  }

  .animated-arrow span:after,
  .animated-arrow span:before {
    transition: all 0.5s ease-in-out;
  }

  .active .animated-arrow span {
    background-color: transparent;
    background: none !important;
  }

  .active .animated-arrow span:after {
    top: -3px;
    width: 23px;
  }

  .active .animated-arrow.active span:after,
  .active .animated-arrow span:before {
    top: -3px;
  }

  .active .animated-arrow span:before {
    bottom: 0;
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .active .animated-arrow span:after {
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .horizontal-overlapbg {
    background-color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    height: 100vh;
    min-height: 100%;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    width: calc(100% - 240px);
  }

  .active .horizontalMenu .horizontal-overlapbg {
    opacity: 1;
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -ms-transition: opacity 1.5s ease-in-out;
    -o-transition: opacity 1.5s ease-in-out;
    visibility: visible;
  }

  .horizontalMenucontainer {
    transition: all 0.25s ease-in-out;
  }

  .active .horizontalMenucontainer {
    margin-left: 240px;
    transition: all 0.25s ease-in-out;
  }

  .active .horizontalMenu {
    -webkit-overflow-scrolling: touch;
    height: 100% !important;
    overflow-y: scroll;
    top: 0;
    visibility: visible;
    z-index: 1000;
  }

  .active .horizontalMenu > .horizontalMenu-list {
    margin-left: 0;
    transition: all 0.25s ease-in-out;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontalMenu-click {
    cursor: pointer;
    display: block;
    height: 49px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    .horizontalMenu-click.horizontal-activearrow
    > i {
    margin-top: 7px;
    transform: rotate(180deg);
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontalMenu-click > i {
    background-size: 25px;
    color: #576482;
    display: block;
    float: right;
    font-size: 16px;
    height: 23px;
    margin-right: 10px;
    margin-top: 14px;
    opacity: 0.4;
    width: 25px;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    .horizontalMenu-click02 {
    cursor: pointer;
    display: block;
    height: 41px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    .horizontalMenu-click02
    > i {
    background-size: 25px;
    color: #576482;
    display: block;
    float: right;
    font-size: 16px;
    margin: 8px 6px;
    opacity: 0.4;
  }

  .sub-menu li:before {
    top: 10px !important;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    .sub-menu-sub:after {
    display: none;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    .horizontalMenu-click02
    > i.horizontalMenu-rotate {
    transform: rotate(180deg);
  }

  .horizontalMenu > .horizontalMenu-list > li > a .horizontal-icon,
  .horizontalMenu > .horizontalMenu-list > li a span {
    display: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu {
    background: transparent;
    box-shadow: none !important;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .link-list
    li
    a {
    color: hsla(0, 0%, 100%, 0.6);
  }

  .mega-menubg {
    border: 0 !important;
    box-shadow: 0 !important;
  }

  .hor-menu
    .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .link-list
    li
    a {
    color: #664dc9;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active {
    background: #664dc9;
    color: #fff;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li:first-child > a {
    margin-top: 0 !important;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li:last-child > a {
    margin-right: 5px !important;
  }

  .mega-menubg {
    box-shadow: none !important;
    padding: 0 20px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1162px) {
  .horizontalMenu > .horizontalMenu-list > li > a {
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .horizontalMenu > .horizontalMenu-list > li > a > .arrow {
    display: none;
  }

  .horizontalMenu > .horizontalMenu-list > li a.menuhomeicon {
    padding-left: 22px;
    padding-right: 22px;
  }
}

.horizontalMenu-list .sub-menu .sub-menu:after,
.horizontalMenu-list .sub-menu .sub-menu:before {
  display: none;
}

@media (max-width: 992px) {
  .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .megamenu-content {
    border: 0;
    box-shadow: none;
  }
}

.sticky.sticky-pin .horizontal-main {
  box-shadow: -6px 5px 8px 0 rgba(24, 23, 38, 0.06);
}

.horizontalMenu ul li a.btn {
  font-size: 14px !important;
  margin: 5px 0;
}

@media (min-width: 992px) {
  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    opacity: 0;
    transform: rotateX(-75deg);
    -o-transform: rotateX(-75deg);
    -moz-transform: rotateX(-75deg);
    -webkit-transform: rotateX(-75deg);
    -o-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -o-transition: -o-transform 0.3s, opacity 0.3s;
    -ms-transition: -ms-transform 0.3s, opacity 0.3s;
    -moz-transition: -moz-transform 0.3s, opacity 0.3s;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    visibility: hidden;
  }

  .horizontalMenu > .horizontalMenu-list > li:hover > .horizontal-megamenu,
  .horizontalMenu > .horizontalMenu-list > li:hover > ul.sub-menu {
    opacity: 1;
    transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    visibility: visible;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu {
    opacity: 0;
    transform: rotateX(-75deg);
    -o-transform: rotateX(-75deg);
    -moz-transform: rotateX(-75deg);
    -webkit-transform: rotateX(-75deg);
    transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    visibility: hidden;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li:hover
    > ul.sub-menu {
    opacity: 1;
    transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -o-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    visibility: visible;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li:hover
    > ul.sub-menu,
  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu {
    -o-transition: -o-transform 0.4s, opacity 0.4s;
    -ms-transition: -ms-transform 0.4s, opacity 0.4s;
    -moz-transition: -moz-transform 0.4s, opacity 0.4s;
    -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu {
    opacity: 0;
    transform: rotateX(-75deg);
    -o-transform: rotateX(-75deg);
    -moz-transform: rotateX(-75deg);
    -webkit-transform: rotateX(-75deg);
    -o-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    visibility: hidden;
  }

  .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li:hover
    > ul.sub-menu {
    opacity: 1;
    transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    visibility: visible;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu {
    opacity: 0;
    transform: rotateX(-75deg);
    -o-transform: rotateX(-75deg);
    -moz-transform: rotateX(-75deg);
    -webkit-transform: rotateX(-75deg);
    -o-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -o-transition: -o-transform 0.3s, opacity 0.3s;
    -ms-transition: -ms-transform 0.3s, opacity 0.3s;
    -moz-transition: -moz-transform 0.3s, opacity 0.3s;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    visibility: hidden;
  }

  .header-style1 .horizontalMenu > .horizontalMenu-list > li {
    padding: 0.75rem 0;
  }
}

.mega-menubg {
  background: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 4px;
  box-shadow: 0 16px 18px rgba(4, 4, 7, 0.1);
  margin-top: -7px;
  padding: 14px 15px;
}

.horizontal-main.hor-menu {
  background: #fff;
  border-bottom: 1px solid transparent;
  box-shadow: 5px 7px 26px -5px rgba(24, 23, 38, 0.06);
}

.hor-menu
  .horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .link-list
  li
  a {
  color: #576482;
}

.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active,
.hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
  background: #f5f6f8;
  box-shadow: none;
  color: #664dc9;
}

.hor-menu .horizontalMenu a.active .hor-icon,
.hor-menu .horizontalMenu a:hover .hor-icon {
  fill: #664dc9;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu .sub-menu-sub:after {
  color: #576482;
  content: "\e92f";
  font-family: feather;
  font-size: 13px;
  opacity: 0.4;
  position: absolute;
  right: 14px;
  top: 9px;
}

.hor-menu .horizontalMenu > .horizontalMenu-list > li:first-child > a {
  margin-left: 0;
}

.hor-menu .horizontalMenu > .horizontalMenu-list > li:last-child > a {
  margin-right: 0;
}

.hor-icon {
  fill: #555b6d;
  color: #555b6d;
  font-size: 25px;
  height: 36px;
  line-height: 30px;
  margin-left: 5px;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  width: 36px;
}

.hor-content .latest-timeline {
  height: 365px;
}

@media (min-width: 768px) {
  .hor-header .header-brand {
    margin-left: -15px;
    margin-top: 11px;
  }
}

@media (max-width: 767px) {
  .hor-header {
    padding: 0.75rem;
  }

  .hor-header .navbar-toggler {
    top: -4px;
  }

  ul.sub-menu {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .hor-header .header-brand-img.mobile-logo {
    display: none !important;
  }

  .hor-header .header-brand .header-brand-img {
    margin: 16px 0 !important;
  }

  .hor-header .animated-arrow.hor-toggle {
    font-size: 1.2rem;
    height: 2.5rem;
    left: 6px;
    margin: 5px;
    margin-bottom: 8px !important;
    padding: 16px 35px 16px 0 !important;
    position: relative;
    text-align: center;
    top: 6px;
    width: 2.5rem;
  }

  .responsive-navbar.navbar .navbar-collapse {
    background: #fff;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #f0f0ff;
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    margin-top: 0;
    position: fixed;
    right: 0;
    top: 78px;
    width: 100%;
    z-index: 999;
  }

  .hor-header .header-brand .header-brand-img {
    left: 0;
    margin: 3px auto 0 !important;
    position: absolute;
    right: 0;
    text-align: center;
  }

  .hor-header .profile-dropdown {
    padding: 0.5rem;
  }

  .hor-header .main-header-end .nav-link.icon {
    padding: 1.8rem 0.3rem !important;
  }
}

.hor-header .header-brand {
  margin-right: 0;
  min-width: 0;
  text-align: justify;
}

@media (max-width: 767.98px) {
  .hor-header .demo-icon .header-icon {
    margin-top: 14px !important;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .hor-header .form-inline .btn {
    margin-top: 1px !important;
  }
}

@media (min-width: 767px) {
  .hor-header .profile-dropdown {
    padding: 0.95rem;
  }

  .hor-header .notifyimg {
    line-height: 40px !important;
  }

  .hor-header .form-inline .btn {
    background-color: #664dc9;
    left: inherit;
    opacity: 1;
    padding: 4px 10px;
    right: 0 !important;
    top: 13px;
  }

  .hor-header .form-inline .btn .search-icon {
    fill: #fff !important;
    margin-top: 4px !important;
  }

  .hor-header .form-control::-webkit-input-placeholder {
    padding-top: 0 !important;
  }

  .hor-header .top-header .search-element input {
    border-radius: 10px !important;
  }

  .hor-header .form-inline .form-control {
    margin-top: 12px;
    padding-left: 14px;
    padding-top: 8px;
  }

  .hor-header .header-search {
    border: 1px solid #e6ebf1 !important;
    min-width: 25rem !important;
  }

  .hor-header .search-icon {
    height: 24px;
    width: 24px;
  }
}

.hor-header .form-control:focus {
  border-color: #dee4ea;
}

.dark-mode .hor-header .form-control:focus {
  border-color: #32394e;
}

@media (min-width: 768px) {
  .hor-header .search-element {
    top: 0;
  }
}

@media (max-width: 767.98px) {
  .navbar-toggler {
    margin-top: 0 !important;
  }

  .hor-header .main-header-end .nav-link.icon.demo-icon {
    padding: 1rem 0.3rem !important;
  }
}

@media (min-width: 992px) {
  .hor-header .demo-icon {
    border-right: 1px solid #e6ebf1 !important;
  }

  .theme-layout {
    border-left: 1px solid #e6ebf1;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.g-0 {
  margin-left: 0;
  margin-right: 0;
}

.g-0 > .col,
.g-0 > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
  min-height: 1px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;
  width: 100%;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  max-width: none;
  width: auto;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    max-width: none;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    max-width: none;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    max-width: none;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 1280px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    max-width: none;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60%,
  to {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60%,
  to {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60%,
  to {
    left: 107%;
    right: -8%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60%,
  to {
    left: 107%;
    right: -8%;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.9);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
  }

  to {
    -webkit-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
  }

  to {
    box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
  }
}

@media (min-width: 768px) {
  .closed-menu.app.sidenav-toggled .app-content {
    margin-left: 0;
  }

  .closed-menu.app.sidenav-toggled .app-sidebar {
    left: 250px;
  }

  .closed-menu.app.sidenav-toggled .app-sidebar__overlay {
    visibility: hidden;
  }

  .closed-menu .app-sidebar__user {
    margin-top: 74px;
  }
}

@media (max-width: 767px) {
  .closed-menu .side-menu {
    margin-top: 0 !important;
  }

  .closed-menu.app {
    overflow-x: hidden;
  }

  .closed-menu.app .app-sidebar {
    left: -265px;
    margin-top: 74px !important;
  }

  .closed-menu.app .app-sidebar__overlay {
    visibility: hidden;
  }

  .closed-menu.app.sidenav-toggled .app-content {
    margin-left: 0;
  }

  .closed-menu.app.sidenav-toggled .app-sidebar {
    left: 0;
  }

  .closed-menu.app.sidenav-toggled .app-sidebar__overlay {
    visibility: visible;
  }

  .closed-menu .sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    line-height: 1;
  }
}

.closed-menu .app-sidebar .ps__thumb-y {
  right: 0;
}

.closed-menu .app-content {
  margin-bottom: 0 !important;
  margin-top: 50px;
  min-height: calc(100vh - 50px);
  overflow: hidden;
  transition: margin-left 0.3s ease;
}

.closed-menu .app-content .side-app {
  padding: 25px 1.5rem 0;
}

.closed-menu .footer .container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
}

@media (min-width: 768px) {
  .closed-menu .app-content {
    margin-left: 250px;
    padding-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .closed-menu .app-content {
    margin-top: 50px;
    min-width: 100%;
  }
}

@media print {
  .closed-menu .app-content {
    background-color: #fff;
    margin: 0;
    padding: 0;
  }
}

.closed-menu .light-text {
  font-weight: 300 !important;
}

.closed-menu .semibold-text {
  font-weight: 600 !important;
}

.closed-menu .line-head {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.closed-menu .app-header {
  border-bottom: 1px solid #e6ebf1 !important;
  box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: padding-left 0.3s ease;
  width: 100%;
  z-index: 999999;
}

@media (min-width: 768px) {
  .closed-menu .app-header {
    padding-bottom: 0;
    padding-left: 250px;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .app-header {
    padding-left: 0;
  }
}

@media print {
  .closed-menu .app-header {
    display: none;
  }
}

.closed-menu .app-header__logo {
  color: #fff;
  flex: 1 0 auto;
  font-family: Niconne;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;
  padding: 0 15px;
  text-align: center;
}

.closed-menu .app-header__logo:focus,
.closed-menu .app-header__logo:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .closed-menu .app-header__logo {
    display: block;
    flex: 0 0 auto;
    width: 250px;
  }
}

.closed-menu .app-sidebar__toggle {
  border-right: 1px solid #e6ebf1;
  font-size: 1.2rem;
  margin-left: -12px;
  padding: 0.85rem 1rem;
  position: relative;
}

.closed-menu .app-sidebar__toggle a {
  color: #b6c1d9;
}

.closed-menu .app-sidebar__toggle .header-icon {
  height: 38px;
  line-height: 42px;
  margin-top: 3px;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  width: 38px;
}

.closed-menu .app-sidebar__toggle:focus,
.closed-menu .app-sidebar__toggle:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  .closed-menu .app-sidebar__toggle {
    order: -1;
  }
}

.closed-menu .app-nav {
  display: flex;
  justify-content: flex-right;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .closed-menu .app-nav {
    flex: 1 0 auto;
  }
}

.closed-menu .app-nav__item {
  color: #a8a8a8;
  display: block;
  line-height: 20px;
  padding: 15px;
  transition: background-color 0.3s ease;
}

.closed-menu .app-nav__item:focus,
.closed-menu .app-nav__item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}

.closed-menu .app-search {
  align-self: center;
  display: flex;
  margin-right: 15px;
  padding: 10px 0;
  position: relative;
}

@media (max-width: 480px) {
  .closed-menu .app-search {
    display: none;
  }
}

.closed-menu .app-search__input {
  background-color: hsla(0, 0%, 100%, 0.8);
  border: 0;
  border-radius: 2px;
  padding: 5px 30px 5px 10px;
  transition: background-color 0.3s ease;
}

.closed-menu .app-search__input:-ms-input-placeholder,
.closed-menu .app-search__input::-ms-input-placeholder,
.closed-menu .app-search__input::-webkit-input-placeholder,
.closed-menu .app-search__input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.closed-menu .app-search__button {
  background: none;
  border: 0;
  bottom: 10px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  padding: 0 10px;
  position: absolute;
  right: 0;
  top: 10px;
}

.closed-menu .app-notification {
  min-width: 270px;
}

.closed-menu .app-notification__title {
  background-color: rgba(0, 150, 136, 0.4);
  color: #333;
  padding: 8px 20px;
  text-align: center;
}

.closed-menu .app-notification__footer {
  background-color: #eee;
  padding: 8px 20px;
  text-align: center;
}

.closed-menu .app-notification__content {
  max-height: 220px;
  overflow-y: auto;
}

.closed-menu .app-notification__content::-webkit-scrollbar {
  width: 6px;
}

.closed-menu .app-notification__content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.closed-menu .app-notification__item {
  border-bottom: 1px solid #ddd;
  color: inherit;
  display: flex;
  padding: 8px 20px;
  transition: background-color 0.3s ease;
}

.closed-menu .app-notification__item:focus,
.closed-menu .app-notification__item:hover {
  background-color: #e6ebf1;
  color: inherit;
  text-decoration: none;
}

.closed-menu .app-notification__message,
.closed-menu .app-notification__meta {
  margin-bottom: 0;
}

.closed-menu .app-notification__icon {
  padding-right: 10px;
}

.closed-menu .app-notification__message {
  line-height: 1.2;
}

.closed-menu .app-sidebar {
  background: #fff !important;
  border-right: 1px solid #e6ebf1;
  bottom: 0;
  box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
  left: 0;
  margin-top: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  transition: left 0.3s ease, width 0.3s ease;
  width: 250px;
  z-index: 9999999;
}

.closed-menu .app-sidebar::-webkit-scrollbar {
  width: 6px;
}

.closed-menu .app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

@media print {
  .closed-menu .app-sidebar {
    display: none;
  }
}

@media (max-width: 767px) {
  .closed-menu .app-sidebar__overlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9;
  }
}

.closed-menu .app-sidebar__user {
  align-items: center;
  border-bottom: 1px solid #e6ebf1;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  display: flex;
  display: inline-block;
  padding: 20px;
  width: 100%;
}

.closed-menu .app-sidebar__user .dropdown-menu {
  top: 10px !important;
}

.closed-menu.sidenav-toggled .app-sidebar__user .user-pic {
  margin: 0;
}

.closed-menu .app-sidebar__user .user-pic {
  margin-bottom: 12px;
}

.closed-menu .app-sidebar__user-avatar {
  flex: 0 0 auto;
  margin-right: 15px;
}

.closed-menu .app-sidebar__user-name {
  font-size: 17px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 0;
  margin-top: 5px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.closed-menu .app-sidebar__user-name.text-sm {
  font-size: 12px;
  font-weight: 400;
}

.closed-menu .app-sidebar__user-designation {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 5px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.closed-menu .side-menu {
  margin-bottom: 0;
  margin-top: 90px;
  padding-bottom: 40px;
}

.closed-menu .side-menu__item {
  align-items: center;
  border-radius: 0.25rem;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 3px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 3px 22px 3px 5px;
  position: relative;
}

.closed-menu .side-menu__item.active,
.closed-menu .side-menu__item:focus,
.closed-menu .side-menu__item:hover {
  background: #f3f6f8;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  text-decoration: none;
}

.closed-menu .side-menu__item.active .side-menu__icon,
.closed-menu .side-menu__item:focus .side-menu__icon,
.closed-menu .side-menu__item:hover .side-menu__icon {
  fill: #664dc9 !important;
}

.closed-menu .side-menu__item .fe-chevron-right {
  font-size: 0.95rem;
}

.closed-menu.sidenav-toggled .side-menu__item:focus {
  background: transparent !important;
}

.closed-menu.sidenav-toggled .side-menu__item:focus .side-menu__icon {
  fill: #6c756e;
}

.closed-menu.sidenav-toggled1 .side-menu__item:focus {
  background: #f3f6f8 !important;
}

.closed-menu.sidenav-toggled1 .side-menu__item:focus .side-menu__icon {
  fill: #fff;
}

@media (min-width: 768px) {
  .closed-menu.app.sidebar-mini.sidenav-toggled .side-menu__label {
    background: transparent;
    box-shadow: none;
    display: none !important;
    font-size: 14px;
    left: 0;
    margin: 0;
    min-width: 100%;
    opacity: 1;
    padding: 0;
    position: relative;
  }
}

.closed-menu .slide-item.active,
.closed-menu .slide-item:focus,
.closed-menu .slide-item:hover {
  text-decoration: none;
}

.closed-menu .slide.is-expanded a.slide-item {
  transition: all 0.3s;
}

.closed-menu .slide.is-expanded a.slide-item:hover {
  margin-left: 5px;
  text-decoration: none;
  transition: all 0.3s;
}

.closed-menu .side-menu .side-menu__icon {
  font-size: 1.24rem;
  height: 36px;
  line-height: 30px;
  margin-right: 0.5rem;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  width: 36px;
}

.closed-menu .side-menu__icon {
  flex: 0 0 auto;
  width: 25px;
}

.closed-menu .side-menu__label {
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  letter-spacing: 0.6px;
  opacity: 1;
  transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
  white-space: nowrap;
}

.closed-menu .slide.is-expanded .slide-menu {
  display: block;
}

.closed-menu .slide.is-expanded .angle {
  transform: rotate(90deg);
}

.closed-menu .slide-menu {
  display: none;
  font-size: 0.8rem !important;
  padding: 0;
}

.closed-menu .slide-item {
  align-items: center;
  color: #6c756e;
  display: flex;
  font-size: 13px;
  padding: 7px 14px 7px 44px;
}

.closed-menu .slide-item .icon {
  margin-right: 5px;
}

.closed-menu .angle {
  font-size: 12px;
  transform-origin: center;
  transition: transform 0.3s ease;
}

@media (min-width: 768px) {
  .closed-menu.sidebar-mini.sidenav-toggled {
    right: 30px !important;
    top: 0.5rem;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .angle,
  .closed-menu.sidebar-mini.sidenav-toggled .app-sidebar__user-designation,
  .closed-menu.sidebar-mini.sidenav-toggled .app-sidebar__user-name,
  .closed-menu.sidebar-mini.sidenav-toggled .user-info,
  .closed-menu.sidebar-mini.sidenav-toggled .user-notification {
    display: none;
  }

  .closed-menu.sidebar-mini.sidenav-toggled
    .sidebar-mini.sidenav-toggled.user-notification:before {
    background: transparent;
    display: none;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .avatar-xl {
    font-size: 1rem;
    height: 3rem !important;
    line-height: 3rem;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    width: 3rem !important;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .sidebar-navs {
    display: none;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .app-sidebar__user {
    margin-top: 74px;
    right: 30px !important;
    top: 0.5rem;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .app-sidebar__user-avatar {
    height: 25px;
    width: 25px;
  }

  .closed-menu.sidebar-mini.sidenav-toggled
    .side-menu
    li
    .side-menu__item.active:before {
    display: none;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .app-sidebar__user {
    margin-bottom: 0;
    padding: 4px 0 0;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .profile-img {
    right: 19px;
    top: 0;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .app-content {
    margin-left: 0;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .app-sidebar {
    left: -250px;
    max-height: 100%;
    overflow: hidden;
    transition: left 0.3s ease, width 0.3s ease;
    width: 71px;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    overflow: visible;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .side-menu__item {
    overflow: hidden;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .side-menu__item:hover {
    overflow: visible;
  }

  .closed-menu.sidebar-mini.sidenav-toggled
    .side-menu__item:hover
    .side-menu__label {
    opacity: 1;
  }

  .closed-menu.sidebar-mini.sidenav-toggled
    .side-menu__item:hover
    + .slide-menu {
    visibility: visible;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .side-menu__label {
    background: #fff;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2);
    display: block;
    left: 50px;
    line-height: 1;
    margin-left: -3px;
    min-width: 180px;
    opacity: 0;
    padding: 12px 5px 12px 20px;
    position: absolute;
    top: 0;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .slide:hover .side-menu__label {
    opacity: 1;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .slide:hover .slide-menu {
    max-height: 100%;
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .slide .side-menu__label {
    border-bottom-right-radius: 0;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .slide-menu {
    border-bottom-right-radius: 4px;
    box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2);
    left: 0;
    min-width: 180px;
    opacity: 0;
    position: absolute;
    transition: visibility 0.3s ease;
    visibility: hidden;
    z-index: 9;
  }

  .closed-menu.app.sidebar-mini.sidenav-toggled .side-menu__item {
    border-left: 0;
    border-radius: 0;
    display: block;
    margin: 0 auto;
    padding: 10px 0;
    text-align: center;
  }

  .closed-menu.app.sidebar-mini.sidenav-toggled .side-menu_label {
    display: block;
    font-size: 12px;
  }

  .closed-menu.app.sidebar-mini.sidenav-toggled .side-menu__label {
    background: transparent;
    box-shadow: none;
    display: block;
    font-size: 14px;
    left: 0;
    margin: 0;
    min-width: 100%;
    opacity: 1;
    padding: 0;
    position: relative;
  }

  .closed-menu.sidebar-mini.sidenav-toggled .app-sidebar__user .avatar-md {
    margin: 0 auto;
  }

  .closed-menu.app.sidebar-mini.sidenav-toggled .nav-badge {
    font-size: 11px;
    padding: 0.2rem 0.4rem;
    position: absolute;
    right: 28px;
    top: 8px;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    max-height: 100%;
    transition: left 0.3s ease, width 0.3s ease;
    width: 250px;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user {
    align-items: center;
    border-bottom: 1px solid #e6ebf1;
    display: inline-block;
    padding: 20px;
    width: 100%;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user
    .dropdown-menu {
    top: 10px !important;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1 .sidebar-navs {
    display: block;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user-avatar {
    display: inline-block;
    flex: 0 0 auto;
    margin-right: 15px;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user-name {
    color: #e5e9ec;
    display: inline-block;
    font-size: 17px;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 0;
    margin-top: 5px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user-name.text-sm {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    height: auto;
    margin: 0 !important;
    width: 100%;
  }

  .closed-menu .app-sidebar__user-designation {
    color: #e5e9ec;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 5px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu {
    margin-bottom: 0;
    padding-bottom: 40px;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu__item {
    align-items: center;
    border-radius: 0.25rem;
    display: flex !important;
    display: -ms-flexbox;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.40625rem;
    margin: 0 10px 3px;
    padding: 1px 22px 1px 5px;
    position: relative;
    transition: border-start-color 0.3s ease, background-color 0.3s ease;
    vertical-align: middle;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-item.active {
    color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  }

  .closed-menu .slide-item:focus,
  .closed-menu .slide-item:hover {
    color: #b48ef4;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-item.active,
  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide.is-expanded
    a,
  .closed-menu .slide-item:focus,
  .closed-menu .slide-item:hover {
    text-decoration: none;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu
    .side-menu__icon {
    margin-right: 0;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu__label {
    display: inline-flex !important;
    min-width: inherit;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu__icon {
    background: transparent;
    font-size: 25px;
    height: 36px;
    line-height: 30px;
    margin-right: 0.5rem;
    padding: 8px !important;
    text-align: center;
    vertical-align: middle;
    width: 36px;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu__label {
    display: inline-block;
    flex: 1 1 auto;
    font-size: 14px;
    position: unset;
    white-space: nowrap;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide.is-expanded
    .slide-menu {
    display: block;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide.is-expanded
    .angle {
    transform: rotate(90deg);
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .angle {
    display: inline-block;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-menu {
    box-shadow: none;
    display: none;
    font-size: 0.8rem !important;
    min-width: 0;
    opacity: inherit;
    padding: 0;
    position: unset;
    top: inherit;
    visibility: inherit;
    width: 100%;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-item {
    align-items: center;
    display: flex;
    font-size: 13px;
    padding: 10px 14px 10px 55px;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-item
    .icon {
    margin-right: 5px;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .angle {
    font-size: 0.95;
    transform-origin: center;
    transition: transform 0.3s ease;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user
    img {
    font-size: 1.75rem;
    height: 4rem !important;
    line-height: 4rem;
    margin-bottom: 0.25rem !important;
    margin-top: 8px;
    width: 4rem !important;
  }

  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .user-info {
    display: inline-block;
  }

  .closed-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar__user .user-pic {
    margin: 0 0 12px;
  }
}

.closed-menu.app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
  position: relative;
}

.closed-menu.app.sidebar-mini .side-menu_label {
  display: none;
}

.closed-menu .dropdown-menu {
  border-radius: 0;
}

.closed-menu .dropdown-menu.dropdown-menu-end {
  left: auto;
}

.closed-menu .dropdown-item .fa,
.closed-menu .dropdown-item .icon {
  vertical-align: middle;
}

.closed-menu .app-title {
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: -30px -30px 30px;
  padding: 20px 30px;
}

.closed-menu .app-title h1 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

.closed-menu .app-title p {
  font-style: italic;
  margin-bottom: 0;
}

@media print {
  .closed-menu .app-title {
    display: none;
  }
}

@media (max-width: 480px) {
  .closed-menu .app-title {
    align-items: flex-left;
    flex-direction: column;
    margin: -15px -15px 15px;
    padding: 20px;
  }

  .closed-menu .app-title p {
    display: none;
  }
}

.closed-menu .app-breadcrumb {
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
  text-align: right;
  text-align: left;
  text-transform: capitalize;
}

@media (max-width: 480px) {
  .closed-menu .app-breadcrumb {
    margin-top: 10px;
  }
}

@media print {
  .closed-menu .tile {
    border: 1px solid #ddd;
  }
}

.closed-menu.sidenav-toggled .app-sidebar__user .avatar-md {
  font-size: 1rem;
  line-height: 2rem;
}

.closed-menu
  .app-sidebar
  .mCS-minimal.mCSB_scrollTools
  .mCSB_dragger
  .mCSB_dragger_bar {
  background: rgba(0, 0, 0, 0.05);
}

.closed-menu .app-sidebar .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.closed-menu .app-sidebar .mCSB_scrollTools .mCSB_draggerRail {
  background: hsla(0, 0%, 100%, 0.2);
}

.closed-menu .slide-menu a:before {
  content: "";
  margin-right: 8px;
  opacity: 0.6;
  position: relative;
}

.closed-menu.sidenav-toggled
  .main-wrapper
  .side-menu
  .side-menu__item
  .nav-badge {
  display: block !important;
  left: 57px;
  padding: 3px 5px !important;
  position: absolute;
  top: 5px;
}

.closed-menu.sidenav-toggled
  .main-wrapper
  .side-menu
  .side-menu__item
  .nav-badge1 {
  display: none;
}

.closed-menu .nav-badge {
  border-radius: 30px;
  font-size: 12px;
  padding: 0.4em 0.6em;
}

@media (max-width: 575.98px) {
  .closed-menu .app-header .header-brand-img.mobile-logo {
    margin-left: 0;
    margin-top: 16px;
  }

  .closed-menu .app-content .side-app {
    padding: 25px 0.75rem 0;
  }

  .closed-menu .app-sidebar {
    margin-top: 74px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .closed-menu .app-header .header-brand,
  .closed-menu .app-header .header-brand-img.desktop-lgo {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .closed-menu .footer {
    padding: 1.25rem;
  }

  .closed-menu footer.footer {
    padding: 1.25rem 1.25rem 1.25rem 250px;
  }

  .closed-menu.sidenav-toggled footer.footer {
    padding: 1.25rem 1.25rem 1.25rem 70px;
  }
}

@media (max-width: 768px) and (min-width: 481px) {
  .closed-menu .header-brand {
    margin-left: 0;
    min-width: auto !important;
  }
}

@media (max-width: 767px) {
  .closed-menu .app-sidebar__logo {
    display: none;
  }
}

@media (min-width: 768px) {
  .closed-menu .app-header .header-brand {
    display: none;
  }

  .closed-menu .header.top-header {
    padding-left: 250px;
  }

  .closed-menu.sidenav-toggled .header.top-header {
    padding-left: 70px;
  }

  .closed-menu.sidenav-toggled .app-sidebar__logo {
    border-right: 0 !important;
    padding: 11px 12px;
    width: 70px;
  }

  .closed-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
    border-right: 1px solid #e6ebf1 !important;
  }
}

.closed-menu .app-sidebar__logo {
  background: #fff;
  border-bottom: 1px solid #e6ebf1;
  border-right: 1px solid #e6ebf1;
  padding: 11px 20px;
  position: fixed;
  text-align: center;
  transition: left 0.3s ease, width 0.3s ease;
  width: 250px;
  z-index: 999999;
}

.closed-menu .close-toggle {
  display: none;
}

.closed-menu .app-sidebar__toggle a i {
  line-height: 1.5;
  margin-top: 0;
}

@media (min-width: 768px) {
  .closed-menu .header-brand-img.dark-logo,
  .closed-menu .header-brand-img.darkmobile-logo,
  .closed-menu .header-brand-img.mobile-logo,
  .closed-menu.sidenav-toggled .header-brand-img.desktop-lgo {
    display: none;
  }

  .closed-menu.sidenav-toggled .header-brand-img.mobile-logo {
    display: flex;
    margin: 0 auto;
  }

  .closed-menu.sidenav-toggled.sidenav-toggled1 .header-brand-img.mobile-logo {
    display: none;
  }

  .closed-menu.sidenav-toggled.sidenav-toggled1 .header-brand-img.desktop-lgo {
    display: flex;
    margin: 0 auto;
  }

  .closed-menu .app-sidebar.app-sidebar3 {
    top: 74px;
  }

  .closed-menu .app-sidebar.app-sidebar2 {
    box-shadow: none;
    height: 73px;
    z-index: 9999;
  }

  .closed-menu.sidenav-toggled .app-sidebar-help .help-dropdown {
    display: none;
  }

  .closed-menu.sidenav-toggled.sidenav-toggled1
    .app-sidebar-help
    .help-dropdown {
    display: flex;
  }

  .closed-menu.sidenav-toggled .app-sidebar-help,
  .closed-menu.sidenav-toggled .app-sidebar .side-item.side-item-category,
  .closed-menu.sidenav-toggled .side-badge {
    display: none;
  }

  .closed-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar-help {
    width: 250px;
  }

  .closed-menu.app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    margin-right: 0;
  }

  .closed-menu.app.sidebar-mini.sidenav-toggled
    .slide
    a.side-menu__item.active:after {
    top: 20px;
  }

  .closed-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar-help,
  .closed-menu.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-item.side-item-category,
  .closed-menu.sidenav-toggled.sidenav-toggled1 .side-badge {
    display: block;
  }

  .closed-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
    border-right: 1px solid #e6ebf1;
    width: 250px;
  }
}

.closed-menu .app-sidebar.app-sidebar3 {
  padding-bottom: 70px;
}

.closed-menu .app-sidebar-help {
  background: #fff;
  border-top: 1px solid #e6ebf1;
  bottom: 0;
  position: fixed;
  width: 250px;
  z-index: 11;
}

.closed-menu .app-sidebar-help .header-icon {
  height: 30px;
  width: 30px;
}

.closed-menu .sub-slide-item {
  align-items: center;
  color: #6c756e;
  display: flex;
  font-size: 12px;
  height: 30px;
  height: 28px !important;
  list-style: none;
  padding: 19px 0 19px 60px !important;
  position: relative;
}

.closed-menu .sub-slide-menu {
  display: none;
  font-size: 0.8rem !important;
  list-style: none;
  padding: 0;
}

.closed-menu .slide-menu .sub-slide.active.is-expanded {
  background: hsla(0, 0%, 100%, 0.09);
}

.closed-menu .sub-slide.is-expanded .sub-slide-menu {
  display: block;
}

.closed-menu .sub-slide.is-expanded .sub-slide-menu li:last-child {
  padding-bottom: 10px;
}

.closed-menu .sub-side-menu__item {
  color: #6c756e;
  display: block;
  font-size: 13px;
  height: 40px !important;
  line-height: 40px;
  padding-left: 44px !important;
  padding-right: 20px !important;
}

.closed-menu .slide a.sub-side-menu__item {
  transition: all 0.3s;
}

.closed-menu .slide a.sub-side-menu__item:hover {
  margin-left: 5px;
  text-decoration: none;
  transition: all 0.3s;
}

.closed-menu .sub-angle {
  float: right;
  line-height: 40px;
  margin-top: 12px;
}

.closed-menu .help-dropdown {
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe !important;
}

.closed-menu .slide.is-expanded a.sub-side-menu__item {
  position: relative;
}

.closed-menu .app-sidebar__user .user-pic img {
  border: 3px solid #f8f8fc;
  box-shadow: 0 0 0 4px #eeeef3;
  height: 58px;
  margin-top: 8px;
  width: 58px;
}

.closed-menu .app-sidebar__user .user-pic img:after {
  background-color: #8760fb;
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.closed-menu .sidemenu-footer .icon {
  margin: 5px;
  padding: 7px 14px;
  position: relative;
  text-align: center;
}

.closed-menu .sidemenu-footer .header-icons {
  fill: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  height: 24px;
  width: 24px;
}

.closed-menu .tooltip {
  z-index: 999 !important;
}

.closed-menu .profile-status {
  border: 2px solid #fff;
  border-radius: 50px;
  bottom: 0;
  content: "";
  height: 12px;
  position: absolute;
  right: 80px;
  top: 51px;
  width: 12px;
}

.closed-menu .app-sidebar .side-item.side-item-category {
  color: #9993b5;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin: 15px 0;
  padding: 0 20px;
  text-transform: uppercase;
}

.closed-menu .app-sidebar .side-item.side-item-category:not(:first-child) {
  margin-bottom: 15px;
  margin-top: 25px;
}

.closed-menu .app-sidebar__user .user-info .user-name {
  font-size: 15px;
  margin-bottom: 2px;
}

.closed-menu .profile-dropdown .nav-link {
  padding-left: 5px;
}

.closed-menu .profile-dropdown img {
  border: 2px solid #f8f8fc;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  height: 35px;
  width: 35px;
}

.closed-menu .user-pro-body .dot-label {
  height: 6px;
  width: 6px;
}

.closed-menu .sidebar-navs a {
  background: #fff;
  color: #68798b !important;
  padding: 1rem !important;
}

.closed-menu .app-sidebar .sidebar-navs {
  padding: 10px 0 0;
}

.closed-menu .sidebar-navs .nav li:last-child a {
  margin-right: 0 !important;
}

.closed-menu .sidebar-navs .header-icons {
  fill: #5c678f;
  color: #5c678f;
  font-size: 20px;
  vertical-align: middle;
}

.closed-menu .slide.active.is-expanded .side-menu__item {
  border-radius: 50px;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  text-decoration: none;
}

.closed-menu.app.sidebar-mini.sidenav-toggled
  .slide.active.is-expanded
  .side-menu__item {
  border-radius: 0;
}

@media (min-width: 767px) {
  .closed-menu.sidebar-mini.sidenav-toggled .app-sidebar,
  .closed-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    max-height: 100%;
  }
}

.closed-menu .sub-slide-item2 {
  font-size: 12px;
  padding: 19px 0 19px 70px !important;
}

.closed-menu .sub-slide-item2,
.closed-menu .sub-slide-menu2 .sub-slide-item2 {
  align-items: center;
  display: flex;
  height: 28px !important;
  list-style: none;
  position: relative;
}

.closed-menu .sub-slide-menu2 .sub-slide-item2 {
  font-size: 11px;
  padding: 19px 0 19px 85px !important;
}

.closed-menu .sub-slide-menu2 {
  display: none;
}

.closed-menu .sub-slide2.is-expanded .sub-slide-menu2 {
  display: block;
}

.closed-menu .sub-angle2 {
  position: absolute;
  right: 20px;
  top: 13px;
  transform-origin: center;
  transition: transform 0.3s ease;
}

.closed-menu .sub-slide2.is-expanded .sub-angle2 {
  transform: rotate(90deg);
}

.closed-menu .sub-slide.is-expanded .sub-angle {
  font-size: 12px;
  transform: rotate(90deg);
}

.closed-menu .side-menu__item .side-badge {
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 0.25em 0.4em !important;
}

.closed-menu .side-menu__icon {
  fill: #555b6d;
  color: #555b6d;
}

.closed-menu .sidemneu-icon2 {
  fill: #8e98db;
}

@media (min-width: 768px) {
  .closed-menu .rtl.app.sidenav-toggled .app-sidebar {
    right: 250px;
  }
}

@media (max-width: 767px) {
  .closed-menu .rtl.app .app-sidebar {
    margin-top: 76px !important;
    right: -265px;
  }

  .closed-menu .rtl.app.sidenav-toggled .app-sidebar {
    right: 0;
  }
}

.closed-menu .rtl .app-content {
  transition: margin-right 0.3s ease;
}

@media (min-width: 768px) {
  .closed-menu .rtl .app-content {
    margin-left: inherit;
    margin-right: 250px;
  }

  .closed-menu .rtl .app-header {
    padding-right: 250px;
  }

  .closed-menu .rtl.sidebar-mini.sidenav-toggled .app-header {
    padding-right: 70px;
  }
}

.closed-menu .rtl .app-sidebar__toggle {
  border-left: 1px solid #e6ebf1;
  margin-right: -12px;
}

.closed-menu .rtl .app-sidebar__toggle .header-icon {
  margin-top: 5px;
}

.closed-menu .rtl .app-sidebar {
  border-left: 1px solid #e6ebf1;
  right: 0;
  transition: right 0.3s ease, width 0.3s ease;
}

.closed-menu .rtl .side-menu__item {
  padding: 3px 5px 3px 22px;
}

.closed-menu .rtl .side-menu .side-menu__icon {
  margin-left: 0.5rem;
}

.closed-menu .rtl .side-menu__label {
  text-align: right;
}

.closed-menu .rtl .slide.is-expanded .angle {
  transform: rotate(-90deg);
}

.closed-menu .rtl .slide-item {
  padding: 7px 44px 7px 14px;
}

.closed-menu .rtl .angle {
  font-size: 12px;
}

@media (min-width: 768px) {
  .closed-menu .rtl.sidebar-mini.sidenav-toggled .app-content {
    margin-right: 70px;
  }

  .closed-menu .rtl.sidebar-mini.sidenav-toggled .app-sidebar {
    right: 0;
    transition: right 0.3s ease, width 0.3s ease;
  }
}

.closed-menu .rtl.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
  transition: right 0.3s ease, width 0.3s ease;
}

.closed-menu
  .rtl.sidebar-min.sidenav-toggled.sidenav-toggled1
  .app-sidebar
  .side-menu__item {
  padding: 3px 5px 3px 22px;
}

.closed-menu
  .rtl.sidebar-mini.sidenav-toggled.sidenav-toggled1
  .app-sidebar
  .side-menu__icon {
  margin-left: 0.5rem;
}

.closed-menu .rtl .angle {
  font-size: 0.95rem;
}

.closed-menu .rtl .dropdown-menu.dropdown-menu-end {
  left: 0 !important;
}

@media (min-width: 768px) {
  .closed-menu .rtl footer.footer {
    padding: 1.25rem 250px 1.25rem 1.25rem;
  }

  .closed-menu .rtl.sidenav-toggled footer.footer {
    padding: 1.25rem 70px 1.25rem 1.25rem;
  }

  .closed-menu .rtl.sidenav-toggled .app-sidebar__logo {
    border-left: 1px solid #e6ebf1 !important;
    padding: 11px 12px;
    width: 70px;
  }

  .closed-menu .rtl.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
    border-left: 1px solid #e6ebf1 !important;
    width: 250px;
  }
}

.closed-menu .rtl .app-sidebar__logo {
  border-left: 1px solid #e6ebf1 !important;
}

.closed-menu .rtl .sub-slide-item {
  padding: 19px 60px 19px 0 !important;
}

.closed-menu .rtl .sub-side-menu__item {
  padding-left: 20px !important;
  padding-right: 44px !important;
  text-align: right;
}

.closed-menu .rtl .sub-angle {
  float: left;
}

.closed-menu .rtl .app-sidebar .side-item.side-item-category {
  text-align: right;
}

.closed-menu .rtl .sub-slide-item2 {
  padding: 19px 70px 19px 0 !important;
}

.closed-menu .rtl .sub-slide-menu2 .sub-slide-item2 {
  padding: 19px 85px 19px 0 !important;
}

.closed-menu .rtl .sub-angle2 {
  left: 20px;
}

.closed-menu .rtl .sub-slide2.is-expanded .sub-angle2,
.closed-menu .rtl .sub-slide.is-expanded .sub-angle {
  transform: rotate(-90deg);
}

@media (min-width: 768px) {
  .closed-menu .rtl .app-header {
    padding-bottom: 0;
    padding-left: 0;
  }

  .closed-menu .rtl .search-element {
    margin-left: inherit;
    margin-right: 16px;
  }

  .closed-menu .rtl.sidebar-mini.sidenav-toggled .app-header {
    padding-left: 0;
  }
}

.closed-menu .rtl.sidenav-toggled.sidenav-toggled1 .header-brand-img {
  height: 2.6rem;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .closed-menu .rtl .desktop-lgo {
    left: 0;
    margin: 0 auto;
    right: 0;
  }
}

@media (min-width: 576px) {
  .closed-menu .rtl .header .main-header-end .nav-link.icon {
    padding: 2.2rem 0.75rem !important;
  }
}

@media (min-width: 768px) {
  .closed-menu .rtl.sidebar-mini.sidenav-toggled .app-content {
    margin-left: 0;
  }
}

@media (max-width: 767.98px) {
  .closed-menu .rtl .app-content {
    margin-top: 50px;
    min-width: 100%;
  }

  .closed-menu .rtl .header .main-header-end .nav-link.icon {
    padding: 1.8rem 0.3rem !important;
  }
}

@media (min-width: 576px) {
  .closed-menu .rtl .header .nav-link.icon.layout-setting {
    border-left: 1px solid #e6ebf1 !important;
  }
}

@media (min-width: 767.98px) and (max-width: 992px) {
  .closed-menu .rtl .header .main-header-end .nav-link.icon {
    padding: 2.2rem 0.3rem !important;
  }
}

@media (min-width: 768px) {
  .closed-menu
    .rtl.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu__item {
    padding: 1px 5px 1px 22px;
  }

  .closed-menu
    .rtl.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-item {
    padding: 10px 55px 10px 14px;
  }

  .closed-menu.rtl.sidebar-mini.sidenav-toggled .app-sidebar {
    right: -70px;
  }

  .closed-menu.rtl.sidebar-mini.sidenav-toggled .app-content {
    margin-right: 0;
  }

  .closed-menu.rtl.sidebar-mini.sidenav-toggled .app-header {
    padding-right: 0;
  }
}

.app-sidebar {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
}

.dark-mode .app-sidebar {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.8) !important;
}

@media (min-width: 768px) {
  .app.sidenav-toggled .app-content {
    margin-left: 0;
  }

  .app.sidenav-toggled .app-sidebar {
    left: 250px;
  }

  .app.sidenav-toggled .app-sidebar__overlay {
    visibility: hidden;
  }

  .app-sidebar__user {
    margin-top: 74px;
  }
}

@media (max-width: 767px) {
  .side-menu {
    margin-top: 0 !important;
  }

  .app {
    overflow-x: hidden;
  }

  .app .app-sidebar {
    left: -265px;
    margin-top: 74px !important;
  }

  .app .app-sidebar__overlay {
    visibility: hidden;
  }

  .app.sidenav-toggled .app-content {
    margin-left: 0;
  }

  .app.sidenav-toggled .app-sidebar {
    left: 0;
  }

  .app.sidenav-toggled .app-sidebar__overlay {
    visibility: visible;
  }

  .sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    line-height: 1;
  }
}

.app-sidebar .ps__thumb-y {
  right: 0;
}

.app-content {
  margin-bottom: 0 !important;
  margin-top: 50px;
  min-height: calc(100vh - 50px);
  overflow: hidden;
  transition: margin-left 0.3s ease;
}

.app-content .side-app {
  padding: 25px 1.5rem 0;
}

.footer .container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
}

@media (min-width: 768px) {
  .app-content {
    margin-left: 250px;
    padding-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .app-content {
    margin-top: 50px;
    min-width: 100%;
  }
}

@media print {
  .app-content {
    background-color: #fff;
    margin: 0;
    padding: 0;
  }
}

.light-text {
  font-weight: 300 !important;
}

.semibold-text {
  font-weight: 600 !important;
}

.line-head {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.app-header {
  border-bottom: 1px solid #e6ebf1 !important;
  box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: padding-left 0.3s ease;
  width: 100%;
  z-index: 999999;
}

@media (min-width: 768px) {
  .app-header {
    padding-bottom: 0;
    padding-left: 250px;
  }

  .sidebar-mini.sidenav-toggled .app-header {
    padding-left: 70px;
  }
}

@media print {
  .app-header {
    display: none;
  }
}

.app-header__logo {
  color: #fff;
  flex: 1 0 auto;
  font-family: Niconne;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;
  padding: 0 15px;
  text-align: center;
}

.app-header__logo:focus,
.app-header__logo:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .app-header__logo {
    display: block;
    flex: 0 0 auto;
    width: 250px;
  }
}

.app-sidebar__toggle {
  border-right: 1px solid #e6ebf1;
  font-size: 1.2rem;
  margin-left: -12px;
  padding: 0.85rem 1rem;
  position: relative;
}

.app-sidebar__toggle a {
  color: #b6c1d9;
}

.app-sidebar__toggle .header-icon {
  height: 38px;
  line-height: 42px;
  margin-top: 3px;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  width: 38px;
}

.app-sidebar__toggle:focus,
.app-sidebar__toggle:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  .app-sidebar__toggle {
    order: -1;
  }
}

.app-nav {
  display: flex;
  justify-content: flex-right;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .app-nav {
    flex: 1 0 auto;
  }
}

.app-nav__item {
  color: #a8a8a8;
  display: block;
  line-height: 20px;
  padding: 15px;
  transition: background-color 0.3s ease;
}

.app-nav__item:focus,
.app-nav__item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}

.app-search {
  align-self: center;
  display: flex;
  margin-right: 15px;
  padding: 10px 0;
  position: relative;
}

@media (max-width: 480px) {
  .app-search {
    display: none;
  }
}

.app-search__input {
  background-color: hsla(0, 0%, 100%, 0.8);
  border: 0;
  border-radius: 2px;
  padding: 5px 30px 5px 10px;
  transition: background-color 0.3s ease;
}

.app-search__input:-ms-input-placeholder,
.app-search__input::-ms-input-placeholder,
.app-search__input::-webkit-input-placeholder,
.app-search__input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.app-search__button {
  background: none;
  border: 0;
  bottom: 10px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  padding: 0 10px;
  position: absolute;
  right: 0;
  top: 10px;
}

.app-notification {
  min-width: 270px;
}

.app-notification__title {
  background-color: rgba(0, 150, 136, 0.4);
  color: #333;
  padding: 8px 20px;
  text-align: center;
}

.app-notification__footer {
  background-color: #eee;
  padding: 8px 20px;
  text-align: center;
}

.app-notification__content {
  max-height: 220px;
  overflow-y: auto;
}

.app-notification__content::-webkit-scrollbar {
  width: 6px;
}

.app-notification__content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.app-notification__item {
  border-bottom: 1px solid #ddd;
  color: inherit;
  display: flex;
  padding: 8px 20px;
  transition: background-color 0.3s ease;
}

.app-notification__item:focus,
.app-notification__item:hover {
  background-color: #e6ebf1;
  color: inherit;
  text-decoration: none;
}

.app-notification__message,
.app-notification__meta {
  margin-bottom: 0;
}

.app-notification__icon {
  padding-right: 10px;
}

.app-notification__message {
  line-height: 1.2;
}

.app-sidebar {
  background: #fff !important;
  border-right: 1px solid #e6ebf1;
  bottom: 0;
  box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
  left: 0;
  margin-top: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  transition: left 0.3s ease, width 0.3s ease;
  width: 250px;
  z-index: 9999999;
}

.app-sidebar::-webkit-scrollbar {
  width: 6px;
}

.app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

@media print {
  .app-sidebar {
    display: none;
  }
}

@media (max-width: 767px) {
  .app-sidebar__overlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9;
  }
}

.app-sidebar__user {
  align-items: center;
  border-bottom: 1px solid #e6ebf1;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  display: flex;
  display: inline-block;
  padding: 20px;
  width: 100%;
}

.app-sidebar__user .dropdown-menu {
  top: 10px !important;
}

.sidenav-toggled .app-sidebar__user .user-pic {
  margin: 0;
}

.app-sidebar__user .user-pic {
  margin-bottom: 12px;
}

.app-sidebar__user-avatar {
  flex: 0 0 auto;
  margin-right: 15px;
}

.app-sidebar__user-name {
  font-size: 17px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 0;
  margin-top: 5px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-sidebar__user-name.text-sm {
  font-size: 12px;
  font-weight: 400;
}

.app-sidebar__user-designation {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 5px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.side-menu {
  margin-bottom: 0;
  margin-top: 90px;
  padding-bottom: 40px;
}

.side-menu__item {
  align-items: center;
  border-radius: 0.25rem;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 3px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 3px 22px 3px 5px;
  position: relative;
}

.side-menu__item.active,
.side-menu__item:focus,
.side-menu__item:hover {
  background: #f3f6f8;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  text-decoration: none;
}

.side-menu__item.active .side-menu__icon,
.side-menu__item:focus .side-menu__icon,
.side-menu__item:hover .side-menu__icon {
  fill: #664dc9 !important;
}

.side-menu__item .fe-chevron-right {
  font-size: 0.95rem;
}

.sidenav-toggled .side-menu__item:focus {
  background: transparent !important;
}

.sidenav-toggled .side-menu__item:focus .side-menu__icon {
  fill: #6c756e;
}

.sidenav-toggled1 .side-menu__item:focus {
  background: #f3f6f8 !important;
}

.sidenav-toggled1 .side-menu__item:focus .side-menu__icon {
  fill: #fff;
}

@media (min-width: 768px) {
  .app.sidebar-mini.sidenav-toggled .side-menu__label {
    background: transparent;
    box-shadow: none;
    display: none !important;
    font-size: 14px;
    left: 0;
    margin: 0;
    min-width: 100%;
    opacity: 1;
    padding: 0;
    position: relative;
  }
}

.slide-item.active,
.slide-item:focus,
.slide-item:hover {
  text-decoration: none;
}

.slide.is-expanded a.slide-item {
  transition: all 0.3s;
}

.slide.is-expanded a.slide-item:hover {
  margin-left: 5px;
  text-decoration: none;
  transition: all 0.3s;
}

.side-menu .side-menu__icon {
  font-size: 1.24rem;
  height: 36px;
  line-height: 30px;
  margin-right: 0.5rem;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  width: 36px;
}

.side-menu__icon {
  flex: 0 0 auto;
  width: 25px;
}

.side-menu__label {
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  letter-spacing: 0.6px;
  opacity: 1;
  transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
  white-space: nowrap;
}

.slide.is-expanded .slide-menu {
  display: block;
}

.slide.is-expanded .angle {
  transform: rotate(90deg);
}

.slide-menu {
  display: none;
  font-size: 0.8rem !important;
  padding: 0;
}

.slide-item {
  align-items: center;
  color: #6c756e;
  display: flex;
  font-size: 13px;
  padding: 7px 14px 7px 44px;
}

.slide-item .icon {
  margin-right: 5px;
}

.angle {
  font-size: 12px;
  transform-origin: center;
  transition: transform 0.3s ease;
}

@media (min-width: 768px) {
  .sidebar-mini.sidenav-toggled {
    right: 30px !important;
    top: 0.5rem;
  }

  .sidebar-mini.sidenav-toggled .angle,
  .sidebar-mini.sidenav-toggled .app-sidebar__user-designation,
  .sidebar-mini.sidenav-toggled .app-sidebar__user-name,
  .sidebar-mini.sidenav-toggled .user-info,
  .sidebar-mini.sidenav-toggled .user-notification {
    display: none;
  }

  .sidebar-mini.sidenav-toggled
    .sidebar-mini.sidenav-toggled.user-notification:before {
    background: transparent;
    display: none;
  }

  .sidebar-mini.sidenav-toggled .avatar-xl {
    font-size: 1rem;
    height: 3rem !important;
    line-height: 3rem;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    width: 3rem !important;
  }

  .sidebar-mini.sidenav-toggled .sidebar-navs {
    display: none;
  }

  .sidebar-mini.sidenav-toggled .app-sidebar__user {
    margin-top: 74px;
    right: 30px !important;
    top: 0.5rem;
  }

  .sidebar-mini.sidenav-toggled .app-sidebar__user-avatar {
    height: 25px;
    width: 25px;
  }

  .sidebar-mini.sidenav-toggled .side-menu li .side-menu__item.active:before {
    display: none;
  }

  .sidebar-mini.sidenav-toggled .app-sidebar__user {
    margin-bottom: 0;
    padding: 4px 0 0;
  }

  .sidebar-mini.sidenav-toggled .profile-img {
    right: 19px;
    top: 0;
  }

  .sidebar-mini.sidenav-toggled .app-content {
    margin-left: 70px;
  }

  .sidebar-mini.sidenav-toggled .app-sidebar {
    left: 0;
    max-height: 100%;
    overflow: hidden;
    transition: left 0.3s ease, width 0.3s ease;
    width: 71px;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    overflow: visible;
  }

  .sidebar-mini.sidenav-toggled .side-menu__item {
    overflow: hidden;
  }

  .sidebar-mini.sidenav-toggled .side-menu__item:hover {
    overflow: visible;
  }

  .sidebar-mini.sidenav-toggled .side-menu__item:hover .side-menu__label {
    opacity: 1;
  }

  .sidebar-mini.sidenav-toggled .side-menu__item:hover + .slide-menu {
    visibility: visible;
  }

  .sidebar-mini.sidenav-toggled .side-menu__label {
    background: #fff;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2);
    display: block;
    left: 50px;
    line-height: 1;
    margin-left: -3px;
    min-width: 180px;
    opacity: 0;
    padding: 12px 5px 12px 20px;
    position: absolute;
    top: 0;
  }

  .sidebar-mini.sidenav-toggled .slide:hover .side-menu__label {
    opacity: 1;
  }

  .sidebar-mini.sidenav-toggled .slide:hover .slide-menu {
    max-height: 100%;
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }

  .sidebar-mini.sidenav-toggled .slide .side-menu__label {
    border-bottom-right-radius: 0;
  }

  .sidebar-mini.sidenav-toggled .slide-menu {
    border-bottom-right-radius: 4px;
    box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2);
    left: 0;
    min-width: 180px;
    opacity: 0;
    position: absolute;
    transition: visibility 0.3s ease;
    visibility: hidden;
    z-index: 9;
  }

  .app.sidebar-mini.sidenav-toggled .side-menu__item {
    border-left: 0;
    border-radius: 0;
    display: block;
    margin: 0 auto;
    padding: 10px 0;
    text-align: center;
  }

  .app.sidebar-mini.sidenav-toggled .side-menu_label {
    display: block;
    font-size: 12px;
  }

  .app.sidebar-mini.sidenav-toggled .side-menu__label {
    background: transparent;
    box-shadow: none;
    display: block;
    font-size: 14px;
    left: 0;
    margin: 0;
    min-width: 100%;
    opacity: 1;
    padding: 0;
    position: relative;
  }

  .sidebar-mini.sidenav-toggled .app-sidebar__user .avatar-md {
    margin: 0 auto;
  }

  .app.sidebar-mini.sidenav-toggled .nav-badge {
    font-size: 11px;
    padding: 0.2rem 0.4rem;
    position: absolute;
    right: 28px;
    top: 8px;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    max-height: 100%;
    transition: left 0.3s ease, width 0.3s ease;
    width: 250px;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user {
    align-items: center;
    border-bottom: 1px solid #e6ebf1;
    display: inline-block;
    padding: 20px;
    width: 100%;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user
    .dropdown-menu {
    top: 10px !important;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .sidebar-navs {
    display: block;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user-avatar {
    display: inline-block;
    flex: 0 0 auto;
    margin-right: 15px;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user-name {
    color: #e5e9ec;
    display: inline-block;
    font-size: 17px;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 0;
    margin-top: 5px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user-name.text-sm {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    height: auto;
    margin: 0 !important;
    width: 100%;
  }

  .app-sidebar__user-designation {
    color: #e5e9ec;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 5px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu {
    margin-bottom: 0;
    padding-bottom: 40px;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__item {
    align-items: center;
    border-radius: 0.25rem;
    display: flex !important;
    display: -ms-flexbox;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.40625rem;
    margin: 0 10px 3px;
    padding: 1px 22px 1px 5px;
    position: relative;
    transition: border-start-color 0.3s ease, background-color 0.3s ease;
    vertical-align: middle;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-item.active {
    color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  }

  .slide-item:focus,
  .slide-item:hover {
    color: #b48ef4;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-item.active,
  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide.is-expanded
    a,
  .slide-item:focus,
  .slide-item:hover {
    text-decoration: none;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu
    .side-menu__icon {
    margin-right: 0;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu__label {
    display: inline-flex !important;
    min-width: inherit;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__icon {
    background: transparent;
    font-size: 25px;
    height: 36px;
    line-height: 30px;
    margin-right: 0.5rem;
    padding: 8px !important;
    text-align: center;
    vertical-align: middle;
    width: 36px;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu__label {
    display: inline-block;
    flex: 1 1 auto;
    font-size: 14px;
    position: unset;
    white-space: nowrap;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide.is-expanded
    .slide-menu {
    display: block;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide.is-expanded
    .angle {
    transform: rotate(90deg);
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .angle {
    display: inline-block;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-menu {
    box-shadow: none;
    display: none;
    font-size: 0.8rem !important;
    min-width: 0;
    opacity: inherit;
    padding: 0;
    position: unset;
    top: inherit;
    visibility: inherit;
    width: 100%;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item {
    align-items: center;
    display: flex;
    font-size: 13px;
    padding: 10px 14px 10px 55px;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-item
    .icon {
    margin-right: 5px;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .angle {
    font-size: 0.95;
    transform-origin: center;
    transition: transform 0.3s ease;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user
    img {
    font-size: 1.75rem;
    height: 4rem !important;
    line-height: 4rem;
    margin-bottom: 0.25rem !important;
    margin-top: 8px;
    width: 4rem !important;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .user-info {
    display: inline-block;
  }

  .sidenav-toggled.sidenav-toggled1 .app-sidebar__user .user-pic {
    margin: 0 0 12px;
  }
}

.app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
  position: relative;
}

.app.sidebar-mini .side-menu_label {
  display: none;
}

.dropdown-menu {
  border-radius: 0;
}

.dropdown-menu.dropdown-menu-end {
  left: auto;
}

.dropdown-item .fa,
.dropdown-item .icon {
  vertical-align: middle;
}

.app-title {
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: -30px -30px 30px;
  padding: 20px 30px;
}

.app-title h1 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

.app-title p {
  font-style: italic;
  margin-bottom: 0;
}

@media print {
  .app-title {
    display: none;
  }
}

@media (max-width: 480px) {
  .app-title {
    align-items: flex-left;
    flex-direction: column;
    margin: -15px -15px 15px;
    padding: 20px;
  }

  .app-title p {
    display: none;
  }
}

.app-breadcrumb {
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
  text-align: right;
  text-align: left;
  text-transform: capitalize;
}

@media (max-width: 480px) {
  .app-breadcrumb {
    margin-top: 10px;
  }
}

@media print {
  .tile {
    border: 1px solid #ddd;
  }
}

.sidenav-toggled .app-sidebar__user .avatar-md {
  font-size: 1rem;
  line-height: 2rem;
}

.app-sidebar .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: rgba(0, 0, 0, 0.05);
}

.app-sidebar .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.app-sidebar .mCSB_scrollTools .mCSB_draggerRail {
  background: hsla(0, 0%, 100%, 0.2);
}

.slide-menu a:before {
  content: "";
  margin-right: 8px;
  opacity: 0.6;
  position: relative;
}

.sidenav-toggled .main-wrapper .side-menu .side-menu__item .nav-badge {
  display: block !important;
  left: 57px;
  padding: 3px 5px !important;
  position: absolute;
  top: 5px;
}

.sidenav-toggled .main-wrapper .side-menu .side-menu__item .nav-badge1 {
  display: none;
}

.nav-badge {
  border-radius: 30px;
  font-size: 12px;
  padding: 0.4em 0.6em;
}

@media (max-width: 575.98px) {
  .app-header .header-brand-img.mobile-logo {
    margin-left: 0;
    margin-top: 16px;
  }

  .app-content .side-app {
    padding: 25px 0.75rem 0;
  }

  .app-sidebar {
    margin-top: 74px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .app-header .header-brand,
  .app-header .header-brand-img.desktop-lgo {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .footer {
    padding: 1.25rem;
  }

  footer.footer {
    padding: 1.25rem 1.25rem 1.25rem 250px;
  }

  .sidenav-toggled footer.footer {
    padding: 1.25rem 1.25rem 1.25rem 70px;
  }
}

@media (max-width: 768px) and (min-width: 481px) {
  .header-brand {
    margin-left: 0;
    min-width: auto !important;
  }
}

@media (max-width: 767px) {
  .app-sidebar__logo {
    display: none;
  }
}

@media (min-width: 768px) {
  .app-header .header-brand {
    display: none;
  }

  .header.top-header {
    padding-left: 250px;
  }

  .sidenav-toggled .header.top-header {
    padding-left: 70px;
  }

  .sidenav-toggled .app-sidebar__logo {
    border-right: 0 !important;
    padding: 11px 12px;
    width: 70px;
  }

  .sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
    border-right: 1px solid #e6ebf1 !important;
  }
}

.app-sidebar__logo {
  background: #fff;
  border-bottom: 1px solid #e6ebf1;
  border-right: 1px solid #e6ebf1;
  padding: 11px 20px;
  position: fixed;
  text-align: center;
  transition: left 0.3s ease, width 0.3s ease;
  width: 250px;
  z-index: 999999;
}

.close-toggle {
  display: none;
}

.app-sidebar__toggle a i {
  line-height: 1.5;
  margin-top: 0;
}

@media (min-width: 768px) {
  .header-brand-img.dark-logo,
  .header-brand-img.darkmobile-logo,
  .header-brand-img.mobile-logo,
  .sidenav-toggled .header-brand-img.desktop-lgo {
    display: none;
  }

  .sidenav-toggled .header-brand-img.mobile-logo {
    display: flex;
    margin: 0 auto;
  }

  .sidenav-toggled.sidenav-toggled1 .header-brand-img.mobile-logo {
    display: none;
  }

  .sidenav-toggled.sidenav-toggled1 .header-brand-img.desktop-lgo {
    display: flex;
    margin: 0 auto;
  }

  .app-sidebar.app-sidebar3 {
    top: 74px;
  }

  .app-sidebar.app-sidebar2 {
    box-shadow: none;
    height: 73px;
    z-index: 9999;
  }

  .sidenav-toggled .app-sidebar-help .help-dropdown {
    display: none;
  }

  .sidenav-toggled.sidenav-toggled1 .app-sidebar-help .help-dropdown {
    display: flex;
  }

  .sidenav-toggled .app-sidebar-help,
  .sidenav-toggled .app-sidebar .side-item.side-item-category,
  .sidenav-toggled .side-badge {
    display: none;
  }

  .sidenav-toggled.sidenav-toggled1 .app-sidebar-help {
    width: 250px;
  }

  .app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    margin-right: 0;
  }

  .app.sidebar-mini.sidenav-toggled .slide a.side-menu__item.active:after {
    top: 20px;
  }

  .sidenav-toggled.sidenav-toggled1 .app-sidebar-help,
  .sidenav-toggled.sidenav-toggled1 .app-sidebar .side-item.side-item-category,
  .sidenav-toggled.sidenav-toggled1 .side-badge {
    display: block;
  }

  .sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
    border-right: 1px solid #e6ebf1;
    width: 250px;
  }
}

.app-sidebar.app-sidebar3 {
  padding-bottom: 70px;
}

.app-sidebar-help {
  background: #fff;
  border-top: 1px solid #e6ebf1;
  bottom: 0;
  position: fixed;
  width: 250px;
  z-index: 11;
}

.app-sidebar-help .header-icon {
  height: 30px;
  width: 30px;
}

.sub-slide-item {
  align-items: center;
  color: #6c756e;
  display: flex;
  font-size: 12px;
  height: 30px;
  height: 28px !important;
  list-style: none;
  padding: 19px 0 19px 60px !important;
  position: relative;
}

.sub-slide-menu {
  display: none;
  font-size: 0.8rem !important;
  list-style: none;
  padding: 0;
}

.slide-menu .sub-slide.active.is-expanded {
  background: hsla(0, 0%, 100%, 0.09);
}

.sub-slide.is-expanded .sub-slide-menu {
  display: block;
}

.sub-slide.is-expanded .sub-slide-menu li:last-child {
  padding-bottom: 10px;
}

.sub-side-menu__item {
  color: #6c756e;
  display: block;
  font-size: 13px;
  height: 40px !important;
  line-height: 40px;
  padding-left: 44px !important;
  padding-right: 20px !important;
}

.slide a.sub-side-menu__item {
  transition: all 0.3s;
}

.slide a.sub-side-menu__item:hover {
  margin-left: 5px;
  text-decoration: none;
  transition: all 0.3s;
}

.sub-angle {
  float: right;
  line-height: 40px;
  margin-top: 12px;
}

.help-dropdown {
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe !important;
}

.slide.is-expanded a.sub-side-menu__item {
  position: relative;
}

.app-sidebar__user .user-pic img {
  border: 3px solid #f8f8fc;
  box-shadow: 0 0 0 4px #eeeef3;
  height: 58px;
  margin-top: 8px;
  width: 58px;
}

.app-sidebar__user .user-pic img:after {
  background-color: #8760fb;
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.sidemenu-footer .icon {
  margin: 5px;
  padding: 7px 14px;
  position: relative;
  text-align: center;
}

.sidemenu-footer .header-icons {
  fill: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  height: 24px;
  width: 24px;
}

.tooltip {
  z-index: 999 !important;
}

.profile-status {
  border: 2px solid #fff;
  border-radius: 50px;
  bottom: 0;
  content: "";
  height: 12px;
  position: absolute;
  right: 80px;
  top: 51px;
  width: 12px;
}

.app-sidebar .side-item.side-item-category {
  color: #9993b5;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin: 15px 0;
  padding: 0 20px;
  text-transform: uppercase;
}

.app-sidebar .side-item.side-item-category:not(:first-child) {
  margin-bottom: 15px;
  margin-top: 25px;
}

.app-sidebar__user .user-info .user-name {
  font-size: 15px;
  margin-bottom: 2px;
}

.profile-dropdown .nav-link {
  padding-left: 5px;
}

.profile-dropdown img {
  border: 2px solid #f8f8fc;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  height: 35px;
  width: 35px;
}

.user-pro-body .dot-label {
  height: 6px;
  width: 6px;
}

.sidebar-navs a {
  background: #fff;
  color: #68798b !important;
  padding: 1rem !important;
}

.app-sidebar .sidebar-navs {
  padding: 10px 0 0;
}

.sidebar-navs .nav li:last-child a {
  margin-right: 0 !important;
}

.sidebar-navs .header-icons {
  fill: #5c678f;
  color: #5c678f;
  font-size: 20px;
  vertical-align: middle;
}

.slide.active.is-expanded .side-menu__item {
  border-radius: 50px;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  text-decoration: none;
}

.app.sidebar-mini.sidenav-toggled .slide.active.is-expanded .side-menu__item {
  border-radius: 0;
}

@media (min-width: 767px) {
  .sidebar-mini.sidenav-toggled .app-sidebar,
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    max-height: 100%;
  }
}

.sub-slide-item2 {
  font-size: 12px;
  padding: 19px 0 19px 70px !important;
}

.sub-slide-item2,
.sub-slide-menu2 .sub-slide-item2 {
  align-items: center;
  display: flex;
  height: 28px !important;
  list-style: none;
  position: relative;
}

.sub-slide-menu2 .sub-slide-item2 {
  font-size: 11px;
  padding: 19px 0 19px 85px !important;
}

.sub-slide-menu2 {
  display: none;
}

.sub-slide2.is-expanded .sub-slide-menu2 {
  display: block;
}

.sub-angle2 {
  position: absolute;
  right: 20px;
  top: 13px;
  transform-origin: center;
  transition: transform 0.3s ease;
}

.sub-slide2.is-expanded .sub-angle2 {
  transform: rotate(90deg);
}

.sub-slide.is-expanded .sub-angle {
  font-size: 12px;
  transform: rotate(90deg);
}

.side-menu__item .side-badge {
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 0.25em 0.4em !important;
}

.side-menu__icon {
  fill: #555b6d;
  color: #555b6d;
}

.sidemneu-icon2 {
  fill: #8e98db;
}

@media (min-width: 768px) {
  .rtl.app.sidenav-toggled .app-sidebar {
    right: 250px;
  }
}

@media (max-width: 767px) {
  .rtl.app .app-sidebar {
    margin-top: 76px !important;
    right: -265px;
  }

  .rtl.app.sidenav-toggled .app-sidebar {
    right: 0;
  }
}

.rtl .app-content {
  transition: margin-right 0.3s ease;
}

@media (min-width: 768px) {
  .rtl .app-content {
    margin-left: inherit;
    margin-right: 250px;
  }

  .rtl .app-header {
    padding-right: 250px;
  }

  .rtl.sidebar-mini.sidenav-toggled .app-header {
    padding-right: 70px;
  }
}

.rtl .app-sidebar__toggle {
  border-left: 1px solid #e6ebf1;
  margin-right: -12px;
}

.rtl .app-sidebar__toggle .header-icon {
  margin-top: 5px;
}

.rtl .app-sidebar {
  border-left: 1px solid #e6ebf1;
  right: 0;
  transition: right 0.3s ease, width 0.3s ease;
}

.rtl .side-menu__item {
  padding: 3px 5px 3px 22px;
}

.rtl .side-menu .side-menu__icon {
  margin-left: 0.5rem;
}

.rtl .side-menu__label {
  text-align: right;
}

.rtl .slide.is-expanded .angle {
  transform: rotate(-90deg);
}

.rtl .slide-item {
  padding: 7px 44px 7px 14px;
}

.rtl .angle {
  font-size: 12px;
}

@media (min-width: 768px) {
  .rtl.sidebar-mini.sidenav-toggled .app-content {
    margin-right: 70px;
  }

  .rtl.sidebar-mini.sidenav-toggled .app-sidebar {
    right: 0;
    transition: right 0.3s ease, width 0.3s ease;
  }
}

.rtl.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
  transition: right 0.3s ease, width 0.3s ease;
}

.rtl.sidebar-min.sidenav-toggled.sidenav-toggled1
  .app-sidebar
  .side-menu__item {
  padding: 3px 5px 3px 22px;
}

.rtl.sidebar-mini.sidenav-toggled.sidenav-toggled1
  .app-sidebar
  .side-menu__icon {
  margin-left: 0.5rem;
}

.rtl .angle {
  font-size: 0.95rem;
}

.rtl .dropdown-menu.dropdown-menu-end {
  left: 0 !important;
}

@media (min-width: 768px) {
  .rtl footer.footer {
    padding: 1.25rem 250px 1.25rem 1.25rem;
  }

  .rtl.sidenav-toggled footer.footer {
    padding: 1.25rem 70px 1.25rem 1.25rem;
  }

  .rtl.sidenav-toggled .app-sidebar__logo {
    border-left: 1px solid #e6ebf1 !important;
    padding: 11px 12px;
    width: 70px;
  }

  .rtl.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
    border-left: 1px solid #e6ebf1 !important;
    width: 250px;
  }
}

.rtl .app-sidebar__logo {
  border-left: 1px solid #e6ebf1 !important;
}

.rtl .sub-slide-item {
  padding: 19px 60px 19px 0 !important;
}

.rtl .sub-side-menu__item {
  padding-left: 20px !important;
  padding-right: 44px !important;
  text-align: right;
}

.rtl .sub-angle {
  float: left;
}

.rtl .app-sidebar .side-item.side-item-category {
  text-align: right;
}

.rtl .sub-slide-item2 {
  padding: 19px 70px 19px 0 !important;
}

.rtl .sub-slide-menu2 .sub-slide-item2 {
  padding: 19px 85px 19px 0 !important;
}

.rtl .sub-angle2 {
  left: 20px;
}

.rtl .sub-slide2.is-expanded .sub-angle2,
.rtl .sub-slide.is-expanded .sub-angle {
  transform: rotate(-90deg);
}

@media (min-width: 768px) {
  .rtl .app-header {
    padding-bottom: 0;
    padding-left: 0;
  }

  .rtl .search-element {
    margin-left: inherit;
    margin-right: 16px;
  }

  .rtl.sidebar-mini.sidenav-toggled .app-header {
    padding-left: 0;
  }
}

.rtl.sidenav-toggled.sidenav-toggled1 .header-brand-img {
  height: 2.6rem;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .rtl .desktop-lgo {
    left: 0;
    margin: 0 auto;
    right: 0;
  }
}

@media (min-width: 576px) {
  .rtl .header .main-header-end .nav-link.icon {
    padding: 2.2rem 0.75rem !important;
  }
}

@media (min-width: 768px) {
  .rtl.sidebar-mini.sidenav-toggled .app-content {
    margin-left: 0;
  }
}

@media (max-width: 767.98px) {
  .rtl .app-content {
    margin-top: 50px;
    min-width: 100%;
  }

  .rtl .header .main-header-end .nav-link.icon {
    padding: 1.8rem 0.3rem !important;
  }
}

@media (min-width: 576px) {
  .rtl .header .nav-link.icon.layout-setting {
    border-left: 1px solid #e6ebf1 !important;
  }
}

@media (min-width: 767.98px) and (max-width: 992px) {
  .rtl .header .main-header-end .nav-link.icon {
    padding: 2.2rem 0.3rem !important;
  }
}

@media (min-width: 768px) {
  .rtl.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu__item {
    padding: 1px 5px 1px 22px;
  }

  .rtl.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item {
    padding: 10px 55px 10px 14px;
  }
}

@media (min-width: 768px) {
  .icontext-menu.dark-mode.sidenav-toggled.sidenav-toggled1
    .app-sidebar__logo
    .header-brand-img.dark-logo {
    display: block !important;
  }

  .icontext-menu.app.sidenav-toggled .app-content {
    margin-left: 0;
  }

  .icontext-menu.app.sidenav-toggled .app-sidebar {
    left: 250px;
  }

  .icontext-menu.app.sidenav-toggled .app-sidebar__overlay {
    visibility: hidden;
  }

  .icontext-menu .app-sidebar__user {
    margin-top: 74px;
  }
}

@media (max-width: 767px) {
  .icontext-menu .side-menu {
    margin-top: 0 !important;
  }

  .icontext-menu.app {
    overflow-x: hidden;
  }

  .icontext-menu.app .app-sidebar {
    left: -265px;
    margin-top: 76px !important;
  }

  .icontext-menu.app .app-sidebar__overlay {
    visibility: hidden;
  }

  .icontext-menu.app .sidenav-toggled .app-content {
    margin-left: 0;
  }

  .icontext-menu.app .sidenav-toggled .app-sidebar {
    left: 0;
  }

  .icontext-menu.app .sidenav-toggled .app-sidebar__overlay {
    visibility: visible;
  }

  .icontext-menu .sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    line-height: 1;
  }
}

.icontext-menu .app-sidebar .ps__thumb-y {
  right: 0;
}

.icontext-menu .app-content {
  margin-bottom: 0 !important;
  margin-top: 50px;
  min-height: calc(100vh - 50px);
  overflow: hidden;
  transition: margin-left 0.3s ease;
}

.icontext-menu .app-content .side-app {
  padding: 25px 1.5rem 0;
}

.icontext-menu .footer .container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
}

@media (min-width: 768px) {
  .icontext-menu .app-content {
    margin-left: 250px;
    padding-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .icontext-menu .app-content {
    margin-top: 50px;
    min-width: 100%;
  }
}

@media print {
  .icontext-menu .app-content {
    background-color: #fff;
    margin: 0;
    padding: 0;
  }
}

.icontext-menu .light-text {
  font-weight: 300 !important;
}

.icontext-menu .semibold-text {
  font-weight: 600 !important;
}

.icontext-menu .line-head {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.icontext-menu .app-header {
  border-bottom: 1px solid #e6ebf1 !important;
  box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: padding-left 0.3s ease;
  width: 100%;
  z-index: 999999;
}

@media (min-width: 768px) {
  .icontext-menu .app-header {
    padding-bottom: 0;
    padding-left: 250px;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .app-header {
    padding-left: 100px;
  }
}

@media print {
  .icontext-menu .app-header {
    display: none;
  }
}

.icontext-menu .app-header__logo {
  color: #fff;
  flex: 1 0 auto;
  font-family: Niconne;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;
  padding: 0 15px;
  text-align: center;
}

.icontext-menu .app-header__logo:focus,
.icontext-menu .app-header__logo:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .icontext-menu .app-header__logo {
    display: block;
    flex: 0 0 auto;
    width: 250px;
  }
}

.icontext-menu .app-sidebar__toggle {
  border-right: 1px solid #e6ebf1;
  font-size: 1.2rem;
  margin-left: -12px;
  padding: 0.85rem 1rem;
  position: relative;
}

.icontext-menu .app-sidebar__toggle a {
  color: #b6c1d9;
}

.icontext-menu .app-sidebar__toggle .header-icon {
  height: 38px;
  line-height: 42px;
  margin-top: 5px;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  width: 38px;
}

.icontext-menu .app-sidebar__toggle:focus,
.icontext-menu .app-sidebar__toggle:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  .icontext-menu .app-sidebar__toggle {
    order: -1;
  }
}

.icontext-menu .app-nav {
  display: flex;
  justify-content: flex-right;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .icontext-menu .app-nav {
    flex: 1 0 auto;
  }
}

.icontext-menu .app-nav__item {
  color: #a8a8a8;
  display: block;
  line-height: 20px;
  padding: 15px;
  transition: background-color 0.3s ease;
}

.icontext-menu .app-nav__item:focus,
.icontext-menu .app-nav__item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}

.icontext-menu .app-search {
  align-self: center;
  display: flex;
  margin-right: 15px;
  padding: 10px 0;
  position: relative;
}

@media (max-width: 480px) {
  .icontext-menu .app-search {
    display: none;
  }
}

.icontext-menu .app-search__input {
  background-color: hsla(0, 0%, 100%, 0.8);
  border: 0;
  border-radius: 2px;
  padding: 5px 30px 5px 10px;
  transition: background-color 0.3s ease;
}

.icontext-menu .app-search__input:-ms-input-placeholder,
.icontext-menu .app-search__input::-ms-input-placeholder,
.icontext-menu .app-search__input::-webkit-input-placeholder,
.icontext-menu .app-search__input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.icontext-menu .app-search__button {
  background: none;
  border: 0;
  bottom: 10px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  padding: 0 10px;
  position: absolute;
  right: 0;
  top: 10px;
}

.icontext-menu .app-notification {
  min-width: 270px;
}

.icontext-menu .app-notification__title {
  background-color: rgba(0, 150, 136, 0.4);
  color: #333;
  padding: 8px 20px;
  text-align: center;
}

.icontext-menu .app-notification__footer {
  background-color: #eee;
  padding: 8px 20px;
  text-align: center;
}

.icontext-menu .app-notification__content {
  max-height: 220px;
  overflow-y: auto;
}

.icontext-menu .app-notification__content::-webkit-scrollbar {
  width: 6px;
}

.icontext-menu .app-notification__content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.icontext-menu .app-notification__item {
  border-bottom: 1px solid #ddd;
  color: inherit;
  display: flex;
  padding: 8px 20px;
  transition: background-color 0.3s ease;
}

.icontext-menu .app-notification__item:focus,
.icontext-menu .app-notification__item:hover {
  background-color: #e6ebf1;
  color: inherit;
  text-decoration: none;
}

.icontext-menu .app-notification__message,
.icontext-menu .app-notification__meta {
  margin-bottom: 0;
}

.icontext-menu .app-notification__icon {
  padding-right: 10px;
}

.icontext-menu .app-notification__message {
  line-height: 1.2;
}

.icontext-menu .app-sidebar {
  background: #fff !important;
  border-right: 1px solid #e6ebf1;
  bottom: 0;
  box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
  left: 0;
  margin-top: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  transition: left 0.3s ease, width 0.3s ease;
  width: 250px;
  z-index: 9999999;
}

.icontext-menu .app-sidebar::-webkit-scrollbar {
  width: 6px;
}

.icontext-menu .app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

@media print {
  .icontext-menu .app-sidebar {
    display: none;
  }
}

@media (max-width: 767px) {
  .icontext-menu .app-sidebar__overlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9;
  }
}

.icontext-menu .app-sidebar__user {
  align-items: center;
  border-bottom: 1px solid #e6ebf1;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  display: flex;
  display: inline-block;
  padding: 20px;
  width: 100%;
}

.icontext-menu .app-sidebar__user .dropdown-menu {
  top: 10px !important;
}

.icontext-menu.sidenav-toggled .app-sidebar__user .user-pic {
  margin: 0;
}

.icontext-menu .app-sidebar__user .user-pic {
  margin-bottom: 12px;
}

.icontext-menu .app-sidebar__user-avatar {
  flex: 0 0 auto;
  margin-right: 15px;
}

.icontext-menu .app-sidebar__user-name {
  font-size: 17px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 0;
  margin-top: 5px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icontext-menu .app-sidebar__user-name.text-sm {
  font-size: 12px;
  font-weight: 400;
}

.icontext-menu .app-sidebar__user-designation {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 5px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icontext-menu .side-menu {
  margin-bottom: 0;
  margin-top: 90px;
  padding-bottom: 40px;
}

.icontext-menu .side-menu__item {
  align-items: center;
  border-radius: 0.25rem;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 3px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 3px 22px 3px 5px;
  position: relative;
}

.icontext-menu .side-menu__item.active,
.icontext-menu .side-menu__item:focus,
.icontext-menu .side-menu__item:hover {
  background: #f3f6f8;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  text-decoration: none;
}

.icontext-menu .side-menu__item.active .side-menu__icon,
.icontext-menu .side-menu__item:focus .side-menu__icon,
.icontext-menu .side-menu__item:hover .side-menu__icon {
  fill: #664dc9 !important;
}

.icontext-menu .side-menu__item .fe-chevron-right {
  font-size: 0.95rem;
}

.icontext-menu.sidenav-toggled .side-menu__item:focus {
  background: transparent !important;
}

.icontext-menu.sidenav-toggled .side-menu__item:focus .side-menu__icon {
  fill: #664dc9;
}

.icontext-menu.sidenav-toggled1 .side-menu__item:focus {
  background: #f3f6f8 !important;
}

.icontext-menu.sidenav-toggled1 .side-menu__item:focus .side-menu__icon {
  fill: #fff;
}

@media (min-width: 768px) {
  .icontext-menu.app.sidebar-mini.sidenav-toggled .side-menu__label {
    background: transparent;
    box-shadow: none;
    display: none;
    font-size: 12px;
    left: 0;
    margin: 0;
    min-width: 100%;
    opacity: 1;
    padding: 0;
    position: relative;
  }
}

.icontext-menu .slide-item.active,
.icontext-menu .slide-item:focus,
.icontext-menu .slide-item:hover {
  text-decoration: none;
}

.icontext-menu .slide.is-expanded a.slide-item {
  transition: all 0.3s;
}

.icontext-menu .slide.is-expanded a.slide-item:hover {
  margin-left: 5px;
  text-decoration: none;
  transition: all 0.3s;
}

.icontext-menu .side-menu .side-menu__icon {
  fill: #555b6d;
  font-size: 1.24rem;
  height: 36px;
  line-height: 30px;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  width: 36px;
}

.icontext-menu .side-menu__icon {
  flex: 0 0 auto;
  width: 25px;
}

.icontext-menu .side-menu__label {
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 400;
  height: auto;
  letter-spacing: 0.6px;
  opacity: 1;
  transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
  white-space: nowrap;
}

.icontext-menu .slide.is-expanded .slide-menu {
  display: block;
}

.icontext-menu .slide.is-expanded .angle {
  transform: rotate(90deg);
}

.icontext-menu .slide-menu {
  display: none;
  font-size: 0.8rem !important;
  padding: 0;
}

.icontext-menu .slide-item {
  align-items: center;
  color: #6c756e;
  display: flex;
  font-size: 13px;
  padding: 7px 14px 7px 44px;
}

.icontext-menu .slide-item .icon {
  margin-right: 5px;
}

.icontext-menu .angle {
  font-size: 12px;
  transform-origin: center;
  transition: transform 0.3s ease;
}

@media (min-width: 768px) {
  .icontext-menu.sidebar-mini.sidenav-toggled {
    right: 30px !important;
    top: 0.5rem;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .angle,
  .icontext-menu.sidebar-mini.sidenav-toggled .app-sidebar__user-designation,
  .icontext-menu.sidebar-mini.sidenav-toggled .app-sidebar__user-name,
  .icontext-menu.sidebar-mini.sidenav-toggled .user-info,
  .icontext-menu.sidebar-mini.sidenav-toggled .user-notification {
    display: none;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled
    .sidebar-mini.sidenav-toggled.user-notification:before {
    background: transparent;
    display: none;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .avatar-xl {
    font-size: 1rem;
    height: 3rem !important;
    line-height: 3rem;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    width: 3rem !important;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .sidebar-navs {
    display: none;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .app-sidebar__user {
    margin-top: 74px;
    right: 30px !important;
    top: 0.5rem;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .app-sidebar__user-avatar {
    height: 25px;
    width: 25px;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled
    .side-menu
    li
    .side-menu__item.active:before {
    display: none;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .app-sidebar__user {
    margin-bottom: 0;
    padding: 4px 0 0;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .profile-img {
    right: 19px;
    top: 0;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .app-content {
    margin-left: 100px;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .app-sidebar {
    left: 0;
    overflow: hidden;
    transition: left 0.3s ease, width 0.3s ease;
    width: 100px;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    overflow: visible;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .side-menu__item {
    overflow: hidden;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .side-menu__item:hover {
    overflow: visible;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled
    .side-menu__item:hover
    .side-menu__label {
    opacity: 1;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled
    .side-menu__item:hover
    + .slide-menu {
    opacity: 0;
    visibility: hidden;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .side-menu__label {
    background: #fff;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2);
    display: block;
    left: 50px;
    line-height: 1;
    margin-left: -3px;
    min-width: 180px;
    opacity: 0;
    padding: 12px 5px 12px 20px;
    position: absolute;
    top: 0;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .slide .side-menu__label {
    border-bottom-right-radius: 0;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .slide-menu {
    border-bottom-right-radius: 4px;
    box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2);
    left: 0;
    min-width: 180px;
    opacity: 0;
    position: absolute;
    transition: visibility 0.3s ease;
    visibility: hidden;
    z-index: 9;
  }

  .icontext-menu.app.sidebar-mini.sidenav-toggled .side-menu__item {
    border-left: 0;
    border-radius: 0;
    display: block;
    margin: 0 auto;
    padding: 10px 0;
    text-align: center;
  }

  .icontext-menu.app.sidebar-mini.sidenav-toggled .side-menu_label {
    display: block;
    font-size: 12px;
  }

  .icontext-menu.app.sidebar-mini.sidenav-toggled .side-menu__label {
    background: transparent;
    box-shadow: none;
    display: block;
    font-size: 12px;
    left: 0;
    margin: 0;
    min-width: 100%;
    opacity: 1;
    padding: 0;
    position: relative;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled .app-sidebar__user .avatar-md {
    margin: 0 auto;
  }

  .icontext-menu.app.sidebar-mini.sidenav-toggled .nav-badge {
    font-size: 11px;
    padding: 0.2rem 0.4rem;
    position: absolute;
    right: 28px;
    top: 8px;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    max-height: 100%;
    transition: left 0.3s ease, width 0.3s ease;
    width: 250px;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user {
    align-items: center;
    border-bottom: 1px solid #e6ebf1;
    display: inline-block;
    padding: 20px;
    width: 100%;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user
    .dropdown-menu {
    top: 10px !important;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1 .sidebar-navs {
    display: block;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user-avatar {
    display: inline-block;
    flex: 0 0 auto;
    margin-right: 15px;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user-name {
    color: #e5e9ec;
    display: inline-block;
    font-size: 17px;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 0;
    margin-top: 5px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user-name.text-sm {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    height: auto;
    margin: 0 !important;
    width: 100%;
  }

  .icontext-menu .app-sidebar__user-designation {
    color: #e5e9ec;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 5px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu {
    margin-bottom: 0;
    padding-bottom: 40px;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu__item {
    align-items: center;
    border-radius: 0.25rem;
    display: flex !important;
    display: -ms-flexbox;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.40625rem;
    margin: 0 10px 3px;
    padding: 3px 22px 3px 5px;
    position: relative;
    transition: border-start-color 0.3s ease, background-color 0.3s ease;
    vertical-align: middle;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-item.active {
    color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  }

  .icontext-menu .slide-item:focus,
  .icontext-menu .slide-item:hover {
    color: #b48ef4;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-item.active,
  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide.is-expanded
    a,
  .icontext-menu .slide-item:focus,
  .icontext-menu .slide-item:hover {
    text-decoration: none;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu
    .side-menu__icon {
    margin-right: 0;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu__label {
    display: inline-flex !important;
    min-width: inherit;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu__icon {
    background: transparent;
    font-size: 25px;
    height: 40px;
    line-height: 30px;
    margin-right: 7px;
    padding: 8px !important;
    text-align: center;
    vertical-align: middle;
    width: 40px;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-menu__label {
    display: inline-block;
    flex: 1 1 auto;
    font-size: 14px;
    position: unset;
    white-space: nowrap;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide.is-expanded
    .slide-menu {
    display: block;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide.is-expanded
    .angle {
    transform: rotate(90deg);
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .angle {
    display: inline-block;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-menu {
    box-shadow: none;
    display: none;
    font-size: 0.8rem !important;
    min-width: 0;
    opacity: inherit;
    padding: 0;
    position: unset;
    top: inherit;
    visibility: inherit;
    width: 100%;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-item {
    align-items: center;
    display: flex;
    font-size: 13px;
    padding: 7px 14px 7px 44px;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .slide-item
    .icon {
    margin-right: 5px;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .angle {
    font-size: 0.95;
    transform-origin: center;
    transition: transform 0.3s ease;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .app-sidebar__user
    img {
    font-size: 1.75rem;
    height: 4rem !important;
    line-height: 4rem;
    margin-bottom: 0.25rem !important;
    margin-top: 8px;
    width: 4rem !important;
  }

  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .user-info {
    display: inline-block;
  }

  .icontext-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar__user .user-pic {
    margin: 0 0 12px;
  }
}

.icontext-menu.app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
  position: relative;
}

.icontext-menu.app.sidebar-mini .side-menu_label {
  display: none;
}

.icontext-menu .dropdown-menu {
  border-radius: 0;
}

.icontext-menu .dropdown-menu.dropdown-menu-end {
  left: auto;
}

.icontext-menu .dropdown-item .fa,
.icontext-menu .dropdown-item .icon {
  vertical-align: middle;
}

.icontext-menu .app-title {
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: -30px -30px 30px;
  padding: 20px 30px;
}

.icontext-menu .app-title h1 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

.icontext-menu .app-title p {
  font-style: italic;
  margin-bottom: 0;
}

@media print {
  .icontext-menu .app-title {
    display: none;
  }
}

@media (max-width: 480px) {
  .icontext-menu .app-title {
    align-items: flex-left;
    flex-direction: column;
    margin: -15px -15px 15px;
    padding: 20px;
  }

  .icontext-menu .app-title p {
    display: none;
  }
}

.icontext-menu .app-breadcrumb {
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
  text-align: right;
  text-align: left;
  text-transform: capitalize;
}

@media (max-width: 480px) {
  .icontext-menu .app-breadcrumb {
    margin-top: 10px;
  }
}

@media print {
  .icontext-menu .tile {
    border: 1px solid #ddd;
  }
}

.icontext-menu.sidenav-toggled .app-sidebar__user .avatar-md {
  font-size: 1rem;
  line-height: 2rem;
}

.icontext-menu
  .app-sidebar
  .mCS-minimal.mCSB_scrollTools
  .mCSB_dragger
  .mCSB_dragger_bar {
  background: rgba(0, 0, 0, 0.05);
}

.icontext-menu .app-sidebar .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.icontext-menu .app-sidebar .mCSB_scrollTools .mCSB_draggerRail {
  background: hsla(0, 0%, 100%, 0.2);
}

.icontext-menu .slide-menu a:before {
  content: "";
  margin-right: 8px;
  opacity: 0.6;
  position: relative;
}

.icontext-menu.sidenav-toggled
  .main-wrapper
  .side-menu
  .side-menu__item
  .nav-badge {
  display: block !important;
  left: 57px;
  padding: 3px 5px !important;
  position: absolute;
  top: 5px;
}

.icontext-menu.sidenav-toggled
  .main-wrapper
  .side-menu
  .side-menu__item
  .nav-badge1 {
  display: none;
}

.icontext-menu .nav-badge {
  border-radius: 30px;
  font-size: 12px;
  padding: 0.4em 0.6em;
}

@media (max-width: 575.98px) {
  .icontext-menu .app-header .header-brand-img.mobile-logo {
    margin-left: 0;
    margin-top: 16px;
  }

  .icontext-menu .app-content .side-app {
    padding: 25px 0.75rem 0;
  }

  .icontext-menu .app-sidebar {
    margin-top: 76px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .icontext-menu .app-header .header-brand,
  .icontext-menu .app-header .header-brand-img.desktop-lgo {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .icontext-menu .footer {
    padding: 1.25rem;
  }

  .icontext-menu footer.footer {
    padding: 1.25rem 1.25rem 1.25rem 250px;
  }

  .icontext-menu.sidenav-toggled footer.footer {
    padding: 1.25rem 1.25rem 1.25rem 70px;
  }
}

@media (max-width: 768px) and (min-width: 481px) {
  .icontext-menu .header-brand {
    margin-left: 0;
    min-width: auto !important;
  }
}

@media (max-width: 767px) {
  .icontext-menu .app-sidebar__logo {
    display: none;
  }
}

@media (min-width: 768px) {
  .icontext-menu .app-header .header-brand {
    display: none;
  }

  .icontext-menu .header.top-header {
    padding-left: 250px;
  }

  .icontext-menu.sidenav-toggled .header.top-header {
    padding-left: 70px;
  }

  .icontext-menu.sidenav-toggled .app-sidebar__logo {
    border-left: 0 !important;
    padding: 11px 12px;
    width: 100px;
  }

  .icontext-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
    border-right: 1px solid #e6ebf1 !important;
  }
}

.icontext-menu .app-sidebar__logo {
  background: #fff;
  border-bottom: 1px solid #e6ebf1;
  border-right: 1px solid #e6ebf1;
  padding: 14px 20px;
  position: fixed;
  text-align: center;
  transition: left 0.3s ease, width 0.3s ease;
  width: 250px;
  z-index: 999999;
}

.icontext-menu .close-toggle {
  display: none;
}

.icontext-menu .app-sidebar__toggle a i {
  line-height: 1.5;
  margin-top: 0;
}

@media (min-width: 768px) {
  .icontext-menu .header-brand-img.dark-logo,
  .icontext-menu .header-brand-img.darkmobile-logo,
  .icontext-menu .header-brand-img.mobile-logo,
  .icontext-menu.sidenav-toggled .header-brand-img.desktop-lgo {
    display: none;
  }

  .icontext-menu.sidenav-toggled .header-brand-img.mobile-logo {
    display: flex;
    margin: 0 auto;
  }

  .icontext-menu.sidenav-toggled.sidenav-toggled1
    .header-brand-img.mobile-logo {
    display: none;
  }

  .icontext-menu.sidenav-toggled.sidenav-toggled1
    .header-brand-img.desktop-lgo {
    display: flex;
    margin: 5px auto 0;
  }

  .icontext-menu .app-sidebar.app-sidebar3 {
    top: 74px;
  }

  .icontext-menu .app-sidebar.app-sidebar2 {
    box-shadow: none;
    height: 73px;
    z-index: 9999;
  }

  .icontext-menu.sidenav-toggled .app-sidebar-help .help-dropdown {
    display: none;
  }

  .icontext-menu.sidenav-toggled.sidenav-toggled1
    .app-sidebar-help
    .help-dropdown {
    display: flex;
  }

  .icontext-menu.sidenav-toggled .app-sidebar-help,
  .icontext-menu.sidenav-toggled .app-sidebar .side-item.side-item-category,
  .icontext-menu.sidenav-toggled .side-badge {
    display: none;
  }

  .icontext-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar-help {
    width: 250px;
  }

  .icontext-menu.app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    margin-right: 0;
  }

  .icontext-menu.app.sidebar-mini.sidenav-toggled
    .slide
    a.side-menu__item.active:after {
    top: 20px;
  }

  .icontext-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar-help,
  .icontext-menu.sidenav-toggled.sidenav-toggled1
    .app-sidebar
    .side-item.side-item-category,
  .icontext-menu.sidenav-toggled.sidenav-toggled1 .side-badge {
    display: block;
  }

  .icontext-menu.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
    border-right: 1px solid #e6ebf1;
    width: 250px;
  }
}

.icontext-menu .app-sidebar.app-sidebar3 {
  padding-bottom: 70px;
}

.icontext-menu .app-sidebar-help {
  background: #fff;
  border-top: 1px solid #e6ebf1;
  bottom: 0;
  position: fixed;
  width: 250px;
  z-index: 11;
}

.icontext-menu .app-sidebar-help .header-icon {
  height: 30px;
  width: 30px;
}

.icontext-menu .sub-slide-item {
  align-items: center;
  color: #6c756e;
  display: flex;
  font-size: 12px;
  height: 30px;
  height: 28px !important;
  list-style: none;
  padding: 19px 0 19px 60px !important;
  position: relative;
}

.icontext-menu .sub-slide-menu {
  display: none;
  font-size: 0.8rem !important;
  list-style: none;
  padding: 0;
}

.icontext-menu .slide-menu .sub-slide.active.is-expanded {
  background: hsla(0, 0%, 100%, 0.09);
}

.icontext-menu .sub-slide.is-expanded .sub-slide-menu {
  display: block;
}

.icontext-menu .sub-slide.is-expanded .sub-slide-menu li:last-child {
  padding-bottom: 10px;
}

.icontext-menu .sub-side-menu__item {
  color: #6c756e;
  display: block;
  font-size: 13px;
  height: 40px !important;
  line-height: 40px;
  padding-left: 44px !important;
  padding-right: 20px !important;
}

.icontext-menu .slide a.sub-side-menu__item {
  transition: all 0.3s;
}

.icontext-menu .slide a.sub-side-menu__item:hover {
  margin-left: 5px;
  text-decoration: none;
  transition: all 0.3s;
}

.icontext-menu .sub-angle {
  float: right;
  line-height: 40px;
}

.icontext-menu .help-dropdown {
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe !important;
}

.icontext-menu .slide.is-expanded a.sub-side-menu__item {
  position: relative;
}

.icontext-menu .app-sidebar__user .user-pic img {
  border: 3px solid #f8f8fc;
  box-shadow: 0 0 0 4px #eeeef3;
  height: 58px;
  margin-top: 8px;
  width: 58px;
}

.icontext-menu .app-sidebar__user .user-pic img:after {
  background-color: #8760fb;
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.icontext-menu .sidemenu-footer .icon {
  margin: 5px;
  padding: 7px 14px;
  position: relative;
  text-align: center;
}

.icontext-menu .sidemenu-footer .header-icons {
  fill: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  height: 24px;
  width: 24px;
}

.icontext-menu .tooltip {
  z-index: 999 !important;
}

.icontext-menu .profile-status {
  border: 2px solid #fff;
  border-radius: 50px;
  bottom: 0;
  content: "";
  height: 12px;
  position: absolute;
  right: 80px;
  top: 51px;
  width: 12px;
}

.icontext-menu .app-sidebar .side-item.side-item-category {
  color: #9993b5;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin: 15px 0;
  padding: 0 20px;
  text-transform: uppercase;
}

.icontext-menu .app-sidebar .side-item.side-item-category:not(:first-child) {
  margin-bottom: 15px;
  margin-top: 25px;
}

.icontext-menu .app-sidebar__user .user-info .user-name {
  font-size: 15px;
  margin-bottom: 2px;
}

.icontext-menu .profile-dropdown .nav-link {
  padding-left: 5px;
}

.icontext-menu .profile-dropdown img {
  border: 2px solid #f8f8fc;
  box-shadow: 0 2px 3px rgba(4, 4, 7, 0.1);
  height: 35px;
  width: 35px;
}

.icontext-menu .user-pro-body .dot-label {
  height: 6px;
  width: 6px;
}

.icontext-menu .sidebar-navs a {
  background: #fff;
  color: #68798b !important;
  padding: 1rem !important;
}

.icontext-menu .app-sidebar .sidebar-navs {
  padding: 10px 0 0;
}

.icontext-menu .sidebar-navs .nav li:last-child a {
  margin-right: 0 !important;
}

.icontext-menu .sidebar-navs .header-icons {
  fill: #5c678f;
  color: #5c678f;
  font-size: 20px;
  vertical-align: middle;
}

.icontext-menu .slide.active.is-expanded .side-menu__item {
  border-radius: 50px;
  color: #49b8e5, #1e98d4, #2a92d0, #3a88c8, #507cbe;
  text-decoration: none;
}

.icontext-menu.app.sidebar-mini.sidenav-toggled
  .slide.active.is-expanded
  .side-menu__item {
  border-radius: 0;
}

@media (min-width: 767px) {
  .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    max-height: 100%;
  }
}

.icontext-menu .sub-slide-item2 {
  font-size: 12px;
  padding: 19px 0 19px 70px !important;
}

.icontext-menu .sub-slide-item2,
.icontext-menu .sub-slide-menu2 .sub-slide-item2 {
  align-items: center;
  display: flex;
  height: 28px !important;
  list-style: none;
  position: relative;
}

.icontext-menu .sub-slide-menu2 .sub-slide-item2 {
  font-size: 11px;
  padding: 19px 0 19px 85px !important;
}

.icontext-menu .sub-slide-menu2 {
  display: none;
}

.icontext-menu .sub-slide2.is-expanded .sub-slide-menu2 {
  display: block;
}

.icontext-menu .sub-angle2 {
  position: absolute;
  right: 20px;
  top: 13px;
  transform-origin: center;
  transition: transform 0.3s ease;
}

.icontext-menu .sub-slide2.is-expanded .sub-angle2 {
  transform: rotate(90deg);
}

.icontext-menu .sub-slide.is-expanded .sub-angle {
  font-size: 12px;
  transform: rotate(90deg);
}

.icontext-menu .side-menu__item .side-badge {
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 0.25em 0.4em !important;
}

@media (min-width: 768px) {
  .icontext-menu .app.sidebar-mini.sidenav-toggled .side-menu__label {
    background: transparent;
    box-shadow: none;
    display: block;
    font-size: 14px;
    left: 0;
    margin: 0;
    min-width: 100%;
    opacity: 1;
    padding: 0;
    position: relative;
  }
}

.icontext-menu.dark-mode .side-menu__icon {
  fill: #ccd4e0 !important;
  color: #ccd4e0 !important;
}

@media (min-width: 768px) {
  .icontext-menu.rtl.app.sidenav-toggled .app-content {
    margin-right: 0 !important;
  }

  .icontext-menu.rtl.app.sidenav-toggled .app-sidebar {
    right: 250px !important;
  }
}

@media (max-width: 767px) {
  .icontext-menu.rtl.app .app-sidebar {
    right: -265px !important;
  }

  .icontext-menu.rtl.app.sidenav-toggled .app-content {
    margin-right: 0;
  }

  .icontext-menu.rtl.app.sidenav-toggled .app-sidebar {
    right: 0 !important;
  }
}

.icontext-menu.rtl .app-sidebar .ps__thumb-y {
  left: 0 !important;
}

.icontext-menu.rtl .app-content {
  transition: margin-right 0.3s ease !important;
}

@media (min-width: 768px) {
  .icontext-menu.rtl .app-content {
    margin-right: 250px !important;
  }
}

.icontext-menu.rtl .app-header {
  transition: padding-right 0.3s ease;
}

@media (min-width: 768px) {
  .icontext-menu.rtl .app-header {
    padding-right: 250px !important;
  }

  .icontext-menu.rtl.sidebar-mini.sidenav-toggled .app-header {
    padding-right: 120px !important;
  }
}

.icontext-menu.rtl .app-sidebar__toggle {
  border-left: 1px solid #e6ebf1 !important;
  margin-right: -12px !important;
}

.icontext-menu.rtl .app-search {
  margin-left: 15px !important;
}

.icontext-menu.rtl .app-search__input {
  padding-left: 30px !important;
}

.icontext-menu.rtl .app-search__button {
  left: 0;
}

.icontext-menu.rtl .app-notification__icon {
  padding-left: 10px !important;
}

.icontext-menu.rtl .app-sidebar {
  border-left: 1px solid #e6ebf1 !important;
  right: 0 !important;
  transition: right 0.3s ease, width 0.3s ease !important;
}

.icontext-menu.rtl .app-sidebar__user-avatar {
  margin-left: 15px !important;
}

.icontext-menu.rtl .side-menu__item {
  padding: 3px 5px 3px 22px !important;
}

.icontext-menu.rtl .slide.is-expanded a.slide-item:hover {
  margin-right: 5px !important;
}

.icontext-menu.rtl .slide-item {
  color: #1a1630;
}

@media (min-width: 768px) {
  .icontext-menu.rtl.sidebar-mini.sidenav-toggled,
  .icontext-menu.rtl.sidebar-mini.sidenav-toggled .app-sidebar__user {
    left: 30px !important;
  }

  .icontext-menu.rtl.sidebar-mini.sidenav-toggled .profile-img {
    left: 19px !important;
  }

  .icontext-menu.rtl.sidebar-mini.sidenav-toggled .app-content {
    margin-right: 100px !important;
  }

  .icontext-menu.rtl.sidebar-mini.sidenav-toggled .app-sidebar {
    right: 0 !important;
    transition: right 0.3s ease, width 0.3s ease !important;
  }

  .icontext-menu.rtl.sidebar-mini.sidenav-toggled .side-menu__label {
    border-bottom-left-radius: 4px !important;
    border-top-left-radius: 4px !important;
    margin-right: -3px !important;
    padding: 12px 20px 12px 5px !important;
    right: 50px !important;
  }

  .icontext-menu.rtl.sidebar-mini.sidenav-toggled .slide .side-menu__label {
    border-bottom-left-radius: 0 !important;
  }

  .icontext-menu.rtl.sidebar-mini.sidenav-toggled .slide-menu {
    border-bottom-left-radius: 4px !important;
    right: 0;
  }

  .icontext-menu.rtl.app.sidebar-mini.sidenav-toggled .side-menu__item {
    border-right: 0 !important;
  }

  .icontext-menu.rtl .side-menu__label {
    right: 0 !important;
  }
}

.icontext-menu.rtl.app.sidebar-mini.sidenav-toggled .nav-badge {
  left: 28px !important;
}

.icontext-menu.rtl.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
  transition: right 0.3s ease, width 0.3s ease !important;
}

.icontext-menu.rtl.sidebar-mini.sidenav-toggled.sidenav-toggled1
  .app-sidebar
  .side-menu__item {
  padding: 3px 5px 3px 22px !important;
}

.icontext-menu.rtl.sidebar-mini.sidenav-toggled.sidenav-toggled1
  .app-sidebar
  .side-menu
  .side-menu__icon {
  margin-left: 0 !important;
}

.icontext-menu.rtl.sidebar-mini.sidenav-toggled.sidenav-toggled1
  .app-sidebar
  .side-menu__icon {
  height: 36px !important;
  margin-left: 7px !important;
  width: 36px !important;
}

.icontext-menu.rtl .slide-item {
  padding: 7px 44px 7px 14px !important;
}

.icontext-menu.rtl .slide-item .icon {
  margin-left: 5px !important;
}

.icontext-menu.rtl .dropdown-menu.dropdown-menu-end {
  right: auto !important;
}

.icontext-menu.rtl .app-breadcrumb {
  text-align: left !important;
  text-align: right !important;
}

.icontext-menu.rtl .slide-menu a:before {
  margin-left: 8px !important;
}

.icontext-menu.rtl.sidenav-toggled
  .main-wrapper
  .side-menu
  .side-menu__item
  .nav-badge {
  right: 57px !important;
}

@media (max-width: 575.98px) {
  .icontext-menu.rtl .app-header .header-brand-img.mobile-logo {
    margin-right: 0 !important;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .icontext-menu.rtl .app-header .header-brand,
  .icontext-menu.rtl .app-header .header-brand-img.desktop-lgo {
    margin-right: 0 !important;
  }
}

@media (min-width: 768px) {
  .icontext-menu.rtl footer.footer {
    padding: 1.25rem 250px 1.25rem 1.25rem !important;
  }

  .icontext-menu.rtl .sidenav-toggled footer.footer {
    padding: 1.25rem 70px 1.25rem 1.25rem !important;
  }
}

@media (max-width: 768px) and (min-width: 481px) {
  .icontext-menu.rtl .header-brand {
    margin-right: 0 !important;
  }
}

@media (min-width: 768px) {
  .icontext-menu.rtl .header.top-header {
    padding-right: 250px !important;
  }

  .icontext-menu.rtl.sidenav-toggled .header.top-header {
    padding-right: 70px !important;
  }

  .icontext-menu.rtl.sidenav-toggled .app-sidebar__logo {
    border-right: 0 !important;
    padding: 14px 12px;
  }

  .icontext-menu.rtl.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
    border-left: 1px solid #e6ebf1 !important;
  }
}

.icontext-menu.rtl .app-sidebar__logo {
  border-left: 1px solid #e6ebf1 !important;
  transition: right 0.3s ease, width 0.3s ease !important;
}

@media (min-width: 768px) {
  .icontext-menu.rtl.app.sidebar-mini.sidenav-toggled
    .side-menu
    .side-menu__icon {
    margin-left: 0 !important;
  }

  .icontext-menu.rtl .app-sidebar__logo {
    border-left: 1px solid #e6ebf1 !important;
  }
}

.icontext-menu.rtl .sub-slide-item {
  padding: 19px 60px 19px 0 !important;
}

.icontext-menu.rtl .sub-slide-menu {
  padding-right: 0 !important;
}

.icontext-menu.rtl .sub-side-menu__item {
  padding-left: 20px !important;
  padding-right: 44px !important;
}

.icontext-menu.rtl .slide a.sub-side-menu__item {
  transition: all 0.3s !important;
}

.icontext-menu.rtl .slide a.sub-side-menu__item:hover {
  text-decoration: none !important;
  transition: all 0.3s !important;
}

.icontext-menu.rtl .sub-angle {
  float: left !important;
}

.icontext-menu.rtl .slide a.sub-side-menu__item:hover {
  margin-right: 5px !important;
}

.icontext-menu.rtl .sub-angle {
  float: left;
  line-height: 40px !important;
}

.icontext-menu.rtl .app-sidebar__user .user-pic img:after {
  right: 0 !important;
}

.icontext-menu.rtl .profile-status {
  left: 80px !important;
}

.icontext-menu.rtl .profile-dropdown .nav-link {
  padding-right: 5px !important;
}

.icontext-menu.rtl .sidebar-navs .nav li:last-child a {
  margin-left: 0 !important;
}

.icontext-menu.rtl .sub-slide-item2 {
  padding: 19px 70px 19px 0 !important;
}

.icontext-menu.rtl .sub-slide-menu2 .sub-slide-item2 {
  padding: 19px 85px 19px 0 !important;
}

.icontext-menu.rtl .sub-angle2 {
  left: 20px !important;
}

@media (min-width: 768px) {
  .icontext-menu.rtl.app.sidebar-mini.sidenav-toggled .side-menu__label {
    right: 0 !important;
  }
}

.icontext-menu.rtl .app-sidebar__toggle {
  border-right: 0 solid #e6ebf1 !important;
}

@media (min-width: 768px) {
  .icontext-menu.rtl .app-header.header {
    padding-left: 0 !important;
  }

  .icontext-menu.rtl.sidebar-mini.sidenav-toggled .app-content {
    margin-left: 0 !important;
    margin-right: 120px !important;
  }
}

.icontext-menu.rtl.app.sidebar-mini.icontext-menu .app-content {
  margin-left: 0 !important;
}

.icontext-menu.rtl.sidenav-toggled.sidenav-toggled1 .header-brand-img {
  height: 3rem;
}

.icontext-menu.rtl.sidenav-toggled .side-menu__label {
  left: 0 !important;
  margin: 0 auto !important;
  right: 0 !important;
  text-align: center;
}

.icontext-menu.rtl.sidebar-mini.sidenav-toggled .side-menu__label {
  padding: 0 !important;
}

.icontext-menu.rtl.app.sidebar-mini.sidenav-toggled .side-menu__item {
  padding: 12px 5px !important;
}

.demo-gallery > ul {
  margin-bottom: 0;
}

.demo-gallery > ul > li {
  border: 0;
  float: left;
  margin-bottom: 15px;
  width: 200px;
}

.demo-gallery > ul > li a {
  border: 3px solid #fff;
  border-radius: 3px;
  display: block;
  float: left;
  overflow: hidden;
  position: relative;
}

.demo-gallery > ul > li a > img {
  height: 100%;
  transform: scaleX(1);
  transition: transform 0.15s ease 0s;
  width: 100%;
}

.demo-gallery > ul > li a:hover > img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.demo-gallery > ul > li a:hover .demo-gallery-poster > img {
  opacity: 1;
}

.demo-gallery > ul > li a .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 0.15s ease 0s;
}

.demo-gallery > ul > li a .demo-gallery-poster > img {
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transition: opacity 0.3s ease 0s;
}

.demo-gallery > ul > li a:hover .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.5);
}

.demo-gallery .justified-gallery > a > img {
  height: 100%;
  transform: scaleX(1);
  transition: transform 0.15s ease 0s;
  width: 100%;
}

.demo-gallery .justified-gallery > a:hover > img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.demo-gallery .justified-gallery > a:hover .demo-gallery-poster > img {
  opacity: 1;
}

.demo-gallery .justified-gallery > a .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 0.15s ease 0s;
}

.demo-gallery .justified-gallery > a .demo-gallery-poster > img {
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transition: opacity 0.3s ease 0s;
}

.demo-gallery .justified-gallery > a:hover .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.5);
}

.demo-gallery .video .demo-gallery-poster img {
  height: 48px;
  margin-left: -24px;
  margin-top: -24px;
  opacity: 0.8;
  width: 48px;
}

.demo-gallery.dark > ul > li a {
  border: 3px solid #04070a;
}

.home .demo-gallery {
  padding-bottom: 80px;
}

.carousel-inner > .item > a > img,
.carousel-inner > .item > img {
  width: 100%;
}

.demo-gallery a,
.demo-gallery a:hover {
  border: 1px solid transparent;
}

.demo-gallery a:hover {
  background: transparent;
}

.gallery-list {
  list-style-type: none;
  margin: -5px -5px 15px;
  padding: 0;
}

.gallery-list > li {
  border: 1px solid #efefef;
  float: left;
  margin: 10px;
  padding: 10px;
  width: 23%;
}

.gallery-list > li .image-container {
  background: #fff;
  border-radius: 5px;
  position: relative;
}

.gallery-list > li .image {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  padding-top: 60%;
  position: relative;
}

.gallery-list > li .image img {
  left: 0;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.gallery-list > li .btn-list {
  left: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.gallery-list > li .info {
  padding: 8px 10px;
}

.gallery-list > li .info * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gallery-list > li .info h5 {
  font-size: 12px;
  margin: 0;
}

@media (max-width: 991px) {
  .gallery-list > li {
    width: 45.33%;
  }
}

@media (max-width: 480px) {
  .gallery-list > li {
    width: 100%;
  }
}

.pricing {
  border-radius: 7px;
  color: #fff;
}

.pricing1 {
  border-radius: 7px;
  color: #8e9cad;
}

.pricing .card-category {
  background: hsla(0, 0%, 100%, 0.34);
  border-radius: 5px;
  color: #fff;
  padding: 7px 0;
}

.pricing .list-unstyled li {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  padding: 7px 0;
}

.pricing1 .list-unstyled li {
  border-bottom: 1px solid #e6ebf1;
  padding: 7px 0;
}

.pricing1 .card-category {
  background: hsla(0, 0%, 100%, 0.3);
  border-radius: 7px 7px 0 0;
  color: #fff;
  padding: 20px 0;
}

.pricing-table {
  border-radius: 0;
  margin: 0 auto;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  width: 100%;
}

.pricing-table:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.pricing-table:hover > .panel-footer-landing {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease;
}

.pricing-table:hover > .panel > .panel-body-landing {
  background: #38a01e;
  -webkit-transition: all 0.3s ease;
}

.pricing-table:hover > .panel > .panel-heading-landing-box {
  background: #fff !important;
  color: #333 !important;
  -webkit-transition: all 0.3s ease;
}

.pricing-table:hover > .panel > .controle-header,
.pricing-table:hover > .panel > .panel-footer {
  background: #38a01e !important;
  -webkit-transition: all 0.3s ease;
}

.pricing-table:hover > .panel > .panel-footer {
  text-align: center;
}

.pricing-table:hover > .panel > .panel-footer > .btn {
  border: 1px solid #fff !important;
  -webkit-transition: all 0.3s ease;
}

.btn-price:hover {
  background: #fff !important;
  color: #38a01e !important;
  -webkit-transition: all 0.3s ease;
}

.pricing-table:hover > .panel > .controle-header > .panel-title-landing {
  color: #fff !important;
  font-size: 35px;
  -webkit-transition: all 0.3s ease;
}

.pricing-table:hover > .panel > .panel-body-landing > .table > tbody > tr > td {
  font-weight: 500;
  -webkit-transition: all 0.3s ease;
}

.pricing-table > .panel > .controle-header > .panel-title-landing {
  color: #000 !important;
  font-size: 35px;
}

.panel-body-landing:hover > .table > tbody > tr > td {
  color: #fff !important;
  -webkit-transition: all 0.3s ease;
}

.panel-heading-landing {
  background: #f7f7f7 !important;
  border: 2px solid #38a01e !important;
  border-bottom: none !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  margin-top: 20px;
  padding: 20px !important;
  text-align: center;
}

.col-sm-4 .pricing-table .table td {
  padding: 0.75rem;
  text-align: left;
  vertical-align: top;
}

.panel-heading-landing-box {
  background: #38a01e !important;
  border-top: none !important;
  color: #fff !important;
  font-size: 16px !important;
  text-align: center;
}

.panel-title-landing {
  color: #38a01e !important;
  font-size: 35px;
  font-weight: 700;
}

.panel-body-landing {
  background: #fff;
  border: 2px solid #38a01e !important;
  border-bottom: none !important;
  border-top: none !important;
  text-align: center;
}

.panel-footer-landing {
  background: #f7f7f7;
  border: 2px solid #38a01e !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top: none !important;
  text-align: center;
}

.plan-card .plan-icon {
  border: 4px solid rgba(242, 246, 249, 0.55);
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 35px;
  height: 75px;
  line-height: 67px;
  overflow: hidden;
  transition: all 0.3s;
  width: 75px;
}

.plan-card .plan-price {
  border-top: 1px solid #e6ebf1;
  padding-top: 20px;
}

.plan-card .plan-price span {
  font-size: 15px;
  vertical-align: middle;
}

.plan-card .plan-features p {
  border-bottom: 1px solid #e6ebf1;
  line-height: 40px;
  margin-bottom: 0;
}

.plan-card .plan-features p:first-child {
  border-top: 1px solid #e6ebf1;
}

.panel.price,
.panel.price > .panel-heading {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}

.panel.price h3 {
  margin-bottom: 0;
  padding: 20px 0;
}

.panel-heading {
  background: #fff;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 10px 15px;
}

.price .list-group-item {
  border-bottom: 1px solid hsla(0, 0%, 98%, 0.5);
}

.panel.price {
  border: 1px solid #e6ebf1;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.panel.price .list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.panel.price .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.price .panel-footer {
  border-bottom: 0;
}

.price.panel-color > .panel-body,
.price .panel-footer {
  background-color: #fff;
}

.panel-body .lead {
  font-size: 20px;
  margin-bottom: 0;
  padding: 10px 0;
}

.panel-body .lead strong {
  font-size: 40px;
  margin-bottom: 0;
}

.panel-footer {
  background-color: #f7f7f7;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: 1px solid #e6ebf1;
  padding: 10px 15px;
}

.panel.price .btn {
  border: 0;
  border-radius: 25px;
  box-shadow: inset 0 -1px 0 rgba(50, 50, 50, 0.2);
}

@media (min-width: 992px) {
  .plan-card.active {
    box-shadow: 0 10px 10px 20px rgba(179, 186, 212, 0.09),
      10px 10px 15px -5px rgba(176, 184, 214, 0.16) !important;
    font-size: 16px;
    height: 100%;
    margin-left: -20px;
    margin-top: -25px;
    width: 120%;
    z-index: 1;
  }

  .plan-card.active .plan-features p {
    line-height: 50px;
  }

  .plan-card.active .plan-card .plan-price span {
    font-size: 18px;
  }

  .plan-card.active .display-4 {
    font-size: 3.5rem;
  }

  .pricing-sctive .display-3 {
    font-size: 4rem;
  }

  .plan-card.active h6 {
    font-size: 16px;
  }

  .pricing-sctive {
    z-index: 1;
  }

  .panel.price.active,
  .pricing-sctive {
    font-size: 16px;
    height: 100%;
    margin-left: -20px;
    margin-top: -25px;
    width: 120%;
  }

  .panel.price.active {
    border-right: 1px !important;
    box-shadow: 0 10px 10px 20px rgba(179, 186, 212, 0.09),
      10px 10px 15px -5px rgba(176, 184, 214, 0.08);
    position: relative;
    z-index: 9;
  }

  .g-0 .panel.price {
    border-right: 0;
  }

  .g-0 .panel.price.active .panel-body .lead strong {
    font-size: 50px;
  }

  .g-0 .panel.price.active .price-title {
    font-size: 26px;
  }

  .g-0 .panel.price:last-child {
    border-right: 1px;
  }
}

.price-svg {
  position: relative;
}

.price-svg .price-title {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 29%;
}

.price-svg svg {
  height: 180px;
}

.pricing-tabs ul.nav-price {
  background: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 5px;
  display: inline-flex;
  margin: 30px auto 40px;
  overflow: hidden;
  text-align: center;
}

.pricing-tabs ul.nav-price li {
  display: block;
}

.pricing-tabs ul.nav-price li a {
  background: #fff;
  border-radius: 0;
  display: block;
  font-size: 16px;
  padding: 12px 22px;
  transition: 0.3s;
}

.pricing-tabs ul.nav-price li a.active {
  color: #fff;
  transition: 0.3s;
}

.leading-loose li {
  padding: 5px 0;
}

.leading-loose li:last-child {
  border-bottom: 0;
}

.pricing-tabs .pri-tabs-heading2 ul.nav-price {
  background: #f3f4f7;
  border: 1px solid #e6ebf1;
  border-radius: 0;
  border-radius: 50px;
  display: inline-flex;
  margin: 30px auto 40px;
  text-align: center;
}

.pricing-tabs .pri-tabs-heading2 ul.nav-price li {
  display: block;
}

.pricing-tabs .pri-tabs-heading2 ul.nav-price li a {
  background: #f3f4f7;
  border-radius: 50px;
  display: block;
  font-size: 16px;
  font-weight: 700;
  padding: 12px 40px;
  transition: 0.3s;
}

.pricing-tabs .pri-tabs-heading3 ul.nav-price {
  border-radius: 50px;
}

.pricing-tabs .pri-tabs-heading3 ul.nav-price li:first-child a {
  border-left: 0;
  border-radius: 50px 0 0 50px;
}

.pricing-tabs .pri-tabs-heading3 ul.nav-price li:last-child a {
  border-radius: 0 50px 50px 0;
}

.pricing-tabs .pri-tabs-heading3 ul.nav-price li a {
  border-left: 1px solid #e6ebf1;
}

.pricing-svg {
  align-items: center;
  border: 4px solid rgba(230, 234, 239, 0.34);
  border-radius: 50px;
  height: 70px;
  line-height: 51px;
  margin: 0 auto;
  padding: 5px;
  width: 70px;
}

.profile-cover {
  width: 100%;
}

.profile-cover .cover {
  border: 10px solid #fff;
  border-radius: 7px;
  position: relative;
}

.profile-cover .cover .inner-cover {
  height: auto;
  overflow: hidden;
}

.profile-cover .cover .inner-cover img {
  border: 1px solid transparent;
  text-align: center;
  width: 100%;
}

.profile-cover .cover .inner-cover .cover-menu-mobile {
  position: absolute;
  right: 10px;
  top: 10px;
}

.profile-cover .cover .inner-cover .cover-menu-mobile button i {
  font-size: 17px;
}

.profile-cover .cover ul.cover-menu {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.24);
  bottom: 0;
  float: left;
  left: 1px;
  margin: 0;
  overflow: hidden;
  padding-left: 150px;
  position: absolute;
  width: 100%;
}

.profile-cover .cover ul.cover-menu li {
  display: block;
  float: left;
  height: 40px;
  line-height: 40px;
  margin-right: 0;
  padding: 0 10px;
  transition: all 0.3s;
}

.profile-cover .cover ul.cover-menu li:hover {
  background-color: rgba(0, 0, 0, 0.44);
}

.profile-cover .cover ul.cover-menu li.active {
  background-color: rgba(0, 0, 0, 0.64);
}

.profile-cover .cover ul.cover-menu li a {
  color: #fff;
  display: block;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  text-decoration: none;
}

.profile-cover .cover ul.cover-menu li a i {
  font-size: 18px;
}

.profile-cover .profile-body {
  margin: 0 auto 10px;
  position: relative;
}

.profile-cover .profile-timeline {
  padding: 15px;
}

.img-post {
  height: 30px;
  width: 30px;
}

.img-post2 {
  height: 50px;
  width: 50px;
}

.widget-user .wideget-user-icons a {
  border-radius: 100px;
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  margin-top: 0.3rem;
  text-align: center;
  width: 2rem;
}

.dialog__input__title {
  font-size: 15px;
  color: #000;
  font-weight: 700 !important;
}

.responsive-dialog-title {
  font-size: 17px !important;
  color: #000;
}

.swal-overlay {
  z-index: 9999999;
}

.feedback__title,
.redeem__title {
  font-weight: 750;
  font-size: 16px;
}

.feedback__details,
.redeem__details {
  font-size: 15px;
}

.categoryImage {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.gift_category__title {
  font-size: 22px;
  color: #000000de;
  font-weight: 650 !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #664dc9 !important;
}

.dash5 {
  color: #fff !important;
  content: "";
  background-image: linear-gradient(90deg, #c94dbc, #ea84e7);
}

/* tabel image class */
.table_image {
  width: 60px;
  height: 60px;
  display: block;
  object-fit: cover;
  border-radius: 12px;
}

.page-link {
  color: black !important;
  font-size: 13px !important;
}

.accordion-button::after {
  flex-shrink: unset !important;
  width: unset !important;
  height: unset !important;
  margin-left: unset !important;
  content: "";
  background-image: unset !important;
  background-repeat: unset !important;
  background-size: unset !important;
  transition: unset !important;
}

.accordion-button:focus {
  border-color: unset !important;
  box-shadow: unset !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.accordion-item {
  border: unset;
}

/* switch css */
.MuiSwitch-colorPrimary.Mui-checked {
  color: #664dc9 !important;
}

.profileImage {
  width: 190px;
  height: 190px;
  border-width: 5px !important;
}

.profileImage img {
  object-fit: cover;
  width: 190px;
  height: 190px;
}

.hostProfileImage {
  border-radius: 50%;
  overflow: hidden;
}

.uTable tr:not(:last-child) {
  border-bottom: 1px solid #e6ebf1;
}

.uTable tr td:first-child {
  color: #664dc9;
}

.uTable td {
  padding: 11px 0px !important;
  font-size: 16px;
  font-weight: bold;
}

.borderCT {
  border-left: 1px solid #e6ebf1;
}

@media (max-width: 768px) {
  .borderCT {
    border-left: none;
  }
}

.imageCard,
.allDetails {
  border-radius: 10px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.sidenav-toggled ul.slide-menu {
  min-width: unset !important;
}

.slide-menu.open .side-menu__item.active svg {
  left: 4px !important;
}

.side-menu__item.active svg {
  left: 0px !important;
}

.slide.is-expanded > a,
.slide.is-expanded > a > svg {
  color: #664dc9 !important;
  fill: #664dc9 !important;
}

img {
  object-fit: cover !important;
}

.notFound {
  text-align: center;
  font-size: 15px !important;
  font-weight: 700 !important;
}

.optionListContainer {
  max-height: 150px;
  overflow: auto;
}

.slick-prev:before,
.slick-next:before {
  color: #664dc9 !important;
  opacity: 1 !important;
  font-size: 16px !important;
}

.slick-next {
  right: -17px !important;
}

.slick-prev {
  left: -17px !important;
}

/* .profile {
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border: 2px solid #00388c;
} */

.profile .slick-arrow {
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.profile:hover .slick-arrow {
  opacity: 1 !important;
  visibility: visible !important;
}

@media (max-width: 991px) {
  .slick-prev:before,
  .slick-next:before {
    color: #664dc9 !important;
    opacity: 1 !important;
    font-size: 22px !important;
  }

  .slick-next {
    right: 30px !important;
  }

  .slick-prev {
    left: 30px !important;
  }

  .mainSlide {
    width: 170px !important;
    margin: auto;
    display: unset !important;
  }
}

.dropdown-option-text {
  font-weight: 650;
  cursor: pointer;
}

.daterange::placeholder {
  color: #fff;
  text-align: center;
}

.daterange {
  padding-right: 0.75rem !important;
  cursor: pointer;
}

.styles-module_Editext__button__sxYQX {
  max-width: 0px !important;
  padding: 0 !important;
  font-size: 12px !important;
  border-radius: 3px !important;
  border-width: 0px !important;
  border-style: none !important;
  margin-left: 4px;
  background-color: #eaeaea;
}

.styles-module_Editext__input__2-M50 {
  height: 0px;
  width: 50% !important;
}

.page-item {
  margin-left: 5px !important;
}

/* dashboard style */

.mainDashBody {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.dashIcon {
  width: 75px;
  height: 75px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: #fff; */
  font-size: 32px;
}

.dashIcon-prime {
  background-color: #dfd9f7;
}

.dashIcon-dangur {
  background-color: #fdd4d4;
}

.dashIcon-success {
  background-color: #b9eebc;
}

.dashIcon-warning {
  background-color: #f7edd9;
}

.dashIcon-danger {
  background-color: #ffc9c9;
}

.dashMain .card-body {
  position: relative;
  padding: 0px !important;
  transition: 0.5s;
}

.dashMain .card-body-primary {
  border-top: 6px solid #6b52cc;
}

.dashMain .card-body-success {
  border-top: 6px solid #15854e;
}

.dashMain .card-body-warning {
  border-top: 6px solid #ffab00;
}

.dashMain .card-body-danger {
  border-top: 6px solid #ef4b4b;
}

.dashMain .card-body:hover::after {
  transform: scaleX(0);
}

.dashMain .card-body:hover::before {
  transform: scaleX(1);
}

.allTxt {
  position: relative;
  z-index: 111;
  padding: 25px;
}

.deg90 {
  rotate: 90deg;
  cursor: pointer;
  color: #9d9d9d;
}

.headSort:hover .deg90 {
  color: #6b52cc;
}

.headSort {
  cursor: pointer;
}

.total_text {
  font-size: "20px";
}

.copyCode {
  color: "000";
}

.copyCode:hover {
  color: #664dc9;
}

@media (max-width: 830px) {
  .total_text {
    font-size: 17px;
  }
}

.displayCountry {
  min-height: 50px;
  max-height: 100px;
  overflow: auto;
}

.displayCountry span {
  background-color: #60529f;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

/* werb paymaebt css */
.vip__card__container {
  border-image-slice: 30%;
  border-radius: 25px !important;
  width: auto;
  color: #fff;
  background-color: #6960dd;
  transition: 0.2s all ease-in-out;
  height: 300px;
  padding-top: 37px;
}

.vip__plan__type {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

.StripeElement {
  display: block;
  padding: 15px;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid #00000042;
  outline: 0;
  border-radius: 0;
  /* background: #e1dcf3;
   */
  background: #f8f7fb;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.stripe_pay_button {
  width: 80%;
  border-radius: 10px;
  padding: 10px;
  font-size: 19px;
}

.vip__card__container:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.stripe_app_name {
  font-size: 70px;
}

.stripe_card_height {
  align-items: center;
  height: 99vh;
}

.planListTag {
  color: #664dc9;
  font-size: 20px;
}

.stripe_button {
  width: 100%;
  height: 45px;
  border: 0;
  background: linear-gradient(
    160deg,
    rgba(156, 36, 196, 1) 0%,
    rgba(91, 31, 251, 1) 100%
  );
  border-radius: 0 0 24px 24px;
}

.planTag {
  background: linear-gradient(
    160deg,
    rgba(156, 36, 196, 1) 0%,
    rgba(91, 31, 251, 1) 100%
  );
  color: #fff;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  padding: 5px 10px !important;
  border-bottom-right-radius: 10px;
}

/* media quray  strpie page */

@media screen and (max-width: 768px) {
  .stripe_card_height {
    height: 99vh;
  }
}

@media screen and (max-width: 425px) {
  .stripe_card_height {
    height: 120vh;
  }

  .stripe_button {
    height: 46px;
  }

  .stripe_card_height {
    height: 99vh;
  }
}

@media screen and (max-width: 320px) {
  .planListTag {
    color: #664dc9;
    font-size: 14px;
  }

  .stripe_card_height {
    height: 80vh;
  }
}

/* Show Image CSS */

.openImages {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 900px;
  width: 100%;
}

#showImg {
  /* max-width: 100%; */
  /* max-height: 900px; */

  width: 400px;
}

.mainPage {
  background-color: #000;
  border: 12px solid #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  max-height: auto;
  position: relative;

  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.note {
  font-size: 18px;
  font-weight: bold;
  color: red;
}

.fadeIn_ {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  visibility: visible;
  transition: 0.25s;
}

.fadeOut_ {
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%) scale(0);
  transition: 0.25s;
}

.closeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  font-size: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 111111;
  top: -20px;
  right: -20px;
}

.sideBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  font-size: 25px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 111111;
}

#next {
  right: -30px;
}

#pre {
  left: -30px;
}

p,
li {
  font-weight: bold;
  font-size: 14px;
}

.animate__animated.animate__fadeInRight {
  --animate-duration: 0.3s;
}

.animate__animated.animate__fadeInLeft {
  --animate-duration: 0.3s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  width: 100%;
  height: 40px;
  padding: 0 5px;
}

.show {
  transition: 0.3s;
}

th,
td {
  vertical-align: middle !important;
}

.accordian-body {
  max-height: 300px !important;
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.mainLoader {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2392156863);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainLoader .lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.mainLoader .lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.mainLoader .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
